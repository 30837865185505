import React from 'react'
import style from './DummyPlayer.module.scss'
import cls from 'classnames'
import moment from 'moment'

const DEFAULT_BACKGROUND = require('assets/video-mock-reduced.png')

const DummyPlayer = ({ backgroundUrl, thumbnailUrlId = 0, className, children }) => {

  let thumbnailCls = null
  if (!backgroundUrl)
    thumbnailCls = `thumbnail-${(thumbnailUrlId || 0) + 1}`

  return (
    <main className={cls(style.main, className, thumbnailCls)} style={backgroundUrl && ({
        backgroundImage: `url("${backgroundUrl}")`
      })}>
      <div className={style.overlay}></div>
      <div className={style.mainContainer}>
        {children}
      </div>
    </main>
  )
}

export default DummyPlayer;
