import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import style from './SignInPage.module.scss'
import { Card, Button, Form, Input, Alert, Radio, Checkbox } from 'antd'
import { signIn, reset, setError } from './reducer'
import Icon from 'components/Icon'
import _ from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory, useRouteMatch } from 'react-router'
import routes from 'routes'
import { Link } from 'react-router-dom'
import { selectLocale } from 'components/language/reducer'

export const SignIn = () => {
  const [form] = Form.useForm()
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const { url, params } = useRouteMatch()
  const [spy, setSpy] = useState(false)
  const { saving, error } = useSelector(s => s.signIn)
  const { user } = useSelector(s => s.session)
  const onForgotPassword = useCallback(() => {
    if (url.startsWith('/admin'))
      history.push(`/admin${routes.forgotPassword}`)
    else
      history.push(`${url}/${routes.forgotPassword}`)
  }, [])
  const onFieldsChange = useCallback(() => {
    error && dispatch(setError())
  }, [error])
  const onSubmit = useCallback(async () => {
    try {
      let values = await form.validateFields()
      await dispatch(signIn(values))
      dispatch(reset())
    } catch (ex) {
      console.warn(ex)
    }
  }, [])
  const showPassword = useCallback(() => {
    setSpy(true)
  }, [])
  const hidePassword = useCallback(() => {
    setSpy(false)
  }, [])

  useEffect(() => {
    return () => dispatch(reset())
  }, [])

  useEffect(() => {
    if (params && params.lang) {
      if (params.lang.toLowerCase() === 'en') {
        dispatch(selectLocale('en'))
      }
      else if (params.lang.toLowerCase() === 'fr') {
        dispatch(selectLocale('fr'))
      }
    }
  }, [params.lang])

  return (
    <div className={style.container}>
      <div className={style.component}>
        <header className={style.header}>
          <h3 className={style.title}>
            <FormattedMessage id='signInPage.title' />
          </h3>
          <span className={style.desc}>
            <FormattedMessage id='signInPage.desc' />
          </span>
        </header>
        <Form
          validateMessages={{
            required: intl.formatMessage({ id: 'validation.required' }),
            types: {
              email: intl.formatMessage({ id: 'validation.invalidEmail' })
            }
          }}
          form={form}
          onFinish={onSubmit}
          onFieldsChange={onFieldsChange}>
          {error && (
            <Alert
              className={style.alert}
              type='error'
              message={intl.formatMessage({ id: error.code === 'auth/user-not-found' ? 'auth/wrong-password' : error.code })} />
          )}
          <Form.Item
            labelCol={{ xs: 24 }}
            label={intl.formatMessage({ id: 'signInPage.fields.email' })}
            className={style.formItem}
            rules={[{ required: true, type: 'email' }]}
            name='email'>
            <Input
              placeholder={intl.formatMessage({ id: 'signInPage.fields.placeholder.email' })}
              className={style.input} />
          </Form.Item>
          <Form.Item
            labelCol={{ xs: 24 }}
            label={intl.formatMessage({ id: 'signInPage.fields.password' })}
            className={style.formItem}
            rules={[{ required: true }]}
            name='password'>
            <div>
              <Input
                placeholder={intl.formatMessage({ id: 'signInPage.fields.placeholder.password' })}
                className={style.input}
                type={spy ? 'text' : 'password'} />
              <div className={style.fpc}>
                <Link to={routes.forgotPassword}>
                  <FormattedMessage id='signInPage.forgotPassword' />
                </Link>
              </div>
              {spy ? (
                <div className={style.spyIcon} onClick={hidePassword}><Icon name='eye' /></div>
              ) : (
                <div className={style.spyIcon} onClick={showPassword}><Icon name='eye' /></div>
              )}
            </div>
          </Form.Item>
          <Form.Item
            className={style.formItem}
            name='remember'
            valuePropName="checked">
            <Checkbox>
              {intl.formatMessage({ id: 'signInPage.fields.remember' })}
            </Checkbox>
          </Form.Item>
          <Button
            className={style.submitButton}
            onClick={onSubmit}
            loading={saving}
            type='primary'
            htmlType='submit'>
            <FormattedMessage id='signInPage.submit' />
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default memo(SignIn)
