import React from "react";

const UploadIcon = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M25.25 32.001C25.25 32.6913 24.6904 33.251 24 33.251C23.3096 33.251 22.75 32.6913 22.75 32.001H25.25ZM23.1161 7.11709C23.6043 6.62894 24.3957 6.62894 24.8839 7.11709L32.8388 15.072C33.327 15.5602 33.327 16.3517 32.8388 16.8398C32.3507 17.328 31.5592 17.328 31.0711 16.8398L24 9.76874L16.9289 16.8398C16.4408 17.328 15.6493 17.328 15.1612 16.8398C14.673 16.3517 14.673 15.5602 15.1612 15.072L23.1161 7.11709ZM22.75 32.001V8.00098H25.25V32.001H22.75Z" fill="#2F80ED" />
    <path d="M8 41.001H40" stroke="#2F80ED" stroke-width="2.5" stroke-linecap="round" />
  </svg>

);

export default UploadIcon;
