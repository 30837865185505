import React from "react";

const ProjectSelectDropdown = () => (
  <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.0625 1.3125L6.28953 6.08547L1.51656 1.3125" stroke="#1A202C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default ProjectSelectDropdown;
