import React from "react";

const ViewIcon = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4715 16C26.6901 19.5795 22.8724 24 16 24C9.12762 24 5.30993 19.5795 2.52847 16C5.30993 12.4205 9.12762 8 16 8C22.8724 8 26.6901 12.4205 29.4715 16ZM32 16C31.8307 16.2167 31.6552 16.4445 31.4729 16.6812L31.472 16.6823L31.4712 16.6834L31.4706 16.684C28.6838 20.3015 24.2936 26 16 26C7.70645 26 3.31624 20.3015 0.529348 16.684L0.529241 16.6839C0.346182 16.4463 0.169875 16.2174 0 16C0.16996 15.7824 0.346192 15.5537 0.529348 15.316C3.31624 11.6985 7.70645 6 16 6C24.2936 6 28.6838 11.6985 31.4706 15.316L31.4711 15.3165L31.4716 15.3171C31.6544 15.5544 31.8303 15.7828 32 16Z"
        fill="currentColor"
      />
      <ellipse
        cx="16.0001"
        cy="16.2759"
        rx="4.96552"
        ry="4.96552"
        fill="currentColor"
      />
    </svg>
  );
};

export default ViewIcon;
