import React, { useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./SharingContactsCard.module.scss";
import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Input,
  Modal,
  notification,
  Table,
  Tag,
} from "antd";
import _, { compact, differenceBy, uniqBy } from "lodash";
import AntIcon from "@ant-design/icons/lib/components/Icon";
import { reset, setVisibility } from "./reducer";
import { PageTitle } from "components/PageItems";
import { FormattedMessage, useIntl } from "react-intl";
import CloseIcon from "components/icons/CloseIcon";
import MemoButton from "components/buttons/MemoButton";
import LeftArrowIcon from "components/icons/LeftArrowIcon";
import { SELECTED_TAG_COLOR } from "../../tags/TagsTable";
import { TAG_COLOR } from "../../tags/TagsTable";
import SearchIcon from "components/icons/SearchIcon";
import CircleMinusIcon from "components/icons/CircleMinusIcon";
import PassedCircleIcon from "components/icons/PassedCircleIcon";
import { share } from "./reducer";
import SuccessIcon from "components/icons/SuccessIcon";
import { STATUS } from "../stats/reducer";

const SharingContactsCard = ({ handleBack, onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { items: contactsList } = useSelector((state) => state.contacts);
  const { items: tagsList } = useSelector((state) => state.tags);
  const { items: invitations } = useSelector((s) => s.memoStats);

  const completedInvitations = React.useMemo(() => {
    return invitations.filter(invitation => invitation.status === STATUS.COMPLETED)
  }, [invitations]);

  const [step, setStep] = React.useState("selecting");

  const [searchValue, setSearchValue] = React.useState("");
  const [selectedContacts, setSelectedContacts] = React.useState([]);
  const [skipChecked, setSkipChecked] = React.useState(true)

  const { form, memo, visible, saving, linkLoading, shareableLink } =
    useSelector((s) => s.memosShare);

  const contactsWithTags = React.useMemo(() => {
    return contactsList.map((contactItem) => {
      const tagIds = contactItem.tags;

      let stagedTags = [];
      if (tagIds && tagIds.length > 0) {
        stagedTags = compact(
          tagIds.map((tagId) =>
            tagsList.find((tagItem) => tagItem.id === tagId)
          )
        );
      }

      return {
        ...contactItem,
        tags: stagedTags,
      };
    });
  }, [contactsList, tagsList]);

  const filteredContacts = React.useMemo(() => {
    if (searchValue) {
      let stagedContacts = contactsWithTags.filter((contactItem) =>
        contactItem.email.toLowerCase().includes(searchValue.toLowerCase())
      );

      return stagedContacts;
    } else {
      return contactsWithTags;
    }
  }, [searchValue, contactsWithTags, tagsList]);

  const filteredContactsOptions = uniqBy(
    filteredContacts.map((contactItem) => ({ value: contactItem.email })),
    "value"
  );

  const renderEmpty = React.useMemo(() => {
    if (contactsList && contactsList.length > 0) {
      return (
        <p>
          <FormattedMessage id="admin.contactsPage.noResultFound" />
        </p>
      );
    }

    return (
      <p>
        <FormattedMessage id="admin.contactsPage.emptyList" />
      </p>
    );
  }, [contactsList, searchValue]);

  const handleToggleAll = () => {
    if (selectedContacts.length === filteredContacts.length) {
      setSelectedContacts([]);
    } else {
      setSelectedContacts(
        filteredContacts.map((contactItem) => contactItem.id)
      );
    }
  };

  const handleSelected = () => {
    setStep("selected");
  };

  const renderSuccessMessage = (message) => {
    return (
      <div className={style.successMessageContainer}>
        <div className={style.successIcon}>
          <SuccessIcon width="24px" height="24px" />
        </div>
        <span>{message}</span>
      </div>
    );
  };

  const renderTitle = React.useMemo(() => {
    if (step === "selecting" || step === "updating") {
      return <FormattedMessage id="navBar.contacts" />;
    } else if (step === "selected") {
      return (
        <FormattedMessage
          id="admin.memos.share.title"
          values={{
            name: memo.name,
            memoId: memo.id,
            projectId: memo.projectId,
          }}
        />
      );
    }
  }, [step, memo]);

  const handleSendInvite = async () => {
    const selectedContactsData = contactsWithTags.filter((contactItem) =>
      selectedContacts.includes(contactItem.id)
    );

    const stagedSelectedContactList = skipChecked ? differenceBy(selectedContactsData, completedInvitations, 'email') : selectedContactsData;
    
    await dispatch(
      share({
        emails: stagedSelectedContactList.map((contactItem) => contactItem.email),
      })
    );

    notification.open({
      style: {
        borderRadius: "4px",
        boxShadow:
          "0px 10px 15px - 3px rgba(26, 32, 44, 0.1), 0px 4px 6px - 2px rgba(26, 32, 44, 0.05)",
      },
      message: renderSuccessMessage(
        intl.formatMessage({ id: "admin.memos.share.contacts.success" })
      ),
      className: style.successMessage,
      onClick: () => { },
    });

    onClose();
  };

  const handleClickUpdate = () => {
    setStep("updating");
  };

  const handleChangeSkip = (event) => {
    setSkipChecked(event.target.checked)
  }

  const renderSelectedContacts = React.useCallback(() => {
    const selectedContactsData = contactsWithTags.filter((contactItem) =>
      selectedContacts.includes(contactItem.id)
    );
    const topFiveContacts = selectedContactsData.slice(0, 5);
    const restContacts = selectedContactsData.slice(5);
    return (
      <>
        {topFiveContacts &&
          topFiveContacts.length > 0 &&
          topFiveContacts.map((contactItem) => {
            return (
              <div key={contactItem.id} className={style.selectedContact}>
                <PageTitle variant="h4" type="medium">
                  {contactItem.email}
                </PageTitle>
              </div>
            );
          })}
        <div className={style.restContactsContainer}>
          {restContacts && restContacts.length > 0 && (
            <PageTitle variant="h4" className={style.moreLabel}>
              + {restContacts.length} <FormattedMessage id="admin.memos.share.more"></FormattedMessage>
            </PageTitle>
          )}
          <PageTitle
            className={style.updateButton}
            variant="h4"
            onClick={handleClickUpdate}
          >
            <FormattedMessage id="general.update" />
          </PageTitle>
        </div>
      </>
    );
  }, [selectedContacts]);

  const rowSelection = {
    columnTitle: (
      <div onClick={handleToggleAll}>
        {selectedContacts.length !== filteredContacts.length ? (
          <AntIcon component={CircleMinusIcon} className={style.statusIcon} />
        ) : (
          <AntIcon component={PassedCircleIcon} className={style.statusIcon} />
        )}
      </div>
    ),
    selectedRowKeys: selectedContacts,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedContacts([...selectedRowKeys]);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const columns = [
    {
      title: <FormattedMessage id="profile.fields.email" />,
      dataIndex: "email",
      key: "email",
      width: "30%",
      render: (email, record) => (
        <div className={style.emailColumn}>
          <span>{email}</span>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="profile.fields.firstName" />,
      dataIndex: "firstName",
      key: "name",
      width: "20%",
    },
    {
      title: <FormattedMessage id="profile.fields.lastName" />,
      dataIndex: "lastName",
      key: "lastName",
      width: "20%",
    },
    {
      title: <FormattedMessage id="admin.tagsPage.tags" />,
      key: "tags",
      dataIndex: "tags",
      width: "20%",
      render: (tags, record) => {
        const isSelected = selectedContacts.find(
          (contactId) => contactId === record.id
        );

        return (
          <>
            {tags &&
              tags.length > 0 &&
              tags
                .filter((tagItem) => !tagItem.deleted)
                .map((tag) => {
                  return (
                    <Tag
                      color={isSelected ? SELECTED_TAG_COLOR : TAG_COLOR}
                      key={tag.id}
                      className={`${style.tag} ${isSelected ? style.selected : ""
                        }`}
                    >
                      {tag.name}
                    </Tag>
                  );
                })}
          </>
        );
      },
    },
  ];

  return (
    <Modal
      className={style.container}
      visible={visible}
      footer={null}
      closable
      maskClosable={false}
      closeIcon={
        <div className={style.closeIcon}>
          <CloseIcon width="32px" height="32px" />
        </div>
      }
      onCancel={onClose}
    >
      <Card
        bodyStyle={{ padding: 0 }}
        bordered={false}
        className={style.contentCard}
      >
        <header className={style.header}>
          <div className={style.modalTitle}>
            {(step === "selecting" || step === "updating") && (
              <Button
                shape="circle"
                className={style.backButton}
                onClick={handleBack}
              >
                <LeftArrowIcon />
              </Button>
            )}
            <PageTitle variant="h2">{renderTitle}</PageTitle>
          </div>
        </header>
        <main>
          {step === "selecting" || step === "updating" ? (
            <>
              <div className={style.topContainer}>
                <MemoButton variant="outlined" size="small" onClick={handleToggleAll}>{selectedContacts.length !== filteredContacts.length ? <FormattedMessage id="admin.memos.share.selectAll" /> : <FormattedMessage id="admin.memos.share.deselectAll" />}</MemoButton>
                <AutoComplete
                  className={style.search}
                  dropdownClassName={style.dropdown}
                  value={searchValue}
                  onChange={(value) => {
                    setSearchValue(value);
                  }}
                  options={filteredContactsOptions}
                >
                  <Input
                    prefix={<SearchIcon />}
                    allowClear
                    className={style.input}
                    placeholder={intl.formatMessage({
                      id: "admin.memos.share.contacts.searchPlaceHolder",
                    })}
                  />
                </AutoComplete>
              </div>
              <div className={style.tableWrapper}>
                <Table
                  columns={columns}
                  dataSource={filteredContacts}
                  pagination={true}
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                  className={style.tableContainer}
                  sticky
                  locale={{
                    emptyText: renderEmpty,
                  }}
                />
              </div>
            </>
          ) : (
            <div>
              <PageTitle variant="h4" className={style.selectedLabel}>
                <FormattedMessage id="admin.memos.share.selectedContacts" />
              </PageTitle>
              <div className={style.selectedContainer}>
                {renderSelectedContacts()}
              </div>
              <div className={style.checkSkipping}>
                <Checkbox checked={skipChecked} onChange={handleChangeSkip}>
                  {intl.formatMessage({ id: 'admin.memos.share.checkSkippingDescription' })}
                </Checkbox>
              </div>
            </div>
          )}
        </main>
        <footer className={style.footer}>
          {step === "selecting" || step === "updating" ? (
            <>
              <div>
                {selectedContacts && selectedContacts.length > 0 && (
                  <PageTitle variant="h4" className={style.selectedCount}>
                    {selectedContacts.length} <FormattedMessage id="admin.memos.share.nContactsSelected" />
                  </PageTitle>
                )}
              </div>
              <MemoButton
                disabled={!selectedContacts || selectedContacts.length === 0}
                onClick={handleSelected}
              >
                {step === "selecting" ? <FormattedMessage id="admin.memos.share.addSelected" /> : <FormattedMessage id="general.update" />}
              </MemoButton>
            </>
          ) : (
            <>
              <div></div>
              <MemoButton onClick={handleSendInvite} loading={saving}><FormattedMessage id="admin.memos.share.submit" /></MemoButton>
            </>
          )}
        </footer>
      </Card>
    </Modal>
  );
};

export default SharingContactsCard;
