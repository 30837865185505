import React, { useCallback, useEffect, useMemo, useState } from 'react'
import style from './MemoAlert.module.scss'
import _ from 'lodash'
import cls from 'classnames'
import { FormattedMessage } from 'react-intl'
import { SUBMISSION_STATUS } from './reducer'
import EMOJI_LIST from 'constants/emojiList'
import { useMediaQuery } from 'react-responsive'
import DEVICE_WIDTH from 'constants/mediaQueries'

export default function MemoAlert({ className, status, sender }) {
  const isMobile = useMediaQuery({ maxWidth: DEVICE_WIDTH.XS })
  return (
    <div className={cls(style.component, status, className)}>
      <h3>
        <FormattedMessage id={`individual.memoAlert.${status}.title`} />
        {status === SUBMISSION_STATUS.COMPLETED ? ` ${EMOJI_LIST.PARTY_POPPER}` : ` ${EMOJI_LIST.CONFUSED_FACE}`}
      </h3>
      <p>
        <FormattedMessage id={`individual.memoAlert.${status}.text`} values={{
          name: <strong>{sender}</strong>
        }} />
      </p>
      {status === SUBMISSION_STATUS.COMPLETED && (
        <>
          <span>
            <FormattedMessage id={`individual.memoAlert.thanksForYourTime`} />
          </span>
          <p>
            <FormattedMessage id={`individual.memoAlert.canCloseTab`} />{` ${EMOJI_LIST.GRINNING_FACE}`}
          </p>
        </>
      )}
    </div>
  )
}