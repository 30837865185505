import React, { useCallback, useEffect, useRef, useState } from 'react'
import style from './EmbedPlayer.module.scss'
import cls from 'classnames'
import { useIntl } from 'react-intl'
import { Button } from 'antd'
import _ from 'lodash'
import PlayIcon from './PlayIcon.png'
import ReactPlayer from 'react-player'
import VideoNative from './VideoNative.jsx'
import { useSelector } from 'react-redux'
import DownloadIcon from 'components/icons/DownloadIcon'
import AntIcon from '@ant-design/icons/lib/components/Icon'

/**
 * @TODO add non-supported UI
 */

export const PROVIDER = {
  YOUTUBE: 'YouTube',
  FIREBASE_STORAGE: 'Firebasestorage'
}

export const isSupported = (provider) => {
  return provider === PROVIDER.YOUTUBE || provider === PROVIDER.FIREBASE_STORAGE
}

var timerId = null

export default function EmbedPlayer({
  embed,
  playable = true,
  onEnded,
  className,
  coverClassName,
  canSeek = true,
  hideSkipPopover = false,
  showVolume = true,
  isSkippable = false,
  downloadable = true,
  memoCode = '',
  resumedTime = 0
}) {
  const intl = useIntl()
  const player = useRef(null)
  const { user } = useSelector(s => s.session)

  const { saving } = useSelector(s => s.individualMemo)
  const [covered, setCovered] = useState(true)
  const [currentTime, setCurrentTime] = useState(0)
  const provider = _.get(embed, 'provider_name')
  const thumbnail = _.get(embed, 'thumbnail_url')
  const onClick = useCallback(() => setCovered(false), [])
  const onVideoEnded = useCallback(() => {
    clearTimeout(timerId)
    timerId = setTimeout(() => {
      const ref = player.current
      if (ref.getCurrentTime() === ref.getDuration())
        onEnded && onEnded()
    }, 0)
  }, [player])
  const onEmbedProgress = useCallback((e) => {
    if (Math.abs(e.playedSeconds - currentTime) > 5) {
      // user was trying to seek
      if (!canSeek) {
        player.current.seekTo(Math.max(0, currentTime - 1))
      } else {
        setCurrentTime(e.playedSeconds)
      }
    } else {
      setCurrentTime(e.playedSeconds)
    }
  }, [player, currentTime])

  let thumbnailCls = null
  if (!thumbnail)
    thumbnailCls = `thumbnail-${(_.get(embed, 'thumbnailId') || 0) + 1}`

  useEffect(() => {
    if (covered || canSeek)
      return

    setTimeout(() => {
      /*if (provider === PROVIDER.FIREBASE_STORAGE) {
        var lastTime = 0
        const video = player.current.getInternalPlayer()
        video.addEventListener('timeupdate', () => {
          if (video.currentTime - lastTime > 1)
            video.currentTime = lastTime - 0.5
          else
            lastTime = video.currentTime
        })
      }*/
    }, 10)
  }, [covered, provider, canSeek])

  React.useEffect(() => {
    if (player && player.current) {
      player.current.onPause()
    }
  }, [saving])

  const handleClickDownload = () => {
    fetch(embed.url, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${memoCode}_video.mp4`);
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (!embed)
    return null

  return (
    <div className={cls(style.component, className, thumbnailCls, {
      [style.covered]: covered,
      [style.playable]: playable
    })} >
      {(() => {
        if (!isSupported(provider))
          return (
            <div>
              not supported yet.
            </div>
          )
        if (covered)
          return (
            <React.Fragment>
              <div className={cls(style.coverBg, coverClassName)} style={{ backgroundImage: `url("${thumbnail}")` }}>
                <Button className={style.coverButton} type='text' onClick={onClick}>
                  <img src={PlayIcon} />
                </Button>
                {downloadable && (
                  <div onClick={handleClickDownload}>
                    <AntIcon component={DownloadIcon} className={style.downloadButton} style={{ fontSize: '16px' }} />
                  </div>
                )}
              </div>
              <div className={style.bottomBarShadow} />
            </React.Fragment>
          )
        if (provider === PROVIDER.YOUTUBE)
          return (
            <div
              className={style.playerWrapper}>
              <ReactPlayer
                playing
                onProgress={onEmbedProgress}
                onEnded={onVideoEnded}
                ref={player}
                url={embed.url}
                height='100%'
                width='100%'
                controls />
            </div>
          )
        if (provider === PROVIDER.FIREBASE_STORAGE)
          return (
            <div className={style.playerWrapper}>
              <VideoNative
                ref={player}
                src={embed.url}
                transcodedSrc={embed.transcodedSources}
                width='100%'
                height='100%'
                onEnded={onVideoEnded}
                hideSkipPopover={hideSkipPopover}
                showVolume={showVolume}
                isSkippable={isSkippable}
                resumedTime={resumedTime}
                isIndividual={!user.role}
              />
            </div>
          )
      })()}
    </div >
  )
}

