import React, { useCallback, useEffect, useState } from 'react'
import style from './Toolbar.module.scss'
import cls from 'classnames'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Spin } from 'antd'
import { openShareModal } from 'pages/admin/memos/share/reducer'
import InitialSharingCard from 'pages/admin/memos/share/InitialSharingCard'
import { Link } from 'react-router-dom'
import Icon from 'components/Icon'
import AntIcon from '@ant-design/icons/lib/components/Icon'
import ViewIcon from 'components/icons/ViewIcon'

export default function Toolbar({ className, onDelete, isView = false }) {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { form } = useSelector(s => s.memos)
  const { user } = useSelector(s => s.session)
  const isMine = !form.userId || form.userId === user.uid
  const onShare = useCallback(() => {
    dispatch(openShareModal({
      data: form
    }))
  }, [form])
  const onEdit = useCallback(() => {
    // dispatch(share())
  }, [])
  const onStats = useCallback(() => {
    // dispatch(share())
  }, [])

  if (!form.id)
    return null

  return (
    <div className={cls(style.component, className)}>
      {!form.deleted && (
        <>
          <Button type='text' onClick={onShare} className={style.button} type='text'>
            <Icon name='share' />
          </Button>
          {/* <Link to={`/app/projects/${form.projectId}/memos/${form.id}`} className={style.button}>
            <Icon name='edit' />
          </Link> */}
        </>
      )}
      {!form.deleted && isMine && isView && (
        <Link to={`/app/projects/${form.projectId}/memos/${form.id}`} className={style.button}>
          <Icon name='edit' />
        </Link>
      )}
      <Link to={`/app/projects/${form.projectId}/memos/${form.id}/stats`} className={style.button}>
        <Icon name='stats' />
      </Link>
      {!form.deleted && isMine && !isView && (
        <Link to={`/app/projects/${form.projectId}/memos/${form.id}/view`} className={`${style.button} ${style.center}`}>
          <AntIcon component={ViewIcon} className={style.viewIcon} style={{ fontSize: '28px' }} />
        </Link>
      )}
      {!form.deleted && isMine && (
        <Button type='text' className={cls(style.button, style.delete)} onClick={onDelete}>
          <Icon name='delete' />
        </Button>
      )}
      <InitialSharingCard />
    </div>
  )
}
