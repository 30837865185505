import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router";
import AntIcon from "@ant-design/icons/lib/components/Icon";
import cls from "classnames";
import style from "./ProjectNameItem.module.scss";
import RenameIcon from "components/icons/RenameIcon";
import useOnClickOutside from "hooks/useOnClickOutside";
import FolderIcon from "components/icons/FolderIcon";
import FileIcon from "components/icons/FileIcon";

const ProjectNameItem = ({ project, onSave }) => {
  const { id, acronym, name, isDefault } = project;
  const [active, setActive] = useState(false);
  const history = useHistory();
  const location = useLocation();
  React.useEffect(() => {
    let projectId = location.pathname
      .replace("/app/projects/", "")
      .split("/")[0];

    setActive(id === projectId);
  }, [location, project]);
  const [editable, setEditable] = React.useState(false);

  const handleNavigate = () => {
    history.push(`/app/projects/${id}/memos`);
  };

  const handleClickEdit = (event) => {
    setEditable(true);
    event.stopPropagation();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (!event.target.value) {
        setEditable(false);
        return;
      }
      onSave({ name: event.target.value, acronym });
    }
  };

  const ref = React.useRef(null);

  useOnClickOutside(ref, () => {
    setEditable(false);
  });

  const renderProjectName = () => {
    if (editable) {
      return (
        <div className={`${style.link} ${active ? style.active : ""}`}>
          <input
            defaultValue={name}
            className={style.nameInput}
            onKeyDown={handleKeyDown}
          />
        </div>
      );
    }

    return (
      <div
        onClick={handleNavigate}
        className={`${style.link} ${active ? style.active : ""}`}
      >
        <div>
          <AntIcon
            component={isDefault ? FolderIcon : FileIcon}
            className={style.icon}
          />
          <span>
            {isDefault ? (
              <FormattedMessage id="admin.projects.default" />
            ) : (
              name
            )}
            <span className={style.acronym}>[{acronym}]</span>
          </span>
        </div>
        <div onClick={handleClickEdit}>
          <AntIcon
            component={RenameIcon}
            className={`${style.editIcon} ${isDefault ? style.disabled : ""}`}
            style={{ fontSize: "16px" }}
          />
        </div>
      </div>
    );
  };

  return <div ref={ref}>{renderProjectName()}</div>;
};

export default ProjectNameItem;
