import React, { memo, useCallback, useEffect, useState } from "react";
import style from "./LinkButton.module.scss";
import { Link, useLocation, useParams } from "react-router-dom";
import cls from "classnames";

export const LinkButton = ({ url, size, variant, className, children }) => {
  return (
    <Link
      to={url}
      className={cls(style.component, className, {
        [style.small]: size === "small",
        [style.outlined]: variant === "outlined",
      })}
    >
      {children}
    </Link>
  );
};

export default memo(LinkButton);
