import { IntlProvider, addLocaleData, createIntl, createIntlCache } from 'react-intl'
import { connect } from 'react-redux'
// import flatten from 'flat'
// import en from 'locale/en'
// import fr from 'locale/fr'

// const cache = createIntlCache()
// const intl = createIntl({
//   locale: 'fr',
//   messages: flatten(fr)
// }, cache)

export default connect(state => {
	return {
		// key: state.language.locale,
		locale: state.language.locale,
		messages: state.language.messages
	}
})(IntlProvider)