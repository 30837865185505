import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import style from './NavBar.module.scss'
import cls from 'classnames'
import CreateButton from 'pages/admin/memos/CreateButton'
import { FormattedMessage } from 'react-intl'

const getProjectId = (url) => {
  try {
    const restUrls = url.split('projects/')
    if(restUrls && restUrls.length > 1) {
      return restUrls[1].split('/')[0]
    }
    else {
      return ''
    }
  } catch (ex) {
    console.warn(ex)
  }

  return ''
}

export default function NavBar() {
  const { user } = useSelector(s => s.session)
  const projectId = getProjectId(useLocation().pathname)
  const baseUrl = `/app/projects/${projectId ? projectId : 'all'}`

  return (
    <div className={style.container}>
      {user.role === 'admin' && (
        <nav className={style.component}>
          <NavLink to='/app/admins' className={style.link}>
            Super admins
          </NavLink>
          <NavLink to='/app/creators' className={style.link}>
            Content creators
          </NavLink>
          <NavLink to='/app/teams' className={style.link}>
            Teams
        </NavLink>
        </nav>
      )}
      {user.role === 'creator' && (
        <nav className={style.component}>
          <NavLink
            exact
            to={`${baseUrl}/memos`}
            className={style.link}>
            <FormattedMessage id='navBar.allMemos' />
          </NavLink>
          <NavLink
            exact
            to={`${baseUrl}/memos/my`}
            className={style.link}>
            <FormattedMessage id='navBar.myMemos' />
          </NavLink>
          <CreateButton className={cls(style.link, style.newMemoButton)} to={null}>
            <FormattedMessage id='navBar.createMemo' />
          </CreateButton>
        </nav>
      )}
    </div>
  )
}
