import firebase from 'firebase/app'
import 'firebase/auth'
import axios from 'axios'
import _ from 'lodash'

const { REACT_APP_API_SHARE_MEMO, REACT_APP_API_SHARE_LINK_MEMO, REACT_APP_API_SHARED_LINK_MEMO, REACT_APP_API_UNSHARE_LINK_MEMO} = process.env

const LINK_GET_START = 'MEMOS_SHARE/LINK_GET_START'
const LINK_GET_END = 'MEMOS_SHARE/LINK_GET_END'
const SAVING_START = 'MEMOS_SHARE/SAVING_START'
const SAVING_END = 'MEMOS_SHARE/SAVING_END'
const SET_VISIBILITY = 'MEMOS_SHARE/SET_VISIBILITY'
const INIT_MEMO = 'MEMOS_SHARE/INIT_MEMO'
const RESET = 'MEMOS_SHARE/RESET'

export const share = ({ emails }) => async (dispatch, getState) => {

  dispatch({
    type: SAVING_START
  })
  try {
    const { memo } = getState().memosShare
    const { user } = getState().session
    const token = await firebase.auth().currentUser.getIdToken(true)
    await axios.post(REACT_APP_API_SHARE_MEMO.replace(':id', memo.id), {
      projectId: memo.projectId,
      teamId: user.teamId,
      emails,
      token
    })
  } catch (ex) {
    console.warn(ex)
    dispatch({
      type: SAVING_END
    })
    throw ex
  }
  dispatch({
    type: SAVING_END
  })
}

export const setVisibility = (payload) => {
  return {
    type: SET_VISIBILITY,
    payload
  }
}


export const createSharedLink = () => async (dispatch, getState) => {
  dispatch({
    type: LINK_GET_START
  })
  try {
    let { user } = getState().session
    let { memo } = getState().memosShare
    let token = await firebase.auth().currentUser.getIdToken(true)
    let response = await axios.post(REACT_APP_API_SHARE_LINK_MEMO.replace(':id', memo.id), {
        projectId: memo.projectId,
        teamId: user.teamId,
        token
    })
    if (!response.data.success)
      dispatch(getSharedLink())
    else
      dispatch(getSharedLink(response.data.id))
  } catch (e) {
    console.error(e)
    dispatch(getSharedLink())
  }

}


export const deleteSharedLink = () => async (dispatch, getState) => {
  dispatch({
    type: LINK_GET_START
  })
  try {
    let { user } = getState().session
    let { memo } = getState().memosShare
    let token = await firebase.auth().currentUser.getIdToken(true)
    let response = await axios.post(REACT_APP_API_UNSHARE_LINK_MEMO.replace(':id', memo.id), {
        projectId: memo.projectId,
        teamId: user.teamId,
        token
    })
    if (!response.data.success)
      dispatch(getSharedLink())
    else
      dispatch(getSharedLink(response.data.id))
  } catch (e) {
    console.error(e)
    dispatch(getSharedLink())
  }
}


export const fetchSharedLink = () => async (dispatch, getState) => {
  dispatch({
    type: LINK_GET_START
  })
  try {
    let { user } = getState().session
    let { memo } = getState().memosShare
    let token = await firebase.auth().currentUser.getIdToken(true)
    let response = await axios.post(REACT_APP_API_SHARED_LINK_MEMO.replace(':id', memo.id), {
        projectId: memo.projectId,
        teamId: user.teamId,
        token
    })
    if (!response.data.success)
      dispatch(getSharedLink())
    else
      dispatch(getSharedLink(response.data.id))
  } catch (e) {
    console.error(e)
    dispatch(getSharedLink())
  }
}

export const getSharedLink = (sharedId) => {
  if (!sharedId)
    return {
      type: LINK_GET_END
    }
  return {
    type: LINK_GET_END,
    payload: `${window.location.origin}/shared/${sharedId}`
  }
}

export const openShareModal = ({ data }) => (dispatch, getState) => {
  dispatch({
    type: INIT_MEMO,
    payload: data
  })
  dispatch(setVisibility(true))
  dispatch(fetchSharedLink())
}

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET
  })
}

const initialState = {
  saving: false,
  visible: false,
  memo: {
    id: null
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LINK_GET_START:
      return {
        ...state,
        linkLoading: true
      }
    case LINK_GET_END:
      return {
        ...state,
        linkLoading: false,
        shareableLink: action.payload
      }
    case SAVING_START:
      return {
        ...state,
        saving: true
      }
    case SAVING_END:
      return {
        ...state,
        saving: false
      }
    case SET_VISIBILITY:
      return {
        ...state,
        visible: action.payload
      }
    case INIT_MEMO:
      return {
        ...state,
        memo: {
          ...initialState.memo,
          ...action.payload
        }
      }
    case RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
