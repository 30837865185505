import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./SharingByLinkCard.module.scss";
import { Button, Card, Input, Modal } from "antd";
import { setVisibility } from "./reducer";
import { PageTitle } from "components/PageItems";
import { FormattedMessage, useIntl } from "react-intl";
import CloseIcon from "components/icons/CloseIcon";
import LeftArrowIcon from "components/icons/LeftArrowIcon";
import { CopyButton } from 'components/CopyButton'
import { createSharedLink } from './reducer'

const SharingByLinkCard = ({ handleBack, onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [step, setStep] = React.useState("selecting");

  const { form, memo, visible, saving, linkLoading, shareableLink } =
    useSelector((s) => s.memosShare);

  const { linkShared } = memo;

  const onCreateLink = useCallback(async () => {
    try {
      await dispatch(createSharedLink())
    } catch (ex) {
      console.warn(ex)
    }
  }, [])

  React.useEffect(() => {
    if (memo && !shareableLink && !linkLoading) {
      onCreateLink()
    }
  }, [linkLoading, shareableLink])

  const renderTitle = React.useMemo(() => {
    if (step === "selecting" || step === "updating") {
      return <FormattedMessage id="navBar.contacts" />;
    } else if (step === "selected") {
      return (
        <FormattedMessage
          id="admin.memos.share.title"
          values={{
            name: memo.name,
            memoId: memo.id,
            projectId: memo.projectId,
          }}
        />
      );
    }
  }, [step, memo]);

  return (
    <Modal
      className={style.container}
      visible={visible}
      footer={null}
      closable
      maskClosable={false}
      closeIcon={
        <div className={style.closeIcon}>
          <CloseIcon width="32px" height="32px" />
        </div>
      }
      onCancel={onClose}
    >
      <Card
        bodyStyle={{ padding: 0 }}
        bordered={false}
        className={style.contentCard}
      >
        <header className={style.header}>
          <div className={style.modalTitle}>
            {(step === "selecting" || step === "updating") && (
              <Button
                shape="circle"
                className={style.backButton}
                onClick={handleBack}
              >
                <LeftArrowIcon />
              </Button>
            )}
            <PageTitle variant="h2">{renderTitle}</PageTitle>
          </div>
        </header>
        <main>

        </main>
        <footer className={style.footer}>
          <div className={style.linkContainer}>
            <Input className={style.link_url} placeholder={intl.formatMessage({
              id: 'admin.memos.share.no_link'
            })} value={shareableLink} readonly={true} />
            {/* <CopyToCliboard
                text={shareableLink}
                onCopy={onCopy}>
                
              </CopyToCliboard> */}
            <CopyButton url={shareableLink}>
              <Button className={style.copyButton} loading={linkLoading} type='primary'>
                {intl.formatMessage({
                  id: 'admin.memos.share.copy_link'
                })}
              </Button>
            </CopyButton>

          </div>
        </footer>
      </Card>
    </Modal>
  );
};

export default SharingByLinkCard;
