import axios from 'axios'
import { API_TOKEN_ADMIN, API_URL } from 'const'
import _ from 'lodash'

const LOADING_START = 'ADMIN_SETTINGS/LOADING_START'
const LOADING_SUCCESS = 'ADMIN_SETTINGS/LOADING_SUCCESS'
const LOADING_END = 'ADMIN_SETTINGS/LOADING_END'
const SAVING_START = 'ADMIN_SETTINGS/SAVING_START'
const SAVING_END = 'ADMIN_SETTINGS/SAVING_END'
const RESET = 'ADMIN_SETTINGS/RESET'
const SET_FORM = 'ADMIN_SETTINGS/SET_FORM'
const LOADING_ONE_SUCCESS = 'ADMIN_SETTINGS/LOADING_ONE_SUCCESS'

const REQUEST_OPTIONS = {
	headers: {
		Authorization: `Bearer ${API_TOKEN_ADMIN}`
	}
}

export const setForm = (form) => async (dispatch, getState) => {
	dispatch({
		type: SET_FORM,
		form
	})
}

export const loadAll = () => async (dispatch, getState) => {
	dispatch({
		type: SAVING_START
	})

	try {
		let result = await axios.get(`${API_URL}/content/training-page/settings`, {
			headers: {
				Authorization: `Bearer ${API_TOKEN_ADMIN}`
			}
		})

		dispatch({
			type: LOADING_SUCCESS,
			items: result.data.items.map(item => {
        return {
          id: item.id,
          clientName: _.get(item, 'data.clientName.iv'),
          clientLogo: _.get(item, 'data.clientLogo.iv')
        }
      })
		})
	} catch (ex) {
		console.warn(ex)
	}

	dispatch({
		type: SAVING_END
	})
}

export const loadOne = (id) => async (dispatch, getState) => {
	dispatch({
		type: LOADING_START
	})
	try {
		let result = await axios.get(`${API_URL}/content/training-page/settings/${id}`, {
			headers: {
				Authorization: `Bearer ${API_TOKEN_ADMIN}`
			}
		})
		let settings = result.data.data

		dispatch({
			type: LOADING_ONE_SUCCESS,
			payload: {
				form: {
					id: result.data.id,
					clientLogo: settings.clientLogo.iv[0],
					clientName: settings.clientName.iv
				}
			}
		})
	} catch (ex) {
		console.warn(ex)
	}

	dispatch({
		type: LOADING_END
	})
}

export const saveForm = (values) => async (dispatch, getState) => {
	dispatch({
		type: SAVING_START
	})

	try {
		let { id } = getState().adminSettings.form
		let body = {
			clientName: {
				iv: values.clientName
			},
			clientLogo: {
				iv: [values.clientLogo]
			}
		}

		if (id)
			await axios.put(`${API_URL}/content/training-page/settings/${id}`, body, REQUEST_OPTIONS)
		else
			await axios.post(`${API_URL}/content/training-page/settings?publish=true`, body, REQUEST_OPTIONS)
	} catch (ex) {
		console.warn(ex)
		dispatch({
			type: SAVING_END
		})
		throw ex
	}

	dispatch({
		type: SAVING_END
	})
}

export const reset = () => (dispatch) => {
	dispatch({
		type: RESET
	})
}

export const actions = {
	setForm,
	loadAll,
	loadOne,
	reset
}

const initialState = {
  saving: false,
  loading: false,
  items: [],
	form: {
    id: null,
		clientName: null,
		clientLogo: null
	}
}

export default function reducer(state = initialState, action) {
	switch(action.type) {
		case LOADING_START:
			return {
				...state,
				loading: true
			}
		case LOADING_ONE_SUCCESS:
			return {
				...state,
				...action.payload
			}
		case LOADING_END:
			return {
				...state,
				loading: false
			}
		case LOADING_SUCCESS:
			return {
				...state,
				items: action.items
			}
		case SET_FORM:
			return {
				...state,
				form: {
					...state.form,
					...action.form
				}
			}
		case SAVING_START:
			return {
				...state,
				saving: true
			}
		case SAVING_END:
			return {
				...state,
				saving: false
			}
		case RESET:
			return {
				...initialState
			}
		default:
			return state
	}
}
