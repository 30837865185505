import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import cls from 'classnames'
import style from './PublicPage.module.scss'
import AppHeader from 'components/appHeader/AppHeader'
import SignIn from 'pages/signIn/SignInPage'
import ForgotPassword from 'pages/signIn/ForgotPassword'
import ResetPassword from 'pages/resetPassword/ResetPassword'
import IndividualPage from 'pages/individual/IndividualPage'
import SharedPage from 'pages/shared/SharedPage'
import { useSelector } from 'react-redux'
import AssistancePage from 'pages/assistance/AssistancePage'
import AssistanceCompletePage from 'pages/assistance/AssistanceCompletePage'
import NotFound from "../notFound/NotFound"
import NoAccess from "../individual/noAccess/NoAccess"

export default function PublicPage() {
  const language = useSelector(s => s.language)
  const { showStepper } = useSelector((s) => s.appHeader);

  return (
    <div className={`${style.component} ${showStepper ? style.withStepper : ''}`}>
      <AppHeader type='public' />
      <main className={cls(style.content, 'page-scroll-container')}>
        <Switch>
          <Route exact path='/'>
            <Redirect to='/sign-in' />
          </Route>
          <Route exact path='/sign-in' component={SignIn} />
          <Route path='/:lang/sign-in' component={SignIn} />
          <Route exact path='/forgot-password' component={ForgotPassword} />
          <Route exact path='/reset-password' component={ResetPassword} />
          <Route exact path='/404' component={NotFound} />
          <Route exact path='/401' component={NoAccess} />
          <Route path='/:teamId/:projectId-:memoId/:token/welcome' component={IndividualPage} />
          <Route path='/:teamId/:projectId-:memoId/:token' component={IndividualPage} />
          <Route path='/linked/:teamId/:projectId-:memoId/:token/welcome' component={IndividualPage} />
          <Route path='/linked/:teamId/:projectId-:memoId/:token' component={IndividualPage} />
          <Route path='/shared/:id' component={SharedPage} />
          <Route exact path="/assistance" component={AssistancePage} />
          <Route exact path="/assistance/complete" component={AssistanceCompletePage} />
          <Route exact path='/:token/welcome' component={IndividualPage} />
          <Route exact path='/:token' component={IndividualPage} />
          <Route>
            <Redirect to='/' />
          </Route>
        </Switch>
      </main>
    </div>
  )
}
/*

path /
<h1 style={{ textAlign: 'center' }}>Landing page</h1>


*/
