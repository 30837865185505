import React from "react";

const FilterIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 3C0 2.44772 0.447715 2 1 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H1C0.447715 4 0 3.55228 0 3Z" fill="currentColor" />
    <rect y="7" width="16" height="2" rx="1" fill="currentColor" />
    <rect y="12" width="16" height="2" rx="1" fill="currentColor" />
    <path d="M6.5 3C6.5 3.82843 5.82843 4.5 5 4.5C4.17157 4.5 3.5 3.82843 3.5 3C3.5 2.17157 4.17157 1.5 5 1.5C5.82843 1.5 6.5 2.17157 6.5 3Z" fill="#F3F5F6" stroke="currentColor" />
    <circle cx="11" cy="8" r="1.5" fill="#F3F5F6" stroke="currentColor" />
    <circle cx="4.5" cy="13" r="1.5" fill="#F3F5F6" stroke="currentColor" />
  </svg>

);

export default FilterIcon;