import React, { memo, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './UsersPage.module.scss'
import { Table, Button } from 'antd'
import { loadAll, reset } from './reducer'
import _ from 'lodash'
import { Switch, Route, Redirect, useRouteMatch, useHistory, Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

const CONFIG = {
	admin: {
		createUrl: '/app/admins/new',
		editUrl: '/app/admins',
		title: 'admin.admins.title'
	},
	creator: {
		createUrl: '/app/creators/new',
		editUrl: '/app/creators',
		title: 'admin.creators.title'
	}
}

export const UsersPage = ({ role = 'admin' }) => {
	const config = CONFIG[role]
	const match = useRouteMatch()
	const intl = useIntl()
	const dispatch = useDispatch()
	const history = useHistory()
	const { items } = useSelector(state => state.adminCreators)
	const createColumns = useCallback(() => {
		return [{
			title: 'First name',
			dataIndex: 'firstName',
			key: 'firstName'
		}, {
			title: 'Last name',
			dataIndex: 'lastName',
			key: 'lastName'
		}, {
			title: 'Role',
			dataIndex: 'role',
			key: 'role'
		}, {
			title: 'Email',
			dataIndex: 'email',
			key: 'email'
		}]
	}, [role])

	const onRowClick = useCallback(row => {
		history.push(config.editUrl + '/' + row.id)
	}, [])
	const onChange = useCallback((pagination, filters, sorter) => {
		dispatch(loadAll({
			role,
			pagination,
			sorter
		}))
	}, [])

	useEffect(() => {
		dispatch(loadAll({
			role
		}))
		return () => dispatch(reset())
	}, [match.path])

	return (
		<div className={style.component}>
			<div className={style.content}>
				<header className={style.header}>
					<h1>
						{intl.formatMessage({ id: config.title })}
					</h1>
					<Link to={config.createUrl} className={style.button}>
						Create
					</Link>
				</header>
				<Table
					rowKey="id"
					pagination={false}
					onChange={onChange}
					onRow={(record) => ({
						onClick: () => onRowClick(record)
					})}
					columns={createColumns()}
					dataSource={items} />
			</div>
		</div>
	)
}

export default memo(UsersPage)