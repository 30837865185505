import React from "react";
import { Menu, Dropdown, Input, Divider, Tag } from "antd";
import cls from "classnames";
import style from "./TagsFilter.module.scss";
import PrimaryFilterIcon from "components/icons/PrimaryFilterIcon";
import { FormattedMessage } from "react-intl";
import TagItem from "./TagItem";
import { CheckOutlined } from "@ant-design/icons";
import useOnClickOutside from "hooks/useOnClickOutside";
import AntIcon from "@ant-design/icons/lib/components/Icon";
import CircleIcon from "components/icons/CircleIcon";
import PassedCircleIcon from "components/icons/PassedCircleIcon";
import { differenceBy, orderBy } from "lodash";

let color = "#E0E0E0";
let selectedColor = "#2F80ED";

export const StyledFilterIcon = ({ active }) => {
  return (
    <div className={`${style.filterIcon} ${active ? style.activeFilter : ""}`}>
      <PrimaryFilterIcon />
    </div>
  );
};

const TagsFilter = ({ onChange, tags }) => {
  const [visibleDropdown, setVisibleDropdown] = React.useState(false);
  const [selectedTags, setSelectedTags] = React.useState([]);

  const sortedTags = orderBy(
    tags,
    [(item) => item.name.toLowerCase()],
    ["asc"]
  );

  const selectedIds = React.useMemo(
    () =>
      selectedTags.reduce((ids, selectedTag) => [...ids, selectedTag.id], []),
    [selectedTags]
  );

  const handleChangeItem = (tag) => {
    if (!selectedIds.includes(tag.id)) {
      const stagedSelectedTags = orderBy(
        [...selectedTags, tag],
        [(item) => item.name.toLowerCase()],
        ["asc"]
      );
      setSelectedTags(stagedSelectedTags);
      if (onChange) {
        const tagNames = stagedSelectedTags.reduce(
          (names, selectedTag) => [...names, selectedTag.name],
          []
        );

        onChange(tagNames);
      }
    }

    setVisibleDropdown(true);
  };

  const checkIsSelected = React.useCallback(
    (id) => {
      return selectedIds.includes(id);
    },
    [selectedTags]
  );

  const handleUnselectTag = (tagId) => {
    setSelectedTags(selectedTags.filter((tagItem) => tagItem.id !== tagId));
    if (onChange) {
      const tagNames = selectedTags
        .filter((tagItem) => tagItem.id !== tagId)
        .reduce((names, selectedTag) => [...names, selectedTag.name], []);

      onChange(tagNames);
    }
  };

  const restTags = React.useMemo(
    () => differenceBy(sortedTags, selectedTags, "id"),
    [sortedTags, selectedTags]
  );

  const menu = (
    <>
      <Menu className={style.menuContainer}>
        {selectedTags && selectedTags.length > 0 && (
          <div className={style.selectedContainer}>
            <span className={style.selecedTagTitle}>
              <FormattedMessage id="admin.tagsPage.selectedTags"></FormattedMessage>
            </span>
            <Divider style={{ margin: "8px 0" }} />
            {selectedTags.map((tag, index) => (
              <div
                className={`${style.menuItem} ${style.selectedMenuItem}`}
                key={tag.id}
                onClick={() => handleUnselectTag(tag.id)}
              >
                <AntIcon
                  component={PassedCircleIcon}
                  className={style.statusIcon}
                  style={{ fontSize: "28px" }}
                />
                <Tag
                  color={selectedColor}
                  className={`${style.tag} ${style.selectedTag}`}
                >
                  {tag.name}
                </Tag>
              </div>
            ))}
          </div>
        )}
        <span className={style.selecedTagTitle}>
          {tags.length === restTags.length ? (
            <FormattedMessage id="admin.tagsPage.allTags"></FormattedMessage>
          ) : (
            <FormattedMessage id="admin.tagsPage.otherTags"></FormattedMessage>
          )}
        </span>
        <Divider style={{ margin: "8px 0" }} />
        {restTags.map((tag, index) => (
          <div
            className={style.menuItem}
            key={tag.id}
            onClick={() => handleChangeItem(tag)}
          >
            {checkIsSelected(tag.id) ? (
              <AntIcon
                component={PassedCircleIcon}
                className={style.statusIcon}
                style={{ fontSize: "28px" }}
              />
            ) : (
              <AntIcon
                component={CircleIcon}
                className={`${style.statusIcon}`}
                style={{ fontSize: "28px" }}
              />
            )}
            <Tag color={color} className={style.tag}>
              {tag.name}
            </Tag>
          </div>
        ))}
      </Menu>
    </>
  );

  return (
    <div className={style.root}>
      <Dropdown overlay={menu} trigger={["click"]}>
        <a
          className={cls(
            "ant-dropdown-link",
            style.menu,
            selectedTags && selectedTags.length > 0 && style.activeMenu
          )}
          onClick={() => setVisibleDropdown(!visibleDropdown)}
        >
          <StyledFilterIcon active={selectedTags && selectedTags.length > 0} />
          <span className={style.label}>
            <FormattedMessage id="admin.tagsPage.filterByTags" />
            {selectedTags && selectedTags.length > 0
              ? ` (${selectedTags.length})`
              : ""}
          </span>
        </a>
      </Dropdown>
    </div>
  );
};

export default TagsFilter;
