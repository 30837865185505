import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ReactMediaRecorder } from "react-media-recorder"
import style from './RecordButton.module.scss'
import cls from 'classnames'
import { Button, Progress } from 'antd'
import RecordIcon from './Record.png'
import { FormattedMessage } from 'react-intl'
import Modal from 'antd/lib/modal/Modal'
import RecordCountdown from 'components/RecordCountdown'

const recordingOptions = {
  // mimeType: "video/webm; codecs=h264"
  mimeType: "video/webm"
}

const RecordButton = ({ className, onRecord }) => {
  const [stream, setStream] = useState(null)
  const [audioStream, setAudioStream] = useState(null)
  const [recording, setRecording] = useState(false)
  const [recorder, setRecorder] = useState(null)
  const [startedCountdown, setStartedCountdown] = useState(false)
  const [endedCountdown, setEndedCountdown] = useState(false)
  const countdownRef = useRef()

  const onClick = useCallback(() => {
    if (recording)
      stopRecording()
    else
      startRecording()
  }, [recording])

  const stopRecording = useCallback(() => {
    stream.getTracks().forEach(t => t.stop())
    recorder.stop()
  }, [stream, recorder])


  const startRecording = async () => {
    try {
      let mic = await window.navigator.mediaDevices.getUserMedia({audio: true});
    } catch (e) {
      alert('Your microphone is disabled on this website');
    }

    try {
      const stream = await window.navigator.mediaDevices.getDisplayMedia({ video: true })
      setStream(stream)

      const recorder = new MediaRecorder(stream, recordingOptions)
      setRecorder(recorder)

      setStartedCountdown(true)
    } catch (e) {
      alert('Your screen share is disabled on this website');
    }
  }

  React.useEffect(() => {
    if (startedCountdown && recorder && stream) {
      const chunks = []
      stream.getVideoTracks().forEach(track => {
        track.onended = () => {
          stream.getTracks().forEach(t => t.stop())
          if (countdownRef && countdownRef.current) {
            countdownRef.current.cancelCountdown()
          }
          if (recording) {
            recorder.stop()
          }
        }
      })

      recorder.ondataavailable = (e) => chunks.push(e.data)
      recorder.onstop = () => {
        const blob = new Blob(chunks, {
          type: recordingOptions.mimeType
        })

        setRecording(false)
        setRecorder(false)
        setStream(null)
        onRecord(blob)
      }
    }
  }, [startedCountdown, recorder, stream])

  const handleStartRecording = async () => {
    try {
      let audioStreamSource = await window.navigator.mediaDevices.getUserMedia({ audio: true })

      audioStreamSource.getAudioTracks().forEach(track => {
        stream.addTrack(track)
      })

      setAudioStream(audioStreamSource)
    } catch(e) {

    }
    recorder.start()
    setRecording(true)
  }

  React.useEffect(() => {
    if (endedCountdown && recorder && !recording) {
      handleStartRecording()
    }
  }, [endedCountdown])

  const handleCountdownEnded = (ended) => {
    setStartedCountdown(false)
    setEndedCountdown(ended)
  }

  return (
    <>
      <Modal
        visible={startedCountdown}
        footer={null}
        maskClosable
        centered
        closable={false}
        className={style.progressContainer}
        maskStyle={{background: 'rgba(255, 255, 255, 0.8)'}}
      >
        <RecordCountdown end={5} started={startedCountdown} onEnded={handleCountdownEnded} ref={countdownRef} />
      </Modal>
      <Button
        className={cls(style.className, className)}
        onClick={onClick}>
        {recording ? (
          <>
            <FormattedMessage id='admin.memosFormPage.recordButton.stop' />
          </>
        ) : (
            <>
              <img src={RecordIcon} />
              <FormattedMessage id='admin.memosFormPage.recordButton.record' />
            </>
          )}
      </Button >
    </>
  )
}

export default memo(RecordButton)
