export default {
  languages: {
    fr: "French",
    en: "English",
  },
  general: {
    start: "Start",
    back: "Back",
    save: "Save",
    create: "Create",
    confirm: "Confirm",
    logOut: "Log out",
    gotIt: "Got it",
    warningBeforeCloseTab:
      "Warning! If you close this tab, you will lose all your progress",
    send: "Send",
    delete: "Delete",
    cancel: "Cancel",
    edit: "Edit",
    update: "Update",
    validation: {
      onlyLetters: "You can only type letters.",
    },
    ok: "Ok",
    share: "Share",
    getStarted: "Get Started",
    next: "Next",
    unknown: "Unkown",
  },
  video: {
    cannotSkip: "At first viewing, video cannot be skipped.",
  },
  navBar: {
    allMemos: "All memos",
    myMemos: "My memos",
    createMemo: "+ Create new memo",
    assistance: "Assistance",
    contacts: "Contact list",
  },
  copyButton: {
    notification: "Copied",
  },
  admin: {
    admins: {
      title: "Super admins",
      create: "Create the admin",
      update: "Update the admin",
    },
    creators: {
      title: "Content creators",
      fields: {
        resetPassword: "Reset password",
        password: "Password",
        passwordAgain: "Confirm password",
      },
    },
    projects: {
      default: "General",
      all: "All projects",
      create: "Add new project",
    },
    projectsFormPage: {
      create: "Create a project",
      update: "Update project",
      desc: "Enter project’s title and its acronym.",
      desc2: "Note that project’s acronym cannot be changed",
      validation: {
        acronymsDoNotMatch: "Entered acronyms do not match",
        acronymsIsNotUnique: "This acronym already exists",
        acronymsLength: "Acronym must be 3 letters",
      },
      fields: {
        name: "Project`s title",
        acronym: "Project`s acronym",
        confirmAcronym: "Confirm project`s acronym",
      },
    },
    teams: {
      title: "Teams",
      create: "Create a team",
      update: "Edit team",
    },
    documents: {
      title: "Training pages",
      form: {
        noSharingUrl: "None",
      },
    },
    superUsers: {
      roles: {
        admin: "Admin",
        creator: "Creator",
      },
    },
    memos: {
      title: "Memos",
      share: {
        chooseSharingOption: "Choose a sharing option",
        shareByTag: "Share by tag",
        shareByContact: "Share by contact",
        delete_link: "Delete Link",
        share_by_link: "Share by link",
        copy_link: "Copy link",
        shareable_link_note: "Note that anyone with the link can view the memo",
        create_link: "Create Link",
        no_link: "No link",
        submit: "Send invite",
        close: "Close",
        title: "Share “{name} [{projectId}-{memoId}]”",
        hasInvalidEmail:
          "There is at least one invalid e-mail adress in the list",
        addSelected: "Add selected",
        selectedContacts: "Selected contacts",
        nContactsSelected: "contacts selected",
        more: "more",
        selectAll: "Select All",
        deselectAll: "Deselect all",
        checkSkippingDescription:
          "If any of the selected contact(s) has already completed this memo before, do not send this memo to them again.",
        fields: {
          emails: "Invite team’s people",
        },
        placeholders: {
          emails: "",
        },
        closeConfirm: {
          title: "Close sharing menu?",
          description:
            "Are you sure you want to close? You will lose the recipient(s) that you typed so far.",
        },
        contacts: {
          searchPlaceHolder: "Search by email",
          success:
            "You have successfully sent your memo to your selected recipient(s).",
        },
        tags: {
          tagList: "Tag list",
          noContactsWaring:
            "The tag you selected does not have any contacts attached to it.",
        },
      },
      item: {
        completed: "{count} people completed this memo",
      },
    },
    memosPage: {
      creatorItem: {
        title: "There are no memos here!",
        hint: "Start your first memo",
        button: "Add new memo",
      },
    },
    memosStatsPage: {
      listTitle: "Details",
      status: {
        completed: "Completed",
        opened: "Opened",
        sent: "Not viewed",
        sending: "Sending",
      },
      counter: {
        completed: "{count} Completed",
        opened: "{count} Opened",
        sent: "{count} Not viewed",
      },
      filter: {
        emailAsc: "E-mail A to Z",
        emailDesc: "Email Z to A",
        nameAsc: "Last name A to Z",
        nameDesc: "Last name Z to A",
      },
      viewMemo: "View memo",
      noInvitations: "You haven't shared this memo yet!",
      share: "Share now",
      remind: "Remind",
      remindAll: "Remind All",
      exportCSV: "Download CSV",
      emailedAt: "Last remind{br}{ts}",
    },
    memosFormPage: {
      create: "Create your memo",
      update: "Update memo",
      deletedTitle: "Warning",
      deletedDesc: "This memo has been deleted and is no longer accessible.",
      upload: "Upload",
      beforeLeavePromt:
        "Warning! If you leave the current page without saving, you will lose all your changes.",
      validation: {},
      confirm: {
        delete: "All users will lose access to the memo.",
      },
      delete: {
        title: "Delete this memo?",
        body: "This memo will no longer available and his ID [{id}] will no longer usable.",
      },
      buttons: {
        create: "Create and publish",
        update: "Update",
        delete: "Delete memo",
        question: "Add question",
      },
      fields: {
        thumbnail: "Custom thumbnail",
        name: "Memo's title",
        desc: "Short description",
        content: "Content",
        attachments: "Attachments",
        project: "Project",
        language: "Language",
        linkText: "Text",
        linkUrl: "URL",
        isPublic: "Make this memo public",
        isPublicTrue: "Yes",
        isPublicFalse: "No",
        isPublicHint: "Public memos are accessible to anyone with the shared link, private memos are only accessible to the people in the contact list.",
      },
      charactersLimit: "{number} characters max",
      attachmentSizeError: "This file is too big",
      placeholders: {
        name: "Enter name",
        desc: "Enter text",
        content: "Écrire la description",
        attachments: {
          title: "Upload a file",
          text: "Drag & Drop your file here, or click to browse",
          limitLabel: "10mb maximum"
        },
        url: "Paste URL here or upload/record your content",
        chooseLanguage: "Choose language",
        linkText: "Text",
        linkUrl: "URL"
      },
      richText: {
        italic: "Italic",
        bold: "Bold",
        underline: "Underline",
        ol: "Numbered list",
        ul: "Bulleted list",
        link: "Link",
      },
      recordButton: {
        stop: "Stop",
        record: "Record",
      },
      form: {
        questions: {
          question: "Question",
          fields: {
            name: "Question",
            options: "Possible answers (check the correct answer)",
          },
          placeholders: {
            name: "Enter text",
            option: "Add an option",
          },
        },
      },
    },
    contactsPage: {
      noResultFound: "No results found",
      emptyList: `You don’t have any contacts in your contact list.
        Start by adding a contact or use our cool Import Contact feature to
        import your contacts from a spreadsheet.`,
      searchPlaceHolder: "Search",
      clearSelection: "Clear selection",
      deleteSelection: "Delete selected",
      create: {
        title: "Add Contact",
        submit: "Add new contact",
        existingEmail:
          "A contact with this email address already exists in your contact list.",
        emailRequired: "Email is required!",
        success: "Contact was successfully added",
        validation: {
          email: "You must enter a valid email address",
        },
      },
      edit: {
        title: "Edit Contact",
        success: "Contact was successfully updated",
      },
      view: {
        title: "View contact",
      },
      delete: {
        title: "Delete a contact",
        description:
          "Are you sure you want to delete the contact {email}? Note that if you do this, this contact will no longer have access to any memos sent to him/her previously.",
        success: "Contact was successfully deleted",
      },
      bulkActions: {
        delete: {
          title: "Delete selected contacts?",
          description:
            "Are you sure you want to delete the selected contact(s)? Note that if you do this, these contact(s) will no longer have access to any memo(s) sent to them previously.",
          success: "You have successfully deleted your selected contact.",
          label: "Delete Contacts",
        },
      },
      editIndividual: {
        title: "Edit name",
        success: "You have successfully updated your name",
      },
    },
    tagsPage: {
      tags: "Tags",
      tagsManagement: "Tags management",
      searchPlaceHolder: "Find a tag",
      findTag: "Find a tag",
      tagsName: "Tag’s name",
      allTags: "All tags",
      selectedTags: "Selected tags",
      otherTags: "Other tags",
      filterByTags: "Filter by tags",
      manageTags: "Manage tags",
      create: {
        title: "Add tag",
        submit: "Add",
        existingTag: "This name already exists.",
        success: "Tag was successfully added",
        validation: {
          max: "Max ${max} characters",
        },
      },
      delete: {
        title: "Delete a tag",
        description:
          "Are you sure you want to delete the tag {name}? All contacts attached to this tag will lose this tag.",
        success: "Tag was successfully deleted",
      },
      edit: {
        title: "Edit Tag",
        submit: "Update",
        success: "Tag was successfully updated",
      },
      bulkActions: {
        delete: {
          title: "Delete tags?",
          description:
            "Are you sure you want to delete the selected tags? If you do this, all contacts attached to these tags will lose these tags.",
          success: "Deleted tags",
          label: "Delete Tag",
        },
      },
    },
  },
  individual: {
    stepper: {
      welcome: "Welcome",
      watch: "Watch the entire video",
      answer: "Answer question(s)",
      complete: "Complete",
    },
    memoPage: {
      completed:
        "{count} {count, plural, one {person} other {people}} completed this memo",
      start: "Start memo",
      submit: "Submit",
      retry: "Try again",
      title: "Hello There,",
      hint: "{sender} will be advised when you'll complete this memo",
      success: "You already completed this memo. Answers can't be changed.",
      reviewMemo: "Review the memo",
      startQuestions: "Start the questions",
      timeToAnswer: "It's time to show your skills",
      whatsNext: "What’s next?",
      questionNote: "Watch the entire video and then, answer the questions.",
      oops: "Oops",
      noAccessMessage: "you no longer have access to this Memo.",
      connectedAs: "Connected as",
      status: {
        completed: 'Completed',
        notCompleted: 'Not completed'
      },
      estimatedTime: 'Estimated time to complete: {time}'
    },
    welcomePage: {
      title: "Welcome to Memo!",
      verifyName: "Please verify your name.",
      whosHere: "Who’s here?",
      startMemo: 'Start Memo',
      viewTutorial: 'View tutorial'
    },
    tutorialPage: {
      helloTitle: "Hello there",
      helloDescription:
        "{name} invited you to complete a Memo. Let’s follow the steps!",
      watchTitle: "Watch the memo",
      watchDescription:
        "Watch the memo entirely to ensure you understood the information.",
      questionsTitle: "Look for any possible questions",
      questionsDescription:
        "A memo may or may not have question(s). If any questions exist, answer them to complete the memo!",
    },
    signUpPage: {
      title: "Welcome to {name}",
      linkedEmail:
        "This memo is intended for {email}. Please confirm your name to start the memo",
      hint: "Get the right info at the right time to build awesome things!",
      fields: {
        firstName: "First name",
        lastName: "Last name",
        placeholder: {
          firstName: "John",
          lastName: "Doe",
        },
      },
      submit: "Get started",
    },
    memoAlert: {
      completed: {
        title: "Congrats!",
        text: "{name} will be advised that you've completed the memo",
      },
      failed: {
        title: "Oops",
        text: "Some answers are wrong, Let’s try again",
      },
      thanksForYourTime: "Thanks for your time.",
      canCloseTab: "You can now close your tab.",
    },
  },
  supervisorPage: {
    title: "Training pages",
  },
  authTypePage: {
    userTitle: "Complete this training as an individual",
    supervisorTitle: "Configure this training for others to complete",
    signIn: "Sign in",
    signUp: "Sign up",
  },
  validation: {
    required: "This field is required!",
    invalidEmail: "Invalid email",
    passwordsDoNotMatch: "Confirm password does not match Password",
  },
  landingPage: {
    duration: "{duration} min",
    expires: "expires on {date}",
  },
  signInPage: {
    title: "Sign in",
    desc: "Log in with the credentials that were given to you",
    submit: "Sign in",
    forgotPassword: "Forgot your password?",
    fields: {
      email: "Email",
      password: "Password",
      remember: "Remember me",
      placeholder: {
        email: "name@domain.com",
        password: "At least 8 characters",
      },
    },
  },
  forgotPassword: {
    title: "Forgot password",
    desc: "Enter the email associated with your account to reset your password.",
    submit: "Send email",
    resubmit: "Resend email",
    success: "Success",
    back: "Cancel",
    fields: {
      email: "Email",
    },
    success: {
      title: "Check your mail",
      desc: "Instructions to reset your password have been sent to your email address.",
    },
  },
  resetPassword: {
    title: "Reset password",
    desc: "Enter and confirm your new password",
    submit: "Change password",
    back: "Back",
    wrongPassword:
      "The password entered does not match the account’s current password",
    success: "Password updated",
    rules: {
      lengthRule: "At least 8 characters",
      caseRule: "At least 1 uppercase letter and 1 lowercase letter",
      numberRule: "At least 1 number",
      specialRule: "At least 1 special character",
    },
    fields: {
      password: "Password",
      password2: "Password again",
      currentPassword: "Current password",
      newPassword: "New password",
      confirmPassword: "Confirm new password",
    },
  },
  assistance: {
    title: "Do you need help?",
    thankYou: "Thank you!",
    formDescription:
      "Report an issue or request a feature by filling out the following form.",
    goBackToMemo: "Go back to memo",
    notification:
      "Our team will check your ticket and help you as quickly as possible!",
    addPicture: "Add a picture",
    needHelp: "Do you need help?",
    contactUs: "Contact Us",
    attachedFile: "Attached file",
    fields: {
      subject: "Subject",
    },
  },
  profile: {
    manageAccount: "Manage account",
    title: "Welcome {name}!",
    personal: "Personal",
    company: "Company",
    avatar: {
      upload: "Upload",
    },
    fields: {
      firstName: "First name",
      lastName: "Last name",
      email: "Email",
      language: "Language Preference",
      companyName: "Name",
      companyAddress: "Address",
      companyCountry: "Country",
      companyState: "State",
      companyCity: "City",
      companyZipCode: "Postal code",
      companyPhoneNumber: "Phone number",
      companyEmail: "Email",
    },
    role: {
      creator: "Content creator",
      admin: "Super admin",
      supervisor: "Supervisor",
    },
  },
  notFound: {
    title: "Whoops... ",
    subtitle: "this page\n is not available",
    text: "The memo you’re looking for was deleted.\n Contact its owner for more information"
  },
  shared: {
    sharedPage: {
      getStarted: {
        title: "Hello there,",
        hint: "{sender} will be advised when you complete this memo.",
        submit: "Get started",
      },
      sendMail: {
        title: "Who's there?",
        hint: "Enter your email to confirm your identity",
        submit: "Submit",
        fields: {
          email: "Email",
        },
      },
      done: {
        title: "Check your email!",
        hint: "Follow the link sent to your email",
      },
      none: {
        title: "Not found.",
        hint: "Memo not found.",
      },
      blank: {
        title: " ",
        hint: " ",
      },
    },
  },
  "auth/email-already-exists":
    "The email address is already in use by another account.",
  "auth/invalid-action-code": "Invalid auth code",
  "auth/weak-password": "Weak password",
  "auth/wrong-password": "Wrong email or password.",
  "auth/too-many-requests": "Too many requests, wait a second",
  "auth/user-not-found": "There is no account associated with this email",
};
