import React, { useCallback, useEffect, useState } from 'react'
import style from './EmbedProvider.module.scss'
import cls from 'classnames'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEmbed, reset } from './reducer'
import { Spin } from 'antd'
import EmbedPlayer from 'components/embedPlayer/EmbedPlayer'

export default function EmbedProvider({ isSkippable, className, downloadable, memoCode }) {
  const intl = useIntl()
  const { loading, embed } = useSelector(s => s.memosFormUrl)

  return (
    <div className={cls(style.component, className, {
      [style.loading]: loading
    })}>
      {loading ? (
        <Spin />
      ) : (
        <EmbedPlayer embed={embed} isSkippable={isSkippable} downloadable={downloadable} memoCode={memoCode} />
      )}
    </div>
  )
}
