import firebase from 'firebase/app'
import 'firebase/firestore'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

const LOADING_START = 'MEMOS_FORM_QUESTIONS/LOADING_START'
const LOADING_SUCCESS = 'MEMOS_FORM_QUESTIONS/LOADING_SUCCESS'
const LOADING_END = 'MEMOS_FORM_QUESTIONS/LOADING_END'
const SAVING_START = 'MEMOS_FORM_QUESTIONS/SAVING_START'
const SAVING_END = 'MEMOS_FORM_QUESTIONS/SAVING_END'
const RESET = 'MEMOS_FORM_QUESTIONS/RESET'
const LOADING_ONE_SUCCESS = 'MEMOS_FORM_QUESTIONS/LOADING_ONE_SUCCESS'
const ADD_QUESTION = 'MEMOS_FORM_QUESTIONS/ADD_QUESTION'
const REMOVE_QUESTION = 'MEMOS_FORM_QUESTIONS/REMOVE_QUESTION'
const UPDATE_QUESTION = 'MEMOS_FORM_QUESTIONS/UPDATE_QUESTION'
const DELETING_START = 'MEMOS_FORM_QUESTIONS/DELETING_START'
const DELETING_END = 'MEMOS_FORM_QUESTIONS/DELETING_END'

const MAX_QUESTIONS = 10

/**
 * generates default option with unique id
 */
export const generateOption = () => {
  return {
    id: uuidv4(),
    title: ''
  }
}
/**
 * generates default question with unique id
 */
export const generateQuestion = () => {
  const option = generateOption()
  const option2 = generateOption()
  return {
    id: uuidv4(),
    title: '',
    phantom: true,
    config: {
      correctId: option.id,
      options: [option, option2]
    }
  }
}

export const loadQuestions = (memoId, projectId, teamId) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_START
  })
  try {
    const result = await firebase
      .firestore()
      .collection(`teams/${teamId}/projects/${projectId}/memos/${memoId}/questions`)
      .orderBy('order', 'asc')
      .get()
    dispatch({
      type: LOADING_SUCCESS,
      items: result.docs.map(d => {
        return {
          id: d.id,
          ...d.data()
        }
      })
    })
  } catch (ex) {
    console.warn(ex)
  }
  dispatch({
    type: LOADING_END
  })
}

export const addQuestion = () => (dispatch, getState) => {
  const { items } = getState().memosFormQuestions

  if (items.length === MAX_QUESTIONS)
    return null

  dispatch({
    type: ADD_QUESTION,
    payload: generateQuestion()
  })
}

export const removeQuestion = (data) => async (dispatch, getState) => {
  dispatch({
    type: DELETING_START
  })
  try {
    const { memosFormQuestions, memos } = getState()
    const index = memosFormQuestions.items.indexOf(data)
    // only saved questions to be removed
    if (!data.phantom) {
      const { form } = memos
      const path = `teams/${form.teamId}/projects/${form.projectId}/memos/${form.id}/questions`
      await firebase
        .firestore()
        .collection(path)
        .doc(data.id)
        .delete()
    }
    dispatch({
      type: REMOVE_QUESTION,
      payload: {
        index
      }
    })
  } catch (ex) {
    console.warn(ex)
  }
  dispatch({
    type: DELETING_END
  })
}

export const updateQuestion = (data) => (dispatch, getState) => {
  const { items } = getState().memosFormQuestions
  dispatch({
    type: UPDATE_QUESTION,
    payload: {
      data,
      index: _.findIndex(items, {
        id: data.id
      })
    }
  })
}

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET
  })
}

export const actions = {
  reset
}

const initialState = {
  saving: false,
  loading: false,
  items: []
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        loading: true
      }
    case LOADING_ONE_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case LOADING_END:
      return {
        ...state,
        loading: false
      }
    case LOADING_SUCCESS:
      return {
        ...state,
        items: action.items
      }
    case SAVING_START:
      return {
        ...state,
        saving: true
      }
    case SAVING_END:
      return {
        ...state,
        saving: false
      }
    case DELETING_START:
      return {
        ...state,
        deleting: true
      }
    case DELETING_END:
      return {
        ...state,
        deleting: false
      }
    case ADD_QUESTION:
      return {
        ...state,
        items: [...state.items, action.payload]
      }
    case REMOVE_QUESTION:
      return {
        ...state,
        items: [
          ...state.items.slice(0, action.payload.index),
          ...state.items.slice(action.payload.index + 1)
        ]
      }
    case UPDATE_QUESTION:
      return {
        ...state,
        items: [
          ...state.items.slice(0, action.payload.index),
          {
            ...action.payload.data
          },
          ...state.items.slice(action.payload.index + 1)
        ]
      }
    case RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
