import React, { useCallback, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import style from "./IndividualPage.module.scss";
import _ from "lodash";
import cls from "classnames";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import MemoPage from "./memo/MemoPage";
import NoAccess from "./noAccess/NoAccess";
import Tutorial from "./tutorial/Tutorial";
import WelcomePage from "./welcome/WelcomePage";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { saveMemoUrl } from "../assistance/reducer";
import { FormattedMessage } from "react-intl";
import { setContactInfo } from "./memo/reducer";
import { setIndividualToken, setMemoData } from "session/reducer";
const { REACT_APP_API } = process.env;

export default function IndividualPage({ className }) {
  const { path, url } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { user } = useSelector((s) => s.session);
  const { form, contact } = useSelector((s) => s.individualMemo);

  const [loadingUser, setLoadingUser] = React.useState(false);
  const [userLoaded, setUserLoaded] = React.useState(false);

  useEffect(() => {if (form?.deleted) window.location.href = "/404"}, [form])

  const signIn = useCallback(async () => {
    setLoadingUser(true)
    try {
      let response = await axios.post(`${REACT_APP_API}/view-user/${params.token}`);
      dispatch(setMemoData({
        memoId: response.data.memoId,
        projectId: response.data.projectId,
        teamId: response.data.teamId
      }))
      if (response.data.contact) {
        dispatch(setContactInfo(response.data.contact))
      }

      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL); // making sure the session persists
      await firebase.auth().signInWithCustomToken(response.data.token);
      dispatch(setIndividualToken(response.data.token))
      setUserLoaded(true)
    } catch (ex) {
      console.warn(ex);
      window.location.href = "/404";
    }
    setLoadingUser(false)
  }, [dispatch, params.token]);
  useEffect(() => {
    if (!loadingUser && !userLoaded) {
      signIn();
    }
  }, [params, loadingUser, user, userLoaded, signIn]);

  if (!user) return null; // waiting for user to authenticate

  const handleClickContact = () => {
    const { teamId, projectId, memoId, token } = params;
    dispatch(
      saveMemoUrl({
        teamId,
        projectId,
        memoId,
        token,
      })
    );
    history.push("/assistance");
    document.querySelector(".page-scroll-container").scrollTo(0, 0);
  };

  const renderContent = () => {
    if (!!contact && contact.deleted) {
      return <NoAccess />
    }

    else if (!!contact && !contact.passedTutorial) {
      return <Tutorial />
    }

    return path.includes('/welcome') ? <WelcomePage baseUrl={url.replace('/welcome', '')}/> : <MemoPage baseUrl={url}/>
  }

  return (
    <div className={cls(style.component, className)}>
      <div className={style.content}>
        <Switch>
          {renderContent()}
          <Route>
            <Redirect to={url} />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
