import React, { useCallback, useEffect, useState } from "react";
import style from "./WelcomePage.module.scss";
import _ from "lodash";
import cls from "classnames";
import { Prompt, useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Button, Input, Card } from "antd";
import { PageTitle } from "components/PageItems";
import { initFields, reset } from "./reducer";
import { updateFields } from "session/reducer";
import DummyPlayer from "components/contentStructure/DummyPlayer";
import MemoFooter from "components/footer/MemoFooter";
import Profile from "components/header/Profile";
import { useMediaQuery } from "react-responsive";
import DEVICE_WIDTH from "constants/mediaQueries";
import LoadingMemoAnimation from "components/animations/LoadingMemoAnimation";
import useSaveMemoUnload from "hooks/useSaveMomoUnload";
import { loadOne } from "../memo/reducer";
import { updateContactInfo } from "../memo/reducer";
import { toCapitalize } from "utils";
import { changeLocale } from "components/language/reducer";
import { setCurrentStep, showSteps } from "components/appHeader/reducer";
import { MEMO_STEPS } from "components/appHeader/MemoStepper/MemoStepper";
import { setContactPassedTutorial } from "../memo/reducer";

export default function WelcomePage({ baseUrl, className }) {
  useSaveMemoUnload();
  const params = useParams();
  const [antForm] = Form.useForm();
  const isMobile = useMediaQuery({ maxWidth: DEVICE_WIDTH.XS });
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const language = useSelector((s) => s.language);
  const { user, memoData } = useSelector((s) => s.session);
  const { form: formMemo, loading, contact } = useSelector((s) => s.individualMemo);
  const { showStepper } = useSelector((s) => s.appHeader);
  const embed = _.get(formMemo, "url.embed");
  const thumbnailUrl = _.get(embed, "thumbnail_url");
  const thumbnailUrlId = _.get(embed, "thumbnailId");
  const { saving, form } = useSelector((s) => s.individualWelcome);
  const [udpated, setUpdated] = useState(false);

  const onSubmit = useCallback(async () => {
    try {
      const values = await antForm.validateFields();
      await dispatch(updateContactInfo({ values, isVerified: true }));
      dispatch(updateFields(values));
      setUpdated(true);
      setTimeout(() => {
        history.push(baseUrl);
      }, 400);
    } catch (ex) {
      console.warn(ex);
    }
  }, []);

  useEffect(() => {
    dispatch(initFields());
  }, []);

  useEffect(() => {
    if (!loading && formMemo && !formMemo.id && memoData) {
      dispatch(loadOne({
        teamId: memoData.teamId, projectId: memoData.projectId, memoId: memoData.memoId, token: params.token
      }));
    }
  }, [dispatch, loading, memoData, params.token, formMemo]);

  useEffect(() => {
    antForm.setFieldsValue({
      email: user.claims.email,
      ...form,
    });
  }, [form, formMemo]);

  useEffect(() => {
    if (contact && contact.firstName && contact.lastName)
      antForm.setFieldsValue({
        firstName: contact.firstName,
        lastName: contact.lastName,
      });
  }, [contact]);

  const handleFormChange = (event, allValues) => {
    const keyName = Object.keys(event)[0];
    if (keyName === "firstName" || keyName === "lastName") {
      antForm.setFieldsValue({
        [keyName]: toCapitalize(event[keyName]),
      });
    }
  };

  React.useEffect(() => {
    dispatch(setCurrentStep(MEMO_STEPS.WELCOME))
    dispatch(showSteps())
  }, [])

  useEffect(() => {
    if (formMemo && formMemo.language && !language.localeSelected) {
      dispatch(changeLocale(formMemo.language));
    }
  }, [formMemo]);

  const profile = {
    name: `${formMemo.user ? formMemo.user.firstName : ""} ${formMemo.user ? formMemo.user.lastName : ""
      }`,
    team: formMemo.team ? formMemo.team.name : "",
    project: `${formMemo.projectId}-${formMemo.id}`,
    avatar:
      formMemo.user && formMemo.user.avatar ? formMemo.user.avatar.url : "",
  };

  const handleShowTutorial = () => {
    dispatch(setContactPassedTutorial(false));
  }

  const renderForm = () => {
    return (
      <React.Fragment>
        <div
          className={`${udpated ? style.disappearAnimation : style.appearAnimation
            }`}
        >
          <PageTitle variant="h2" className={style.title}>
            <FormattedMessage
              id={
                contact &&
                  contact.firstName &&
                  contact.lastName &&
                  !contact.isVerified
                  ? "individual.welcomePage.whosHere"
                  : "individual.welcomePage.title"
              }
            />
          </PageTitle>
          <p className={style.hint}>
            <FormattedMessage
              id="individual.signUpPage.linkedEmail"
              values={{
                email: <b>{user.claims.email}</b>,
              }}
            />
          </p>
          <Form
            validateMessages={{
              required: intl.formatMessage({ id: "validation.required" }),
              types: {
                email: intl.formatMessage({ id: "validation.invalidEmail" }),
              },
            }}
            form={antForm}
            className={style.form}
            onValuesChange={handleFormChange}
          >
            <Form.Item
              labelCol={{ xs: 24 }}
              label={
                isMobile
                  ? ""
                  : intl.formatMessage({
                    id: "individual.signUpPage.fields.firstName",
                  })
              }
              className={style.formItem}
              rules={[
                { required: true },
              ]}
              name="firstName"
            >
              <Input
                placeholder={
                  isMobile
                    ? intl.formatMessage({
                      id: "individual.signUpPage.fields.firstName",
                    })
                    : intl.formatMessage({
                      id: "individual.signUpPage.fields.placeholder.firstName",
                    })
                }
                className={style.input}
              />
            </Form.Item>
            <Form.Item
              labelCol={{ xs: 24 }}
              label={
                isMobile
                  ? ""
                  : intl.formatMessage({
                    id: "individual.signUpPage.fields.lastName",
                  })
              }
              className={style.formItem}
              rules={[{ required: true }]}
              name="lastName"
            >
              <Input
                placeholder={
                  isMobile
                    ? intl.formatMessage({
                      id: "individual.signUpPage.fields.lastName",
                    })
                    : intl.formatMessage({
                      id: "individual.signUpPage.fields.placeholder.lastName",
                    })
                }
                className={style.input}
              />
            </Form.Item>
            <Form.Item hidden name="email" />
          </Form>
          <Button
            className={style.submitButton}
            onClick={onSubmit}
            loading={saving}
            type="primary"
          >
            <FormattedMessage id="individual.welcomePage.startMemo" />
          </Button>
          <Button onClick={handleShowTutorial} type="link" className={style.showTutorialButton}><FormattedMessage id="individual.welcomePage.viewTutorial" /></Button>
        </div>
      </React.Fragment>
    );
  };

  if (loading || !formMemo.id) {
    return (
      <div className={style.container}>
        <div className={style.component}>
          <LoadingMemoAnimation />
        </div>
      </div>
    );
  }

  return (
    <div className={cls(style.container, { showStepper },)}>
      <div className={style.component}>
        {renderForm()}
      </div>
    </div>
  );
}
