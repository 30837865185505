import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import qs from 'qs'

const LOADING_START = 'ADMIN_DOCUMENTS/LOADING_START'
const LOADING_SUCCESS = 'ADMIN_DOCUMENTS/LOADING_SUCCESS'
const LOADING_END = 'ADMIN_DOCUMENTS/LOADING_END'
const SAVING_START = 'ADMIN_DOCUMENTS/SAVING_START'
const SAVING_END = 'ADMIN_DOCUMENTS/SAVING_END'
const RESET = 'ADMIN_DOCUMENTS/RESET'
const SET_FORM = 'ADMIN_DOCUMENTS/SET_FORM'
const LOADING_ONE_SUCCESS = 'ADMIN_DOCUMENTS/LOADING_ONE_SUCCESS'
const SET_ACTIVE_TAB = 'ADMIN_DOCUMENTS/SET_ACTIVE_TAB'

const { REACT_APP_API_TOKEN_ADMIN, REACT_APP_API_URL } = process.env
const SORT_ORDER = {
	descend: 'desc',
	ascend: 'asc'
}

const REQUEST_OPTIONS = {
	headers: {
		Authorization: `Bearer ${REACT_APP_API_TOKEN_ADMIN}`
	}
}

export const setActiveTab = (tab) => async (dispatch, getState) => {
	dispatch({
		type: SET_ACTIVE_TAB,
		tab
	})
}

export const setForm = (data) => async (dispatch, getState) => {
	const { activeTab, enForm, frForm } = getState().adminDocuments

	if (activeTab === 'fr')
		return dispatch({
			type: SET_FORM,
			formName: 'frForm',
			form: {
				...frForm,
				...data
			}
		})

	return dispatch({
		type: SET_FORM,
		formName: 'enForm',
		form: {
			...enForm,
			...data
		}
	})
}

export const loadAll = ({ pagination, filters, sorter } = {}) => async (dispatch, getState) => {
	dispatch({
		type: SAVING_START
	})

	try {
		let params = ''

		if (sorter && sorter.field && sorter.order)
			params = qs.stringify({
				$orderby: `data/${sorter.field}/iv ${SORT_ORDER[sorter.order]}`
			})

		const result = await axios.get(`${REACT_APP_API_URL}/content/training-page/pages?${params}`, {
			headers: {
				Authorization: `Bearer ${REACT_APP_API_TOKEN_ADMIN}`
			}
		})

		dispatch({
			type: LOADING_SUCCESS,
			items: result.data.items.map(item => {
        return {
          id: item.id,
					title: _.get(item, 'data.title.iv'),
					description: _.get(item, 'data.description.iv'),
					embedCode: _.get(item, 'data.embedCode.iv'),
					passingScore: _.get(item, 'data.passingScore.iv'),
					duration: _.get(item, 'data.duration.iv'),
					expirationDate: _.get(item, 'data.expirationDate.iv'),
					questions: []
        }
      })
		})
	} catch (ex) {
		console.warn(ex)
	}

	dispatch({
		type: SAVING_END
	})
}

export const loadOne = (id) => async (dispatch, getState) => {
	dispatch({
		type: LOADING_START
	})
	try {
		let response = await axios.post(`${REACT_APP_API_URL}/content/training-page/graphql`, {
			query: `{
				queryPagesContents(filter: "id eq '${id}'") {
					id
					data {
						title { iv }
						description { iv }
						passingScore { iv }
						duration { iv }
						expirationDate { iv }
						embedCode {
							iv {
								... on Media {
									id
									data {
										title { iv }
										embedCode { iv }
									}
								}
							}
						}
						questions {
							iv {
								... on Questions {
									id
									data {
										title { iv }
										choices {
											iv {
												isAnswer
												title
											}
										}
									}
								}
							}
						}
					}
				}
			}`
		}, {
			headers: {
				Authorization: `Bearer ${REACT_APP_API_TOKEN_ADMIN}`
			}
		})
		let [result] = response.data.data.queryPagesContents
		let { data } = result

		data.questions = data.questions || { iv: [] }
		data.embedCode = data.embedCode || { iv: [] }

		if (data.embedCode.iv.length) {
			data.embedTitle = data.embedCode.iv[0].data.title.iv
			data.embedUrl = data.embedCode.iv[0].data.embedCode.iv
			data.embedId = data.embedCode.iv[0].id
		}

		const form = {
			id,
			title: data.title.iv,
			description: data.description.iv,
			passingScore: data.passingScore.iv,
			duration: data.duration.iv,
			embedId: data.embedId,
			embedUrl: data.embedUrl,
			embedTitle: data.embedTitle,
			expirationDate: data.expirationDate ? moment.utc(data.expirationDate.iv) : null,
			questions: data.questions.iv.map(q => {
				q.data.id = q.id
				q.data.choices = q.data.choices.iv
				q.data.title = q.data.title.iv
				return q.data
			}),
			embedCode: data.embedCode.iv
		}

		dispatch({
			type: LOADING_ONE_SUCCESS,
			payload: {
				enForm: {
					...form
				},
				frForm: {
					...form
				}
			}
		})
	} catch (ex) {
		console.warn(ex)
	}

	dispatch({
		type: LOADING_END
	})
}

export const saveEmbedCode = (values) => {
	let body = {
		title: { iv: values.embedTitle },
		embedCode: { iv: values.embedUrl }
	}

	if (values.embedId)
		return axios.patch(`${REACT_APP_API_URL}/content/training-page/media/${values.embedId}`, body, REQUEST_OPTIONS)

	return axios.post(`${REACT_APP_API_URL}/content/training-page/media?publish=true`, body, REQUEST_OPTIONS)
}

export const saveQuestion = (data) => {
	let body = {
		title: { iv: data.title },
		choices: { iv: data.choices }
	}

	if (data.id)
		return axios.patch(`${REACT_APP_API_URL}/content/training-page/questions/${data.id}`, body, REQUEST_OPTIONS)

	return axios.post(`${REACT_APP_API_URL}/content/training-page/questions?publish=true`, body, REQUEST_OPTIONS)
}

export const saveForm = (values) => async (dispatch, getState) => {
	dispatch({
		type: SAVING_START
	})

	try {
		let { id } = getState().adminDocuments.form
		let body = {
			title: {
				iv: values.title
			},
			description: {
				iv: values.description
			},
			passingScore: {
				iv: Number(values.passingScore)
			},
			duration: {
				iv: Number(values.duration)
			},
			expirationDate: {
				iv: values.expirationDate ? values.expirationDate.utc().format() : null
			}
		}

		let promises = values.questions.map(q => saveQuestion(q))
		let results = await Promise.all(promises)
		let ids = results.map(n => n.data.id)
		body.questions = { iv: ids }
		let embedPromise = saveEmbedCode(values)
		let embedResult = await Promise.resolve(embedPromise)
		body.embedCode = { iv: [embedResult.data.id] }

		// then save / update the page
		if (id)
			await axios.patch(`${REACT_APP_API_URL}/content/training-page/pages/${id}`, body, REQUEST_OPTIONS)
		else
			await axios.post(`${REACT_APP_API_URL}/content/training-page/pages?publish=true`, body, REQUEST_OPTIONS)
	} catch (ex) {
		console.warn(ex)
		dispatch({
			type: SAVING_END
		})
		throw ex
	}

	dispatch({
		type: SAVING_END
	})
}

export const reset = () => (dispatch) => {
	dispatch({
		type: RESET
	})
}

export const actions = {
	setForm,
	loadAll,
	loadOne,
	reset
}

const initialState = {
  saving: false,
  loading: false,
	items: [],
	activeTab: 'en',
	frForm: {
		id: null,
		title: null,
		description: null,
		duration: null,
		passingScore: null,
		expirationDate: null,
		embedId: null,
		embedTitle: null,
		embedCode: null,
		questions: []
	},
	enForm: {
		id: null,
		title: null,
		description: null,
		duration: null,
		passingScore: null,
		expirationDate: null,
		embedId: null,
		embedTitle: null,
		embedCode: null,
		questions: []
	}
}

export default function reducer(state = initialState, action) {
	switch(action.type) {
		case LOADING_START:
			return {
				...state,
				loading: true
			}
		case LOADING_ONE_SUCCESS:
			return {
				...state,
				...action.payload
			}
		case LOADING_END:
			return {
				...state,
				loading: false
			}
		case LOADING_SUCCESS:
			return {
				...state,
				items: action.items
			}
		case SET_FORM:
			return {
				...state,
				[action.formName]: action.form
			}
		case SAVING_START:
			return {
				...state,
				saving: true
			}
		case SAVING_END:
			return {
				...state,
				saving: false
			}
		case SET_ACTIVE_TAB:
			return {
				...state,
				activeTab: action.tab
			}
		case RESET:
			return {
				...initialState
			}
		default:
			return state
	}
}
