import React, { useCallback, useEffect } from 'react'
import { Input, Radio } from 'antd'
import { useIntl } from 'react-intl'
import style from './Options.module.scss'
import cls from 'classnames'
import _ from 'lodash'
import { generateOption } from './reducer'

export const Options = ({ onChange, value, className }) => {
  const intl = useIntl()
  const onAnswerChange = useCallback((e) => {
    onChange({
      ...value,
      correctId: e.target.value
    })
  }, [value])
  const onTextChange = useCallback((e, id) => {
    const index = _.findIndex(value.options, { id })
    const options = [
      ...value.options.slice(0, index),
      {
        ...value.options[index],
        title: e.target.value
      },
      ...value.options.slice(index + 1)
    ]
    const filtered = _.filter(options, n => !n.title)
    if (filtered.length === 0)
      options.push(generateOption())

    onChange({
      ...value,
      options
    })
  }, [value])

  useEffect(() => {
    if (!value)
      return
    const emptyOptions = _.filter(value.options, o => !o.title)
    if (emptyOptions.length === 0) {
      const options = [...value.options]
      options.push(generateOption())
      onChange({
        ...value,
        options
      })
    }
  }, [value])

  if (!value)
    return null

  return (
    <Radio.Group
      className={cls(style.component, className)}
      onChange={onAnswerChange}
      defaultValue={value.correctId}>
      {value.options.map(item => {
        return (
          <Radio
            key={item.id}
            value={item.id}
            className={style.option}>
            <Input
              type='text'
              value={item.title}
              className={style.input}
              onChange={(e) => onTextChange(e, item.id)}
              placeholder={intl.formatMessage({
                id: 'admin.memosFormPage.form.questions.placeholders.option'
              })} />
          </Radio>
        )
      })}
    </Radio.Group>
  )
}

export default Options