import React, { useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './ResetPassword.module.scss'
import { Button, Form, Input, Alert } from 'antd'
import { FormattedMessage } from 'react-intl'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import cls from 'classnames'
import { confirmPasswordReset, setError, setForm } from './reducer'
import { useHistory, useLocation, useParams } from 'react-router'
import routes from 'routes'
import qs from 'qs'

const fieldsToArray = (form) => {
	return _.keys(form).map(k => {
		return {
			name: k,
			value: form[k]
		}
	})
}

export default function ResetPassword() {
	const formRef = useRef(null)
	const { form, saving, error } = useSelector(state => state.resetPassword)
	const intl = useIntl()
	const params = useParams()
	const location = useLocation()
	const dispatch = useDispatch()
	const history = useHistory()
	const onBack = useCallback(() => {
		window.history.back()
	}, [])

	const onFieldsChange = useCallback((fields) => {
		dispatch(setError())
		fields.forEach(field => {
			dispatch(setForm({
				[field.name[0]]: field.value
			}))
		})
	}, [])

	const onSubmit = useCallback(async () => {
		try {
			let { password } = await formRef.current.validateFields()
			let { oobCode } = qs.parse(location.search.slice(1))

			await dispatch(confirmPasswordReset(oobCode, password))
			history.replace(routes.signIn)
		} catch (ex) {
			console.warn(ex)
		}
	}, [formRef, location])

	const validatePasswords = ({ getFieldValue }) => ({
		validator(rule, value) {
			if (!value || getFieldValue('password') === value)
				return Promise.resolve()
			return Promise.reject(
				intl.formatMessage({ id: 'validation.passwordsDoNotMatch' })
			)
		}
	})

	return (
		<div className={cls(style.container)}>
			<div className={style.component}>
				<header className={style.header}>
					<h3 className={style.title}>
						<FormattedMessage id='resetPassword.title' />
					</h3>
					<span className={style.desc}>
						<FormattedMessage id='resetPassword.desc' />
					</span>
				</header>
				{error && (
					<Alert
						type='error'
						message={intl.formatMessage({ id: error.code })} />
				)}
				<Form
					validateMessages={{
						required: intl.formatMessage({ id: 'validation.required' }),
						types: {
							email: intl.formatMessage({ id: 'validation.invalidEmail' })
						}
					}}
					id='form_id'
					ref={formRef}
					fields={fieldsToArray(form)}
					onFieldsChange={onFieldsChange}>
					<Form.Item
						labelCol={{ xs: 24 }}
						label={intl.formatMessage({ id: 'resetPassword.fields.password' })}
						className={style.formItem}
						rules={[{ required: 'password is required' }]}
						name='password'>
						<Input
							type='password'
							className={style.input} />
					</Form.Item>
					<Form.Item
						labelCol={{ xs: 24 }}
						label={intl.formatMessage({ id: 'resetPassword.fields.password2' })}
						className={style.formItem}
						rules={[
							{
								required: true
							},
							validatePasswords
						]}
						name='password2'>
						<Input
							type='password'
							className={style.input} />
					</Form.Item>
					<footer className={style.footer}>
						{/* <Button onClick={onBack}>
							<FormattedMessage id='resetPassword.back' />
						</Button> */}
						<Button
							className={style.submitButton}
							type='primary'
							onClick={onSubmit}
							loading={saving}>
							<FormattedMessage id='resetPassword.submit' />
						</Button>
					</footer>
				</Form>
			</div>
		</div>
	)
}