import React from 'react'
import Lottie from "react-lottie";
import loadingMemoLotti from '../../animations/loadingMemo.json'
import style from './LoadingMemoAnimation.module.scss'

const LoadingMemoAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingMemoLotti,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={style.root}>
      <Lottie options={defaultOptions} height="100%" width="100%" />
    </div>
  );
}

export default LoadingMemoAnimation