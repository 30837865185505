import React, { memo, useCallback, useEffect } from "react";
import { Select, Space } from "antd";
import style from "./LanguageSelect.module.scss";
import { useDispatch, useSelector } from "react-redux";
import cls from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import ProjectSelectDropdown from "../icons/ProjectSelectDropdown";
import AntIcon from "@ant-design/icons/lib/components/Icon";
import GlobalIcon from "components/icons/GlobalIcon";

export const StyledDropdownIcon = () => {
  return (
    <div className={style.downdownIcon}>
      <ProjectSelectDropdown />
    </div>
  );
};

export default memo(({ value, className, onChange, readOnly }) => {
  const intl = useIntl();
  const onSearch = useCallback((value) => {
    // dispatch(loadAll(value))
  }, []);

  return (
    <Select
      dropdownMatchSelectWidth={true}
      className={cls(style.component, className, {
        [style.readOnly]: readOnly,
      })}
      showSearch={true}
      disabled={readOnly}
      onChange={onChange}
      value={value}
      suffixIcon={<StyledDropdownIcon />}
      onSearch={onSearch}
      placeholder={
        <>
          <AntIcon component={GlobalIcon} className={style.icon} />
          <span>
            <FormattedMessage id="admin.memosFormPage.placeholders.chooseLanguage" />
          </span>
        </>
      }
    >
      <Select.Option value="en">
        <FormattedMessage id="languages.en" />
      </Select.Option>
      <Select.Option value="fr">
        <FormattedMessage id="languages.fr" />
      </Select.Option>
    </Select>
  );
});
