import React, { useCallback, useRef } from 'react'
import { Button } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import style from './Questions.module.scss'
import cls from 'classnames'
import { addQuestion, updateQuestion } from './reducer'
import Question from './Question'

const Questions = ({
  className,
  canAddQuestions = true,
  forms,
  readOnly
}) => {
  const dispatch = useDispatch()
  const { items } = useSelector(s => s.memosFormQuestions)
  const onQuestionChange = useCallback(data => dispatch(updateQuestion(data)), [])
  const onAddClick = useCallback(() => dispatch(addQuestion()), [])
  return (
    <section className={cls(style.component, className, {
      [style.readOnly]: readOnly
    })}>
      <div className={style.items}>
        {items.map((item) => {
          return (
            <Question
              readOnly={readOnly}
              forms={forms}
              key={item.id}
              data={item}
              onChange={onQuestionChange} />
          )
        })}
      </div>
      {canAddQuestions && (
        <Button
          onClick={onAddClick}
          className={style.button}>
          <FormattedMessage id='admin.memosFormPage.buttons.question' />
        </Button>
      )}
    </section>
  )
}

export default Questions