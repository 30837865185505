import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./TagsPage.module.scss";
import { Card, Button, Form, Input, Alert, AutoComplete } from "antd";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { submit, setError, reset, loadAll, setForm } from "./reducer";
import _, { isEqual, union, uniqBy, uniqWith } from "lodash";
import cls from "classnames";
import { PageTitle } from "components/PageItems";
import { useHistory, useRouteMatch } from "react-router";
import SearchIcon from "components/icons/SearchIcon";
import LeftArrowIcon from "components/icons/LeftArrowIcon";
import TagsTable from "./TagsTable";
import TagModal from "./tagModal/TagModal";
import { loadAll as loadAllTags } from "./reducer";
import LinkButton from "components/buttons/LinkButton";
import PlusIcon from "components/icons/PlusIcon";
import MemoButton from "components/buttons/MemoButton";

export const StyledPlusIcon = () => {
  return (
    <div className={style.plusIcon}>
      <PlusIcon />
    </div>
  )
};

const TagsPage = ({ className, supervisor, admin, creator }) => {
  const match = useRouteMatch()
  const { user } = useSelector((s) => s.session);

  const intl = useIntl();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState("");

  const [filteredTags, setFilteredTags] = React.useState([]);
  const [tagModal, setTagModal] = useState(false)

  const handleChangeFilteredTags = (filteredItems) => {
    setFilteredTags(uniqBy(filteredItems.map(tagItem => ({ value: tagItem.name })), 'value'))
  }

  const openTagModal = () => {
    setTagModal(true)
  }

  const handleEditTag = (tagData) => {
    dispatch(setForm(tagData))
    setTagModal(true)
  }

  useEffect(() => {
    dispatch(loadAll())
    dispatch(loadAllTags())
    return () => dispatch(reset())
  }, [match.path])

  return (
    <div className={style.root}>
      <header className={style.header}>
        <div className={style.pageTitle}>
          <LinkButton size="small" url="/app/contactlist" className={style.backButton}><LeftArrowIcon /></LinkButton>
          <PageTitle variant="h1"><FormattedMessage id="admin.tagsPage.tagsManagement" /></PageTitle>
        </div>
        <AutoComplete
          className={style.search}
          dropdownClassName={style.dropdown}
          value={searchValue}
          onChange={value => {
            setSearchValue(value);
          }}
          options={filteredTags}
        >
          <Input prefix={<SearchIcon />} className={style.input} placeholder={intl.formatMessage({ id: 'admin.tagsPage.findTag' })} />
        </AutoComplete>
        <MemoButton className={style.addButton} onClick={openTagModal}><StyledPlusIcon /><FormattedMessage id="admin.tagsPage.create.title"/></MemoButton>
      </header>
      <div>
        <TagsTable searchValue={searchValue} onChangeFilteredTags={handleChangeFilteredTags} handleEditTag={handleEditTag} />
      </div>
      <TagModal open={tagModal} onClose={() => setTagModal(false)} />
    </div>
  );
};

export default memo(TagsPage);
