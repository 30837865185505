import React, { useCallback, useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { Button, Card, Form, Input, notification } from "antd";
import "firebase/auth";
import { FormattedMessage, useIntl } from "react-intl";
import { PageTitle } from "components/PageItems";
import { useDispatch, useSelector } from "react-redux";
import { setError, reset, saveForm, reSetForm } from "../reducer";
import SuccessIcon from "components/icons/SuccessIcon";
import CloseIcon from "components/icons/CloseIcon";
import style from "./TagModal.module.scss";
import { trim } from "lodash";

const TagModal = ({ open, onClose }) => {
  const { user } = useSelector((state) => state.session);
  const [antForm] = Form.useForm();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { items: tagsList, form, error, saving } = useSelector((s) => s.tags);

  const [existingTag, setExistingTag] = useState(false);
  const [invalidTag, setInvalidTag] = useState(true);
  const [submitClicked, setSubmitClicked] = useState(false);

  const onFieldsChange = useCallback(
    (fields) => {
      error && dispatch(setError());
    },
    [error]
  );

  const handleFormChange = (_, allValues) => {
    if (!!allValues.name) {
      setInvalidTag(false);
    } else {
      setInvalidTag(true);
    }
  };

  React.useEffect(() => {
    if (form.id) {
      antForm.resetFields();
      antForm.setFieldsValue(form);
      if (form.name) {
        setInvalidTag(false);
      }
    }
  }, [form]);

  const renderSuccessMessage = (message) => {
    return (
      <div className={style.successMessageContainer}>
        <div className={style.successIcon}>
          <SuccessIcon width="24px" height="24px" />
        </div>
        <span>{message}</span>
      </div>
    );
  };

  const onSubmit = useCallback(async () => {
    setSubmitClicked(true);
    try {
      const values = await antForm.validateFields();

      let stagedValues = { ...values, name: trim(values.name) }

      const result = tagsList.filter(tagItem => tagItem.name.toLowerCase() === stagedValues.name.toLowerCase());

      if (result && result.length > 0) {
        setExistingTag(true);
      } else {
        setExistingTag(false);
        const tagId = await dispatch(saveForm(stagedValues));

        if (tagId) {
          dispatch(reset());
          notification.open({
            style: {
              borderRadius: "4px",
              boxShadow:
                "0px 10px 15px - 3px rgba(26, 32, 44, 0.1), 0px 4px 6px - 2px rgba(26, 32, 44, 0.05)",
            },
            message: renderSuccessMessage(
              intl.formatMessage({
                id: !!values.id
                  ? "admin.tagsPage.edit.success"
                  : "admin.tagsPage.create.success",
              })
            ),
            className: style.successMessage,
            onClick: () => { },
          });
          handleClose();
        }
      }
    } catch (ex) {
      console.warn(ex);
    }
  }, [tagsList]);

  const handleClose = () => {
    antForm.resetFields();
    setExistingTag(false);
    setInvalidTag(false);
    setSubmitClicked(false);
    dispatch(reSetForm());
    onClose();
  };

  return (
    <Modal
      className={style.container}
      visible={open}
      closable
      maskClosable={false}
      width={440}
      footer={null}
      onCancel={handleClose}
      destroyOnClose
      centered
      closeIcon={
        <div className={style.closeIcon}>
          <CloseIcon width="32px" height="32px" />
        </div>
      }
    >
      <Card
        bodyStyle={{ padding: 0 }}
        bordered={false}
        className={style.contentCard}
      >
        <Form
          validateMessages={{
            required: "${label} is required!",
            string: {
              max: intl.formatMessage(
                {
                  id: "admin.tagsPage.create.validation.max",
                }
              ),
            },
          }}
          form={antForm}
          className={style.formContainer}
          onFieldsChange={onFieldsChange}
          onFinish={onSubmit}
          onValuesChange={handleFormChange}
        >
          <header className={style.header}>
            <PageTitle variant="h2">
              <FormattedMessage
                id={
                  form.id
                    ? "admin.tagsPage.edit.title"
                    : "admin.tagsPage.create.title"
                }
              />
            </PageTitle>
          </header>
          <main>
            <Form.Item
              className={style.formItem}
              name="name"
              labelCol={{ xs: 24 }}
              rules={[{ max: 100 }]}
            >
              <Input className={style.input} />
            </Form.Item>
            {!invalidTag && existingTag && (
              <div className={style.tagError}>
                <span>
                  <FormattedMessage id="admin.tagsPage.create.existingTag" />
                </span>
              </div>
            )}
            {invalidTag && submitClicked && (
              <div className={style.tagError}>
                <span>Invalid tag</span>
              </div>
            )}
            <Form.Item hidden name="id" />
          </main>
          <footer className={style.footer}>
            <div className={style.actionContainer}>
              <Button
                className={style.submitButton}
                loading={saving}
                type="primary"
                htmlType="submit"
                disabled={invalidTag}
              >
                {intl.formatMessage({
                  id: !!form.id
                    ? "admin.tagsPage.edit.submit"
                    : "admin.tagsPage.create.submit",
                })}
              </Button>
            </div>
          </footer>
        </Form>
      </Card>
    </Modal>
  );
};

export default TagModal;
