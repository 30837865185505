import React, { memo, useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./ForgotPassword.module.scss";
import { Button, Form, Input, Alert, Modal } from "antd";
import {
  sendPasswordResetEmail,
  setForgotPasswordForm,
  reset,
} from "./reducer";
import _ from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useRouteMatch } from "react-router";
import EmailSent from "../../assets/email-sent.png";

export const ForgotPassword = () => {
  const [form] = Form.useForm();
  const [submitted, setSubmitted] = useState(false);
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const onSubmit = useCallback(async () => {
    let emailValue = ''
    try {
      let { email } = await form.validateFields();
      emailValue = email
    } catch (ex) {
      console.warn(ex);
      return
    }

    try {
      await dispatch(sendPasswordResetEmail(emailValue));
      dispatch(reset());
    }
    catch (ex) {
      console.warn(ex);
    }
    setSubmitted(true);
  }, []);
  const onBack = useCallback(() => {
    window.history.back();
  }, []);

  let { passwordEmailSending, forgotPasswordError } = useSelector(
    (s) => s.signIn
  );

  return (
    <div className={style.container}>
      <div className={style.component}>
        <header className={style.header}>
          {submitted ? (
            <React.Fragment>
              <img src={EmailSent} alt="Email" className={style.icon} />
              <h3 className={style.title}>
                <FormattedMessage id="forgotPassword.success.title" />
              </h3>
              <span className={style.desc}>
                <FormattedMessage id="forgotPassword.success.desc" />
              </span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h3 className={style.title}>
                <FormattedMessage id="forgotPassword.title" />
              </h3>
              <span className={style.desc}>
                <FormattedMessage id="forgotPassword.desc" />
              </span>
            </React.Fragment>
          )}
        </header>
        {/* {forgotPasswordError && (
          <Alert
            type='error'
            message={intl.formatMessage({ id: forgotPasswordError.code })} />
        )} */}
        <Form
          form={form}
          validateMessages={{
            required: intl.formatMessage({ id: "validation.required" }),
            types: {
              email: intl.formatMessage({ id: "validation.invalidEmail" }),
            },
          }}
        >
          <Form.Item
            labelCol={{ xs: 24 }}
            label={intl.formatMessage({ id: "forgotPassword.fields.email" })}
            className={style.formItem}
            name="email"
            rules={[
              {
                required: true,
                type: "email",
              },
            ]}
            validateTrigger="onSubmit"
          >
            <Input
              type="email"
              placeholder="name@domain.com"
              className={style.input}
            />
          </Form.Item>
          <footer className={style.footer}>
            <Button
              type="primary"
              onClick={onSubmit}
              loading={passwordEmailSending}
              className={style.submitButton}
            >
              <FormattedMessage
                id={
                  submitted
                    ? "forgotPassword.resubmit"
                    : "forgotPassword.submit"
                }
              />
            </Button>
            <Button onClick={onBack} className={style.backButton}>
              <FormattedMessage id="forgotPassword.back" />
            </Button>
          </footer>
        </Form>
      </div>
    </div>
  );
};

export default memo(ForgotPassword);
