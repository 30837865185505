import _ from 'lodash'
import firebase from 'firebase/app'
import 'firebase/storage'

export const ALLOWED_IMAGE_TYPES = ['image/png', 'image/jpg', 'image/jpeg']
export const THUMBNAIL = {
  MEDIUM: '480x480',
  SMALL: '200x200'
}

export const wait = (delay) => {
  return new Promise(resolve => {
    setTimeout(resolve, delay)
  })
}

export const getThumbnail = (data) => {
  return _.get(data, 'thumbnail') || _.get(data, 'url') || data
}

export const fetchThumbnail = async (path, fileId, size = THUMBNAIL.SMALL) => {
  let url = null
  for (let i = 0; i < 1; i++) {
    try {
      await wait(3000)
      url = await firebase.storage().ref(`${path}/thumbnails/${fileId}_${size}`).getDownloadURL()
      break
    } catch (ex) {
      console.info('thumbnail not ready')
      //  console.warn(ex)
    }
  }
  return url
}

export const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const toCapitalize = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export const csvToArray = (str, delimiter = ",") => {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf("\n") + 1).split("\n");

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const arr = rows.map(function (row) {
    const values = row.split(delimiter);
    const el = headers.reduce(function (object, header, index) {
      object[header] = values[index];
      return object;
    }, {});
    return el;
  });

  // return the array
  return arr;
}