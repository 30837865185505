import flatten from 'flat'
import en from 'locale/en'
import fr from 'locale/fr'

const CHANGE_LOCALE = 'LANGUAGE_SWITCH/CHANGE_LOCALE'
const SELECT_LOCALE = 'LANGUAGE_SWITCH/SELECT_LOCALE'
const messages = {
  fr: flatten(fr),
  en: flatten(en)
}

export const changeLocale = (locale) => async (dispatch, getState) => {
  dispatch({
    type: CHANGE_LOCALE,
    payload: {
      locale,
      key: locale,
      messages: messages[locale]
    }
  })
}

export const selectLocale = (locale) => async (dispatch, getState) => {
  dispatch({
    type: SELECT_LOCALE,
    payload: {
      locale,
      key: locale,
      messages: messages[locale]
    }
  })
}

export const actions = {
  changeLocale
}

const initialState = {
  locale: 'fr',
  key: 'fr',
  messages: messages.fr,
  localeSelected: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return {
        ...state,
        ...action.payload
      }

    case SELECT_LOCALE:
      return {
        ...state,
        ...action.payload,
        localeSelected: true
      }
    default:
      return state
  }
}