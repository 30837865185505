import React from "react";

const PassedCircleIcon = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="16" height="16" rx="8" fill="#2F80ED" />
    <g clipPath="url(#clip0)">
      <rect width="1.66667" height="5.66669" rx="0.833336" transform="matrix(-0.715801 0.698305 -0.715801 -0.698305 7.85181 10.8359)" fill="white" />
      <rect width="1.66667" height="9.33336" rx="0.833336" transform="matrix(0.715801 0.698305 -0.715801 0.698305 12.1404 4.31836)" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="10.6667" height="10.6667" fill="white" transform="translate(2.66675 2.6665)" />
      </clipPath>
    </defs>
  </svg>
);

export default PassedCircleIcon;