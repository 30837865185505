import React from "react";

const RenameIcon = () => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 12V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6C2 3.79086 3.79086 2 6 2H12" stroke="currentColor" strokeWidth="3" strokeLinecap="round" />
    <path d="M21.0829 2.83333L12.8333 11.0829" stroke="currentColor" strokeWidth="3" strokeLinecap="round" />
  </svg>

);

export default RenameIcon;