import React, { memo, useCallback, useEffect } from 'react'
import { Select } from 'antd'
import style from './TeamSelect.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { loadAll } from './reducer'

export default memo(({ value, onChange }) => {
  const dispatch = useDispatch()
  const { items } = useSelector(s => s.adminTeamSelect)
  const onSearch = useCallback((value) => {
    // dispatch(loadAll(value))
  }, [])
  useEffect(() => {
    dispatch(loadAll(value))
  }, [])
  return (
    <Select
      showSearch={true}
      onChange={onChange}
      value={value}
      onSearch={onSearch}>
      {items.map(item => {
        return (
          <Select.Option value={item.id} key={item.id}>
            {item.name}
          </Select.Option>
        )
      })}
    </Select>
  )
})