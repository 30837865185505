import { Progress } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import useSound from 'use-sound'
import style from './RecordCountdown.module.scss'

import boopSfx from './countdown.mp3';

const soundUrl = 'https://media.geeksforgeeks.org/wp-content/uploads/20190531135120/beep.mp3'

const RecordCountdown = forwardRef((props, ref) => {
  const beepAudio = new Audio(soundUrl)
  const { end, started, onEnded } = props;
  const [seconds, setSeconds] = useState(end);
  const increment = useRef(null);
  const audioRef = useRef(null)
  const [play, { stop }] = useSound(boopSfx)


  React.useEffect(() => {
    const audioEl = document.getElementsByClassName("audio-element")[0];
    if (started) {
      setSeconds(end)
      increment.current = setInterval(() => {
        setSeconds(seconds => seconds > 1 ? seconds - 1 : 0)
        audioEl.play();
      }, 1000);
    }

    return () => clearInterval(increment.current);
  }, [started])

  React.useEffect(() => {
    if (seconds < 1) {
      clearInterval(increment.current)
      onEnded(true)
    }
  }, [seconds])

  useImperativeHandle(ref, () => ({
    cancelCountdown() {
      clearInterval(increment.current)
      onEnded(false);
    },
  }));

  return (
    <div className={style.countdown}>
      {/* <div onClick={play} ref={audioRef} >hello</div> */}
      <audio className="audio-element">
        <source src={boopSfx} />
      </audio>
      <Progress type="circle" percent={(100 / end) * seconds} format={percent => `${seconds}`} strokeColor="#EB5757" width={160} strokeWidth={14}  />
    </div>
  )
})

export default RecordCountdown;