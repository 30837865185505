import React from "react";

const CloseIcon = (props) => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M24.4854 8.14209L7.51479 25.1127" stroke="black" strokeWidth="2.5" strokeLinecap="round" />
    <path d="M24.4854 25.1128L7.51479 8.14223" stroke="black" strokeWidth="2.5" strokeLinecap="round" />
  </svg>
);

export default CloseIcon;
