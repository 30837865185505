import React, { memo, useCallback } from 'react'
import { Input } from 'antd'

const ZipCode = memo(({ value, country, onChange }) => {
	const handleOnChange = useCallback((e) => {
		onChange(e.target.value)
	}, [])

	return (
		<Input
			value={value}
			onChange={handleOnChange} />
	)
})

export default ZipCode