import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './UsersFormPage.module.scss'
import { Form, Input, Button, Select, Card, Spin, Modal, Alert } from 'antd'
import { loadOne, setForm, saveForm, resetPassword, reset } from './reducer'
import _ from 'lodash'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import TeamSelect from 'components/teamSelect/TeamSelect'

const USER_ROLES = {
  ADMIN: 'admin',
  CREATOR: 'creator'
}

const USER_LANGUAGES = {
  FR: 'fr',
  EN: 'en'
}

const CONFIG = {
  admin: {
    plural: 'admins',
    listUrl: '/app/admins'
  },
  creator: {
    plural: 'creators',
    listUrl: '/app/creators'
  }
}

export default function UsersFormPage({ role }) {
  const config = CONFIG[role]
  const [antdForm] = Form.useForm()
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const { params } = useRouteMatch()
  const { form, loading, resetPasswordLoading, saving, error } = useSelector(state => state.adminCreators)
  const onResetPassword = useCallback(async () => {
    try {
      await dispatch(resetPassword())
      Modal.success({
        title: 'Email has been sent'
      })
    } catch (ex) {
      console.warn(ex)
    }
  }, [])
  const onSubmit = useCallback(async () => {
    try {
      const values = await antdForm.validateFields()
      values.role = role
      await dispatch(saveForm(values))
      history.push(config.listUrl)
    } catch (ex) {
      console.warn(ex)
    }
  }, [])
  const validatePasswords = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue('password') === value)
        return Promise.resolve()
      return Promise.reject(
        intl.formatMessage({ id: 'validation.passwordsDoNotMatch' })
      )
    }
  })

  useEffect(() => {
    antdForm.setFieldsValue(form)
  }, [form])

  useEffect(() => {
    if (params.id)
      dispatch(loadOne(params.id))
    return () => dispatch(reset())
  }, [])

  return (
    <Spin spinning={loading}>
      <div className={style.component}>
        <Card className={style.content}>
          <header>
            <h1>
              {intl.formatMessage({
                id: `admin.${config.plural}.${params.id ? 'update' : 'create'}`
              })}
            </h1>
          </header>
          {error && (
            <Alert
              type='error'
              message={intl.formatMessage({ id: error.code })} />
          )}
          <Form
            autoComplete="off"
            form={antdForm}
            validateMessages={{
              required: intl.formatMessage({ id: 'validation.required' }),
              types: {
                email: intl.formatMessage({ id: 'validation.invalidEmail' })
              }
            }}>
            <Form.Item
              labelCol={{ xs: 24 }}
              label='First name'
              className={style.formItem}
              rules={[{ required: true }]}
              name='firstName'>
              <Input className={style.input} placeholder='' />
            </Form.Item>
            <Form.Item
              labelCol={{ xs: 24 }}
              label='Last name'
              className={style.formItem}
              rules={[{ required: true }]}
              name='lastName'>
              <Input className={style.input} placeholder='' />
            </Form.Item>
            <Form.Item
              labelCol={{ xs: 24 }}
              label='Email'
              className={style.formItem}
              rules={[{ required: true, type: 'email' }]}
              name='email'>
              <Input className={style.input} placeholder='' type='email' />
            </Form.Item>
            {role === 'creator' && (
              <Form.Item
                labelCol={{ xs: 24 }}
                label='Team'
                className={style.formItem}
                rules={[{ required: true }]}
                name='teamId'>
                <TeamSelect
                  className={style.input} />
              </Form.Item>
            )}
            {/* <Form.Item
							labelCol={{ xs: 24 }}
							label='Role'
							className={style.formItem}
							rules={[{ required: true }]}
							name='role'>
							<Select className={style.input}>
								<Select.Option value={USER_ROLES.ADMIN}>
									<FormattedMessage id={`admin.superUsers.roles.${USER_ROLES.ADMIN}`} />
								</Select.Option>
								<Select.Option value={USER_ROLES.CREATOR}>
									<FormattedMessage id={`admin.superUsers.roles.${USER_ROLES.CREATOR}`} />
								</Select.Option>
							</Select>
						</Form.Item> */}
            {/* <Form.Item
							labelCol={{ xs: 24 }}
							label='Company name'
							rules={[{ required: true }]}
							className={style.formItem}
							name='companyName'>
							<Input className={style.input} placeholder='' />
						</Form.Item>
						<Form.Item
							labelCol={{ xs: 24 }}
							label='Phone number'
							rules={[{ required: true }]}
							className={style.formItem}
							name='phoneNumber'>
							<Input
								className={style.input}
								placeholder='' />
						</Form.Item> */}
            <Form.Item
              labelCol={{ xs: 24 }}
              label='Language'
              className={style.formItem}
              rules={[{ required: true }]}
              name='language'>
              <Select className={style.input}>
                <Select.Option value={USER_LANGUAGES.FR}>
                  <FormattedMessage id={`languages.${USER_LANGUAGES.FR}`} />
                </Select.Option>
                <Select.Option value={USER_LANGUAGES.EN}>
                  <FormattedMessage id={`languages.${USER_LANGUAGES.EN}`} />
                </Select.Option>
              </Select>
            </Form.Item>
            {params.id ? (
              <Form.Item>
                <Button onClick={onResetPassword} loading={resetPasswordLoading}>
                  <FormattedMessage id='admin.creators.fields.resetPassword' />
                </Button>
              </Form.Item>
            ) : (
              <>
                <Form.Item
                  labelCol={{ xs: 24 }}
                  label={intl.formatMessage({ id: 'admin.creators.fields.password' })}
                  className={style.formItem}
                  rules={[{
                    required: true,
                    min: 8
                  }]}
                  name='password'>
                  <Input className={style.input} placeholder='' type='password' />
                </Form.Item>
                <Form.Item
                  labelCol={{ xs: 24 }}
                  label={intl.formatMessage({ id: 'admin.creators.fields.passwordAgain' })}
                  className={style.formItem}
                  rules={[{ required: true }, validatePasswords]}
                  name='passwordAgain'>
                  <Input className={style.input} placeholder='' type='password' />
                </Form.Item>
              </>
            )}
            <Button onClick={onSubmit} type='primary' loading={saving}>
              {intl.formatMessage({ id: params.id ? 'general.save' : 'general.create' })}
            </Button>
          </Form>
        </Card>
      </div>
    </Spin>
  )
}