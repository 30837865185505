import React from "react";
import style from "./NotFound.module.scss";
import individualPageStyle from "../individual/IndividualPage.module.scss";
import {FormattedMessage} from "react-intl";

const NotFound = () => {
  return (
    <div className={style.container}>
      <div className={style.content}>
        <div className={style.image}>
          <img src={'404.svg'} alt="404"/>
        </div>
        <h2 className={style.title}>
          <FormattedMessage id="notFound.title" />
          <span className={style.subtitle}><FormattedMessage id="notFound.subtitle" /></span>
        </h2>
        <p className={style.text}>
          <FormattedMessage id="notFound.text" />
        </p>
      </div>
      <div className={individualPageStyle.contactContainer}>
        <p className={individualPageStyle.contactContent}>
          <FormattedMessage id="assistance.needHelp" />
          <span className={individualPageStyle.contactButton}>
             <FormattedMessage id="assistance.contactUs" />
           </span>
        </p>
      </div>
    </div>
  )
}

export default NotFound;