import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const LOADING_START = 'SIGN_IN/LOADING_START'
const LOADING_SUCCESS = 'SIGN_IN/LOADING_SUCCESS'
const LOADING_END = 'SIGN_IN/LOADING_END'
const SAVING_START = 'SIGN_IN/SAVING_START'
const SAVING_END = 'SIGN_IN/SAVING_END'
const RESET = 'SIGN_IN/RESET'
const SET_ERROR = 'SIGN_IN/SET_ERROR'
const SET_FORGOT_PASSWORD_ERROR = 'SIGN_IN/SET_FORGOT_PASSWORD_ERROR'
const SET_FORGOT_PASSWORD_FORM = 'SIGN_IN/SET_FORGOT_PASSWORD_FORM'
const SEND_PASSWORD_RESET_EMAIL_START = 'SIGN_IN/SEND_PASSWORD_RESET_EMAIL_START'
const SEND_PASSWORD_RESET_EMAIL_END = 'SIGN_IN/SEND_PASSWORD_RESET_EMAIL_END'

export const setForgotPasswordError = (error) => {
	return {
		type: SET_FORGOT_PASSWORD_ERROR,
		error
	}
}

export const setError = (error) => {
	return {
		type: SET_ERROR,
		error
	}
}

export const setForgotPasswordForm = (form) => (dispatch) => {
	dispatch({
		type: SET_FORGOT_PASSWORD_FORM,
		form
	})
}

export const signIn = ({ email, password, remember }) => async (dispatch) => {
	dispatch({ type: SAVING_START })
	try {
		const auth = firebase.auth()
		const persistence = remember ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION
		await auth.setPersistence(persistence)
		await auth.signInWithEmailAndPassword(email, password)
	} catch (ex) {
		dispatch(setError(ex))
		throw ex
	}
	dispatch({ type: SAVING_END })
}

export const sendPasswordResetEmail = (email) => async (dispatch) => {
	dispatch({
		type: SEND_PASSWORD_RESET_EMAIL_START
	})
	try {
		await firebase.auth().sendPasswordResetEmail(email)
	} catch (ex) {
		dispatch(setForgotPasswordError(ex))
		throw ex
	}
	dispatch({
		type: SEND_PASSWORD_RESET_EMAIL_END
	})
}

export const submit = (values) => async (dispatch, getState) => {
	dispatch({
		type: SAVING_START
	})

	try {
		let { email, password } = values
		let { user } = await firebase.auth().createUserWithEmailAndPassword(email, password)
		await firebase.firestore().collection('users').doc(user.uid).set({
			...values,
			password: null
		})
	} catch (ex) {
		console.warn(ex)
	}

	dispatch({
		type: SAVING_END
	})
}

export const reset = () => (dispatch) => {
	dispatch({
		type: RESET
	})
}

export const actions = {
	setForgotPasswordForm,
	signIn,
	sendPasswordResetEmail,
	reset
}

const initialState = {
	saving: false,
	passwordEmailSending: false,
	forgotPasswordError: null,
	forgotPasswordForm: {
		email: null
	},
	error: null
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case LOADING_START:
			return {
				...state,
				loading: true
			}
		case LOADING_END:
			return {
				...state,
				loading: false
			}
		case LOADING_SUCCESS:
			return {
				...state,
				items: action.items
			}
		case SET_FORGOT_PASSWORD_FORM:
			return {
				...state,
				forgotPasswordForm: {
					...state.forgotPasswordForm,
					...action.form
				}
			}
		case SAVING_START:
			return {
				...state,
				saving: true
			}
		case SAVING_END:
			return {
				...state,
				saving: false
			}
		case SEND_PASSWORD_RESET_EMAIL_START:
			return {
				...state,
				passwordEmailSending: true
			}
		case SEND_PASSWORD_RESET_EMAIL_END:
			return {
				...state,
				passwordEmailSending: false
			}
		case SET_FORGOT_PASSWORD_ERROR:
			return {
				...state,
				passwordEmailSending: false,
				forgotPasswordError: action.error
			}
		case SET_ERROR:
			return {
				...state,
				saving: false,
				error: action.error
			}
		case RESET:
			return {
				...initialState
			}
		default:
			return state
	}
}
