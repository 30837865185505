import React, { memo, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './MemoItem.module.scss'
import { Avatar, Button } from 'antd'
import { } from './reducer'
import _ from 'lodash'
import cls from 'classnames'
import { useParams, Link } from 'react-router-dom'
import { getThumbnail } from 'utils'
import { openShareModal } from './share/reducer'
import Icon from 'components/Icon'
import { FormattedMessage } from 'react-intl'

export const MemoItem = ({ data }) => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.session)
  const { items: creators } = useSelector(state => state.adminCreators)
  const userId = _.get(data, 'user.uid')
  const currentCreator = creators.find(creator => creator.id === userId)

  const avatar = _.get(currentCreator, 'avatar')
  const url = `/app/projects/${data.projectId}/memos/${data.id}/view`
  const thumbnail = _.get(data, 'url.embed.thumbnail_url')
  const thumbnailId = _.get(data, 'url.embed.thumbnailId')
  let thumbnailCls = null
  if (!thumbnail && thumbnailId)
    thumbnailCls = `thumbnail-${thumbnailId + 1}`
  const onShare = useCallback((e) => {
    e.preventDefault()
    dispatch(openShareModal({ data }))
  }, [data])

  const editable = currentCreator && user && (currentCreator.id === user.uid)

  return (
    <Link className={style.component} to={url}>
      <Avatar
        src={getThumbnail(avatar)}
        className={style.avatar} />
      <div className={cls(style.bg, thumbnailCls)} style={thumbnail && ({
        backgroundImage: `url("${thumbnail}")`
      })}>
        <header className={style.header}>
          {/* <Button type='text' className={cls(style.iconButton, style.edit)}>
            <Icon name='edit' small />
          </Button> */}
          <div className={style.actions}>
            {editable && (
              <Link
                to={`/app/projects/${data.projectId}/memos/${data.id}`}
                className={cls(style.iconButton, style.stats)}>
                <Icon name='edit' small />
              </Link>
            )}
            <Button
              type='text'
              className={cls(style.iconButton, style.share)}
              onClick={onShare}>
              <Icon name='share' small />
            </Button>
            <Link
              to={`/app/projects/${data.projectId}/memos/${data.id}/stats`}
              className={cls(style.iconButton, style.stats)}>
              <Icon name='stats' small />
            </Link>

          </div>

          <span className={style.memoId}>
            {data.projectId}-{data.id}
          </span>
        </header>
      </div>
      <div className={style.info}>
        <h4 className={style.title}>
          {data.name}
        </h4>
        <span className={style.hint}>
          <FormattedMessage
            id='admin.memos.item.completed'
            values={{ count: data.completedCount || 0 }} />
        </span>
      </div>
    </Link>
  )
}

export default MemoItem
