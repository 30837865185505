import React from 'react'
import { StyleButton } from './StyleButton'
import style from "./RichEditor.module.scss"

const BLOCK_TYPES = [
  { label: 'OL', style: 'ordered-list-item' },
  { label: 'UL', style: 'unordered-list-item' }
];

const BlockStyleControls = ({ editorState, onToggle }) => {
  const selection = editorState.getSelection()
  const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType()

  return (
    <div className={style.editorControls}>
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
          className={style.editorStyleButton}
        />
      ))}
    </div>
  );
};

export default BlockStyleControls