const routes = {
	landing: '/',
	home:'/',
	authType: '/types',
	root: '/:docId',
	// root: '/:docId{=:userId}?',
	// root: '/:id1?{-:id2}?',
	rootSupervised: '/:docId/s/:userId',
	userSignUp: '/i/sign-up',
	userSignIn: '/i/sign-in',
	supervisorSignUp: '/s/sign-up',
	supervisorSignIn: '/s/sign-in',
	content: '/content',
	stats: '/stats',
	forgotPassword: '/forgot-password',
	resetPassword: '/reset-password',
	signIn: '/sign-in',
	admin: {
		root: '/admin',
		signIn: '/sign-in'
	},
	app: {
		projects: {
			new: '/app/projects/new'
		}
	}
}

export default routes
export const toUrl = (name, params) => {
	return routes[name].replace(/\:([a-z]+)/g, (a, b) => {
		return params[b]
	})
}
export const toRoot = (location, params) => {
	if (location.pathname.startsWith(routes.admin.root))
		return routes.admin.root
	return `/${params.docId}`
}
