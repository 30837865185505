import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./ContactsPage.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { reset, loadAll, setForm } from "./reducer";
import { PageTitle } from "components/PageItems";
import { useRouteMatch } from "react-router";
import ContactsTable from "./ContactsTable";
import ContactModal from "./contactModal/ContactModal";
import ImportContactModal from "./importContact/ImportContactModal";
import { loadAll as loadAllTags } from "../tags/reducer";

const ContactsPage = ({ className, supervisor, admin, creator }) => {
  const match = useRouteMatch();
  const { user } = useSelector((s) => s.session);

  const intl = useIntl();
  const dispatch = useDispatch();

  const [contactModal, setContactModal] = useState({
    open: false,
    mode: 'create'
  });

  const [importContactModal, setImportContactModal] = useState({
    open: false
  })

  const openContactModal = () => {
    setContactModal({ open: true, mode: 'create' });
  };

  useEffect(() => {
    dispatch(loadAll());
    dispatch(loadAllTags());
    return () => dispatch(reset());
  }, [match.path]);

  const handleViewContact = (contactData) => {
    dispatch(
      setForm({
        ...contactData,
        tags:
          contactData.tags && contactData.tags.length > 0
            ? contactData.tags.map((tagItem) => tagItem.id)
            : [],
      })
    );
    setContactModal({ open: true, mode: 'view' });
  };

  const handleEditContact = (contactData) => {
    dispatch(
      setForm({
        ...contactData,
        tags:
          contactData.tags && contactData.tags.length > 0
            ? contactData.tags.map((tagItem) => tagItem.id)
            : [],
      })
    );
    setContactModal({ open: true, mode: 'edit' });
  };

  const handleClickImport = () => {
    setImportContactModal({
      open: true
    });
  }

  return (
    <div className={style.root}>
      <header className={style.header}>
        <PageTitle variant="h1"><FormattedMessage id="navBar.contacts" /></PageTitle>
      </header>
      <div>
        <ContactsTable onCreate={openContactModal} onView={handleViewContact} onEdit={handleEditContact} onImportClick={handleClickImport} />
      </div>
      <ContactModal
        open={contactModal.open}
        mode={contactModal.mode}
        onClose={() => setContactModal(false)}
      />
      <ImportContactModal
        open={importContactModal.open}
        onClose={() => setImportContactModal(false)}
      />
    </div>
  );
};

export default memo(ContactsPage);
