import React from "react";

const DeleteIcon = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 2.5V11.5C3 13.7091 4.79086 15.5 7 15.5H9C11.2091 15.5 13 13.7091 13 11.5V2.5"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M1.5 2.5H14.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 0.5H10"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 5V13" stroke="currentColor" strokeLinecap="round" />
    <path d="M10.5 5V13" stroke="currentColor" strokeLinecap="round" />
    <path d="M5.5 5V13" stroke="currentColor" strokeLinecap="round" />
  </svg>
);

export default DeleteIcon;
