import { Button, Popover } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import style from './StickyNotificaton.module.scss'

const StickyNotificaton = ({ children }) => {
  const [visible, setVisible] = React.useState(true)
  return (
    <React.Fragment>
      {visible && (
        <div className={style.root}>
          <span>
            {children}
          </span>
          <Button type='primary' className={style.closeButton} onClick={() => setVisible(false)}><FormattedMessage id="general.gotIt" /></Button>
        </div>
      )}
    </React.Fragment>
  )
}

export default StickyNotificaton;