import React, { useCallback, useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { Button, Card, Divider, Form, Input, notification, Select, Tag } from "antd";
import firebase from "firebase/app";
import "firebase/auth";
import { FormattedMessage, useIntl } from "react-intl";
import { PageTitle } from "components/PageItems";
import { useDispatch, useSelector } from "react-redux";
import { setError, reset, saveForm, reSetForm } from "../reducer";
import SuccessIcon from "components/icons/SuccessIcon";
import CloseIcon from "components/icons/CloseIcon";
import { validateEmail } from "utils";

import style from "./ContactModal.module.scss";
import { trim } from "lodash";
import { TAG_COLOR } from "../../tags/TagsTable";
import { toCapitalize } from "utils";

const ContactModal = ({ open, mode, onClose }) => {
  const { user } = useSelector((state) => state.session);
  const [antdForm] = Form.useForm();
  const intl = useIntl();
  const dispatch = useDispatch();
  const language = useSelector(s => s.language)

  const { error, saving, form } = useSelector((s) => s.contacts);
  const { items: tagsList, } = useSelector((s) => s.tags);

  const [existingEmail, setExistingEmail] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const [modalMode, setModalMode] = useState(mode);

  React.useEffect(() => {
    if (form.id) {
      antdForm.resetFields();
      antdForm.setFieldsValue(form);
    }
  }, [form]);

  React.useEffect(() => {
    setModalMode(mode)
  }, [mode])

  const onFieldsChange = useCallback(
    (fields) => {
      error && dispatch(setError());
    },
    [error]
  );

  const handleFormChange = (event, allValues) => {
    if (submitClicked) {
      if (allValues.email) {
        const isValidEmail = validateEmail(trim(allValues.email));
        setInvalidEmail(!isValidEmail);
      } else {
        setInvalidEmail(false);
      }
    }

    const keyName = Object.keys(event)[0];
    if (keyName === 'firstName' || keyName === 'lastName') {
      antdForm.setFieldsValue({
        [keyName]: toCapitalize(event[keyName])
      })
    }
  };

  const renderSuccessMessage = (message) => {
    return (
      <div className={style.successMessageContainer}>
        <div className={style.successIcon}>
          <SuccessIcon width="24px" height="24px" />
        </div>
        <span>{message}</span>
      </div>
    );
  };

  const onSubmit = useCallback(async () => {
    setSubmitClicked(true);
    try {
      let values = await antdForm.validateFields();

      const isValidEmail = validateEmail(trim(values.email));

      if (!isValidEmail) {
        setInvalidEmail(true);
        return;
      }

      if (!values.id) {
        const result = await firebase
          .firestore()
          .collection(`teams/${user.teamId}/contacts`)
          .where("email", "==", values.email)
          .get();

        if (result.docs && result.docs.length > 0) {
          const filteredResult = result.docs.filter((d) => !d.data().deleted);
          if (filteredResult && filteredResult.length > 0) {
            setExistingEmail(true);
            return;
          }
        }
      }

      setExistingEmail(false);
      const contactId = await dispatch(saveForm(values));

      if (contactId) {
        dispatch(reset());
        notification.open({
          style: {
            borderRadius: "4px",
            boxShadow:
              "0px 10px 15px - 3px rgba(26, 32, 44, 0.1), 0px 4px 6px - 2px rgba(26, 32, 44, 0.05)",
          },
          message: renderSuccessMessage(
            values.id ? intl.formatMessage({ id: "admin.contactsPage.edit.success" }) :
              intl.formatMessage({ id: "admin.contactsPage.create.success" })
          ),
          className: style.successMessage,
          onClick: () => { },
        });
        handleClose();
      }

    } catch (ex) {
      console.warn(ex);
    }
  }, []);

  const handleClose = () => {
    antdForm.resetFields();
    dispatch(reSetForm());
    setExistingEmail(false);
    setInvalidEmail(false);
    setSubmitClicked(false);
    onClose();
  };

  const filterTag = (inputValue, option) => {
    return option.label.toLowerCase().startsWith(inputValue.toLowerCase())
  }

  const isViewMode = React.useMemo(() => {
    return modalMode === 'view'
  }, [modalMode])

  const tagsValue = React.useMemo(() => {
    if (form && form.tags && form.tags.length > 0) {
      return tagsList.filter(tagItem => form.tags.includes(tagItem.id))
    }
  }, [tagsList, form])

  const modalTitle = React.useMemo(() => {
    if (modalMode === 'view') {
      return <FormattedMessage id="admin.contactsPage.view.title" />
    }
    else if (modalMode === 'edit') {
      return <FormattedMessage id="admin.contactsPage.edit.title" />
    }

    return <FormattedMessage id="admin.contactsPage.create.title" />

  }, [modalMode, language])

  const handleToggleEdit = () => {
    setModalMode('edit')
  }

  return (
    <Modal
      className={style.container}
      visible={open}
      closable
      maskClosable={false}
      width={440}
      footer={null}
      onCancel={handleClose}
      destroyOnClose
      closeIcon={
        <div className={style.closeIcon}>
          <CloseIcon width="32px" height="32px" />
        </div>
      }
    >
      <Card
        bodyStyle={{ padding: 0 }}
        bordered={false}
        className={style.contentCard}
      >
        <Form
          validateMessages={{
            required: "${label} is required!",
            types: {
              email: intl.formatMessage({
                id: "admin.contactsPage.create.validation.email",
              }),
            },
          }}
          form={antdForm}
          onFieldsChange={onFieldsChange}
          onFinish={onSubmit}
          onValuesChange={handleFormChange}
        >
          <header className={style.header}>
            <PageTitle variant="h2">
              {modalTitle}
            </PageTitle>
            <Divider />
          </header>
          <main>
            <Form.Item
              className={style.formItem}
              name="firstName"
              label={intl.formatMessage({ id: `profile.fields.firstName` })}
              labelCol={{ xs: 24 }}
            >
              <Input className={style.input} disabled={isViewMode} />
            </Form.Item>
            <Form.Item
              className={style.formItem}
              name="lastName"
              label={intl.formatMessage({ id: `profile.fields.lastName` })}
              labelCol={{ xs: 24 }}
            >
              <Input className={style.input} disabled={isViewMode} />
            </Form.Item>
            <Form.Item
              className={style.formItem}
              rules={[{
                required: true, message: intl.formatMessage({
                  id: "admin.contactsPage.create.emailRequired",
                }),
              }]}
              name="email"
              label={intl.formatMessage({ id: `profile.fields.email` })}
              labelCol={{ xs: 24 }}
            >
              <Input className={style.input} disabled={isViewMode || form.id} />
            </Form.Item>
            {!invalidEmail && existingEmail && (
              <div className={style.emailError}>
                <span>
                  <FormattedMessage id="admin.contactsPage.create.existingEmail" />
                </span>
              </div>
            )}
            {invalidEmail && (
              <div className={style.emailError}>
                <span>
                  <FormattedMessage id="admin.contactsPage.create.validation.email" />
                </span>
              </div>
            )}
            <Form.Item
              className={style.formItem}
              name="tags"
              label={intl.formatMessage({ id: `admin.tagsPage.tags` })}
              labelCol={{ xs: 24 }}
            >
              {isViewMode ? <div className={style.tagsContainer}>
                {tagsValue && tagsValue.length > 0 && (tagsValue.map(tagItem => (
                  <Tag color={TAG_COLOR} className={style.tag}>
                    {tagItem.name}
                  </Tag>
                )))}
              </div> : (
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  className={style.tagsSelect}
                  showArrow
                  optionFilterProp="label"
                  filterOption={filterTag}
                  options={tagsList.map((tagItem) => ({
                    value: tagItem.id,
                    label: tagItem.name,
                  }))}
                  disabled={isViewMode}
                ></Select>
              )}
            </Form.Item>
            <Form.Item hidden name="id" />
          </main>
          <footer className={style.footer}>
            <div className={style.actionContainer}>
              <Form.Item className={style.actionButton}>
                {isViewMode && (
                  <Button
                    className={style.submitButton}
                    onClick={handleToggleEdit}
                    htmlType="button"
                    type="primary"
                  >
                    {intl.formatMessage({
                      id: "general.edit",
                    })}
                  </Button>
                )}
              </Form.Item>
              <Form.Item className={style.actionButton}>
                {!isViewMode && (
                  <Button
                    className={style.submitButton}
                    loading={saving}
                    type="primary"
                    htmlType="submit"
                  >
                    {form.id ? intl.formatMessage({
                      id: "general.save",
                    }) : intl.formatMessage({
                      id: "admin.contactsPage.create.submit",
                    })}
                  </Button>
                )}
              </Form.Item>
            </div>
          </footer>
        </Form>
      </Card>
    </Modal>
  );
};

export default ContactModal;
