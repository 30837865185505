import React, { memo } from 'react'

import style from './AssistanceCompletePage.module.scss'
import { Button, Card, Input } from 'antd'

import _ from 'lodash'
import cls from 'classnames'
import SuccessIcon from 'components/icons/SuccessIcon'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { clearMemoUrl } from './reducer'

const AssistanceCompletePage = ({ className }) => {
  const { user } = useSelector(s => s.session)
  const { memoURL } = useSelector(s => s.assistance)
  const history = useHistory()
  const dispatch = useDispatch()
  const handleGoBack = () => {
    const { teamId, projectId, memoId, token } = memoURL
    history.push(`/${teamId}/${projectId}-${memoId}/${token}`)
    dispatch(clearMemoUrl())
  }



  return (
    <div className={style.root}>
      <Card className={cls(style.component, className)}>
        <SuccessIcon />
        <p className={style.title}>
          <FormattedMessage id='assistance.thankYou' />
        </p>
        <p className={style.description}>
          <FormattedMessage id='assistance.notification' />
        </p>
        {user.role !== 'admin' && user.role !== 'creator' && memoURL && (
          <div className={style.actionContainer}>
            <Button
              type='primary'
              onClick={handleGoBack}
              className={style.backButton}>
              <FormattedMessage id='assistance.goBackToMemo' />
            </Button>
          </div>
        )}
      </Card>
    </div>

  )
}

export default memo(AssistanceCompletePage)