import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
const SAVING_START = 'RESET_PASSWORD/SAVING_START'
const SAVING_END = 'RESET_PASSWORD/SAVING_END'
const SAVING_ERROR = 'RESET_PASSWORD/SAVING_ERROR'
const RESET = 'RESET_PASSWORD/RESET'
const SET_FORM = 'RESET_PASSWORD/SET_FORM'
const SET_ERROR = 'RESET_PASSWORD/SET_ERROR'

export const setForm = (form) => async (dispatch, getState) => {
	dispatch({
		type: SET_FORM,
		form
	})
}

export const confirmPasswordReset = (code, password) => async (dispatch) => {
	dispatch({ type: SAVING_START })
	try {
		await firebase.auth().confirmPasswordReset(code, password)
	} catch (ex) {
		dispatch(setError(ex))
		throw ex
	}
	dispatch({ type: SAVING_END })
}

export const reset = () => (dispatch) => {
	dispatch({
		type: RESET
	})
}

export const setError = (error) => {
	return {
		type: SET_ERROR,
		error
	}
}

const initialState = {
	saving: false,
	error: false,
	form: {
		password: null,
		password2: null
	}
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_FORM:
			return {
				...state,
				form: {
					...state.form,
					...action.form
				}
			}
		case SAVING_START:
			return {
				...state,
				saving: true
			}
		case SAVING_END:
			return {
				...state,
				saving: false
			}
		case SET_ERROR:
			return {
				...state,
				saving: false,
				error: action.error
			}
		case RESET:
			return {
				...initialState
			}
		default:
			return state
	}
}
