import axios from 'axios'
import { API_TOKEN_CLIENT, API_URL } from 'const'
import firebase from 'firebase/app'
import 'firebase/firestore'
import _ from 'lodash'
import Bowser from 'bowser'

const LOADING_START = 'DOCUMENT_PAGE/LOADING_START'
const LOADING_SUCCESS = 'DOCUMENT_PAGE/LOADING_SUCCESS'
const LOADING_END = 'DOCUMENT_PAGE/LOADING_END'
const LOADING_ERROR = 'DOCUMENT_PAGE/LOADING_ERROR'
const ANSWERS_LOADED = 'DOCUMENT_PAGE/ANSWERS_LOADED'
const SET_ANSWERS = 'DOCUMENT_PAGE/SET_ANSWERS'
const RESET = 'DOCUMENT_PAGE/RESET'

const GENERATE_CERTIFICATE_URL = 'https://j0f3170ysb.execute-api.ca-central-1.amazonaws.com/dev/genpdf'

export const calculateScore = (data, answers) => {
	let { questions } = data
	let correct = questions.iv.map(q => {
		if (!(q.id in answers))
			return false
		let answerIndex = answers[q.id]
		return q.data.choices.iv[answerIndex].isAnswer
	})
	correct = _.filter(correct, c => !!c)

	return Math.round((correct.length / questions.iv.length * 100) * 100) / 100
}

export const load = (id) => async (dispatch, getState) => {
	dispatch({ type: LOADING_START })
	try {
		let response = await axios.post(`${API_URL}/content/training-page/graphql`, {
			query: `{
				querySettingsContents {
					id
					data {
						clientName { iv }
						clientLogo {
							iv {
								... on Asset {
									id
									url
									thumbnailUrl
								}
							}
						}
					}
				}
				queryPagesContents(filter: "id eq '${id}'") {
					id
					data {
						title { iv }
						description { iv }
						passingScore { iv }
						duration { iv }
						expirationDate { iv }
						embedCode {
							iv {
								... on Media {
									id
									data {
										title { iv }
										embedCode { iv }
									}
								}
							}
						}
						questions {
							iv {
								... on Questions {
									id
									data {
										title { iv }
										choices {
											iv {
												isAnswer
												title
											}
										}
									}
								}
							}
						}
					}
				}
			}`
		}, {
			headers: {
				Authorization: `Bearer ${API_TOKEN_CLIENT}`
			}
		})
		await dispatch(loadAnswers(id))
		let { queryPagesContents, querySettingsContents } = response.data.data
		let [content] = queryPagesContents
		let [settings] = querySettingsContents

		dispatch({
			type: LOADING_SUCCESS,
			payload: {
				content,
				settings
			}
		})
	} catch (ex) {
		console.warn(ex)
	}
	dispatch({ type: LOADING_END })
}

export const issueCertificate = (data, answers) => async (dispatch, getState) => {
	try {
		let score = calculateScore(data, answers)

		if (score < data.passingScore.iv)
			return

		let response = await axios.post(GENERATE_CERTIFICATE_URL, {
			score,
			page: data,
			user: getState().session.user,
			browser: Bowser.parse(navigator.userAgent)
		})

		let blob = response.blob()
		let url = window.URL.createObjectURL(blob)
		let a = document.createElement('a')
		a.href = ''
	} catch (ex) {
		console.warn(ex)
	}
}

export const loadAnswers = (id) => async (dispatch, getState) => {
	try {
		let { user } = getState().session

		if (!user)
			return null

		let result = await firebase.firestore()
		.collection('users')
		.doc(user.uid)
		.collection('documents')
		.doc(id)
		.collection('answers')
		.get()

		dispatch({
			type: ANSWERS_LOADED,
			payload: {
				answers: result.docs.map(d => {
					return {
						id: d.id,
						...d.data()
					}
				})
			}
		})
	} catch (ex) {
		console.warn(ex)
	}
}

export const saveAnswer = (documentId, answers) => async (dispatch, getState) => {
	try {
		let { user } = getState().session
		let keys = _.keys(answers)
		let promises = []

		keys.forEach((id) => {
			let promise = firebase.firestore()
			.collection('users')
			.doc(user.uid)
			.collection('documents')
			.doc(documentId)
			.collection('answers')
			.doc(id)
			.set({
				value: answers[id]
			})

			promises.push(promise)
		})

		await Promise.all(promises)
		dispatch({
			type: SET_ANSWERS,
			answers: keys.map(id => {
				return {
					id,
					value: answers[id]
				}
			})
		})
	} catch (ex) {
		console.warn(ex)
	}
}

export const reset = () => async (dispatch, getState) => {
	dispatch({
		type: RESET
	})
}

export const actions = {
	load,
	reset,
	issueCertificate,
	saveAnswer,
	loadAnswers
}

const initialState = {
	content: null,
	error: false,
	loading: false,
	answers: []
}

export default function reducer(state = initialState, action) {
	switch(action.type) {
		case LOADING_START:
			return {
				...state,
				error: false,
				loading: true
			}
		case LOADING_END:
			return {
				...state,
				loading: false
			}
		case LOADING_SUCCESS:
			return {
				...state,
				...action.payload
			}
		case LOADING_ERROR:
			return {
				...state,
				...action.payload
			}
		case ANSWERS_LOADED:
			return {
				...state,
				...action.payload
			}
		case SET_ANSWERS:
				return {
					...state,
					answers: action.answers
				}
		case RESET:
			return {
				...initialState
			}
		default:
			return state
	}
}
