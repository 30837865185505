import React, { useCallback, useEffect } from 'react'
import style from './SharedPage.module.scss'
import _ from 'lodash'
import cls from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Button, Input, Card } from 'antd'
import { PageTitle } from 'components/PageItems'
import EmbedPlayer from 'components/embedPlayer/EmbedPlayer'
import Profile from 'components/header/Profile'
import MemoFooter from 'components/footer/MemoFooter'
import DummyPlayer from 'components/contentStructure/DummyPlayer'
import { createEmail, loadData, next } from './reducer'
import { useMediaQuery } from 'react-responsive'
import LoadingMemoAnimation from 'components/animations/LoadingMemoAnimation'
import DEVICE_WIDTH from 'constants/mediaQueries'

const STEPS = ['shared.sharedPage.blank', 'shared.sharedPage.none', 'shared.sharedPage.getStarted', 'shared.sharedPage.sendMail', 'shared.sharedPage.done']
const getStepKey = (step, key) => {
  return `${STEPS[step]}.${key}`
}


export default function SharedPage({ baseUrl, className }) {
  const isMobile = useMediaQuery({ maxWidth: DEVICE_WIDTH.XS })
  const [antForm] = Form.useForm()
  const intl = useIntl()
  const dispatch = useDispatch()
  const { form, loading, showDone, view, step } = useSelector(s => s.sharedPage)

  const embed = _.get(view, 'memo.url.embed')
  const thumbnailUrl = _.get(embed, 'thumbnail_url')

  const user = null
  const team = null

  const onSubmit = useCallback(async () => {
    try {
      const values = await antForm.validateFields()
      await dispatch(createEmail(values))
      dispatch(next())
    } catch (ex) {
      console.warn(ex)
    }
  }, [])
  const onNext = useCallback(() => {
    dispatch(next())
  })
  useEffect(() => {
    dispatch(loadData())
  }, [])
  useEffect(() => {
    antForm.setFieldsValue({
      ...form
    })
  }, [form])

  const formDom = (
    <>
      <Form
        validateMessages={{
          required: intl.formatMessage({ id: 'validation.required' }),
          types: {
            email: intl.formatMessage({ id: 'validation.invalidEmail' })
          }
        }}
        form={antForm}
        className={style.form}
        layout="vertical"
      >
        <Form.Item
          className={style.formItem}
          rules={[{ required: true }]}
          name='email'>
          <Input
            placeholder={intl.formatMessage({ id: 'shared.sharedPage.sendMail.fields.email' })}
            className={style.input} />
        </Form.Item>
      </Form>
      <Button
        className={style.submitButton}
        onClick={onSubmit}
        loading={loading}
        type='primary'>
        <FormattedMessage id={getStepKey(3, 'submit')} />
      </Button>

    </>
  )


  const startDom = (
    <Button
      className={style.submitButton}
      onClick={onNext}
      loading={loading}
      type='primary'>
      <FormattedMessage id={getStepKey(2, 'submit')} />
    </Button>
  )
  let profileObj = { name: '', avatar: '', team: '' }
  if (view.user) {
    profileObj.name = `${view.user.firstName} ${view.user.lastName}`
    profileObj.avatar = view.user.avatar ? view.user.avatar.url : ''
  }

  if (view.team) {
    profileObj.team = view.team.name
  }

  if (view.project && view.memo) {
    profileObj.project = `${view.project.id}-${view.memo.id}`
  }

  const profile = step > 1 ? (<Profile profile={profileObj} />) : null
  const footer = step > 1 ? (<MemoFooter memo={view.memo} projectName={view.project ? view.project.name : ''} createdAt={view.memo ? view.memo.createdAt : null} isDefaultProject={view.project && view.project.isDefault} />) : null

  const renderTitleAndHint = <React.Fragment>
    <PageTitle variant="h2" className={style.title}>
      <FormattedMessage
        id={getStepKey(step, 'title')}
        values={{
          name: (<span>memo<span className={style.domain}>.do</span></span>)
        }}
      />
    </PageTitle>
    <p className={style.hint}>
      <FormattedMessage
        id={getStepKey(step, 'hint')}
        values={{
          sender: `${view.user && view.user.firstName} ${view.user && view.user.lastName}`
        }} />
    </p>
  </React.Fragment>

  if (loading) {
    return (
      <div className={style.container}>
        <div className={style.loading}>
          <LoadingMemoAnimation />
        </div>
      </div>
    )
  }

  return (
    <div className={style.container}>
      {isMobile && (
        <div className={cls(style.component, style.flexColumn, style.titleContainer)}>
          {renderTitleAndHint}
        </div>
      )}
      {isMobile && (step === 3 || step === 4) ? null : (
        <div className={style.component}>
          <Card className={style.card} loading={loading}>
            {profile}
            <DummyPlayer className={style.dummyPlayer} backgroundUrl={thumbnailUrl}>
              {!isMobile && renderTitleAndHint}
              {step == 2 && !isMobile ? startDom : null}
              {step == 3 && !isMobile ? formDom : null}
            </DummyPlayer>
            {footer}
          </Card>
        </div>
      )}

      {isMobile && (
        <div className={style.component}>
          {step == 2 ? startDom : null}
          {step == 3 ? formDom : null}
        </div>
      )}
    </div>
  )
}
