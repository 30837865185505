import React from "react";
import { PageTitle } from "components/PageItems";
import MemoButton from "components/buttons/MemoButton";
import { Image, Space } from "antd";
import _ from "lodash";
import style from "./Tutorial.module.scss";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import WatchMemoImage from "assets/watch-memo.png";
import MemoQuestionsImage from "assets/memo-questions.png";
import { setContactPassedTutorial } from "../memo/reducer";
import LoadingMemoAnimation from "components/animations/LoadingMemoAnimation";
import { hideSteps, showSteps } from "components/appHeader/reducer";
import cls from 'classnames';
import { loadOne } from "../memo/reducer";
import { useParams } from "react-router";

const Tutorial = () => {
  const params = useParams();

  const { form, loading } = useSelector((s) => s.individualMemo);
  const { user, token, memoData } = useSelector((s) => s.session);

  const firstName = _.get(form, "user.firstName");
  const lastName = _.get(form, "user.lastName");

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(hideSteps())
  }, [])

  const [step, setStep] = React.useState(0);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleConfirm = () => {
    dispatch(setContactPassedTutorial(true));
    dispatch(showSteps())
  };

  React.useEffect(() => {
    if (!form.id && token) {
      dispatch(loadOne({
        teamId: memoData.teamId, projectId: memoData.projectId, memoId: memoData.memoId, token: params.token
      }));
    }
  }, [dispatch, memoData, params.token, user, token, form.id]);

  if (loading || !form.id) {
    return (
      <div className={style.container}>
        <div className={style.center}>
          <LoadingMemoAnimation />
        </div>
      </div>
    );
  }

  const renderContent = () => {
    if (step === 0) {
      return (
        <Space direction="vertical" size="large" className={style.p2}>
          <PageTitle variant="h1" className={style.title}>
            <FormattedMessage id="individual.tutorialPage.helloTitle" /> 👋
          </PageTitle>
          <p className={style.hint}>
            <FormattedMessage
              id="individual.tutorialPage.helloDescription"
              values={{ name: `${firstName} ${lastName}` }}
            />
          </p>
          <div className={style.center}>
            <MemoButton onClick={handleNext} className={style.actionButton}>
              <FormattedMessage id="general.getStarted" />
            </MemoButton>
          </div>
        </Space>
      );
    } else if (step === 1) {
      return (
        <div className={style.watchMemoStep}>
          <Space direction="vertical" size="large">
            <div className={style.center}>
              <Image width={128} src={WatchMemoImage} />
            </div>
            <PageTitle variant="h2" className={style.title}>
              <FormattedMessage id="individual.tutorialPage.watchTitle" />
            </PageTitle>
            <p className={style.hint}>
              <FormattedMessage id="individual.tutorialPage.watchDescription" />
            </p>
          </Space>
          <div className={`${style.justifyBetweenAction} ${style.actionsContainer}`}>
            <MemoButton onClick={handleBack} className={style.backButton}>
              <FormattedMessage id="general.back" />
            </MemoButton>
            <MemoButton onClick={handleNext} className={style.actionButton}>
              <FormattedMessage id="general.next" />
            </MemoButton>
          </div>
        </div>
      );
    } else if (step === 2) {
      return (
        <div className={style.watchMemoStep}>
          <Space direction="vertical" size="large">
            <div className={style.center}>
              <Image width={128} src={MemoQuestionsImage} />
            </div>
            <PageTitle variant="h2" className={style.title}>
              <FormattedMessage id="individual.tutorialPage.questionsTitle" />
            </PageTitle>
            <p className={style.hint}>
              <FormattedMessage id="individual.tutorialPage.questionsDescription" />
            </p>
          </Space>
          <div className={`${style.justifyBetweenAction} ${style.actionsContainer}`}>
            <MemoButton onClick={handleBack} className={style.backButton}>
              <FormattedMessage id="general.back" />
            </MemoButton>
            <MemoButton onClick={handleConfirm} className={style.actionButton}>
              <FormattedMessage id="general.gotIt" />
            </MemoButton>
          </div>
        </div>
      );
    }
  };

  return (
    <div className={`${style.container} ${style.bgWhite}`}>
      <div className={`${style.component} ${step > 0 ? style.fullHeight : ''}`}>
        <div className={style.card}>{renderContent()}</div>
      </div>
    </div>
  );
};

export default Tutorial;
