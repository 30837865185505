import React from "react";

const PrimaryFilterIcon = (props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0837 1.16699H2.91699C1.95049 1.16699 1.16699 1.95049 1.16699 2.91699V3.59949C1.16691 3.84038 1.21656 4.07868 1.31283 4.29949V4.33449C1.39524 4.52173 1.51197 4.69188 1.65699 4.83616L5.25032 8.40616V12.2503C5.24992 12.4519 5.35358 12.6393 5.52449 12.7462C5.61732 12.8037 5.72444 12.834 5.83366 12.8337C5.92497 12.8331 6.01489 12.8111 6.09616 12.7695L8.42949 11.6028C8.62565 11.504 8.74966 11.3033 8.75032 11.0837V8.40616L12.3203 4.83616C12.4653 4.69188 12.5821 4.52173 12.6645 4.33449V4.29949C12.7688 4.08041 12.8264 3.84203 12.8337 3.59949V2.91699C12.8337 1.95049 12.0502 1.16699 11.0837 1.16699ZM7.75284 7.75282C7.64364 7.86291 7.58277 8.01194 7.58367 8.16699V10.722L6.417 11.3053V8.16699C6.4179 8.01194 6.35703 7.86291 6.24784 7.75282L3.15617 4.66699H10.8445L7.75284 7.75282ZM11.667 3.50034H2.33366V2.91701C2.33366 2.59484 2.59483 2.33367 2.91699 2.33367H11.0837C11.4058 2.33367 11.667 2.59484 11.667 2.91701V3.50034Z"
      fill="currentColor"
    />
  </svg>
);

export default PrimaryFilterIcon;
