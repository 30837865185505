import { Popover, Slider } from "antd";
import DEVICE_WIDTH from "constants/mediaQueries";
import React from "react";
import { useMediaQuery } from "react-responsive";
import style from "./VolumeControl.module.scss";
const VolumeControlPath = require("../../icons/VolumeControl.svg");
const VolumeMutedControlPath = require("../../icons/VolumeMutedControl.svg");

const VolumeControl = ({
  muted = false,
  volume = 0.5,
  onMute,
  onChangeVolume,
}) => {
  const max = 1;
  const isMobile = useMediaQuery({ maxWidth: DEVICE_WIDTH.XS })

  const volumeBar = () => {
    return (
      <div style={{ width: "90px" }}>
        {!muted &&
          <Slider
            min={0}
            max={max}
            step={0.05}
            value={volume}
            onChange={onChangeVolume}
            className={style.volumeBar}
            tooltipVisible={false}
            trackStyle={{ backgroundColor: "#fff" }}
            handleStyle={{ borderColor: "#fff" }}
          />}
      </div>
    );
  };

  return (
    <div style={{ display: "flex" }} className={style.container}>
      <Popover
        content={volumeBar}
        placement="right"
        getPopupContainer={() =>
          document.querySelector("[class*=VolumeControl_container]")}
        overlayClassName={style.popover}
        align={{
          offset: [-4, 0]
        }}
        trigger={isMobile ? 'click' : 'hover'}
      >
        <div className={style.muteContainer}>
          {muted
            ? <img src={VolumeMutedControlPath} alt="volume" />
            : <img src={VolumeControlPath} alt="volume" />}
        </div>
      </Popover>
    </div>
  );
};

export default VolumeControl;
