import React from "react";

const ShareLinkIcon = (props) => (
  <svg
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.0437 22.4563C29.1408 25.5534 29.1408 30.5747 26.0437 33.6718L16.8222 42.8933C13.7251 45.9904 8.70382 45.9904 5.60677 42.8933C2.50971 39.7963 2.50972 34.775 5.60677 31.6779L14.8283 22.4563"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.9563 26.5456C18.8592 23.4486 18.8592 18.4273 21.9563 15.3302L31.1778 6.10864C34.2749 3.01159 39.2962 3.01159 42.3932 6.10864C45.4903 9.20569 45.4903 14.227 42.3932 17.3241L33.1717 26.5456"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ShareLinkIcon;
