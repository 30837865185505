import axios from 'axios'

const LOADING_START = 'LANDING/LOADING_START'
const LOADING_SUCCESS = 'LANDING/LOADING_SUCCESS'
const LOADING_END = 'LANDING/LOADING_END'
const LOADING_ERROR = 'LANDING/LOADING_ERROR'
const RESET = 'LANDING/RESET'

export const load = () => async (dispatch, getState) => {
	dispatch({ type: LOADING_START })
	dispatch({ type: LOADING_END })
}

export const reset = () => async (dispatch, getState) => {
	dispatch({
		type: RESET
	})
}

export const actions = {
	load,
	reset
}

const initialState = {
	error: false,
	loading: false
}

export default function reducer(state = initialState, action) {
	switch(action.type) {
		case LOADING_START:
			return {
				...state,
				error: false,
				loading: true
			}
		case LOADING_END:
			return {
				...state,
				loading: false
			}
		case LOADING_SUCCESS:
			return {
				...state,
				...action.payload
			}
		case LOADING_ERROR:
			return {
				...state,
				...action.payload
			}
		case RESET:
			return {
				...initialState
			}
		default:
			return state
	}
}