import React from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Modal,
  notification,
  AutoComplete,
  Input,
} from "antd";
import { LinkButton, MemoButton } from "components/buttons";
import EditIcon from "components/icons/EditIcon";
import DeleteIcon from "components/icons/DeleteIcon";
import TagsFilter from "./tagsFilter/TagsFilter";
import { compact, difference, uniqBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import Form from "antd/lib/form/Form";
import { useIntl } from "react-intl";
import { deleteContact, deleteContacts } from "./reducer";
import SuccessIcon from "components/icons/SuccessIcon";
import SearchIcon from "components/icons/SearchIcon";
import { StyledPlusIcon } from "../tags/TagsPage";
import CircleMinusIcon from "components/icons/CircleMinusIcon";
import PassedCircleIcon from "components/icons/PassedCircleIcon";
import AntIcon from "@ant-design/icons/lib/components/Icon";
import { SELECTED_TAG_COLOR } from "../tags/TagsTable";
import { TAG_COLOR } from "../tags/TagsTable";
import ViewIcon from "components/icons/ViewIcon";

import style from "./ContactsTable.module.scss";

const { confirm } = Modal;

const ContactsTable = ({ onCreate, onView, onEdit, onImportClick }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const language = useSelector(s => s.language)
  const { items: contactsList } = useSelector((state) => state.contacts);
  const { items: tagsList } = useSelector((state) => state.tags);

  const [searchValue, setSearchValue] = React.useState("");
  const [selectedContacts, setSelectedContacts] = React.useState([]);
  const [filterTags, setFilterTags] = React.useState([]);

  const contactsWithTags = React.useMemo(() => {
    return contactsList.map((contactItem) => {
      const tagIds = contactItem.tags;

      let stagedTags = [];
      if (tagIds && tagIds.length > 0) {
        stagedTags = compact(
          tagIds.map((tagId) =>
            tagsList.find((tagItem) => tagItem.id === tagId)
          )
        );
      }

      return {
        ...contactItem,
        tags: stagedTags,
      };
    });
  }, [contactsList, tagsList]);

  const filteredContacts = React.useMemo(() => {
    if (searchValue || (filterTags && filterTags.length > 0)) {
      let stagedContacts = contactsWithTags.filter(
        (contactItem) =>
          contactItem.email.toLowerCase().includes(searchValue.toLowerCase()) ||
          contactItem.firstName
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          contactItem.lastName.toLowerCase().includes(searchValue.toLowerCase())
      );

      return stagedContacts.filter((contactItem) => {
        return (
          difference(
            filterTags,
            contactItem.tags.map((tagItem) => tagItem.name)
          ).length === 0
        );
      });
    } else {
      return contactsWithTags;
    }
  }, [searchValue, filterTags, contactsWithTags, tagsList]);

  const filteredContactsOptions = uniqBy(filteredContacts.map(contactItem => ({ value: contactItem.firstName })), 'value')

  const handleSelectTags = (tagNames) => {
    setFilterTags(tagNames);
  };

  const handleToggleAll = () => {
    if (selectedContacts.length === filteredContacts.length) {
      setSelectedContacts([]);
    } else {
      setSelectedContacts(
        filteredContacts.map((contactItem) => contactItem.id)
      );
    }
  };

  const rowSelection = {
    columnTitle: (
      <div onClick={handleToggleAll}>
        {selectedContacts.length !== filteredContacts.length ? (
          <AntIcon component={CircleMinusIcon} className={style.statusIcon} />
        ) : (
          <AntIcon component={PassedCircleIcon} className={style.statusIcon} />
        )}
      </div>
    ),
    selectedRowKeys: selectedContacts,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedContacts([...selectedRowKeys]);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const renderSuccessMessage = (message) => {
    return (
      <div className={style.successMessageContainer}>
        <div className={style.successIcon}>
          <SuccessIcon width="24px" height="24px" />
        </div>
        <span>{message}</span>
      </div>
    );
  };

  const handleClearSelection = () => {
    setSelectedContacts([]);
  };

  const handleDeleteTags = async (contactIds) => {
    const resultIds = await dispatch(deleteContacts(contactIds));

    if (resultIds && resultIds.length > 0) {
      notification.open({
        style: {
          borderRadius: "4px",
          boxShadow:
            "0px 10px 15px - 3px rgba(26, 32, 44, 0.1), 0px 4px 6px - 2px rgba(26, 32, 44, 0.05)",
        },
        message: renderSuccessMessage(
          intl.formatMessage({ id: "admin.contactsPage.bulkActions.delete.success" })
        ),
        className: style.successMessage,
        onClick: () => { },
      });
      setSelectedContacts([]);
    }
  };

  const handleDeleteSelection = React.useCallback(() => {
    confirm({
      maskStyle: {
        background: "rgba(26, 32, 44, 0.5)",
      },
      className: style.confirmModal,
      icon: null,
      title: intl.formatMessage({ id: "admin.contactsPage.bulkActions.delete.title" }),
      content: intl.formatMessage({
        id: "admin.contactsPage.bulkActions.delete.description",
      }),
      okText: intl.formatMessage({ id: "general.cancel" }),
      cancelText: intl.formatMessage({ id: "admin.contactsPage.bulkActions.delete.label" }),
      onOk() {
        Modal.destroyAll();
      },
      onCancel() {
        handleDeleteTags(selectedContacts);
      },
    });
  }, [selectedContacts]);

  const renderEmpty = React.useMemo(() => {
    if (contactsList && contactsList.length > 0) {
      return (
        <p>
          <FormattedMessage id="admin.contactsPage.noResultFound" />
        </p>
      );
    }

    return (
      <p>
        <FormattedMessage id="admin.contactsPage.emptyList" />
      </p>
    );
  }, [contactsList, searchValue, language]);

  const handleDeleteContact = async (contactId) => {
    const deletedId = await dispatch(deleteContact(contactId));
    if (deletedId) {
      notification.open({
        style: {
          borderRadius: "4px",
          boxShadow:
            "0px 10px 15px - 3px rgba(26, 32, 44, 0.1), 0px 4px 6px - 2px rgba(26, 32, 44, 0.05)",
        },
        message: renderSuccessMessage(
          intl.formatMessage({ id: "admin.contactsPage.delete.success" })
        ),
        className: style.successMessage,
        onClick: () => { },
      });
    }
  };

  const handleClickDelete = (contactId, email) => {
    confirm({
      maskStyle: {
        background: "rgba(26, 32, 44, 0.5)",
      },
      className: style.confirmModal,
      icon: null,
      title: intl.formatMessage({ id: "admin.contactsPage.delete.title" }),
      content: intl.formatMessage(
        {
          id: "admin.contactsPage.delete.description",
        },
        {
          email,
        }
      ),
      okText: intl.formatMessage({ id: "general.cancel" }),
      cancelText: intl.formatMessage({ id: "general.delete" }),
      onOk() {
        Modal.destroyAll();
      },
      onCancel() {
        handleDeleteContact(contactId);
      },
    });
  };

  const columns = [
    {
      title: <FormattedMessage id="profile.fields.email" />,
      dataIndex: "email",
      key: "email",
      width: "30%",
      render: (email, record) => (
        <div className={style.emailColumn}>
          <span>{email}</span>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="profile.fields.firstName" />,
      dataIndex: "firstName",
      key: "name",
      width: "20%",
    },
    {
      title: <FormattedMessage id="profile.fields.lastName" />,
      dataIndex: "lastName",
      key: "lastName",
      width: "20%",
    },
    {
      title: <FormattedMessage id="admin.tagsPage.tags" />,
      key: "tags",
      dataIndex: "tags",
      width: "20%",
      render: (tags, record) => {
        const isSelected = selectedContacts.find(
          (contactId) => contactId === record.id
        );

        return (
          <>
            {tags &&
              tags.length > 0 &&
              tags
                .filter((tagItem) => !tagItem.deleted)
                .map((tag) => {
                  return (
                    <Tag
                      color={isSelected ? SELECTED_TAG_COLOR : TAG_COLOR}
                      key={tag.id}
                      className={`${style.tag} ${isSelected ? style.selected : ""
                        }`}
                    >
                      {tag.name}
                    </Tag>
                  );
                })}
          </>
        );
      },
    },
    {
      title: "",
      key: "action",
      render: (text, record) => (
        <Space size={1}>
          <Button
            icon={<ViewIcon className={style.viewIcon} />}
            type="link"
            className={style.actionButton}
            onClick={() => onView(record)}
          />
          <Button
            icon={<EditIcon />}
            type="link"
            className={style.actionButton}
            onClick={() => onEdit(record)}
          />
          <Button
            icon={<DeleteIcon />}
            type="link"
            onClick={() => handleClickDelete(record.id, record.email)}
            className={style.actionButton}
          />
        </Space>
      ),
    },
  ];

  return (
    <div className={style.root}>
      <div className={style.topContainer}>
        <Space size="small">
          <AutoComplete
            className={style.search}
            dropdownClassName={style.dropdown}
            value={searchValue}
            onChange={(value) => {
              setSearchValue(value);
            }}
            options={filteredContactsOptions}
          >
            <Input
              prefix={<SearchIcon />}
              className={style.input}
              placeholder={intl.formatMessage({
                id: "admin.contactsPage.searchPlaceHolder",
              })}
            />
          </AutoComplete>
          <TagsFilter tags={tagsList} onChange={handleSelectTags}></TagsFilter>
        </Space>
        <Space size="small">
          <LinkButton size="small" url="/app/tags" variant="outlined">
            <span className={style.manageTagLink}><FormattedMessage id="admin.tagsPage.manageTags" /></span>
          </LinkButton>
          {/* <MemoButton size="small">
            Import contact
          </MemoButton> */}
          <MemoButton size="small" onClick={onCreate}>
            <StyledPlusIcon />
            <FormattedMessage id="admin.contactsPage.create.title" />
          </MemoButton>
        </Space>
      </div>
      <div className={style.tableWrapper}>
        <Table
          columns={columns}
          dataSource={filteredContacts}
          pagination={true}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          className={style.tableContainer}
          sticky
          locale={{
            emptyText: renderEmpty,
          }}
        />
      </div>

      <div
        className={`${style.selectionNotification} ${selectedContacts.length > 0 ? style.openedNotification : ""
          }`}
      >
        <span className={style.selectedCount}>
          {selectedContacts.length} Contact Selected
        </span>
        <div className={style.selectActionContainer}>
          <Space size="small">
            <MemoButton
              variant="outlined"
              size="small"
              className={style.clearButton}
              onClick={handleDeleteSelection}
            >
              <FormattedMessage id="admin.contactsPage.deleteSelection" />
            </MemoButton>
            <MemoButton
              variant="outlined"
              size="small"
              className={style.clearButton}
              onClick={handleClearSelection}
            >
              <FormattedMessage id="admin.contactsPage.clearSelection" />
            </MemoButton>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default ContactsTable;
