import React from 'react'
import style from './AppLogo.module.scss'
import cls from 'classnames'
import LogoWithName from '../assets/LogoFull.png'
import Logo from '../assets/logo_full.png'

export default class AppLogo extends React.PureComponent {
  render() {
    let { className, wrapperClassName, isWithName = true } = this.props

    return (
      <a href="https://memo.do" className={wrapperClassName} >
        {/* <Link
				className={wrapperClassName}
				to="https://memo.do"
				onClick={this.props.onClick}>
				<img
					className={cls(style.logo, className)}
					src={Logo} />
			</Link> */}
        <img
          className={cls(style.logo, className, isWithName ? style.withName : '')}
          src={isWithName ? LogoWithName : Logo} />
      </a>
    )
  }
}
