import React, { useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./InitialSharingCard.module.scss";
import {
  Card,
  Form,
  Modal,
} from "antd";
import _ from "lodash";
import { setVisibility } from "./reducer";
import { PageTitle } from "components/PageItems";
import { FormattedMessage, useIntl } from "react-intl";
import CloseIcon from "components/icons/CloseIcon";
import ShareByTagIcon from "../../../../components/icons/ShareByTagIcon";
import ContactsIcon from "../../../../components/icons/ContactsIcon";
import ShareLinkIcon from "../../../../components/icons/ShareLinkIcon";
import SharingContactsCard from "./SharingContactsCard";
import SharingTagsCard from "./SharingTagsCard";
import SharingByLinkCard from "./SharingByLinkCard";
import { loadAll as loadAllContacts } from "../../contacts/reducer";
import { loadAll as loadAllTags } from "../../tags/reducer";
import { loadAll as loadMemoStats } from "../stats/reducer";

export default function InitialSharingCard() {
  const [antdForm] = Form.useForm();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { memo, visible } =
    useSelector((s) => s.memosShare);

  const [category, setCategory] = React.useState('');

  const onClose = useCallback(() => {
    dispatch(setVisibility(false));
    setCategory('')
  }, []);

  const handleChangeSharable = (checked) => { };

  const handleClickAction = (categoryName) => {
    setCategory(categoryName)
  }

  const renderSteps = React.useMemo(() => {
    if (category === 'contacts') {
      return <SharingContactsCard handleBack={() => setCategory('')} onClose={onClose} />
    }
    if (category === 'tags') {
      return <SharingTagsCard handleBack={() => setCategory('')} onClose={onClose} />
    }
    if (category === 'link') {
      return <SharingByLinkCard handleBack={() => setCategory('')} onClose={onClose} />
    }
  }, [category, setCategory])

  useEffect(() => {
    dispatch(loadAllContacts());
    dispatch(loadAllTags());
  }, []);

  useEffect(() => {
    if (memo && memo.id) {
      dispatch(loadMemoStats(memo.id, memo.projectId))
    }
  }, [memo])

  return (
    <>
      <Modal
        className={style.container}
        visible={visible && !category}
        footer={null}
        closable
        maskClosable={false}
        closeIcon={
          <div className={style.closeIcon}>
            <CloseIcon width="32px" height="32px" />
          </div>
        }
        onCancel={onClose}
      >
        <Card
          bodyStyle={{ padding: 0 }}
          bordered={false}
          className={style.contentCard}
        >
          <header className={style.header}>
            <PageTitle variant="h2">
              <FormattedMessage
                id="admin.memos.share.title"
                values={{
                  name: memo.name,
                  memoId: memo.id,
                  projectId: memo.projectId,
                }}
              />
            </PageTitle>
          </header>
          <main>
            <div className={style.description}>
              <PageTitle variant="h4" type="regular">
                <FormattedMessage id="admin.memos.share.chooseSharingOption" />
              </PageTitle>
            </div>
            <div className={style.actionsContainer}>
              <div className={style.actionCard} onClick={() => handleClickAction('tags')}>
                <div className={style.actionIcon}>
                  <ShareByTagIcon width="48px" height="48px" />
                </div>
                <PageTitle variant="h4" type="regular" className={style.actionLabel}>
                  <FormattedMessage id="admin.memos.share.shareByTag" />
                </PageTitle>
              </div>
              <div className={style.actionCard} onClick={() => handleClickAction('contacts')}>
                <div className={style.actionIcon}>
                  <ContactsIcon width="48px" height="48px" />
                </div>
                <PageTitle variant="h4" type="regular" className={style.actionLabel}>
                  <FormattedMessage id="admin.memos.share.shareByContact" />
                </PageTitle>
              </div>
              <div className={style.actionCard} onClick={() => handleClickAction('link')}>
                <div className={style.actionIcon}>
                  <ShareLinkIcon width="48px" height="48px" />
                </div>
                <PageTitle variant="h4" type="regular" className={style.actionLabel}>
                  <FormattedMessage id="admin.memos.share.share_by_link" />
                </PageTitle>
              </div>
            </div>
          </main>
        </Card>
      </Modal>
      {renderSteps}
    </>
  );
}
