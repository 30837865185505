import React from 'react'
import PropTypes from 'prop-types'
import styleFile from './Icon.module.scss'
import cls from 'classnames'

export default class Icon extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func
  }

  static defaultProps = {
    shadow: ""
  }

  render() {
    let { name, style, transparent, large, small, animated, shadow = false } = this.props

    return (
      <div
        onClick={this.props.onClick}
        style={style}
        className={cls(styleFile.component, {
          [styleFile[name]]: true,
          [styleFile.small]: small,
          [styleFile.transparent]: transparent,
          [styleFile.large]: large,
          [styleFile.animated]: animated,
          [styleFile.shadow]: shadow
        }, this.props.className)}>
      </div>
    )
  }
}
