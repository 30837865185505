import React, { useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./SharingTagsCard.module.scss";
import {
  AutoComplete,
  Button,
  Card,
  Checkbox,
  Input,
  Modal,
  notification,
  Table,
  Tag,
} from "antd";
import _, { compact, differenceBy, intersectionBy, orderBy, uniqBy } from "lodash";
import AntIcon from "@ant-design/icons/lib/components/Icon";
import { reset, setVisibility } from "./reducer";
import { PageTitle } from "components/PageItems";
import { FormattedMessage, useIntl } from "react-intl";
import CloseIcon from "components/icons/CloseIcon";
import MemoButton from "components/buttons/MemoButton";
import LeftArrowIcon from "components/icons/LeftArrowIcon";
import { SELECTED_TAG_COLOR } from "../../tags/TagsTable";
import { TAG_COLOR } from "../../tags/TagsTable";
import SearchIcon from "components/icons/SearchIcon";
import CircleMinusIcon from "components/icons/CircleMinusIcon";
import PassedCircleIcon from "components/icons/PassedCircleIcon";
import { share } from "./reducer";
import SuccessIcon from "components/icons/SuccessIcon";
import EMOJI_LIST from "constants/emojiList";
import { STATUS } from "../stats/reducer";

const { confirm, info } = Modal;

const SharingTagsCard = ({ handleBack, onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const language = useSelector(s => s.language)
  const { items: contactsList } = useSelector((state) => state.contacts);
  const { items: tagsList } = useSelector((state) => state.tags);
  const { items: invitations } = useSelector((s) => s.memoStats);

  const completedInvitations = React.useMemo(() => {
    return invitations.filter(invitation => invitation.status === STATUS.COMPLETED)
  }, [invitations]);

  const [step, setStep] = React.useState("selecting");

  const [searchValue, setSearchValue] = React.useState("");
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [skipChecked, setSkipChecked] = React.useState(true)

  const { form, memo, visible, saving, linkLoading, shareableLink } =
    useSelector((s) => s.memosShare);

  const filteredTags = React.useMemo(() => {
    let stagedTags = orderBy(
      tagsList,
      [(item) => item.name.toLowerCase()],
      ["asc"]
    );
    if (searchValue) {
      stagedTags = stagedTags.filter((tagItem) =>
        tagItem.name.toLowerCase().includes(searchValue.toLowerCase())
      );

      return stagedTags;
    } else {
      return stagedTags;
    }
  }, [searchValue, tagsList]);

  const filteredTagsOptions = uniqBy(
    filteredTags.map((tagItem) => ({ value: tagItem.name })),
    "value"
  );

  const renderEmpty = React.useMemo(() => {
    if (tagsList && tagsList.length > 0) {
      return (
        <p>
          <FormattedMessage id="admin.contactsPage.noResultFound" />
        </p>
      );
    }

    return (
      <p>
        <FormattedMessage id="admin.contactsPage.emptyList" />
      </p>
    );
  }, [tagsList, searchValue]);

  const handleSelected = () => {
    const includedContacts = contactsList.filter((contactItem) => {
      const stagedContacts = intersectionBy(contactItem.tags, selectedTags);
      return stagedContacts && stagedContacts.length > 0;
    });

    if (includedContacts && includedContacts.length > 0) {
      setStep("selected");
    } else {
      info({
        maskStyle: {
          background: "rgba(26, 32, 44, 0.5)",
        },
        className: style.confirmModal,
        title: <PageTitle variant="h2" className={style.confirmTitle}>
          {intl.formatMessage({ id: "individual.memoPage.oops" })}  <span className={style.face}>{` ${EMOJI_LIST.CONFUSED_FACE}`}</span>
        </PageTitle>,
        icon: null,
        content: <PageTitle variant="h4" type="regular" className={style.confirmDescription}>{intl.formatMessage({
          id: "admin.memos.share.tags.noContactsWaring",
        })}</PageTitle>,
        okText: intl.formatMessage({ id: "general.ok" }),
      });
    }
  };

  const renderTitle = React.useMemo(() => {
    if (step === "selecting" || step === "updating") {
      return <FormattedMessage id="admin.memos.share.tags.tagList" />;
    } else if (step === "selected") {
      return (
        <FormattedMessage
          id="admin.memos.share.title"
          values={{
            name: memo.name,
            memoId: memo.id,
            projectId: memo.projectId,
          }}
        />
      );
    }
  }, [step, memo, language]);

  const renderSuccessMessage = (message) => {
    return (
      <div className={style.successMessageContainer}>
        <div className={style.successIcon}>
          <SuccessIcon width="24px" height="24px" />
        </div>
        <span>{message}</span>
      </div>
    );
  };

  const handleSendInvite = async () => {
    const includedContacts = contactsList.filter((contactItem) => {
      const stagedContacts = intersectionBy(contactItem.tags, selectedTags);
      return stagedContacts && stagedContacts.length > 0;
    });

    const stagedSelectedContactList = skipChecked ? differenceBy(includedContacts, completedInvitations, 'email') : includedContacts;

    await dispatch(
      share({
        emails: stagedSelectedContactList.map((contactItem) => contactItem.email),
      })
    );

    notification.open({
      style: {
        borderRadius: "4px",
        boxShadow:
          "0px 10px 15px - 3px rgba(26, 32, 44, 0.1), 0px 4px 6px - 2px rgba(26, 32, 44, 0.05)",
      },
      message: renderSuccessMessage(
        intl.formatMessage({ id: "admin.memos.share.contacts.success" })
      ),
      className: style.successMessage,
      onClick: () => { },
    });

    onClose();
  };

  const handleClickUpdate = () => {
    setStep("updating");
  };

  const handleChangeSkip = (event) => {
    setSkipChecked(event.target.checked)
  }

  const renderSelectedTags = React.useCallback(() => {
    const selectedTagsData = tagsList.filter((tagItem) =>
      selectedTags.includes(tagItem.id)
    );
    const topFiveTags = selectedTagsData.slice(0, 5);
    const restTags = selectedTagsData.slice(5);
    return (
      <>
        {topFiveTags &&
          topFiveTags.length > 0 &&
          topFiveTags.map((tagItem) => {
            return (
              <div key={tagItem.id} className={style.selectedTag}>
                <PageTitle variant="h4" type="medium">
                  {tagItem.name}
                </PageTitle>
              </div>
            );
          })}
        <div className={style.restTagsContainer}>
          {restTags && restTags.length > 0 && (
            <PageTitle variant="h4" className={style.moreLabel}>
              + {restTags.length} more
            </PageTitle>
          )}
          <PageTitle
            className={style.updateButton}
            variant="h4"
            onClick={handleClickUpdate}
          >
            <FormattedMessage id="general.update" />
          </PageTitle>
        </div>
      </>
    );
  }, [selectedTags]);

  const handleToggleAll = () => {
    if (selectedTags.length === filteredTags.length) {
      setSelectedTags([]);
    } else {
      setSelectedTags(filteredTags.map((tagItem) => tagItem.id));
    }
  };

  const rowSelection = {
    columnTitle: (
      <div onClick={handleToggleAll}>
        {selectedTags.length !== filteredTags.length ? (
          <AntIcon component={CircleMinusIcon} className={style.statusIcon} />
        ) : (
          <AntIcon component={PassedCircleIcon} className={style.statusIcon} />
        )}
      </div>
    ),
    selectedRowKeys: selectedTags,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTags([...selectedRowKeys]);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const columns = React.useMemo(
    () => [
      {
        title: <FormattedMessage id="admin.tagsPage.tagsName" />,
        dataIndex: "name",
        key: "name",
        className: "hide",
        width: "90%",
        render: (tag, record) => {
          const isSelected = selectedTags.find((tagId) => tagId === record.id);

          return (
            <div className={style.emailColumn}>
              <Tag
                color={isSelected ? SELECTED_TAG_COLOR : TAG_COLOR}
                className={`${style.tag} ${isSelected ? style.selected : ""}`}
              >
                {tag}
              </Tag>
            </div>
          );
        },
      },
    ],
    [selectedTags]
  );

  return (
    <Modal
      className={style.container}
      visible={visible}
      footer={null}
      closable
      maskClosable={false}
      closeIcon={
        <div className={style.closeIcon}>
          <CloseIcon width="32px" height="32px" />
        </div>
      }
      onCancel={onClose}
    >
      <Card
        bodyStyle={{ padding: 0 }}
        bordered={false}
        className={style.contentCard}
      >
        <header className={style.header}>
          <div className={style.modalTitle}>
            {(step === "selecting" || step === "updating") && (
              <Button
                shape="circle"
                className={style.backButton}
                onClick={handleBack}
              >
                <LeftArrowIcon />
              </Button>
            )}
            <PageTitle variant="h2">{renderTitle}</PageTitle>
          </div>
        </header>
        <main>
          {step === "selecting" || step === "updating" ? (
            <>
              <AutoComplete
                className={style.search}
                dropdownClassName={style.dropdown}
                value={searchValue}
                onChange={(value) => {
                  setSearchValue(value);
                }}
                options={filteredTagsOptions}
              >
                <Input
                  prefix={<SearchIcon />}
                  className={style.input}
                  placeholder={intl.formatMessage({
                    id: "admin.tagsPage.searchPlaceHolder",
                  })}
                />
              </AutoComplete>
              <div className={style.tableWrapper}>
                <Table
                  columns={columns}
                  dataSource={filteredTags}
                  pagination={false}
                  rowSelection={{
                    type: "radio",
                    ...rowSelection,
                  }}
                  className={style.tableContainer}
                  sticky
                  locale={{
                    emptyText: renderEmpty,
                  }}
                />
              </div>
            </>
          ) : (
            <div>
              <PageTitle variant="h4" className={style.selectedLabel}>
                <FormattedMessage id="admin.tagsPage.selectedTags" />
              </PageTitle>
              <div className={style.selectedContainer}>
                {renderSelectedTags()}
              </div>
              <div className={style.checkSkipping}>
                <Checkbox checked={skipChecked} onChange={handleChangeSkip}>
                  {intl.formatMessage({ id: 'admin.memos.share.checkSkippingDescription' })}
                </Checkbox>
              </div>
            </div>
          )}
        </main>
        <footer className={style.footer}>
          {step === "selecting" || step === "updating" ? (
            <>
              <div></div>
              <MemoButton
                disabled={!selectedTags || selectedTags.length === 0}
                onClick={handleSelected}
              >
                {step === "selecting" ? <FormattedMessage id="admin.memos.share.addSelected" /> : <FormattedMessage id="general.update" />}
              </MemoButton>
            </>
          ) : (
            <>
              <div></div>
              <MemoButton onClick={handleSendInvite} loading={saving}><FormattedMessage id="admin.memos.share.submit" /></MemoButton>
            </>
          )}
        </footer>
      </Card>
    </Modal>
  );
};

export default SharingTagsCard;
