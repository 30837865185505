import React from 'react'
import style from './RichEditor.module.scss'
import { StyleButton } from './StyleButton'

const INLINE_STYLES = [
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Bold', style: 'BOLD' },
  { label: 'Underline', style: 'UNDERLINE' }
];

const InlineStyleControls = ({ onToggle, editorState }) => {
  const currentStyle = editorState.getCurrentInlineStyle()

  return (
    <div className={style.editorControls}>
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
          className={style.editorStyleButton}
        />
      ))}
    </div>
  );
};

export default InlineStyleControls