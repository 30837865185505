import React from 'react'
import style from "./Attachment.module.scss"
import cls from "classnames"

const Attachment = ({file, uploadProgress, deleteFile, displayMode}) => {

  const formatBytes = (bytes, decimals) => {
    if (bytes === 0) return '0 Bytes'
    let step = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(step))
    return parseFloat((bytes / Math.pow(step, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  return (
    <div
      className={cls(style.attachment, displayMode && style.attachmentDisplay)}
      onClick={() => window.open(file.url, '_blank')}
    >
      <div className={style.attachmentBody}>
        <div>
          <img
            src={
              `/memo_form/${
                file.type === 'pdf' ? 'pdf' :
                  file.type === 'application' ? 'doc' :
                    file.type === 'image' ? 'image' :
                      'generic'
              }.svg`
            }
            alt={file.name}
          />
          <div className={style.attachmentInfo}>
            <p className={style.attachmentName}>
              {file.name.length > (displayMode ? 28 : 22) ?
                `${file.name.slice(0, displayMode ? 25 : 18)}...` :
                file.name
              }
            </p>
            {(uploadProgress || uploadProgress === 0) ? (
              <div className={style.progressBar}>
                <div className={style.filledProgressBar} style={{width: uploadProgress + '%'}}/>
              </div>
            ) : <p className={style.attachmentSize}>{formatBytes(file.size)}</p>}
          </div>
        </div>
      </div>
      <button
        className={style.deleteAttachmentButton}
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          displayMode ? window.open(file.url, '_blank') : deleteFile()
        }}
      >
        <img src={`/memo_form/${displayMode ? 'download' : 'delete'}.svg`} alt="delete"/>
      </button>
    </div>
  )
}

export default Attachment