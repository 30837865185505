import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './reducers'

export default function configureStore(preloadedState) {
  let middlewares = [thunkMiddleware]
  let middlewareEnhancer = applyMiddleware(...middlewares)
  let enhancers = [middlewareEnhancer]
  let composedEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  let store = createStore(rootReducer, preloadedState, composedEnhancers(...enhancers))
  return store
}