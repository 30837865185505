import axios from "axios";
import firebase from "firebase/app";
import "firebase/firestore";
import { trim } from "lodash";
import history from "../../../history";

const { REACT_APP_API } = process.env;

const LOADING_START = "CONTACTS/LOADING_START";
const LOADING_END = "CONTACTS/LOADING_END";
const LOADING_SUCCESS = "CONTACTS/LOADING_SUCCESS";
const RESET = "CONTACTS/RESET";
const SET_ERROR = "CONTACTS/SET_ERROR";
const SAVING_START = "CONTACTS/SAVING_START";
const SAVING_END = "CONTACTS/SAVING_END";
const DELETING_START = "CONTACTS/DELETING_START";
const DELETING_END = "CONTACTS/DELETING_END";
const SET_FORM = "CONTACTS/SET_FORM";
const RESET_FORM = "CONTACTS/RESET_FORM";

export const loadAll =
  ({ pagination, sorter, projectId, teamId, userId } = {}) =>
    async (dispatch, getState) => {
      dispatch({
        type: LOADING_START,
      });
      try {
        const { user } = getState().session;

        let query = firebase
          .firestore()
          .collection(`teams/${user.teamId}/contacts`);

        query = query.orderBy("createdAt", "desc");

        const result = await query.get();

      dispatch({
        type: LOADING_SUCCESS,
        items: result.docs
          .filter((d) => !d.data().deleted)
          .map((d) => {
            return {
              id: d.id,
              key: d.id,
              ...d.data(),
            };
          }),
      });
    } catch (ex) {
      console.warn(ex);
    }
    dispatch({
      type: LOADING_END,
    });
  };

export const saveForm = (values) => async (dispatch, getState) => {
  dispatch({ type: SAVING_START });
  let resultContactId = "";
  const stagedValues = {
    ...values,
    email: trim(values.email),
    firstName: trim(values.firstName),
    lastName: trim(values.lastName),
    tags: values.tags && values.tags.length > 0 ? values.tags : [],
  };
  try {
    const { form } = getState().contacts;
    const { user } = getState().session;

    if (!!values.id) {
      await firebase
        .firestore()
        .collection(`teams/${user.teamId}/contacts`)
        .doc(values.id)
        .set(
          {
            firstName: stagedValues.firstName,
            lastName: stagedValues.lastName,
            tags: stagedValues.tags,
          },
          { merge: true }
        );

      resultContactId = values.id;
    } else {
      if (!form.createdAt)
        stagedValues.createdAt =
          firebase.firestore.FieldValue.serverTimestamp();
      const newContact = await firebase
        .firestore()
        .collection(`teams/${user.teamId}/contacts`)
        .add({
          email: stagedValues.email,
          firstName: stagedValues.firstName,
          lastName: stagedValues.lastName,
          tags: stagedValues.tags,
          createdAt: stagedValues.createdAt,
        });
      resultContactId = newContact.id;
    }

    dispatch({ type: SAVING_END });
    dispatch(loadAll());
    return resultContactId;
  } catch (ex) {
    console.warn(ex);
    dispatch({
      type: SAVING_END,
    });

    return;
  }
};

export const deleteContact = (contactId) => async (dispatch, getState) => {
  dispatch({ type: DELETING_START });

  try {
    const { user } = getState().session;
    // add createdAt if user creates new team
    await firebase
      .firestore()
      .collection(`teams/${user.teamId}/contacts`)
      .doc(contactId)
      .update({
        deleted: true,
      });

    dispatch({ type: DELETING_END });
    dispatch(loadAll());
    return contactId;
  } catch (ex) {
    console.warn(ex);
    dispatch({
      type: DELETING_END,
    });

    return;
  }
};

export const deleteContacts = (contactIds) => async (dispatch, getState) => {
  dispatch({ type: DELETING_START });

  try {
    const { user } = getState().session;
    // add createdAt if user creates new team

    let batch = firebase
      .firestore().batch();

    contactIds.forEach(contactId => {
      let contactDoc = firebase
        .firestore().collection(`teams/${user.teamId}/contacts`).doc(contactId);
      batch.update(contactDoc, { deleted: true });
    })

    await batch.commit()

    dispatch({ type: DELETING_END });
    dispatch(loadAll());
    return contactIds;
  } catch (ex) {
    console.warn(ex);
    dispatch({
      type: DELETING_END,
    });

    return;
  }
};

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setForm = (contactData) => {
  return {
    type: SET_FORM,
    form: contactData,
  };
};

export const reSetForm = () => {
  return {
    type: RESET_FORM,
  };
};

export const setError = (error) => {
  return {
    type: SET_ERROR,
    error,
  };
};

const initialState = {
  saving: false,
  loading: false,
  deleting: false,
  error: false,
  items: [],
  form: {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    tags: [],
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case LOADING_END:
      return {
        ...state,
        loading: false,
      };
    case LOADING_SUCCESS:
      return {
        ...state,
        items: action.items,
      };
    case DELETING_START:
      return {
        ...state,
        deleting: true,
      };
    case DELETING_END:
      return {
        ...state,
        deleting: false,
      };
    case SET_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.form,
        },
      };
    case SAVING_START:
      return {
        ...state,
        saving: true,
      };
    case SAVING_END:
      return {
        ...state,
        saving: false,
      };
    case RESET_FORM:
      return {
        ...state,
        form: {
          ...initialState.form,
        },
      };
    default:
      return state;
  }
}
