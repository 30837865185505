import Modal from 'antd/lib/modal/Modal'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import cls from 'classnames'
import { FormattedMessage } from 'react-intl'
import { Button, Row } from 'antd'
import style from './MemoPage.module.scss'
import { submitAnswers, SUBMISSION_STATUS } from './reducer'
import MemoAlert from './MemoAlert'

const MemoFooterIN = ({ completed, answers, resetAnswers, ended, sender }) => {
  const dispatch = useDispatch()
  const { form } = useSelector(s => s.individualMemo)
  const status = _.get(form, 'submission.status')
  const [answerStatusModal, setAnswerStatusModal] = useState(false)

  useEffect(() => {
    if (status === SUBMISSION_STATUS.FAILED && answerStatusModal === false) {
      setAnswerStatusModal(true)
    }
  }, [status, answerStatusModal])

  const canSubmit = useMemo(() => {
    const questionsArr = _.get(form, 'questions', [])
    const answersArr = Object.keys(answers)
    return answersArr.length === questionsArr.length && ended
  }, [answers, form, ended])

  const onSubmit = useCallback(() => {
    dispatch(submitAnswers(answers))
    document.querySelector('.page-scroll-container').scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    setAnswerStatusModal(true)
  }, [form, answers, completed, status])

  const onRetry = useCallback(() => {
    setAnswerStatusModal(false)
    resetAnswers()
  }, [])

  const onReview = useCallback(() => {
    setAnswerStatusModal(false)
  }, [])

  return (
    <footer className={cls(style.centered, style.footer)}>
      {completed ? (
        <>
          <Modal
            maskClosable
            className={style.modal}
            centered
            closable={false}
            visible={answerStatusModal}
            width={440}
            footer={null}>
            <p>
              <MemoAlert status={status} sender={sender} />
            </p>
            <footer>
              {status === SUBMISSION_STATUS.FAILED && (
                <Row justify="center">
                  <Button onClick={onRetry} className={style.primaryButton}>
                    <FormattedMessage id='individual.memoPage.retry' />
                  </Button>
                </Row>

              )}
              {status === SUBMISSION_STATUS.COMPLETED && (
                <Row justify="center">
                  <Button onClick={onReview} className={`${style.primaryButton}`}>
                    <FormattedMessage id='individual.memoPage.reviewMemo' />
                  </Button>
                </Row>
              )}
            </footer>
          </Modal>
        </>
      ) : (
        <Button
          onClick={onSubmit}
          className={cls(style.startButton, style.centered)}
          disabled={!canSubmit}>
          <FormattedMessage id='individual.memoPage.submit' />
        </Button>
      )}
    </footer>
  )
}


export default MemoFooterIN