import React, { useCallback, useState } from "react";
import Modal from "antd/lib/modal/Modal";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  message,
  notification,
  Select,
  Tag,
  Upload,
} from "antd";
import "firebase/auth";
import { PageTitle } from "components/PageItems";
import { MemoButton } from "components/buttons";
import CloseIcon from "components/icons/CloseIcon";

import style from "./ImportContactModal.module.scss";
import UploadIcon from "../../../../components/icons/UploadIcon";
import { csvToArray } from "utils";

const ImportContactModal = ({ open, onClose }) => {
  const [uploaded, setUploaded] = React.useState(false);

  const props = {
    accept: ".csv",
    beforeUpload: (file) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target.result) {
          console.log("result", csvToArray(e.target.result));
        }
      };
      reader.readAsText(file);

      // Prevent upload
      return false;
    },
    onChange: (info) => {
      if (info.fileList && info.fileList.length) {
        setUploaded(true);
      } else {
        setUploaded(false);
      }
    },
  };

  const handleClose = () => {
    onClose();
  };

  const handleClickUpload = () => { };

  return (
    <Modal
      className={style.container}
      visible={open}
      closable
      maskClosable={false}
      width={440}
      footer={null}
      onCancel={handleClose}
      destroyOnClose
      closeIcon={
        <div className={style.closeIcon}>
          <CloseIcon width="32px" height="32px" />
        </div>
      }
    >
      <Card
        bodyStyle={{ padding: 0 }}
        bordered={false}
        className={style.contentCard}
      >
        <header className={style.header}>
          <PageTitle variant="h2">Upload your contacts</PageTitle>
        </header>
        <main>
          <div className={style.content}>
            <Upload {...props}>
              {!uploaded && (
                <Button className={style.actionCard}>
                  <div className={style.actionIcon}>
                    <UploadIcon width="48px" height="48px" />
                  </div>
                  <PageTitle
                    variant="h4"
                    type="medium"
                    className={style.actionLabel}
                  >
                    Browse
                  </PageTitle>
                </Button>
              )}
            </Upload>
            <a
              href="https://docs.google.com/spreadsheets/d/15jX1camP2K_Q7UbQfLWfmAf2QUcPHleFDft1Dzcny1c/edit?usp=sharing"
              target="_blank"
            >
              <PageTitle
                variant="h4"
                type="regular"
                className={style.description}
                variant="h4"
                type="underline"
              >
                Click here to see our required format
              </PageTitle>
            </a>
          </div>
        </main>
        <footer className={style.footer}>
          <div className={style.submitContainer}>
            <MemoButton>Continue</MemoButton>
          </div>
        </footer>
      </Card>
    </Modal>
  );
};

export default ImportContactModal;
