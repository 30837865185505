import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { API_TOKEN_ADMIN, API_URL } from 'const'
import _ from 'lodash'

const LOADING_START = 'ADMIN_TEAMS/LOADING_START'
const LOADING_SUCCESS = 'ADMIN_TEAMS/LOADING_SUCCESS'
const LOADING_END = 'ADMIN_TEAMS/LOADING_END'
const SAVING_START = 'ADMIN_TEAMS/SAVING_START'
const SAVING_END = 'ADMIN_TEAMS/SAVING_END'
const RESET = 'ADMIN_TEAMS/RESET'
const SET_FORM = 'ADMIN_TEAMS/SET_FORM'
const LOADING_ONE_SUCCESS = 'ADMIN_TEAMS/LOADING_ONE_SUCCESS'
const RESET_PASSWORD_START = 'ADMIN_TEAMS/RESET_PASSWORD_START'
const RESET_PASSWORD_END = 'ADMIN_TEAMS/RESET_PASSWORD_END'

export const setForm = (form) => async (dispatch, getState) => {
  dispatch({
    type: SET_FORM,
    form
  })
}

export const loadAll = ({ pagination, sorter } = {}) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_START
  })
  try {
    const result = await firebase
      .firestore()
      .collection('teams')
      .limit(100)
      .get()

    dispatch({
      type: LOADING_SUCCESS,
      items: result.docs.map(d => {
        return {
          id: d.id,
          ...d.data()
        }
      })
    })
  } catch (ex) {
    console.warn(ex)
  }
  dispatch({
    type: LOADING_END
  })
}

export const loadOne = (id) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_START
  })
  try {
    const result = await firebase
      .firestore()
      .collection('teams')
      .doc(id)
      .get()
    dispatch({
      type: LOADING_ONE_SUCCESS,
      payload: {
        form: {
          id,
          ...result.data()
        }
      }
    })
  } catch (ex) {
    console.warn(ex)
    throw ex
  }

  dispatch({
    type: LOADING_END
  })
}

export const saveForm = (values) => async (dispatch, getState) => {
  dispatch({ type: SAVING_START })
  try {
    const { form } = getState().adminTeams
    // add createdAt if user creates new team
    if (!form.createdAt)
      values.createdAt = firebase.firestore.FieldValue.serverTimestamp()
    await firebase
      .firestore()
      .collection('teams')
      .doc(values.shortcode)
      .set(values, { merge: true })
  } catch (ex) {
    console.warn(ex)
    dispatch({
      type: SAVING_END
    })
    throw ex
  }
  dispatch({ type: SAVING_END })
  return values.shortcode
}

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET
  })
}

export const actions = {
  setForm,
  loadAll,
  loadOne,
  reset
}

const initialState = {
  saving: false,
  loading: false,
  resetPasswordLoading: false,
  items: [],
  form: {
    id: null,
    name: null,
    shortcode: null,
    logo: null
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        loading: true
      }
    case LOADING_ONE_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case LOADING_END:
      return {
        ...state,
        loading: false
      }
    case LOADING_SUCCESS:
      return {
        ...state,
        items: action.items
      }
    case SET_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.form
        }
      }
    case SAVING_START:
      return {
        ...state,
        saving: true
      }
    case SAVING_END:
      return {
        ...state,
        saving: false
      }
    case RESET_PASSWORD_START:
      return {
        ...state,
        resetPasswordLoading: true
      }
    case RESET_PASSWORD_END:
      return {
        ...state,
        resetPasswordLoading: false
      }
    case RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
