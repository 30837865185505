import React, { memo, useCallback, useEffect } from "react";
import { Select } from "antd";
import style from "./ProjectSelect.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { loadAll } from "./reducer";
import cls from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import ProjectSelectDropdown from "../icons/ProjectSelectDropdown";
import AntIcon from "@ant-design/icons/lib/components/Icon";
import FileIcon from "components/icons/FileIcon";
import FolderIcon from "components/icons/FolderIcon";

export const StyledDropdownIcon = () => {
  return (
    <div className={style.downdownIcon}>
      <ProjectSelectDropdown />
    </div>
  );
};

export default memo(({ value, className, onChange, readOnly }) => {
  const dispatch = useDispatch();
  const { items } = useSelector((s) => s.projectSelect);
  const intl = useIntl();
  const onSearch = useCallback((value) => {
    // dispatch(loadAll(value))
  }, []);
  useEffect(() => {
    dispatch(loadAll(value));
  }, []);

  return (
    <Select
      dropdownMatchSelectWidth={true}
      className={cls(style.component, className, {
        [style.readOnly]: readOnly,
      })}
      showSearch={true}
      disabled={readOnly}
      onChange={onChange}
      value={value}
      suffixIcon={<StyledDropdownIcon />}
      onSearch={onSearch}
    >
      {items.map((item) => {
        return (
          <Select.Option value={item.id} key={item.id}>
            {item.isDefault ? (
              <>
                <AntIcon component={FolderIcon} className={style.icon} />
                <span>
                  <FormattedMessage id="admin.projects.default" />
                </span>
              </>
            ) : (
              <>
                <AntIcon component={FileIcon} className={style.icon} />
                <span>{item.name}</span>
              </>
            )}
          </Select.Option>
        );
      })}
    </Select>
  );
});
