import React from "react";
import { Table, Tag, Space, Button, Modal, notification } from "antd";
import style from "./TagsTable.module.scss";
import { LinkButton, MemoButton } from "components/buttons";
import EditIcon from "components/icons/EditIcon";
import DeleteIcon from "components/icons/DeleteIcon";
import { orderBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import Form from "antd/lib/form/Form";
import { useIntl } from "react-intl";
import { deleteTag, setForm, deleteTags } from "./reducer";
import SuccessIcon from "components/icons/SuccessIcon";
import CircleMinusIcon from "components/icons/CircleMinusIcon";
import PassedCircleIcon from "components/icons/PassedCircleIcon";
import AntIcon from "@ant-design/icons/lib/components/Icon";
import ViewIcon from "components/icons/ViewIcon";

const { confirm } = Modal;

export const TAG_COLOR = "#E6E6E6";
export const SELECTED_TAG_COLOR = '#2F80ED';

const TagsTable = ({ searchValue, onChangeFilteredTags, handleEditTag }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { items: tagsList } = useSelector((state) => state.tags);
  const [selectedTags, setSelectedTags] = React.useState([]);

  const renderSuccessMessage = (message) => {
    return (
      <div className={style.successMessageContainer}>
        <div className={style.successIcon}>
          <SuccessIcon width="24px" height="24px" />
        </div>
        <span>{message}</span>
      </div>
    );
  };

  const filteredTags = React.useMemo(() => {
    let stagedTags = orderBy(tagsList, [(item) => item.name.toLowerCase()], ["asc"])
    if (searchValue) {
      stagedTags = stagedTags.filter(
        (tagItem) =>
          tagItem.name.toLowerCase().includes(searchValue.toLowerCase())
      );

      return stagedTags;
    } else {
      return stagedTags;
    }
  }, [searchValue, tagsList]);

  React.useEffect(() => {
    if (searchValue) {
      onChangeFilteredTags(filteredTags);
    } else {
      onChangeFilteredTags([]);
    }
  }, [filteredTags, searchValue]);

  const handleClearSelection = () => {
    setSelectedTags([]);
  };

  const renderEmpty = React.useMemo(() => {
    if (tagsList && tagsList.length > 0) {
      return (
        <p>
          <FormattedMessage id="admin.contactsPage.noResultFound" />
        </p>
      );
    }

    return (
      <p>
        <FormattedMessage id="admin.contactsPage.emptyList" />
      </p>
    );
  }, [tagsList, searchValue]);

  const handleDeleteTag = async (tagId) => {
    const deletedId = await dispatch(deleteTag(tagId));
    if (deletedId) {
      notification.open({
        style: {
          borderRadius: '4px',
          boxShadow: '0px 10px 15px - 3px rgba(26, 32, 44, 0.1), 0px 4px 6px - 2px rgba(26, 32, 44, 0.05)',
        },
        message: renderSuccessMessage(intl.formatMessage({ id: 'admin.tagsPage.delete.success' })),
        className: style.successMessage,
        onClick: () => {
        },
      });
    }
  }

  const handleClickDelete = (tagId, name) => {
    confirm({
      maskStyle: {
        background: "rgba(26, 32, 44, 0.5)",
      },
      className: style.confirmModal,
      icon: null,
      title: intl.formatMessage({ id: "admin.tagsPage.delete.title" }),
      content: intl.formatMessage({
        id: "admin.tagsPage.delete.description",
      }, {
        name
      }),
      okText: intl.formatMessage({ id: "general.cancel" }),
      cancelText: intl.formatMessage({ id: "general.delete" }),
      onOk() {
        Modal.destroyAll();
      },
      onCancel() {
        handleDeleteTag(tagId);
      },
    });
  };

  const handleClickEdit = (tagData) => {
    handleEditTag(tagData)
  }

  const handleToggleAll = () => {
    if (selectedTags.length === filteredTags.length) {
      setSelectedTags([])
    }
    else {
      setSelectedTags(filteredTags.map(tagItem => tagItem.id))
    }
  }

  const handleDeleteTags = async (tagIds) => {
    const resultIds = await dispatch(deleteTags(tagIds));

    if (resultIds && resultIds.length > 0) {
      notification.open({
        style: {
          borderRadius: "4px",
          boxShadow:
            "0px 10px 15px - 3px rgba(26, 32, 44, 0.1), 0px 4px 6px - 2px rgba(26, 32, 44, 0.05)",
        },
        message: renderSuccessMessage(
          intl.formatMessage({ id: "admin.tagsPage.bulkActions.delete.success" })
        ),
        className: style.successMessage,
        onClick: () => { },
      });
      setSelectedTags([]);
    }
  };

  const handleDeleteSelection = React.useCallback(() => {
    confirm({
      maskStyle: {
        background: "rgba(26, 32, 44, 0.5)",
      },
      className: style.confirmModal,
      icon: null,
      title: intl.formatMessage({ id: "admin.tagsPage.bulkActions.delete.title" }),
      content: intl.formatMessage({
        id: "admin.tagsPage.bulkActions.delete.description",
      }),
      okText: intl.formatMessage({ id: "general.cancel" }),
      cancelText: intl.formatMessage({ id: "admin.tagsPage.bulkActions.delete.label" }),
      onOk() {
        Modal.destroyAll();
      },
      onCancel() {
        handleDeleteTags(selectedTags);
      },
    });
  }, [selectedTags]);

  const columns = React.useMemo(() => [
    {
      title: <FormattedMessage id="admin.tagsPage.tagsName" />,
      dataIndex: "name",
      key: "name",
      width: "90%",
      render: (tag, record) => {
        const isSelected = selectedTags.find(tagId => tagId === record.id);

        return (
          <div className={style.emailColumn}>
            <Tag color={isSelected ? SELECTED_TAG_COLOR : TAG_COLOR} className={`${style.tag} ${isSelected ? style.selected : ''}`}>
              {tag}
            </Tag>
          </div>
        )
      }
    },
    {
      title: "",
      key: "action",
      render: (text, record) => (
        <Space size={1}>
          <Button icon={<EditIcon />} type="link" onClick={() => handleClickEdit(record)} className={style.actionButton} />
          <Button icon={<DeleteIcon />} type="link" onClick={() => handleClickDelete(record.id, record.name)} className={style.actionButton} />
        </Space>
      ),
    },
  ], [selectedTags]);

  const rowSelection = {
    columnTitle: (
      <div onClick={handleToggleAll}>
        {selectedTags.length !== filteredTags.length ? (
          <AntIcon component={CircleMinusIcon} className={style.statusIcon} />
        ) : (
          <AntIcon component={PassedCircleIcon} className={style.statusIcon} />
        )}
      </div>
    ),
    selectedRowKeys: selectedTags,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTags([...selectedRowKeys]);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  return (
    <div className={style.root}>
      <div className={style.tableWrapper}>
        <Table
          columns={columns}
          dataSource={filteredTags}
          pagination={false}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          className={style.tableContainer}
          sticky
          locale={{
            emptyText: renderEmpty,
          }}
        />
      </div>
      <div
        className={`${style.selectionNotification} ${selectedTags.length > 0 ? style.openedNotification : ""
          }`}
      >
        <span className={style.selectedCount}>
          {selectedTags.length} Tag Selected
        </span>
        <div className={style.selectActionContainer}>
          <Space size="small">
            <MemoButton
              variant="outlined"
              size="small"
              className={style.clearButton}
              onClick={handleDeleteSelection}
            >
              <FormattedMessage id="admin.contactsPage.deleteSelection" />
            </MemoButton>
            <MemoButton
              variant="outlined"
              size="small"
              onClick={handleClearSelection}
              className={style.clearButton}
            >
              <FormattedMessage id="admin.contactsPage.clearSelection" />
            </MemoButton>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default TagsTable;
