import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import _ from 'lodash'

const { REACT_APP_API_REMIND, REACT_APP_API_MEMO } = process.env

const LOADING_START = 'ADMIN_MEMOS_STATS/LOADING_START'
const LOADING_SUCCESS = 'ADMIN_MEMOS_STATS/LOADING_SUCCESS'
const LOADING_END = 'ADMIN_MEMOS_STATS/LOADING_END'
const SAVING_START = 'ADMIN_MEMOS_STATS/SAVING_START'
const SAVING_END = 'ADMIN_MEMOS_STATS/SAVING_END'
const REMINDING_START = 'ADMIN_MEMOS_STATS/REMINDING_START'
const REMINDING_END = 'ADMIN_MEMOS_STATS/REMINDING_END'
const RESET = 'ADMIN_MEMOS_STATS/RESET'

export const STATUS = {
  SENT: 1,
  OPENED: 2,
  COMPLETED: 3,
  SENDING: 4,
  ERROR: 5
}

export const STATUS_NAMES = {
  1: 'sent',
  2: 'opened',
  3: 'completed',
  4: 'sending',
  5: 'error',
}

export const remind = (invitation) => async (dispatch, getState) => {
  dispatch({
    type: REMINDING_START,
    invitationIds: [invitation.id]
  })
  try {
    const { memo } = getState().memoStats
    const token = await firebase.auth().currentUser.getIdToken(true)
    const result = await axios.post(REACT_APP_API_REMIND.replace(':id', memo.id), {
      projectId: memo.projectId,
      teamId: memo.teamId,
      email: invitation.email
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  } catch (ex) {
    console.warn(ex)
  }
  dispatch({
    type: REMINDING_END
  })
}

export const remindAll = (invitations) => async (dispatch, getState) => {
  dispatch({
    type: REMINDING_START,
    invitationIds: []
  })

  try {
    const emails = invitations.map(invitation => invitation.email);

    const { memo } = getState().memoStats
    const token = await firebase.auth().currentUser.getIdToken(true)
    const result = await axios.post(`${(REACT_APP_API_MEMO.replace(':id', memo.id))}/remindAll`, {
      projectId: memo.projectId,
      teamId: memo.teamId,
      emails: emails
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  } catch (ex) {
    console.warn(ex)
  }

  dispatch({
    type: REMINDING_END
  })
}

export const loadAll = (memoId, projectId) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_START
  })
  try {
    const { user } = getState().session
    const fs = firebase.firestore()
    const [result1, result2] = await Promise.all([
      fs.collection(`teams/${user.teamId}/projects/${projectId}/memos`).doc(memoId).get(),
      // fs.collection(`teams/${user.teamId}/projects/${projectId}/memos/${memoId}/submissions`).get(),
      fs.collection(`teams/${user.teamId}/projects/${projectId}/memos/${memoId}/invitations`).get()
    ])
    const items = result2.docs.map(d => {
      return {
        id: d.id,
        ...d.data()
      }
    })

    dispatch({
      type: LOADING_SUCCESS,
      items,
      memo: {
        id: result1.id,
        ...result1.data()
      },
      counters: {
        [STATUS.COMPLETED]: items.filter(item => item.status === STATUS.COMPLETED || item.firstEverCompleted).length,
        [STATUS.SENT]: items.filter(item => !item.firstEverCompleted && item.status === STATUS.SENT).length,
        [STATUS.OPENED]: items.filter(item => !item.firstEverCompleted && item.status === STATUS.OPENED).length
      }
    })
  } catch (ex) {
    console.warn(ex)
  }
  dispatch({
    type: LOADING_END
  })
}

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET
  })
}

export const actions = {
  loadAll,
  reset
}

const initialState = {
  saving: false,
  loading: false,
  reminding: false,
  memo: {
    id: null
  },
  counters: {
    [STATUS.COMPLETED]: 0,
    [STATUS.SENT]: 0,
    [STATUS.OPENED]: 0
  },
  items: [],
  invitationIds: []
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        loading: true
      }
    case LOADING_END:
      return {
        ...state,
        loading: false
      }
    case LOADING_SUCCESS:
      return {
        ...state,
        items: action.items,
        counters: action.counters,
        memo: action.memo
      }
    case REMINDING_START:
      return {
        ...state,
        reminding: true,
        invitationIds: action.invitationIds
      }
    case REMINDING_END:
      return {
        ...state,
        reminding: false
      }
    case RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
