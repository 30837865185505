import React, { memo, useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './AssistancePage.module.scss'
import { Card, Button, Form, Input, Alert } from 'antd'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import { submit, setError, reset } from './reducer'
import _ from 'lodash'
import cls from 'classnames'
import { PageTitle } from 'components/PageItems'
import AttachmentIcon from 'components/icons/AttachmentIcon'
import Avatar from 'pages/profile/Avatar'
import { useHistory } from 'react-router'
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
  mobileVendor,
  mobileModel,
  isMobile
} from "react-device-detect";

const { TextArea } = Input;

export const AttachPicture = ({ value, onChange, onLoadingChange }) => {
  const { user } = useSelector(s => s.session)

  return (
    <div className={style.attachment}>
      <Avatar
        isCustom={false}
        //value={value}
        onChange={onChange}
        onLoadingChange={onLoadingChange}
      >
        <Button className={style.addPicture}>
          <div className={style.addPictureIcon}>
            <AttachmentIcon />
          </div>
          <FormattedMessage id='assistance.addPicture' />
        </Button>
      </Avatar>
      {value && value.url && <a className={style.attachmentLink} href={value.url} target="_blank"><FormattedMessage id="assistance.attachedFile" /></a>}
    </div>
  )
}

const AssistancePage = ({ className, supervisor, admin, creator }) => {
  const [form] = Form.useForm()
  const { user } = useSelector(s => s.session)
  const { saving, error } = useSelector(state => state.assistance)
  const { showStepper } = useSelector((s) => s.appHeader);

  const intl = useIntl()
  const dispatch = useDispatch()
  const [attachmentLoading, setAttachmentLoading] = useState(false)

  const onSubmit = useCallback(async () => {
    try {
      const deviceInformation = `${isMobile ? mobileModel : 'Desktop or laptop'}, ${browserName} ${browserVersion}, OS: ${osName} ${osVersion}`
      const nextUrl = user.role === 'admin' || user.role === 'creator' ? '/app/assistance/complete' : '/assistance/complete'
      const values = await form.validateFields()
      values.attachmentUrl = (values.attachmentUrl && values.attachmentUrl.url) ? values.attachmentUrl.url : ''
      values.deviceInformation = deviceInformation
      await dispatch(submit(values, nextUrl))
      dispatch(reset())
    } catch (ex) {
      console.warn(ex)
    }
  }, [form])

  const onFieldsChange = useCallback(() => {
    error && dispatch(setError())
  }, [error])

  const onAttachmentLoading = useCallback((state) => {
    setAttachmentLoading(state)
  }, [])

  return (
    <div className={cls(style.root, { showStepper },)}>
      <Card className={cls(style.component, className)}>
        <Form
          form={form}
          onFinish={onSubmit}
          onFieldsChange={onFieldsChange}
          validateMessages={{
            required: intl.formatMessage({ id: 'validation.required' }),
            types: {
              email: intl.formatMessage({ id: 'validation.invalidEmail' })
            }
          }}>
          <header className={style.header}>
            <PageTitle variant="h1">
              <FormattedMessage id='assistance.title' />
            </PageTitle>
            <p className={style.description}>
              <FormattedMessage id='assistance.formDescription' />
            </p>
          </header>
          {error && (
            <Alert
              type='error'
              message={intl.formatMessage({ id: error.code })} />
          )}
          <div className={style.cards}>
            <Form.Item
              label={intl.formatMessage({ id: `profile.fields.firstName` })}
              className={style.formItem}
              labelCol={{ xs: 24 }}
              rules={[{ required: true }]}
              name='firstName'>
              <Input className={style.input} />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: `profile.fields.lastName` })}
              className={style.formItem}
              labelCol={{ xs: 24 }}
              rules={[{ required: true }]}
              name='lastName'>
              <Input className={style.input} />
            </Form.Item>

            <Form.Item
              label={intl.formatMessage({ id: `profile.fields.email` })}
              className={style.formItem}
              labelCol={{ xs: 24 }}
              rules={[{ required: true }]}
              name='email'>
              <Input className={style.input} />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: `assistance.fields.subject` })}
              className={style.formItem}
              labelCol={{ xs: 24 }}
              rules={[{ required: true }]}
              name='subject'>
              <TextArea className={style.input} rows={5} />
            </Form.Item>
            <Form.Item
              className={style.formItem}
              name='attachmentUrl'>
              <AttachPicture onLoadingChange={onAttachmentLoading} />
            </Form.Item>
          </div>
          <footer className={style.footer}>
            <Button
              type='primary'
              onClick={onSubmit}
              loading={attachmentLoading || saving}
              className={style.submitButton}>
              <FormattedMessage id='general.send' />
            </Button>
          </footer>
        </Form>
      </Card>
    </div>

  )
}

export default memo(AssistancePage)