import React, {useCallback, useState} from 'react';
import ReactPaginate from 'react-paginate';
import {useDispatch, useSelector} from "react-redux";
import {loadAll, remind, STATUS, STATUS_NAMES} from "./reducer";
import _ from "lodash";
import moment from "moment";
import style from "./StatsPage.module.scss";
import avatarSrc from "../../../../icons/avatar.png";
import cls from "classnames";
import {FormattedMessage} from "react-intl";
import AntIcon from "@ant-design/icons/lib/components/Icon";
import RetryIcon from "../../../../components/icons/RetryIcon";
import {Button} from "antd";
import Icon from "../../../../components/Icon";
import {useParams} from "react-router-dom";

const ListItem = ({ data, onRemind }) => {
  const dispatch = useDispatch();
  const { reminding, invitationIds } = useSelector((s) => s.memoStats);
  const { firstName, lastName, email, status, id, retries, firstEverCompleted } = data;

  const statusName = STATUS_NAMES[firstEverCompleted ? STATUS.COMPLETED : status];
  const isCompleted = status === STATUS.COMPLETED || firstEverCompleted;

  let completedAt = _.get(data, "completedAt.seconds");
  let emailedAt =
    _.get(data, "lastReminder.createdAt.seconds") ||
    _.get(data, "createdAt.seconds");
  if (completedAt) completedAt = moment.unix(completedAt);
  if (emailedAt) emailedAt = moment.unix(emailedAt);

  const onRemindHandler = useCallback(async () => {
    await dispatch(remind(data));
    onRemind();
  }, []);

  return (
    <div className={style.item}>
      <div
        className={style.avatar}
        style={{
          backgroundImage: `url(${avatarSrc})`,
        }}
      />
      <div>
        {firstName && (
          <div className={style.name}>
            {`${firstName || ""} ${lastName || ""}`}
          </div>
        )}
        <div className={style.email}>{email}</div>
      </div>

      <div className={cls(style.status, style[statusName])}>
        <div>
          <span className={style.circle}></span>
          <FormattedMessage id={`admin.memosStatsPage.status.${statusName}`} />
        </div>
        {retries && (
          <div className={style.retries}>
            <AntIcon
              component={RetryIcon}
              className={style.retryIcon}
              style={{ fontSize: "16px" }}
            />
            {retries}
          </div>
        )}
      </div>
      <div className={style.emailedAt}>
        {!isCompleted && (
          <FormattedMessage
            id="admin.memosStatsPage.emailedAt"
            values={{
              br: <br />,
              ts: emailedAt.format("DD/MM/YYYY"),
            }}
          />
        )}
      </div>
      <div className={style.remind}>
        {isCompleted ? (
          <b className={style.date}>
            {completedAt && completedAt.format("DD/MM/YYYY")}
          </b>
        ) : (
          <Button
            loading={reminding && invitationIds.includes(id)}
            type="primary"
            className={style.remindButton}
            onClick={onRemindHandler}
          >
            <Icon name="notify" className={style.icon} />
            <FormattedMessage id="admin.memosStatsPage.remind" />
          </Button>
        )}
      </div>
    </div>
  );
};

const Items = ({ currentItems }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const onRemind = useCallback(() => {
    dispatch(loadAll(params.id, params.pid));
  }, [params]);

  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
          <ListItem key={item.id} data={item} onRemind={onRemind} />
        ))}
    </>
  );
}

const StatsRecipientsList = ({ itemsPerPage, items }) => {
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items currentItems={currentItems} />
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className={style.statsRecipientsList}
        pageClassName="ant-pagination-item"
        activeClassName="ant-pagination-item-active"
        previousClassName="ant-pagination-item ant-pagination-prev"
        nextClassName="ant-pagination-item ant-pagination-next"
      />
    </>
  );
}

export default StatsRecipientsList;