import React from 'react'
import style from './Profile.module.scss'
import cls from 'classnames'
import { Avatar } from 'antd';
import { FormattedMessage } from 'react-intl';

export default class Profile extends React.PureComponent {
  render() {
    let { className, profile, completed } = this.props

    if (!profile)
      return null

    let name = profile.name ? (
      <div className={style.user}>
        {profile.name}
      </div>
    ) : null

    let team = profile.team ? (
      <span className={style.team}>
        {profile.team}
      </span>
    ) : null

    let avImg = (profile.avatar && profile.avatar.url ? profile.avatar.url : profile.avatar)

    let project = profile.project && (
      <div className={style.projectContainer}>
        <div className={`${completed ? style.completed : ''} ${style.project}`}>
          <FormattedMessage
            id={completed ? "individual.memoPage.status.completed" : "individual.memoPage.status.notCompleted"}
          />
        </div>
      </div>
    ) || null

    return (
      <div className={cls(style.profileBar, className)}>
        <div className={style.detailsContainer}>
          <Avatar size="large" className={style.avatar} src={avImg} />
          <div className={style.details}>
            {name}
            {team}
          </div>
        </div>
        {project}
      </div>
    )
  }
}
