import React from 'react';
import cls from 'classnames';
import style from './ShowQuestions.module.scss'
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";

elementScrollIntoViewPolyfill();

const ShowQuestions = ({ onShowQuestions }) => {
  const containerRef = React.useRef();

  React.useEffect(() => {
    if (containerRef && containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }, [])

  return (
    <div className={cls(style.centered, style.flexColumn, style.container)} ref={containerRef}>
      <p className={style.title}>
        <FormattedMessage id='individual.memoPage.whatsNext' />
      </p>
      <p className={style.hint}>
        <FormattedMessage id='individual.memoPage.timeToAnswer' />
      </p>
      <Button
        className={cls(style.startButton)}
        onClick={onShowQuestions}
        type='primary'>
        <FormattedMessage id='individual.memoPage.startQuestions' />
      </Button>
    </div>
  )
}

export default ShowQuestions;