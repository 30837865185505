import React, { useState } from "react";
import { Button, Card, Form, Input, notification } from "antd";

import { PageTitle } from "components/PageItems";
import FilledEditIcon from "components/icons/FilledEditIcon";

import styles from "./EditContactInfo.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Modal from "antd/lib/modal/Modal";
import SuccessIcon from "components/icons/SuccessIcon";
import CloseIcon from "components/icons/CloseIcon";
import { toCapitalize } from "utils";
import cls from "classnames";
import { updateContactInfo } from "pages/individual/memo/reducer";
import { useMediaQuery } from "react-responsive";
import DEVICE_WIDTH from "constants/mediaQueries";

export const EditContactInfo = () => {
  const isMobile = useMediaQuery({ maxWidth: DEVICE_WIDTH.XS });
  const [antForm] = Form.useForm();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const { contact } = useSelector((s) => s.individualMemo);
  const { saving } = useSelector((s) => s.individualWelcome);

  const openContactModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    antForm.resetFields();

    setModalOpen(false);
  };

  const renderSuccessMessage = (message) => {
    return (
      <div className={styles.successMessageContainer}>
        <div className={styles.successIcon}>
          <SuccessIcon width="24px" height="24px" />
        </div>
        <span>{message}</span>
      </div>
    );
  };

  const onSubmit = async () => {
    setSubmitClicked(true);
    try {
      let values = await antForm.validateFields();

      const contactId = await dispatch(updateContactInfo({ values, isVerified: true }));

      if (contactId) {
        notification.open({
          style: {
            borderRadius: "4px",
            boxShadow:
              "0px 10px 15px - 3px rgba(26, 32, 44, 0.1), 0px 4px 6px - 2px rgba(26, 32, 44, 0.05)",
          },
          message: renderSuccessMessage(
            intl.formatMessage({ id: "admin.contactsPage.editIndividual.success" })
          ),
          className: styles.successMessage,
          onClick: () => { },
        });
        handleCloseModal();
      }

    } catch (ex) {
      console.warn(ex);
    }
  };

  const handleFormChange = (event) => {
    const keyName = Object.keys(event)[0];
    antForm.setFieldsValue({
      [keyName]: toCapitalize(event[keyName])
    })
  };

  const onFieldsChange = (values) => {
  };

  React.useEffect(() => {
    if (contact.id) {
      antForm.resetFields();
      antForm.setFieldsValue({
        firstName: contact.firstName,
        lastName: contact.lastName,
        id: contact.id
      });
    }
  }, [contact, modalOpen]);

  const lastName = React.useMemo(() => {
    if (!contact.lastName) {
      return ''
    }

    return isMobile ? contact.lastName.charAt(0) : contact.lastName
  }, [isMobile, contact.lastName])

  return (
    <div className={styles.container}>
      {contact && contact.firstName && contact.lastName && (
        <div className={styles.button}>
          {!isMobile && <PageTitle variant="h4" type="regular">
            <span className={styles.content}><FormattedMessage id="individual.memoPage.connectedAs" /> :</span>
          </PageTitle>}
          <PageTitle variant="h4">
            <span className={cls(styles.content, styles.nameContent)}>{`${contact.firstName} ${lastName}`}</span>
          </PageTitle>
        </div>
      )}

      <Modal
        className={styles.modalContainer}
        visible={modalOpen}
        closable
        maskClosable={false}
        width={440}
        footer={null}
        onCancel={handleCloseModal}
        destroyOnClose
        centered
        closeIcon={
          <div className={styles.closeIcon}>
            <CloseIcon width="32px" height="32px" />
          </div>
        }
      >
        <Card
          bodyStyle={{ padding: 0 }}
          bordered={false}
          className={styles.contentCard}
        >
          <Form
            validateMessages={{
              required: "${label} is required!",
              string: {
                max: intl.formatMessage({
                  id: "admin.tagsPage.create.validation.max",
                }),
              },
            }}
            form={antForm}
            className={styles.formContainer}
            onFieldsChange={onFieldsChange}
            onFinish={onSubmit}
            onValuesChange={handleFormChange}
          >
            <header className={styles.header}>
              <PageTitle variant="h2">
                <FormattedMessage
                  id='admin.contactsPage.editIndividual.title'
                />
              </PageTitle>
            </header>
            <main>
              <Form.Item
                labelCol={{ xs: 24 }}
                label={intl.formatMessage({
                  id: "individual.signUpPage.fields.firstName",
                })}
                className={styles.formItem}
                rules={[{ required: true }]}
                name="firstName"
              >
                <Input className={styles.input} />
              </Form.Item>
              <Form.Item
                labelCol={{ xs: 24 }}
                label={intl.formatMessage({
                  id: "individual.signUpPage.fields.lastName",
                })}
                className={styles.formItem}
                rules={[{ required: true }]}
                name="lastName"
              >
                <Input className={styles.input} />
              </Form.Item>
              <Form.Item hidden name="id" />
            </main>
            <footer className={styles.footer}>
              <div className={styles.actionContainer}>
                <Button
                  className={styles.submitButton}
                  loading={saving}
                  type="primary"
                  htmlType="submit"
                >
                  <FormattedMessage id="general.update" />
                </Button>
              </div>
            </footer>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};
