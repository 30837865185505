import React, { memo, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './MemosPage.module.scss'
import memoItemStyle from './MemoItem.module.scss'
import memoCreatorStyle from './MemoCreatorItem.module.scss'
import { Avatar, Spin } from 'antd'
import { loadAll, reset } from './reducer'
import { loadAll as loadAllContacts } from '../contacts/reducer'
import { loadAll as loadAllTags } from '../tags/reducer'
import { loadAll as loadUsers } from '../users/reducer'
import _ from 'lodash'
import { useParams, useLocation, Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { getThumbnail } from 'utils'
import { PageTitle } from 'components/PageItems'
import icon from './creatorIcon.png'
import CreateButton from './CreateButton'
import MemoItem from './MemoItem'
import InitialSharingCard from './share/InitialSharingCard'

export const MemoCreatorItem = ({ onClick }) => {
  const style = memoCreatorStyle

  return (
    <div className={style.component}>
      <img src={icon} className={style.icon} />
      <strong className={style.title}>
        <FormattedMessage id='admin.memosPage.creatorItem.title' />
      </strong>
      <span className={style.hint}>
        <FormattedMessage id='admin.memosPage.creatorItem.hint' />
      </span>
      <CreateButton className={style.button}>
        <FormattedMessage
          id='admin.memosPage.creatorItem.button' />
      </CreateButton>
    </div>
  )
}

export const MemosTitle = memo(({ projectId }) => {
  const intl = useIntl()
  const { items } = useSelector(s => s.projects)
  let project = null
  let text = null

  if (projectId) {
    project = _.find(items, { id: projectId })
    if (project) {
      if (project.isDefault)
        text = intl.formatMessage({ id: 'admin.projects.default' })
      else
        text = project.name
    } else {
      text = ''
    }
  } else {
    text = intl.formatMessage({ id: 'admin.memos.title' })
  }

  return (
    <PageTitle variant="h1">
      {text}
    </PageTitle>
  )
})

export const MemosPage = ({ mine }) => {
  const params = useParams()
  const location = useLocation()
  const intl = useIntl()
  const dispatch = useDispatch()
  const { items, loading } = useSelector(state => state.memos)

  const { user } = useSelector(s => s.session)
  const onItemClick = useCallback((data) => {

  }, [])
  useEffect(() => {
    dispatch(loadAll({
      teamId: user.teamId,
      projectId: params.pid,
      userId: mine && user.uid
    }))
    return () => dispatch(reset())
  }, [location.pathname])

  useEffect(() => {
    dispatch(loadUsers({
      role: 'creator'
    }))
    return () => dispatch(reset())
  }, [location.pathname])

  useEffect(() => {
    dispatch(loadAllContacts());
    dispatch(loadAllTags());
  }, [location.pathname]);

  return (
    <div className={style.component}>
      <header className={style.header}>
        <MemosTitle projectId={params.pid} />
      </header>
      <main className={`${style.main}`}>
        {loading && (<Spin />)}
        {!loading && !items.length && (
          <MemoCreatorItem />
        )}
        {items.map((item, k, arr) => {
          return (
            <MemoItem
              key={`memos-id-${item.id}-${k}`}
              data={item}
              onClick={onItemClick} />
          )
        })}
      </main>
      <InitialSharingCard />
    </div>
  )
}

export default memo(MemosPage)
