import React from "react";
import style from "./MemoStepper.module.scss";
import PassedCircleIcon from "components/icons/PassedCircleIcon";
import { Steps, Popover } from "antd";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import cls from "classnames";

const { Step } = Steps;

export const MEMO_STEPS = {
  WELCOME: "WELCOME",
  WATCH: "WATCH",
  ANSWER: "ANSWER",
  COMPLETE: "COMPLETE",
};

const STEP_LABEL_KEYS = {
  WELCOME: "welcome",
  WATCH: "watch",
  ANSWER: "answer",
  COMPLETE: "complete",
};

const customDot = (dot, { status, index }) => {
  const renderStatusIcon = () => {
    if (status === "finish") {
      return <PassedCircleIcon height="24px" width="24px" />;
    } else {
      // return <div style={{ marginTop: '7px' }}>{dot}</div>
      return dot;
    }
  };

  return <>{renderStatusIcon()}</>;
};

export const MemoStepper = () => {
  const intl = useIntl();
  const { steps, showStepper, currentStep } = useSelector((s) => s.appHeader);
  const language = useSelector((s) => s.language);

  const current = steps.indexOf(currentStep);

  return (
    <div
      className={cls(
        style.container,
        { fourSteps: steps.length === 4 },
        { threeSteps: steps.length === 3 },
        { french: language.locale === 'fr' }
      )}
    >
      <Steps current={currentStep === MEMO_STEPS.COMPLETE ? current + 1 : current} progressDot={customDot}>
        {steps &&
          steps.length > 0 &&
          steps.map((step) => (
            <Step
              title={intl.formatMessage({
                id: `individual.stepper.${STEP_LABEL_KEYS[step]}`,
              })}
              key={step}
              id={step}
            />
          ))}
      </Steps>
    </div>
  );
};
