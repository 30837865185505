import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import _ from 'lodash'

const LOADING_START = 'SIGN_UP_SUPERVISOR/LOADING_START'
const LOADING_SUCCESS = 'SIGN_UP_SUPERVISOR/LOADING_SUCCESS'
const LOADING_END = 'SIGN_UP_SUPERVISOR/LOADING_END'
const SAVING_START = 'SIGN_UP_SUPERVISOR/SAVING_START'
const SAVING_END = 'SIGN_UP_SUPERVISOR/SAVING_END'
const RESET = 'SIGN_UP_SUPERVISOR/RESET'
const SET_FORM = 'SIGN_UP_SUPERVISOR/SET_FORM'

export const setForm = (form) => async (dispatch, getState) => {
	if (form.country) {
		form.state = null
		form.zipCode = null
	}
	dispatch({
		type: SET_FORM,
		form
	})
}

export const submit = (values) => async (dispatch, getState) => {
	dispatch({
		type: SAVING_START
	})

	try {
		let { email, password } = values
		let { user } = await firebase.auth().createUserWithEmailAndPassword(email, password)
		let data = _.omit(values, 'password')
		await firebase.firestore().collection('users').doc(user.uid).set({
			...data,
			role: 'supervisor'
		})
		dispatch({
			type: SAVING_END
		})
	} catch (ex) {
		console.warn(ex)
		throw ex
	}

	dispatch({
		type: SAVING_END
	})
}

export const reset = () => (dispatch) => {
	dispatch({
		type: RESET
	})
}

export const actions = {
	setForm,
	submit,
	reset
}

const initialState = {
	saving: false,
	form: {
		companyName: null,
		companyAddress: null,
		companyPhoneNumber: null,
		email: null,
		password: null,
		country: null,
		state: null,
		province: null,
		zipCode: null
	}
}

export default function reducer(state = initialState, action) {
	switch(action.type) {
		case LOADING_START:
			return {
				...state,
				loading: true
			}
		case LOADING_END:
			return {
				...state,
				loading: false
			}
		case LOADING_SUCCESS:
			return {
				...state,
				items: action.items
			}
		case SET_FORM:
			return {
				...state,
				form: {
					...state.form,
					...action.form
				}
			}
		case SAVING_START:
			return {
				...state,
				saving: true
			}
		case SAVING_END:
			return {
				...state,
				saving: false
			}
		case RESET:
			return {
				...initialState
			}
		default:
			return state
	}
}
