import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './SettingsPage.module.scss'
import { Table, Button } from 'antd'
import { loadAll, loadOne, setForm, reset } from './reducer'
import _ from 'lodash'
import { Switch, Route, Redirect, useRouteMatch, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'

export default function SettingsPage() {
	let match = useRouteMatch()
	let intl = useIntl()
	let dispatch = useDispatch()
	let history = useHistory()
	let { items } = useSelector(state => state.adminSettings)
	let columns = [{
		title: 'Client name',
		dataIndex: 'clientName',
		key: 'clientName'
	}, {
		title: 'Client logo',
		dataIndex: 'clientLogo',
		key: 'clientLogo'
	}]

	useEffect(() => {
		dispatch(loadAll())
		return () => dispatch(reset())
	}, [])

	function onCreate() {
		history.push('/admin/settings/new')
	}

	function onRowClick(row) {
		dispatch(loadOne(row.id))
		history.push(`/admin/settings/${row.id}`)
	}

	return (
		<div className={style.component}>
			<div className={style.content}>
				<header className={style.header}>
					<h1>{intl.formatMessage({ id: 'admin.settings.title' })}</h1>
					{/* <Button onClick={onCreate}>
						Create
					</Button> */}
				</header>
				<Table
					onRow={(record) => ({
						onClick: () => onRowClick(record)
					})}
					columns={columns}
					dataSource={items} />
			</div>
		</div>
	)
}