import React, { memo, useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './ProfilePage.module.scss'
import { Card, Button, Form, Input, Alert } from 'antd'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import { submit, loadOne, setError, reset } from './reducer'
import _ from 'lodash'
import cls from 'classnames'
import Countries from 'components/Countries'
import States from 'components/States'
import ZipCode from 'components/ZipCode'
import Language from 'components/Language'
import { initUser } from 'session/reducer'
import Avatar from './Avatar'
import { UserRole, UserName } from './UserItems'
import { PageTitle } from 'components/PageItems'
import ChangePasswordModal from './ChangePasswordModal'

export const FormItem = (props) => {
  const intl = useIntl()
  return (
    <Form.Item
      label={intl.formatMessage({ id: `profile.fields.${props.name}` })}
      className={style.formItem}
      labelCol={{ xs: 24 }}
      {...props}>
      {props.children}
    </Form.Item>
  )
}

const ProfilePage = ({ className, supervisor, admin, creator }) => {
  const [form] = Form.useForm()
  const { data, loading, saving, error } = useSelector(state => state.profile)
  const { user } = useSelector(s => s.session)
  const [avatarLoading, setAvatarLoading] = useState(false)
  const [changePasswordModal, setChagnePasswordModal] = useState(false)
  const intl = useIntl()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadOne())
  }, [])

  useEffect(() => {
    form.setFieldsValue(data)
  }, [data])

  const onBack = useCallback(() => {
    window.history.back()
  }, [])

  const onSubmit = useCallback(async () => {
    try {
      const values = await form.validateFields()
      values.avatar = values.avatar || null
      user.language = values.language
      await dispatch(submit(values))
      await dispatch(initUser(user))
      dispatch(reset())
      window.history.back()
    } catch (ex) {
      console.warn(ex)
    }
  }, [user])

  const onFieldsChange = useCallback(() => {
    error && dispatch(setError())
  }, [error])

  const onAvatarLoading = useCallback((state) => {
    setAvatarLoading(state)
  }, [])

  const openChangePasswordModal = () => {
    setChagnePasswordModal(true)
  }

  return (
    <Card className={cls(style.component, className)} loading={loading}>
      <Form
        form={form}
        onFinish={onSubmit}
        onFieldsChange={onFieldsChange}
        validateMessages={{
          required: intl.formatMessage({ id: 'validation.required' }),
          types: {
            email: intl.formatMessage({ id: 'validation.invalidEmail' })
          }
        }}>
        <header className={style.header}>
          <PageTitle>
            <span className={style.title}>
              <FormattedMessage id='profile.title' values={{
                name: <UserName />
              }} />
            </span>
          </PageTitle>
          <Form.Item
            className={style.formItem}
            name='avatar'>
            <Avatar onLoadingChange={onAvatarLoading} />
          </Form.Item>
          <UserName className={style.name} />
          <UserRole className={style.role} />
          <span className={style.email}>
            {user.email}
          </span>
        </header>
        {error && (
          <Alert
            type='error'
            message={intl.formatMessage({ id: error.code })} />
        )}
        <div className={style.cards}>
          <FormItem
            className={style.formItem}
            rules={[{ required: true }]}
            name='firstName'>
            <Input className={style.input} />
          </FormItem>
          <FormItem
            className={style.formItem}
            rules={[{ required: true }]}
            name='lastName'>
            <Input className={style.input} />
          </FormItem>
          <FormItem
            className={style.formItem}
            rules={[{ required: true, type: 'email' }]}
            name='email'>
            <Input className={style.input} type='email' />
          </FormItem>
          <FormItem
            className={style.formItem}
            rules={[{ required: true }]}
            name='language'>
            <Language className={style.input} />
          </FormItem>
          {supervisor && (
            <Card
              className={style.card}
              title={intl.formatMessage({ id: 'profile.company' })}>
              <FormItem rules={[{ required: true }]} name='companyName'>
                <Input />
              </FormItem>
              <FormItem rules={[{ required: true }]} name='companyAddress'>
                <Input />
              </FormItem>
              <FormItem rules={[{ required: true }]} name='companyCity'>
                <Input />
              </FormItem>
              <FormItem rules={[{ required: true }]} name='companyCountry'>
                <Countries />
              </FormItem>
              {form.companyCountry && (
                <FormItem rules={[{ required: true }]} name='companyState'>
                  <States country={form.companyCountry} />
                </FormItem>
              )}
              <FormItem rules={[{ required: true }]} name='companyZipCode'>
                <ZipCode country={form.companyCountry} />
              </FormItem>
              <FormItem rules={[{ required: true }]} name='companyPhoneNumber'>
                <Input />
              </FormItem>
              <FormItem rules={[{ required: true }]} name='companyEmail'>
                <Input type='email' />
              </FormItem>
            </Card>
          )}
          {creator && (
            // specific fields for creator
            null
          )}
          {admin && (
            // specific fields for admins
            null
          )}
          <div className={style.openChangePassword}>
            <Button onClick={openChangePasswordModal} className={style.backButton}>
              <FormattedMessage id='resetPassword.submit' />
            </Button>
          </div>
        </div>
        <footer className={style.footer}>
          <Button
            type='primary'
            onClick={onSubmit}
            loading={avatarLoading || saving}
            className={style.submitButton}>
            <FormattedMessage id='general.save' />
          </Button>
          <Button onClick={onBack} className={style.backButton}>
            <FormattedMessage id='forgotPassword.back' />
          </Button>
        </footer>
      </Form>
      <ChangePasswordModal open={changePasswordModal} onClose={() => setChagnePasswordModal(false)} />
    </Card>
  )
}

export default memo(ProfilePage)