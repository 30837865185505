import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './ProjectsFormPage.module.scss'
import { Alert, Form, Input, Button, Select, Card, Spin, message } from 'antd'
import { loadAll, loadOne, saveForm, reset, loadAllByAcronym, resetForm } from './reducer'
import _ from 'lodash'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { PageTitle } from 'components/PageItems'

export default function TeamsFormPage({ role }) {
  const [antdForm] = Form.useForm()
  const [error, setError] = useState(false)
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const { params } = useRouteMatch()
  const { form, loading, saving } = useSelector(state => state.projects)
  const isEditing = !!params.pid
  const onSubmit = useCallback(async () => {
    try {
      const values = await antdForm.validateFields()
      values.acronym = values.acronym.toUpperCase()
      values.confirmAcronym = values.confirmAcronym.toUpperCase()
      // validate uniq acronym
      const projects = await dispatch(loadAllByAcronym(values.acronym))
      if (projects.length)
        throw {
          ACRONYM_IS_NOT_UNIQUE: true
        }
      const id = await dispatch(saveForm(
        _.omit(values, 'confirmAcronym')
      ))
      dispatch(resetForm())
      history.push(`/app/projects/${id}/memos`)
      await dispatch(loadAll())
    } catch (ex) {
      console.warn(ex)
      if (ex.ACRONYM_IS_NOT_UNIQUE)
        setError({
          id: 'admin.projectsFormPage.validation.acronymsIsNotUnique'
        })
    }
  }, [])
  const validateAcronym = useCallback(({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue('acronym') === value)
        return Promise.resolve()
      return Promise.reject(
        intl.formatMessage({
          id: 'admin.projectsFormPage.validation.acronymsDoNotMatch'
        })
      )
    }
  }), [])
  const validateAcronymMinLength = useCallback(({ getFieldValue }) => ({
    validator(rule, value) {
      if (value.length === 3)
        return Promise.resolve()
      return Promise.reject(intl.formatMessage({
        id: 'admin.projectsFormPage.validation.acronymsLength'
      }))
    }
  }))
  const onFieldsChange = useCallback(() => setError(null), [])
  useEffect(() => {
    antdForm.setFieldsValue(form)
  }, [form])

  useEffect(() => {
    if (params.pid)
      dispatch(loadOne(params.pid))
    // return () => dispatch(reset())
  }, [params.pid])

  return (
    <div className={style.container}>
      <div className={style.component}>
        <header className={style.header}>
          <PageTitle variant="h2" className={style.title}>
            {intl.formatMessage({
              id: `admin.projectsFormPage.${isEditing ? 'update' : 'create'}`
            })}
          </PageTitle>
          <span className={style.desc}>
            <FormattedMessage id='admin.projectsFormPage.desc' /><br />
            <u>
              <FormattedMessage id='admin.projectsFormPage.desc2' />
            </u>
          </span>
        </header>
        {error && (
          <Alert
            type='error'
            message={intl.formatMessage({ id: error.id })} />
        )}
        <Form
          autoComplete="off"
          form={antdForm}
          onFieldsChange={onFieldsChange}
          validateMessages={{
            required: intl.formatMessage({ id: 'validation.required' }),
            types: {
              email: intl.formatMessage({ id: 'validation.invalidEmail' })
            }
          }}>
          <Form.Item
            labelCol={{ xs: 24 }}
            label={intl.formatMessage({ id: 'admin.projectsFormPage.fields.name' })}
            className={style.formItem}
            rules={[{ required: true }]}
            name='name'>
            <Input className={style.input} />
          </Form.Item>
          <Form.Item
            labelCol={{ xs: 24 }}
            label={intl.formatMessage({ id: 'admin.projectsFormPage.fields.acronym' })}
            className={style.formItem}
            rules={[
              { required: true },
              validateAcronymMinLength
            ]}
            name='acronym'>
            <Input maxLength={3} className={style.input} style={{ textTransform: 'uppercase' }} />
          </Form.Item>
          <Form.Item
            labelCol={{ xs: 24 }}
            label={intl.formatMessage({ id: 'admin.projectsFormPage.fields.confirmAcronym' })}
            className={style.formItem}
            rules={[
              { required: true },
              validateAcronym
            ]}
            name='confirmAcronym'>
            <Input maxLength={3} className={style.input} style={{ textTransform: 'uppercase' }} />
          </Form.Item>
          <Button
            className={style.submitButton}
            onClick={onSubmit}
            loading={saving}
            type='primary'
            htmlType='submit'>
            {intl.formatMessage({
              id: `general.${isEditing ? 'update' : 'create'}`
            })}
          </Button>
        </Form>
      </div>
    </div>
  )
}