import React, { useCallback, useEffect, useState } from "react";
import { Upload, message } from "antd";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import style from "./Avatar.module.scss";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/app";
import cls from "classnames";
import "firebase/storage";
import {
  THUMBNAIL,
  fetchThumbnail,
  ALLOWED_IMAGE_TYPES,
  getThumbnail,
} from "utils";
import _ from "lodash";
import { FormattedMessage, useIntl } from "react-intl";

const { REACT_APP_MAX_IMAGE_SIZE = 8 } = process.env;

export default function Avatar({
  value,
  onChange,
  onLoadingChange,
  className,
  children,
  isCustom = true,
}) {
  const isValidUrl = React.useMemo(() => {
    if (!getThumbnail(value)) {
      return false;
    } else if (getThumbnail(value)) {
      if (typeof getThumbnail(value) === 'string') {
        return true;
      }
      const urlData = getThumbnail(value);
      if (!urlData.thumbnail && !urlData.url) {
        return false;
      }
    }
    return true;
  }, [value]);

  const [loading, setLoading] = useState(false);
  const { user } = useSelector((s) => s.session);
  const intl = useIntl();
  const beforeUpload = useCallback((file) => {
    const isJpgOrPng = ALLOWED_IMAGE_TYPES.indexOf(file.type) > -1;
    const imageSizePass = file.size / 1024 / 1024 < REACT_APP_MAX_IMAGE_SIZE;
    if (!isJpgOrPng)
      message.error(
        intl.formatMessage({
          id: "errors.onlyImages",
        })
      );
    if (!imageSizePass)
      message.error(
        intl.formatMessage({
          id: "errors.bigImage",
        })
      );
    return isJpgOrPng && imageSizePass;
  }, []);
  const customRequest = useCallback(async (data) => {
    setLoading(true);
    try {
      const { file } = data;
      const id = uuidv4();
      const path = `users/${user.uid}`;
      const result = await firebase.storage().ref(path).child(id).put(file);
      const thumbnail = await fetchThumbnail(path, id, THUMBNAIL.MEDIUM);
      const url = await result.ref.getDownloadURL();
      onChange({
        url,
        thumbnail,
      });
    } catch (ex) {
      console.warn(ex);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    onLoadingChange(loading);
  }, [loading]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }} className="label">
        <FormattedMessage id="profile.avatar.upload" />
      </div>
    </div>
  );

  const renderChildren = () => {
    if (children) {
      return <div className={style.content}>{children}</div>;
    } else {
      return loading ? (
        uploadButton
      ) : value && isValidUrl ? (
        <img src={getThumbnail(value)} alt="avatar" style={{ width: "100%" }} />
      ) : (
        uploadButton
      );
    }
  };

  return (
    <div>
      <Upload
        accept=".jpg, .png, .jpeg, .gif"
        name="avatar"
        listType={isCustom ? "picture-card" : ""}
        className={isCustom ? cls(style.component, className) : ""}
        showUploadList={false}
        beforeUpload={beforeUpload}
        customRequest={customRequest}
      >
        {renderChildren()}
      </Upload>
    </div>
  );
}
