export const EMAIL_ASC = 'emailAsc'
export const EMAIL_DESC = 'emailDesc'
export const NAME_ASC = 'nameAsc'
export const NAME_DESC = 'nameDesc'

export const INVITATION_FILTERS = [
  {
    value: EMAIL_ASC
  },
  {
    value: EMAIL_DESC
  },
  {
    value: NAME_ASC
  },
  {
    value: NAME_DESC
  },
]

export default INVITATION_FILTERS;
