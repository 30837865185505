import React from 'react'
import { PageTitle } from 'components/PageItems'

import style from './NoAccess.module.scss'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

const NoAccess = () => {
  const data = useSelector((s) => s.individualMemo);

  return (
    <div className={style.container}>
      <div className={style.component}>
        <div className={style.card}>
          <PageTitle variant="h2" className={style.title}>
            <FormattedMessage id="individual.memoPage.oops" />!
          </PageTitle>
          <p className={style.hint}>
            <FormattedMessage id="individual.memoPage.noAccessMessage" />!
          </p>
        </div>
      </div>
    </div>
  )
}

export default NoAccess