import React, { useState } from 'react'
import {
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  KeyBindingUtil,
  Modifier,
  CompositeDecorator,
  convertFromRaw,
  convertToRaw,
} from 'draft-js'
import style from './RichEditor.module.scss'
import cls from 'classnames'

import BlockStyleControls from './BlockStyleControls'
import InlineStyleControls from './InlineStyleControls'
import { StyleButton } from './StyleButton'
import LinkInput from "./LinkInput"

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
  }, callback)
}

const Link = (props) => {
  const { url, linkText } = props.contentState.getEntity(props.entityKey).getData()
  return <a href={url} target="_blank" rel="noopener noreferrer">{linkText || props.children}</a>
}

const initializeState = (savedState) => {
  const decorator = new CompositeDecorator([
    {strategy: findLinkEntities, component: Link}
  ])
  let initialEditorState
  if (savedState.length > 0) {
    const deSerializedDraftState = JSON.parse(savedState)
    const savedContentState = convertFromRaw(deSerializedDraftState)
    initialEditorState = EditorState.createWithContent(savedContentState, decorator)
  } else {
    const initialBlankEditorContentState = EditorState.createEmpty().getCurrentContent()
    initialEditorState = EditorState.createWithContent(initialBlankEditorContentState, decorator)
  }
  return initialEditorState
}

const RichTextEditor = (
  {placeholder, savedState = '', onSave, readOnly = false, displayMode = false}
) => {
  const [editorState, setEditorState] = useState(initializeState(savedState))
  const [linkInputOpen, setLinkInputOpen] = useState(false)
  const selection = editorState.getSelection()

  const getCurrentBlock = (content) => convertToRaw(content).blocks.find(block => block.key === selection.getFocusKey())
  const blockType = getCurrentBlock(editorState.getCurrentContent()).entityRanges?.length

  const { onTab, toggleBlockType, toggleInlineStyle } = RichUtils
  const { hasCommandModifier } = KeyBindingUtil

  const addLink = (linkUrl, linkText) => {
    setLinkInputOpen(true)
    const selectionState = editorState.getSelection()
    let link = linkUrl
    if (!link) return
    if (!linkUrl.includes('http://') && !linkUrl.includes('https://')) link = `http://${linkUrl}`

    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {url: link})
    let currentBlock = getCurrentBlock(contentState)
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

    const contentStateWithLink = currentBlock.text ?
      Modifier.applyEntity(contentStateWithEntity, selectionState, entityKey) :
      Modifier.replaceText(
        contentStateWithEntity,
        selectionState,
        linkText,
        editorState.getCurrentInlineStyle(),
        entityKey
      )

    const newEditorState = EditorState.set(editorState, {currentContent: contentStateWithLink})
    const newStateRaw = convertToRaw(newEditorState.getCurrentContent())
    currentBlock = getCurrentBlock(newEditorState.getCurrentContent())
    if (!currentBlock.text) {
      return onEditorChange(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey))
    }
    currentBlock.text = linkText
    const updateCurrentBlockData = (text) => {
      if (currentBlock?.[text]?.length) {currentBlock[text] = [ {...currentBlock[text][0], length: linkText.length} ]}
    }
    updateCurrentBlockData('entityRanges')
    updateCurrentBlockData('inlineStyleRanges')

    const currentBlockEntityKey = currentBlock?.entityRanges?.[0]?.key
    if (newStateRaw.entityMap[currentBlockEntityKey]?.data?.url) {
      const entity = newStateRaw.entityMap[currentBlockEntityKey]
      entity.data.url = link
      newStateRaw.entityMap[currentBlockEntityKey] = entity
    }
    newStateRaw.blocks[newStateRaw.blocks.findIndex(block => block.key === selectionState.getFocusKey())] = currentBlock
    const newEditorStateFinal = EditorState.set(editorState, {currentContent: convertFromRaw(newStateRaw)})
    return onEditorChange(newEditorStateFinal)
  }

  const getInitialData = () => {
    const contentState = editorState.getCurrentContent()
    const block = getCurrentBlock(contentState)
    const text = block.text
    const url = convertToRaw(contentState).entityMap[block?.entityRanges?.[0]?.key]?.data?.url
    return {text: text, url: url}
  }

  const customKeyBindingFn = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = onTab(e, editorState, 4 /* maxDepth */)
      setEditorState(newEditorState)
      return 'editor-tab'
    }
    else if (e.keyCode === 83 /* `S` key */ && hasCommandModifier(e)) return 'editor-save'
    else if (e.keyCode === 66 /* `B` key */ && hasCommandModifier(e)) return 'editor-toggle-bold'
    else if (e.keyCode === 73 /* `I` key */ && hasCommandModifier(e)) return 'editor-toggle-italic'
    else if (e.keyCode === 85 /* `U` key */ && hasCommandModifier(e)) return 'editor-toggle-underline'
    else if (e.keyCode === 75 /* `k` key */ && hasCommandModifier(e)) return 'editor-toggle-link'
    return getDefaultKeyBinding(e)
  }

  const customHandleKeyCommand = (command, editorState) => {
    switch (command) {
      case 'editor-tab': return 'handled'
      case 'editor-save':
        onSave(JSON.stringify(convertToRaw(editorState.getCurrentContent()), null, 2))
        return 'handled'
      case 'editor-toggle-bold':
        customToggleInlineStyle('BOLD')
        return 'handled'
      case 'editor-toggle-italic':
        customToggleInlineStyle('ITALIC')
        return 'handled'
      case 'editor-toggle-underline':
        customToggleInlineStyle('UNDERLINE')
        return 'handled'
      case 'editor-toggle-link':
        addLink()
        return 'handled'
      default: return 'not-handled'
    }
  }

  const customToggleBlockType = (blockType) => setEditorState(toggleBlockType(editorState, blockType))
  const customToggleInlineStyle = (blockType) => setEditorState(toggleInlineStyle(editorState, blockType))

  const onEditorChange = (newState) => {
    setEditorState(newState)
    onSave(JSON.stringify(convertToRaw(newState.getCurrentContent()), null, 2))
  }
  
  const getActiveGroup = () => (
    document.querySelectorAll(`[data-offset-key="${selection.getFocusKey() + "-0-0"}"]`)
  )

  return (
    <>
      {linkInputOpen && (
        <LinkInput
          positionTop={getActiveGroup()?.[0].offsetTop}
          bottomMode={getActiveGroup()?.[0]?.getBoundingClientRect().top < (212 + 144)}
          initialData={getInitialData()}
          setLinkData={(data) => addLink(data.url, data.text)}
          close={() => setLinkInputOpen(false)}
        />
      )}
      <div className={cls(displayMode ? style.editorRootDisplayMode : "", style.editorRoot)}>
        <div className={cls(displayMode ? style.editorDisplayMode : "", style.editor)}>
          <Editor
            editorState={editorState}
            handleKeyCommand={customHandleKeyCommand}
            keyBindingFn={customKeyBindingFn}
            onChange={onEditorChange}
            placeholder={placeholder}
            readOnly={readOnly}
            spellCheck={true}
          />
        </div>
        {!displayMode && (
          <div className={style.editorControlsContainer}>
            <InlineStyleControls editorState={editorState} onToggle={customToggleInlineStyle} />
            <BlockStyleControls editorState={editorState} onToggle={customToggleBlockType} />
            <StyleButton
              key="Link"
              label="Link"
              active={blockType > 0}
              onToggle={() => {setLinkInputOpen(!linkInputOpen)}}
              className={style.editorStyleButton}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default RichTextEditor