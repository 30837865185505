import React, { useCallback, useEffect, useState } from 'react'
import { Upload, message } from 'antd'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import PlusOutlined from '@ant-design/icons/PlusOutlined'
import style from './Logo.module.scss'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import firebase from 'firebase/app'
import 'firebase/storage'
import { THUMBNAIL, fetchThumbnail, ALLOWED_IMAGE_TYPES, getThumbnail } from 'utils'
import _ from 'lodash'
import { useIntl } from 'react-intl'

const { REACT_APP_MAX_IMAGE_SIZE = 8 } = process.env

export default function Logo({ value, onChange, onLoadingChange }) {
  const [loading, setLoading] = useState(false)
  const { user } = useSelector(s => s.session)
  const intl = useIntl()
  const beforeUpload = useCallback((file) => {
    const isJpgOrPng = ALLOWED_IMAGE_TYPES.indexOf(file.type) > -1
    const imageSizePass = file.size / 1024 / 1024 < REACT_APP_MAX_IMAGE_SIZE
    if (!isJpgOrPng)
      message.error(intl.formatMessage({
        id: 'errors.onlyImages'
      }))
    if (!imageSizePass)
      message.error(intl.formatMessage({
        id: 'errors.bigImage'
      }))
    return isJpgOrPng && imageSizePass
  }, [])
  const customRequest = useCallback(async (data) => {
    setLoading(true)
    try {
      const { file } = data
      const id = uuidv4()
      const path = `teams-logos`
      const result = await firebase.storage().ref(path).child(id).put(file)
      const thumbnail = await fetchThumbnail(path, id, THUMBNAIL.MEDIUM)
      const url = await result.ref.getDownloadURL()
      onChange({
        url,
        thumbnail
      })
    } catch (ex) {
      console.warn(ex)
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    onLoadingChange(loading)
  }, [loading])

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  return (
    <Upload
      name='avatar'
      listType='picture-card'
      className={style.component}
      showUploadList={false}
      beforeUpload={beforeUpload}
      customRequest={customRequest}
    >
      {loading ? (
        uploadButton
      ) : (
          value ? <img src={getThumbnail(value)} alt="avatar" style={{ width: '100%' }} /> : uploadButton
        )}
    </Upload>
  )
}
