import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  withRouter,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router";
import AppLogo from "components/AppLogo";
import Icon from "components/Icon";
import style from "./AppHeader.module.scss";
import { FormattedMessage } from "react-intl";
import { actions } from "./reducer";
import { Row, Col, Button, Form, Space } from "antd";
import cls from "classnames";
import _ from "lodash";
import routes, { toRoot } from "routes";
import { Mobile, Tablet, Touch, Desktop } from "responsive";
import { Link } from "react-router-dom";
import LanguageSwitch from "components/language/LanguageSwitch";
import { EditContactInfo } from "components/appHeader/EditContactInfo";
import { MemoStepper } from "components/appHeader/MemoStepper";
import { logOut } from "session/reducer";
import { setSteps } from "./reducer";

export default function AppHeader({ className, type }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { user } = useSelector((state) => state.session);
  const { contact, form } = useSelector((s) => s.individualMemo);
  const { steps, showStepper, currentStep } = useSelector((s) => s.appHeader);

  const current = steps.indexOf(currentStep) + 1;

  const stepRef = React.useRef()

  const onLogOut = useCallback(async () => {
    let url = toRoot(location, params);
    await dispatch(logOut());
    history.push(url);
  }, [location, params]);

  const onSignIn = useCallback(() => { }, []);

  const onSignUp = useCallback(() => { }, []);

  const hasAdminPanelAccess =
    user && (user.role === "admin" || user.role === "creator");

  React.useEffect(() => {
    if (form && form.id) {
      dispatch(setSteps(form.questions && form.questions.length > 0))
    }
  }, [form.questions])



  React.useEffect(() => {
    if (stepRef && stepRef.current && current === steps.length) {
      stepRef.current.scrollLeft = stepRef.current.scrollWidth;
    }

    const currentIndex = steps.indexOf(currentStep);

    if (currentIndex !== steps.length - 1 && currentIndex !== 0) {
      if (currentStep && document.getElementById(`${currentStep}`)) {
        document.getElementById(`${currentStep}`).scrollIntoView({
          inline: "end",
          behavior: 'smooth',
        });
        setTimeout(() => {
          document.getElementById('stepContainer').scrollLeft += 7;
        }, 500)
      }
    }
  }, [stepRef, current, steps])

  return (
    <div className={style.container}>
      <div
        className={cls(style.component, className, {
          [style.withUser]: !!user,
          [style[type]]: true,
        })}
      >
        <div className={style.content}>
          <span className={style.title}>
            <AppLogo className={style.appLogo} />
            {user && user.role && (
              <span className={style.role}>({user.role})</span>
            )}
          </span>
          {user ? (
            <>
              <ul className={style.menu}>
                {/* {(() => {
                switch (user.role) {
                  case "admin":
                    return (
                      <React.Fragment>
                        <Link to="/admin/creators">Creators</Link>
                        <Link to="/admin/pages">Pages</Link>
                        <Link to="/admin/media">Media</Link>
                        <Link to="/admin/settings">Settings</Link>
                        <Link to="/admin/profile">Profile</Link>
                      </React.Fragment>
                    );
                  case "creator":
                    return (
                      <React.Fragment>
                        <Link to="/admin/pages">Pages</Link>
                        <Link to="/admin/media">Media</Link>
                        <Link to="/admin/settings">Settings</Link>
                        <Link to="/admin/profile">Profile</Link>
                      </React.Fragment>
                    );
                  case "supervisor":
                    return <Link to="/profile">Profile</Link>;
                  default:
                    return null;
                }
              })()} */}
              </ul>
              <div className={style.topActionContainer}>
                <Space size={8}>
                  {contact && contact.id && <EditContactInfo />}
                  <LanguageSwitch />
                </Space>
              </div>

              <span onClick={onLogOut} className={style.logOut}>
                log out
              </span>
            </>
          ) : (
            <React.Fragment>
              <ul className={style.menu}>
                {/* <li>Features</li>
								<li>Pricing</li>
								<li>F.A.Q.</li>
								<li>About</li> */}
              </ul>
              <LanguageSwitch />
              {/* <Link to='/sign-in'>
								Sign in
							</Link> */}
              {/*<Link to='/sign-up'>
								Sign up
							</Link>*/}
            </React.Fragment>
          )}
        </div>
      </div>
      {showStepper && (
        <div
          className={cls(style.stepComponent, className, {
            [style.withUser]: !!user,
            [style[type]]: true,
          })}
        >
          <div className={style.stepContainer} ref={stepRef} id="stepContainer">
            <MemoStepper />
          </div>
        </div>
      )}
    </div>
  );
}
