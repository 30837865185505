import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'

const {REACT_APP_API_SHARED_ADD_EMAIL, REACT_APP_API_SHARED_VIEW, REACT_APP_API} = process.env

const SET_STEP = 'SHARED_PAGE/SET_STEP'
const NEXT_STEP = 'SHARED_PAGE/NEXT_STEP'
const INIT_VIEW = 'SHARED_PAGE/INIT_VIEW'
const LOADING_START = 'SHARED_PAGE/LOADING_START'
const LOADING_END = 'SHARED_PAGE/LOADING_END'
const SAVING_DONE = 'SHARED_PAGE/SAVING_DONE'

 const getSharedId = (url) => {
  try {
    return url.split('shared/')[1].split('/')[0]
  } catch (ex) {
    console.warn(ex)
  }

  return ''
}

export const createEmail = (form) => async(dispatch, getState) => {
  dispatch({type: LOADING_START})
  let response = await axios.post(REACT_APP_API_SHARED_ADD_EMAIL.replace(':id', getSharedId(window.location.href)), form)
  dispatch({type: LOADING_END})
  dispatch({type: SAVING_DONE})
}

export const loadData = () => async (dispatch, getState) => {
  dispatch({type: LOADING_START})
  let user = firebase.auth().currentUser

  if (user && user.uid) {
    try {
      let instantAccessResponse = await axios.post(`${REACT_APP_API}/shared/instant/${getSharedId(window.location.href)}`, {uid: user.uid})
      window.location.href = instantAccessResponse.data.redirect
    } catch (e) {
      console.warn('could not pre log in on this memo')
    }
  }

  let response = await axios.post(REACT_APP_API_SHARED_VIEW.replace(':id', getSharedId(window.location.href)))

  if (!response.data.success) {
    dispatch({
      type: SET_STEP,
      payload: 1
    })
  } else {
    dispatch(setView(response.data))
    dispatch({
      type: SET_STEP,
      payload: 2
    })
  }
  dispatch({type: LOADING_END})
}

export const setView = (payload) => {
  return {
    type: INIT_VIEW,
    payload
  }
}

export const next = () => {
    return {
      type: NEXT_STEP
    }
}


const initialState = {
  loading: false,
  step: 0,
  form: {},
  view: {}
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
    case NEXT_STEP:
      return {
        ...state,
        step: state.step + 1
      }
    case SET_STEP:
      return {
        ...state,
        step: action.payload
      }
    case INIT_VIEW:
      return {
        ...state,
        view: action.payload
      }
		case LOADING_START:
			return {
				...state,
				loading: true
			}
		case LOADING_END:
			return {
				...state,
				loading: false
			}
    case SAVING_DONE:
      return {
        ...state,
        showDone: true
      }
		default:
			return state
	}
}
