import firebase from 'firebase/app'
import 'firebase/firestore'
import _ from 'lodash'

const LOADING_START = 'ADMIN_TEAM_SELECT/LOADING_START'
const LOADING_SUCCESS = 'ADMIN_TEAM_SELECT/LOADING_SUCCESS'
const LOADING_END = 'ADMIN_TEAM_SELECT/LOADING_END'
const RESET = 'ADMIN_TEAM_SELECT/RESET'

export const loadAll = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_START
  })
  try {
    // @TODO include algolia full text search solution
    const result = await firebase.firestore().collection('teams').get()
    dispatch({
      type: LOADING_SUCCESS,
      items: result.docs.map(d => {
        return {
          id: d.id,
          ...d.data()
        }
      })
    })
  } catch (ex) {
    console.warn(ex)
  }
  dispatch({
    type: LOADING_END
  })
}

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET
  })
}

export const actions = {
  loadAll,
  reset
}

const initialState = {
  loading: false,
  items: []
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        loading: true
      }
    case LOADING_END:
      return {
        ...state,
        loading: false
      }
    case LOADING_SUCCESS:
      return {
        ...state,
        items: action.items
      }
    case RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
