import React from "react";

const CircleMinusIcon = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="16" height="16" rx="8" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3.16663 8.00033C3.16663 7.54009 3.53972 7.16699 3.99996 7.16699H12C12.4602 7.16699 12.8333 7.54009 12.8333 8.00033C12.8333 8.46056 12.4602 8.83366 12 8.83366H3.99996C3.53972 8.83366 3.16663 8.46056 3.16663 8.00033Z" fill="white" />
  </svg>
);

export default CircleMinusIcon;


