import React, { memo } from 'react'
import { Select } from 'antd'
import countries from 'dicts/countries.js'

export default memo(({ value, onChange }) => {
	return (
		<Select onChange={onChange} value={value}>
			{countries.map(item => {
				return (
					<Select.Option value={item.code} key={item.code}>
						{item.name}
					</Select.Option>
				)
			})}
		</Select>
	)
})