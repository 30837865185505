import { combineReducers } from 'redux'
import session from 'session/reducer'
import landing from 'pages/landing/reducer'
import authType from 'pages/authType/reducer'
import signUpUser from 'pages/signUp/userReducer'
import signUpSupervisor from 'pages/signUp/supervisorReducer'
import signIn from 'pages/signIn/reducer'
import resetPassword from 'pages/resetPassword/reducer'
import document from 'pages/document/reducer'
import profile from 'pages/profile/reducer'
import assistance from 'pages/assistance/reducer'
// admin pages
import admin from 'pages/admin/reducer'
import adminCreators from 'pages/admin/users/reducer'
import adminMedia from 'pages/admin/media/reducer'
import adminDocuments from 'pages/admin/documents/reducer'
import adminTeams from 'pages/admin/teams/reducer'
import adminSettings from 'pages/admin/settings/reducer'
import projects from 'pages/admin/projects/reducer'
import memos from 'pages/admin/memos/reducer'
import memosFormQuestions from 'pages/admin/memos/form/questions/reducer'
import memosShare from 'pages/admin/memos/share/reducer'
import memosFormUrl from 'pages/admin/memos/form/url/reducer'
import memoStats from 'pages/admin/memos/stats/reducer'
import contacts from 'pages/admin/contacts/reducer'
import tags from 'pages/admin/tags/reducer'
// individual pages
import individualMemo from 'pages/individual/memo/reducer'
import individualWelcome from 'pages/individual/welcome/reducer'

//shared memos
import sharedPage from 'pages/shared/reducer'

// components
import language from 'components/language/reducer'
import adminTeamSelect from 'components/teamSelect/reducer'
import projectSelect from 'components/projectSelect/reducer'
import appHeader from 'components/appHeader/reducer'

const app = combineReducers({
  session,
  landing,
  authType,
  signIn,
  resetPassword,
  signUpUser,
  signUpSupervisor,
  document,
  language,
  admin,
  adminCreators,
  adminMedia,
  adminDocuments,
  adminTeams,
  adminTeamSelect,
  adminSettings,
  profile,
  projects,
  memos,
  memosFormQuestions,
  memosFormUrl,
  memosShare,
  memoStats,
  projectSelect,
  individualMemo,
  individualWelcome,
  sharedPage,
  assistance,
  contacts,
  tags,
  appHeader
})

export default app
