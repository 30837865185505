import firebase from 'firebase/app'
import 'firebase/firestore'
import _ from 'lodash'

const LOADING_START = 'PROJECTS/LOADING_START'
const LOADING_SUCCESS = 'PROJECTS/LOADING_SUCCESS'
const LOADING_END = 'PROJECTS/LOADING_END'
const SAVING_START = 'PROJECTS/SAVING_START'
const SAVING_END = 'PROJECTS/SAVING_END'
const RESET = 'PROJECTS/RESET'
const RESET_FORM = 'PROJECTS/RESET_FORM'
const LOADING_ONE_SUCCESS = 'PROJECTS/LOADING_ONE_SUCCESS'

export const DEFAULT_PROJECT = {
  name: 'Default',
  acronym: 'GEN',
  isDefault: true
}

export const loadAll = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_START
  })
  try {
    const { user } = getState().session
    const result = await firebase
      .firestore()
      .collection(`teams/${user.teamId}/projects`)
      .orderBy('name', 'asc')
      .get()
    const items = result.docs.map(d => {
      return {
        id: d.id,
        ...d.data()
      }
    })

    dispatch({
      type: LOADING_SUCCESS,
      items: _.sortBy(items, ['isDefault', 'name'])
    })
  } catch (ex) {
    console.warn(ex)
  }
  dispatch({
    type: LOADING_END
  })
}

export const loadOne = (id) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_START
  })
  try {
    const result = await firebase
      .firestore()
      .collection('teams')
      .doc(id)
      .get()
    dispatch({
      type: LOADING_ONE_SUCCESS,
      payload: {
        form: {
          id,
          ...result.data()
        }
      }
    })
  } catch (ex) {
    console.warn(ex)
    throw ex
  }

  dispatch({
    type: LOADING_END
  })
}

export const loadAllByAcronym = (acronym) => async (dispatch, getState) => {
  try {
    const { teamId } = getState().session.user
    const result = await firebase
      .firestore()
      .collection(`teams/${teamId}/projects`)
      .where('acronym', '==', acronym)
      .get()
    return result.docs
  } catch (ex) {
    console.warn(ex)
  }
  return []
}

export const saveForm = (values) => async (dispatch, getState) => {
  const { user } = getState().session
  const { form: project } = getState().projects

  dispatch({
    type: SAVING_START
  })
  try {
    // add createdAt if user creates new project
    if (!project.createdAt)
      values.createdAt = firebase.firestore.FieldValue.serverTimestamp()
    await firebase
      .firestore()
      .collection(`teams/${values.teamId || user.teamId}/projects`)
      .doc(values.acronym)
      .set(values, {
        merge: true
      })
  } catch (ex) {
    console.warn(ex)
    dispatch({
      type: SAVING_END
    })
    throw ex
  }
  dispatch({
    type: SAVING_END
  })
  return values.acronym
}

export const reset = () => {
  return {
    type: RESET
  }
}

export const resetForm = () => {
  return {
    type: RESET_FORM
  }
}

const initialState = {
  saving: false,
  loading: false,
  items: [],
  form: {
    id: null,
    name: null,
    teamId: null,
    acronym: null,
    confirmAcronym: null
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        loading: true
      }
    case LOADING_ONE_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case LOADING_END:
      return {
        ...state,
        loading: false
      }
    case LOADING_SUCCESS:
      return {
        ...state,
        items: action.items
      }
    case SAVING_START:
      return {
        ...state,
        saving: true
      }
    case SAVING_END:
      return {
        ...state,
        saving: false
      }
    case RESET_FORM:
      return {
        ...state,
        form: {
          ...initialState.form
        }
      }
    case RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
