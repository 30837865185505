import React from 'react'
import { useSelector } from 'react-redux'
import style from './AdminPage.module.scss'
import { Card, Spin, Button, Layout } from 'antd'
import { actions } from './reducer'
import _ from 'lodash'
import { Switch, Route, Redirect, useRouteMatch, useParams, useLocation } from 'react-router-dom'
import UsersPage from './users/UsersPage'
import UsersFormPage from './users/UsersFormPage'
import SettingsFormPage from './settings/SettingsFormPage'
import SettingsPage from './settings/SettingsPage'
import ProfilePage from 'pages/profile/ProfilePage'
import AssistancePage from 'pages/assistance/AssistancePage'
import ContactsPage from 'pages/admin/contacts/ContactsPage'
import TagsPage from 'pages/admin/tags/TagsPage'
import AssistanceCompletePage from 'pages/assistance/AssistanceCompletePage'
import TeamsPage from './teams/TeamsPage'
import TeamsFormPage from './teams/TeamsFormPage'
import ProjectsFormPage from './projects/ProjectsFormPage'
import MemosPage from './memos/MemosPage'
import MemosFormPage from './memos/MemosFormPage'
import MemoViewMode from './memos/MemoViewMode'
import MemosStatsPage from './memos/stats/StatsPage'
import NavBar from 'components/navBar/NavBar'
import SideBar from 'components/sideBar/SideBar'

const { Sider, Content } = Layout

export default function AdminPage() {
  const { user } = useSelector(s => s.session)
  return (
    <Layout className={style.container}>
      <SideBar />
      <Layout>
        <NavBar />
        <Content className={style.content}>
          {user.role === 'admin' && (
            <Switch>
              <Route exact path="/app/settings/new" component={SettingsFormPage} />
              <Route exact path="/app/settings/:id" component={SettingsFormPage} />
              <Route exact path="/app/settings" component={SettingsPage} />
              {/* creators */}
              <Route exact path="/app/creators/new">
                <UsersFormPage role="creator" />
              </Route>
              <Route exact path="/app/creators/:id">
                <UsersFormPage role="creator" />
              </Route>
              <Route exact path="/app/creators">
                <UsersPage role="creator" />
              </Route>
              {/* admins */}
              <Route exact path="/app/admins/new">
                <UsersFormPage role="admin" />
              </Route>
              <Route exact path="/app/admins/:id">
                <UsersFormPage role="admin" />
              </Route>
              <Route exact path="/app/admins">
                <UsersPage role="admin" />
              </Route>
              {/* teams */}
              <Route exact path="/app/teams" component={TeamsPage} />
              <Route exact path="/app/teams/new" component={TeamsFormPage} />
              <Route exact path="/app/teams/:id" component={TeamsFormPage} />
              {/* profile */}
              <Route exact path="/app/profile" component={ProfilePage} />
              <Route exact path="/app/assistance" component={AssistancePage} />
              <Route exact path="/app/assistance/complete" component={AssistanceCompletePage} />
              <Route>
                <Redirect to="/app/admins" />
              </Route>
            </Switch>
          )}
          {user.role === 'creator' && (
            <Switch>
              <Route exact path="/app/memos/new" component={TeamsFormPage} />
              <Route exact path="/app/memos/my" component={TeamsFormPage} />

              <Route exact path="/app/projects/new" component={ProjectsFormPage} />
              <Route exact path="/app/projects/:pid" component={ProjectsFormPage} />

              <Route exact path="/app/projects/all/memos" component={MemosPage} />
              <Route exact path="/app/projects/:pid/memos" component={MemosPage} />
              <Route exact path="/app/projects/all/memos/my">
                <MemosPage mine />
              </Route>
              <Route exact path="/app/projects/:pid/memos/my">
                <MemosPage mine />
              </Route>
              <Route exact path="/app/projects/all/memos/new" component={MemosFormPage} />
              <Route exact path="/app/projects/:pid/memos/new" component={MemosFormPage} />
              <Route exact path="/app/projects/:pid/memos/:id" component={MemosFormPage} />
              <Route exact path="/app/projects/:pid/memos/:id/view" component={MemoViewMode} />
              <Route exact path="/app/projects/:pid/memos/:id/stats" component={MemosStatsPage} />

              {/* contacts */}
              <Route exact path="/app/contactlist" component={ContactsPage} />

              {/* tags */}
              <Route exact path="/app/tags" component={TagsPage} />

              {/* profile */}
              <Route exact path="/app/profile" component={ProfilePage} />
              {/** Assistance */}
              <Route exact path="/app/assistance" component={AssistancePage} />
              <Route exact path="/app/assistance/complete" component={AssistanceCompletePage} />
              <Route>
                <Redirect to="/app/projects/all/memos" />
              </Route>
            </Switch>
          )}

          {/* {user.role === 'admin' && (
							<Switch>
								<Route exact path={`${match.url}/settings/new`} component={SettingsFormPage} />
								<Route exact path={`${match.url}/settings/:id`} component={SettingsFormPage} />
								<Route exact path={`${match.url}/settings`} component={SettingsPage} />
								<Route exact path={`${match.url}/creators/new`} component={CreatorFormPage} />
								<Route exact path={`${match.url}/creators/:id`} component={CreatorFormPage} />
								<Route exact path={`${match.url}/creators`} component={CreatorsPage} />
								<Route exact path={`${match.url}/pages/new`} component={DocumentFormPage} />
								<Route exact path={`${match.url}/pages/:id`} component={DocumentFormPage} />
								<Route exact path={`${match.url}/pages`} component={DocumentsPage} />
								<Route exact path={`${match.url}/media/new`} component={MediaFormPage} />
								<Route exact path={`${match.url}/media/:id`} component={MediaFormPage} />
								<Route exact path={`${match.url}/media`} component={MediaPage} />
								<Route exact path={`${match.url}/profile`}>
									<ProfilePage admin />
								</Route>
								<Route>
									<Redirect to={`${match.url}/creators`} />
								</Route>
							</Switch>
						)}
						{user.role === 'creator' && (
							<Switch>
								<Route exact path={`${match.url}/settings/:id`} component={SettingsFormPage} />
								<Route exact path={`${match.url}/settings`} component={SettingsPage} />
								<Route exact path={`${match.url}/pages/new`} component={DocumentFormPage} />
								<Route exact path={`${match.url}/pages/:id`} component={DocumentFormPage} />
								<Route exact path={`${match.url}/pages`} component={DocumentsPage} />
								<Route exact path={`${match.url}/media/new`} component={MediaFormPage} />
								<Route exact path={`${match.url}/media/:id`} component={MediaFormPage} />
								<Route exact path={`${match.url}/media`} component={MediaPage} />
								<Route exact path={`${match.url}/profile`}>
									<ProfilePage creator />
								</Route>
								<Route>
									<Redirect to={`${match.url}/pages`} />
								</Route>
							</Switch>
						)} */}
        </Content>
      </Layout>
    </Layout>
  )
}