import React, { memo, useCallback } from 'react'
import { notification } from 'antd'
import CopyToCliboard from 'react-copy-to-clipboard'
import { CopyOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'

export const CopyButton = ({ url, children }) => {
  const intl = useIntl()
  const onCopy = useCallback(() => {
    notification.open({
      duration: 1,
      message: intl.formatMessage({ id: 'copyButton.notification' })
    })
  }, [])
  return (
    <CopyToCliboard
      text={url}
      onCopy={onCopy}>
      {children ? children : <CopyOutlined />}

    </CopyToCliboard>
  )
}

export default memo(CopyButton)