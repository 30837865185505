import React, { memo } from 'react'
import { Select } from 'antd'
import states from 'dicts/states.js'

export default memo(({ value, country, onChange }) => {
	return (
		<Select onChange={onChange} value={value}>
			{states[country].map(item => {
				return (
					<Select.Option value={item.abbreviation} key={item.abbreviation}>
						{item.name}
					</Select.Option>
				)
			})}
		</Select>
	)
})