import React, { memo, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './ProfilePopover.module.scss'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import cls from 'classnames'
import { logOut } from 'session/reducer'
import { Button } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import { Link } from 'react-router-dom'
import { UserRole, UserName } from './UserItems'

const ProfilePopover = ({ className }) => {
  const { user } = useSelector(s => s.session)
  const intl = useIntl()
  const dispatch = useDispatch()
  const onLogOut = useCallback(() => {
    dispatch(logOut())
  }, [])

  return (
    <div className={cls(style.component, className)}>
      <header className={style.header}>
        <Avatar
          src={_.get(user, 'avatar.url')}
          className={style.avatar} />
        <UserName className={style.userName} />
        <UserRole />
        <span>{user.email}</span>
      </header>
      <main className={style.main}>
        <Link to="/app/profile" className={style.button}>
          <FormattedMessage id='profile.manageAccount' />
        </Link>
      </main>
      <footer className={style.footer}>
        <Button onClick={onLogOut} className={style.button} type='text'>
          <FormattedMessage id='general.logOut' />
        </Button>
      </footer>
    </div>
  )
}

export default memo(ProfilePopover)