import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './MemosFormPage.module.scss'
import { Modal, Form, Input, Card, Button, Alert, Space, Upload, Radio } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { saveForm, deleteOne, loadOne, reset } from './reducer'
import { reset as resetQuestions, loadQuestions } from './form/questions/reducer'
import _ from 'lodash'
import { Prompt, useHistory, useRouteMatch } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { PageTitle } from 'components/PageItems'
import ProjectSelect from 'components/projectSelect/ProjectSelect'
import LanguageSelect from 'components/languageSelect/LanguageSelect'
import FormFooter from './form/Footer'
import Questions from './form/questions/Questions'
import UrlField from './form/url/UrlField'
import Toolbar from './toolbar/Toolbar'
import { updateQuestion } from './form/questions/reducer'
import { initField } from './form/url/reducer'
import Icon from 'components/Icon'
import LoadingMemoAnimation from 'components/animations/LoadingMemoAnimation'
import RichTextEditor from "./share/RichTextEditor/RichTextEditor"
import firebase from "firebase"
import {v4 as uuidv4} from "uuid"
import Attachment from "./share/Attachment/Attachment"

const DESCRIPTION_MAX_LENGTH = 160

var forms = []

export default function MemosFormPage({ role }) {
  const history = useHistory()
  const [prompt, setPrompt] = useState(true)
  const [antdForm] = Form.useForm()
  const [deleteModal, setDeleteModal] = useState(false)
  const intl = useIntl()
  const dispatch = useDispatch()
  const { params } = useRouteMatch()
  const { form, loading, deleting, saving } = useSelector(state => state.memos)
  const { user } = useSelector(s => s.session)
  const isEditing = !!params.id
  const { deleted } = form
  const isMine = !form.userId || form.userId === user.uid
  const loadingMask = loading || (isEditing && !form.id)

  const [uploadProgress, setUploadProgress] = useState({})
  const [fileList, setFileList] = useState((form?.attachments && JSON.parse(form.attachments)) || [])

  useEffect(() => {if (form.attachments) setFileList(JSON.parse(form.attachments))}, [form])

  const uploadFile = async (file) => {
    if (file.size > 10485760) {
      return alert(intl.formatMessage({ id: 'admin.memosFormPage.attachmentSizeError' }))
    }
    const id = uuidv4()
    const updatedFileList = [
      ...fileList,
      {
        id: id,
        type: file.type?.includes('pdf') ? 'pdf' : file.type?.split('/')?.[0],
        name: file.name,
        size: file.size
      }
    ]
    setFileList(updatedFileList)
    const result = firebase.storage().ref(`users/${user.uid}`).child(id).put(file)
    result.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        setUploadProgress({...uploadProgress, [id]: progress})
      },
      (error) => {console.log(error)},
      async () => {
        updatedFileList[
          updatedFileList.findIndex(file => file.id === id)
          ].url = await result.snapshot.ref.getDownloadURL()
        setUploadProgress({...uploadProgress, [id]: null})
        setFileList(updatedFileList)
      }
    )
  }

  const onSubmit = useCallback(async () => {
    const questions = []
    let isValid = true

    try {
      const values = await antdForm.validateFields()
      // validate each question
      for (let i = 0, l = forms.length; i < l; i++) {
        try {
          const result = await forms[i].validateFields()
          questions.push(result)
        } catch (ex) {
          isValid = false
          console.warn('validation failed')
        }
      }
      if (fileList) { values.attachments = JSON.stringify(fileList) }

      if (!isValid)
        return // let user fix the validation

      // update the questions in redux store
      questions.forEach(q => dispatch(updateQuestion(q)))
      // save the form
      await dispatch(saveForm(values))
      setPrompt(false)
      dispatch(reset())
      setTimeout(() => {
        history.push(`/app/projects/${params.pid}/memos`)
      }, 1)
    } catch (ex) {
      console.warn(ex)
    }
  }, [fileList, params])
  const onDeleteClick = useCallback(() => setDeleteModal(true), [])
  const onConfirmCancel = useCallback(() => setDeleteModal(false), [])
  const onConfirmDelete = useCallback(async () => {
    try {
      await dispatch(deleteOne(form))
      setDeleteModal(false)
      dispatch(loadOne(form.id, params.pid))
    } catch (ex) {
      console.error(ex)
    }
  }, [form, params])

  useEffect(() => {
    forms = []
    dispatch(initField(form.url))
    antdForm.resetFields()
    antdForm.setFieldsValue({
      ...form,
      userId: user.uid,
      teamId: user.teamId,
      projectId: params.pid
    })
    return () => {
      forms = []
    }
  }, [form])

  useEffect(() => {
    if (params.id) {
      dispatch(loadOne(params.id, params.pid))
      dispatch(loadQuestions(params.id, params.pid, user.teamId))
    }
    return () => {
      dispatch(reset())
      dispatch(resetQuestions())
    }
  }, [params.id, params.pid])

  const renderProject = React.useCallback(() => {
    return (
      <>
        {isEditing && (
          <div className={style.projectContainer}>
            <div className={style.project}>
              {params.pid}-{params.id}
            </div>
          </div>
        )}
      </>
    )
  }, [params.id, params.pid, isEditing])

  const renderFormContent = () => {
    if (loadingMask) {
      return (
        <div className={style.loading}>
          <LoadingMemoAnimation />
        </div>
      )
    }
    else {
      return (
        <>
          <Card className={style.component} loading={loadingMask} bodyStyle={{ padding: 0 }}>
            {deleted && (
              <Alert
                className={style.deletedWarning}
                type='warning'
                message={intl.formatMessage({ id: 'admin.memosFormPage.deletedTitle' })}
                description={intl.formatMessage({ id: 'admin.memosFormPage.deletedDesc' })} />
            )}
            <div className={style.titleContainer}>
              <PageTitle variant="h2" className={style.title}>
                {intl.formatMessage({
                  id: `admin.memosFormPage.${isEditing ? 'update' : 'create'}`
                })}
              </PageTitle>
              {renderProject()}
            </div>
            <Form
              autoComplete="off"
              form={antdForm}
              validateMessages={{
                required: intl.formatMessage({ id: 'validation.required' })
              }}>
              <Form.Item
                labelCol={{ xs: 24 }}
                className={style.formItem}
                rules={[{ required: true }]}
                name='url'>
                <UrlField
                  readOnly={!isMine}
                  placeholder={intl.formatMessage({ id: 'admin.memosFormPage.placeholders.url' })}
                  inputClassName={style.input}
                  memoCode={`${params.pid}-${params.id}`}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ xs: 24 }}
                label={intl.formatMessage({ id: 'admin.memosFormPage.fields.name' })}
                className={`${style.formItem}`}
                rules={[{ required: true }]}
                name='name'>
                <Input
                  readOnly={!isMine}
                  placeholder={intl.formatMessage({ id: 'admin.memosFormPage.placeholders.name' })}
                  className={style.input} />
              </Form.Item>
              <Space>
                <Form.Item
                  labelCol={{ xs: 24 }}
                  label={intl.formatMessage({ id: 'admin.memosFormPage.fields.project' })}
                  className={style.formItem}
                  rules={[{ required: true }]}
                  name='projectId'>
                  <ProjectSelect readOnly={!isMine || (form && form.id)} className={style.projectSelect} />
                </Form.Item>
                <Form.Item
                  labelCol={{ xs: 24 }}
                  label={intl.formatMessage({ id: 'admin.memosFormPage.fields.language' })}
                  className={style.formItem}
                  rules={[{ required: true }]}
                  name='language'>
                  <LanguageSelect readOnly={!isMine} className={style.projectSelect} />
                </Form.Item>
                <Form.Item
                  labelCol={{ xs: 24 }}
                  label={intl.formatMessage({ id: 'admin.memosFormPage.fields.isPublic' })}
                  className={style.formItem}
                  rules={[{ required: true }]}
                  name='isPublicMemo'>
                  <Radio.Group disabled={!isMine} buttonStyle="solid" defaultValue={false}>
                    <Radio value={true}>{intl.formatMessage({ id: 'admin.memosFormPage.fields.isPublicTrue' })}</Radio>
                    <Radio value={false}>{intl.formatMessage({ id: 'admin.memosFormPage.fields.isPublicFalse' })}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Space>
              <Form.Item
                labelCol={{ xs: 24, span: 12 }}
                label={
                  <div className={style.formLabel}>
                    <span>{intl.formatMessage({ id: 'admin.memosFormPage.fields.desc' })}</span>
                    <span className={style.formLabelLimit}>
                      <FormattedMessage
                        id={`admin.memosFormPage.charactersLimit`}
                        values={{number: DESCRIPTION_MAX_LENGTH}}
                      />
                    </span>
                  </div>
                }
                className={style.formItem}
                rules={[{ required: true, max: DESCRIPTION_MAX_LENGTH }]}
                name='description'>
                <Input.TextArea
                  readOnly={!isMine}
                  maxLength={DESCRIPTION_MAX_LENGTH}
                  placeholder={intl.formatMessage({ id: 'admin.memosFormPage.placeholders.desc' })}
                  className={style.input} />
              </Form.Item>
              <Form.Item
                labelCol={{ xs: 24 }}
                label={intl.formatMessage({ id: 'admin.memosFormPage.fields.content' })}
                className={style.formItem}
                name='content'
                initialValue=''>
                <RichTextEditor
                  savedState={antdForm.getFieldValue("content")}
                  onSave={(value) => antdForm.setFieldsValue({content: value})}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ xs: 24 }}
                label={intl.formatMessage({ id: 'admin.memosFormPage.fields.attachments' })}
                className={style.formItem}
                name='attachments'
                initialValue={'[]'}
              >
                <Upload
                  className={style.fileUpload}
                  showUploadList={false}
                  beforeUpload={uploadFile}
                >
                  {fileList.length ?
                    <div className={style.attachmentsList}>
                      <button className={style.addFileButton}>
                        <img src={'/memo_form/add.svg'} alt="Add"/>
                      </button>
                      {fileList.map((file, index) => (
                        <Attachment
                          file={file}
                          deleteFile={() => {
                            const currentFileList = [...fileList]
                            currentFileList.splice(currentFileList.findIndex(f => f.id === file.id), 1)
                            setFileList(currentFileList)
                          }}
                          uploadProgress={uploadProgress[file.id]}
                          key={index}
                        />
                      ))}
                    </div> :
                    <div className={style.uploadFileContainer}>
                      <img src={'/memo_form/uploadIcon.svg'} alt='upload'/>
                      <h3>
                        {intl.formatMessage({ id: 'admin.memosFormPage.placeholders.attachments.title' })}
                      </h3>
                      <p>{intl.formatMessage({ id: 'admin.memosFormPage.placeholders.attachments.text' })}</p>
                      <p className={style.fileLimitLabel}>
                        {intl.formatMessage({ id: 'admin.memosFormPage.placeholders.attachments.limitLabel' })}
                      </p>
                    </div>
                  }
                </Upload>
              </Form.Item>
              <Form.Item hidden name='teamId' />
              <Form.Item hidden name='userId' />
            </Form>
          </Card>

          <React.Fragment>
            <Questions
              forms={forms}
              readOnly={!isMine}
              canAddQuestions={!deleted && isMine}
              className={style.centered} />
            {!deleted && (
              <FormFooter
                onSubmit={onSubmit}
                onDelete={onDeleteClick}
                className={style.centered}
                hideDelete
              />
            )}
            <Toolbar
              className={style.toolbar}
              onDelete={onDeleteClick} />
          </React.Fragment>
        </>
      )
    }
  }

  return (
    <div className={style.container}>
      <Prompt
        when={prompt && !deleted}
        message={() => intl.formatMessage({ id: 'admin.memosFormPage.beforeLeavePromt' })}
      />
      {renderFormContent()}
      <Modal
        maskClosable
        maskStyle={{
          backgroundColor: '#F3F5F7'
        }}
        closable={false}
        visible={deleteModal}
        className={style.deleteModal}
        footer={null}>
        <Icon name='close' onClick={onConfirmCancel} className={style.closeButton} />
        <h2>
          <FormattedMessage id='admin.memosFormPage.delete.title' />
        </h2>
        <p>
          <FormattedMessage
            id='admin.memosFormPage.delete.body'
            values={{
              id: 'MKT-43'
            }} />
        </p>
        <footer>
          <Button onClick={onConfirmDelete} className={style.deleteButton} loading={deleting}>
            <FormattedMessage
              id='admin.memosFormPage.buttons.delete' />
          </Button>
          <Button className={style.cancelButton} onClick={onConfirmCancel}>
            <FormattedMessage
              id='general.cancel' />
          </Button>
        </footer>
      </Modal>
    </div>
  )
}
