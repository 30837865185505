import React, { useCallback, useEffect, useState } from 'react'
import { Button, Form, Input } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import style from './Question.module.scss'
import cls from 'classnames'
import { removeQuestion } from './reducer'
import { PageTitle } from 'components/PageItems'
import closeIcon from './closeIcon.png'
import Options from './Options'

export const Question = ({ data, className, autoFocus, onDelete, onChange, forms, readOnly }) => {
  const [antdForm] = Form.useForm()
  const intl = useIntl()
  const [deleting, setDeleting] = useState(false)
  const dispatch = useDispatch()
  const onRemove = useCallback(async () => {
    setDeleting(true)
    dispatch(removeQuestion(data))
    setDeleting(false)
    // clean up the form
    forms.splice(forms.indexOf(antdForm), 1)
  }, [])
  const onFieldsChange = useCallback(() => {
    // onChange(antdForm.getFieldsValue())
  }, [])
  useEffect(() => {
    antdForm.setFieldsValue(data)
    forms.push(antdForm)
  }, [data])
  return (
    <div className={cls(style.component, className, {
      [style.readOnly]: readOnly
    })}>
      <header className={style.header}>
        <PageTitle variant="h2">
          <FormattedMessage id='admin.memosFormPage.form.questions.question' />
        </PageTitle>
        {!readOnly && (
          <Button
            type='text'
            loading={deleting}
            className={style.close}
            onClick={onRemove}>
            <img src={closeIcon} />
          </Button>
        )}

      </header>
      <main>
        <Form
          autoComplete="off"
          onFieldsChange={onFieldsChange}
          form={antdForm}
          validateMessages={{
            required: intl.formatMessage({ id: 'validation.required' })
          }}>
          <Form.Item hidden name='id' />
          <Form.Item
            labelCol={{ xs: 24 }}
            label={intl.formatMessage({ id: 'admin.memosFormPage.form.questions.fields.name' })}
            className={style.formItem}
            rules={[{ required: true }]}
            name='name'>
            <Input
              autoFocus={autoFocus}
              placeholder={intl.formatMessage({ id: 'admin.memosFormPage.form.questions.placeholders.name' })}
              className={style.input} />
          </Form.Item>
          <Form.Item
            labelCol={{ xs: 24 }}
            label={
              <span className={style.optionLabel}><FormattedMessage id="admin.memosFormPage.form.questions.fields.options" /></span>}
            className={style.formItem}
            rules={[{ required: true }]}
            name='config'>
            <Options />
          </Form.Item>
        </Form>
      </main>
    </div>
  )
}

export default Question