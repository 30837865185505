import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './SettingsFormPage.module.scss'
import { Upload, message, Form, Input, Button, Select, Spin } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import { loadOne, setForm, saveForm, reset } from './reducer'
import _ from 'lodash'
import { Switch, Route, Redirect, useRouteMatch, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { API_TOKEN_ADMIN, API_URL } from 'const'

function LogoField({ value, onChange }) {
	const uploadProps = {
		name: 'file',
		action: `${API_URL}/apps/training-page/assets`,
		headers: {
			authorization: `Bearer ${API_TOKEN_ADMIN}`,
		},
		onChange(info) {
			if (info.file.status !== 'uploading') {
			}
			if (info.file.status === 'done') {
				onChange(info.file.response.id)
				message.success(`${info.file.name} file uploaded successfully`);
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
	}

	return (
		<Upload {...uploadProps}>
			<div>{value}</div>
			<Button>
				<UploadOutlined /> Click to Upload
      </Button>
		</Upload>
	)
}

export default function SettingsFormPage() {
	let formRef = React.createRef()
	let intl = useIntl()
	let dispatch = useDispatch()
	let history = useHistory()
	let { params } = useRouteMatch()
	let { form, loading, saving } = useSelector(state => state.adminSettings)



	function fieldsToArray(form) {
		return _.keys(form).map(k => {
			return {
				name: k,
				value: form[k]
			}
		})

	}

	function onFieldsChange(fields) {
		fields.forEach(field => {
			dispatch(setForm({
				[field.name[0]]: field.value
			}))
		})
	}

	async function onSubmit() {
		let form = formRef.current

		try {
			let values = await form.validateFields()

			await dispatch(saveForm(values))
			await dispatch(reset())
			history.push('/admin/settings')
		} catch (ex) {
			console.warn(ex)
		}
	}

	useEffect(() => {
		if (params.id)
			dispatch(loadOne(params.id))
		return () => dispatch(reset())
	}, [])

	return (
		<Spin spinning={loading || saving}>
			<div className={style.component}>
				<div className={style.content}>
					<header>
						<h1>
							{intl.formatMessage({
								id: `admin.settings.formTitle.${form.id ? 'update' : 'create'}`
							})}
						</h1>
					</header>
					<Form
						validateMessages={{
							required: intl.formatMessage({ id: 'validation.required' }),
							types: {
								email: intl.formatMessage({ id: 'validation.invalidEmail' })
							}
						}}
						id='form_id'
						ref={formRef}
						fields={fieldsToArray(form)}
						onFieldsChange={onFieldsChange}>
						<Form.Item
							labelCol={{ xs: 24 }}
							label='Client name'
							className={style.formItem}
							rules={[{ required: true }]}
							name='clientName'>
							<Input className={style.input} />
						</Form.Item>
						<Form.Item
							labelCol={{ xs: 24 }}
							label='Client logo'
							className={style.formItem}
							rules={[{ required: true }]}
							name='clientLogo'>
							<LogoField />
						</Form.Item>
						<Button onClick={onSubmit}>
							{intl.formatMessage({
								id: `general.${form.id ? 'update' : 'create'}`
							})}
						</Button>
					</Form>
				</div>
			</div>
		</Spin>
	)
}