import React, { useCallback, useState } from 'react'
import Modal from 'antd/lib/modal/Modal'
import { Button, Card, Form, Input, notification } from 'antd'
import firebase from 'firebase/app'
import 'firebase/auth'
import { FormattedMessage, useIntl } from 'react-intl'
import { PageTitle } from 'components/PageItems'
import { useDispatch, useSelector } from 'react-redux'
import { setError, reset } from './reducer'
import Icon from 'components/Icon'
import AntIcon from '@ant-design/icons/lib/components/Icon'
import PassedCircleIcon from 'components/icons/PassedCircleIcon'
import CircleIcon from 'components/icons/CircleIcon'
import SuccessIcon from 'components/icons/SuccessIcon'
import {
  AT_LEAST_8_CHARACTER, AT_LEAST_U_AND_L_CHARACTER,
  AT_LEAST_ONE_NUMBER, AT_LEAST_ONE_SPECIAL
} from 'constants/regexList'

import style from './ChangePasswordModal.module.scss'

const ChangePasswordModal = ({ open, onClose }) => {
  const [form] = Form.useForm()
  const intl = useIntl()
  const dispatch = useDispatch()

  const { error, saving } = useSelector(s => s.profile)

  const [wrongPassword, setWrongPassword] = useState(false)
  const [oldPasswordSpy, setOldPasswordSpy] = useState(false)
  const [newPasswordSpy, setNewPasswordSpy] = useState(false)
  const [confirmPasswordSpy, setConfirmPasswordSpy] = useState(false)

  const [changePasswordClicked, setChangePasswordClicked] = useState(false)

  const [rules, setRules] = useState({
    length: false,
    number: false,
    case: false,
    special: false
  })

  const onFieldsChange = useCallback((fields) => {
    error && dispatch(setError())
  }, [error])

  const reauthenticate = currentPassword => {
    const user = firebase.auth().currentUser;
    const cred = firebase.auth.EmailAuthProvider.credential(
      user.email, currentPassword);
    return user.reauthenticateWithCredential(cred);
  }

  const renderSuccessMessage = (message) => {
    return (
      <div className={style.successMessageContainer}>
        <div className={style.successIcon}>
          <SuccessIcon width="24px" height="24px" />
        </div>
        <span>{message}</span>
      </div>
    )
  }

  const onSubmit = useCallback(async () => {
    setChangePasswordClicked(true)
    try {
      let values = await form.validateFields()
      if (rules.length && rules.number && rules.case && rules.special) {
        const user = firebase.auth().currentUser
        try {
          // reauthenticating
          await reauthenticate(values.oldPassword)
          // updating password
          await user.updatePassword(values.newPassword)
          dispatch(reset())
          notification.open({
            style: {
              borderRadius: '4px',
              boxShadow: '0px 10px 15px - 3px rgba(26, 32, 44, 0.1), 0px 4px 6px - 2px rgba(26, 32, 44, 0.05)',
            },
            message: renderSuccessMessage(intl.formatMessage({ id: 'resetPassword.success' })),
            className: style.successMessage,
            onClick: () => {
            },
          });
          onClose()
        } catch (err) {
          if (err && (err.code == 'auth/wrong-password')) {
            setWrongPassword(true)
          }
        }
      }

    } catch (ex) {
      console.warn(ex)
    }
  }, [rules])

  const handleFormChange = (_, allValues) => {
    setWrongPassword(false)
    const lengthPassed = allValues.newPassword && AT_LEAST_8_CHARACTER.test(allValues.newPassword)
    const numberPassed = AT_LEAST_ONE_NUMBER.test(allValues.newPassword)
    const casePassed = AT_LEAST_U_AND_L_CHARACTER.test(allValues.newPassword)
    const specialPassed = AT_LEAST_ONE_SPECIAL.test(allValues.newPassword)
    setRules({
      length: lengthPassed,
      number: numberPassed,
      case: casePassed,
      special: specialPassed
    })
  }

  const validatePasswords = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue('newPassword') === value)
        return Promise.resolve()
      return Promise.reject(
        intl.formatMessage({ id: 'validation.passwordsDoNotMatch' })
      )
    }
  })

  const renderRuleStatusIcon = (passed) => {
    return (
      <>
        {passed ? <AntIcon component={PassedCircleIcon} className={style.ruleIcon} style={{ fontSize: '28px' }} />
          : <AntIcon component={CircleIcon} className={`${style.ruleIcon}`} style={{ fontSize: '28px' }} />}
      </>
    )
  }

  return (
    <Modal
      maskClosable
      className={style.container}
      centered
      closable={false}
      visible={open}
      width={440}
      footer={null}
      onCancel={onClose}
    >
      <Card bodyStyle={{ padding: 0 }} bordered={false} className={style.contentCard}>
        <header className={style.header}>
          <PageTitle variant="h1">
            <FormattedMessage
              id='resetPassword.submit'
            />
          </PageTitle>
          <p className={style.secondTitle}>
            <FormattedMessage
              id='resetPassword.desc'
            />
          </p>
        </header>
        <main>
          <Form
            validateMessages={{
              required: intl.formatMessage({ id: 'validation.required' }),
            }}
            form={form}
            className={style.formContainer}
            onFieldsChange={onFieldsChange}
            onFinish={onSubmit}
            onValuesChange={handleFormChange}
          >
            <Form.Item
              labelCol={{ xs: 24 }}
              label={intl.formatMessage({ id: 'resetPassword.fields.currentPassword' })}
              className={style.formItem}
              rules={[{ required: true }]}
              name='oldPassword'
              {...wrongPassword && {
                validateStatus: 'error'
              }}

            >
              <div>
                <Input
									className={style.input}
									autoComplete="new-password"
                  type={oldPasswordSpy ? 'text' : 'password'} />
                {wrongPassword && (
                  <div className={style.wrongPassword}>
                    <span>
                      <FormattedMessage id='resetPassword.wrongPassword' />
                    </span>
                  </div>
                )}

                {oldPasswordSpy ? (
                  <div className={style.spyIcon} onClick={() => setOldPasswordSpy(false)}><Icon name='eye' /></div>
                ) : (
                  <div className={style.spyIcon} onClick={() => setOldPasswordSpy(true)}><Icon name='eye' /></div>
                )}
              </div>
            </Form.Item>
            <Form.Item
              labelCol={{ xs: 24 }}
              label={intl.formatMessage({ id: 'resetPassword.fields.newPassword' })}
              className={style.formItem}
              rules={[{ required: true }]}
              name='newPassword'>
              <div>
                <Input
                  className={style.input}
                  type={newPasswordSpy ? 'text' : 'password'} />
                {newPasswordSpy ? (
                  <div className={style.spyIcon} onClick={() => setNewPasswordSpy(false)}><Icon name='eye' /></div>
                ) : (
                  <div className={style.spyIcon} onClick={() => setNewPasswordSpy(true)}><Icon name='eye' /></div>
                )}
              </div>
            </Form.Item>
            <Form.Item
              labelCol={{ xs: 24 }}
              label={intl.formatMessage({ id: 'resetPassword.fields.confirmPassword' })}
              className={style.formItem}
              rules={[{ required: true }, validatePasswords]}
              name='confirmPassword'>
              <div>
                <Input
                  className={style.input}
                  type={confirmPasswordSpy ? 'text' : 'password'} />
                {confirmPasswordSpy ? (
                  <div className={style.spyIcon} onClick={() => setConfirmPasswordSpy(false)}><Icon name='eye' /></div>
                ) : (
                  <div className={style.spyIcon} onClick={() => setConfirmPasswordSpy(true)}><Icon name='eye' /></div>
                )}
              </div>
            </Form.Item>

          </Form>
        </main>
        <footer className={style.footer}>
          <div className={style.ruleList}>
            <div className={`${style.rule} ${!rules.length && changePasswordClicked ? style.error : ''}`}>
              {renderRuleStatusIcon(rules.length)}
              <span><FormattedMessage id="resetPassword.rules.lengthRule" /></span>
            </div>
            <div className={`${style.rule} ${!rules.case && changePasswordClicked ? style.error : ''}`}>
              {renderRuleStatusIcon(rules.case)}
              <span><FormattedMessage id="resetPassword.rules.caseRule" /></span>
            </div>

            <div className={`${style.rule} ${!rules.number && changePasswordClicked ? style.error : ''}`}>
              {renderRuleStatusIcon(rules.number)}
              <span><FormattedMessage id="resetPassword.rules.numberRule" /></span>
            </div>

            <div className={`${style.rule} ${!rules.special && changePasswordClicked ? style.error : ''}`}>
              {renderRuleStatusIcon(rules.special)}
              <span><FormattedMessage id="resetPassword.rules.specialRule" /></span>
            </div>
          </div >
          <div className={style.actionContainer}>
            <Button onClick={onClose} className={style.backButton}>
              <FormattedMessage id='forgotPassword.back' />
            </Button>
            <Button
              className={style.submitButton}
              onClick={onSubmit}
              loading={saving}
              type='primary'
              htmlType='submit'>
              {intl.formatMessage({
                id: 'resetPassword.submit'
              })}
            </Button>
          </div>
        </footer >
      </Card >
    </Modal >
  )
}

export default ChangePasswordModal