import React  from 'react'
import cls from 'classnames'
import {
  ItalicOutlined, BoldOutlined, UnderlineOutlined, UnorderedListOutlined, OrderedListOutlined, LinkOutlined
} from '@ant-design/icons'
import classes from "./RichEditor.module.scss"
import {useIntl} from "react-intl"

export const StyleButton = ({ style, active, label, onToggle, className }) => {
  const onToggleCustom = (e) => {
    e.preventDefault();
    onToggle(style);
  };

  const intl = useIntl()

  let toolTipText = label || ''
  if (label === 'UL') toolTipText = intl.formatMessage({id: 'admin.memosFormPage.richText.ul'})
  if (label === 'OL') toolTipText = intl.formatMessage({id: 'admin.memosFormPage.richText.ol'})
  if (label === 'Italic') toolTipText = intl.formatMessage({id: 'admin.memosFormPage.richText.italic'})
  if (label === 'Bold') toolTipText = intl.formatMessage({id: 'admin.memosFormPage.richText.bold'})
  if (label === 'Underline') toolTipText = intl.formatMessage({id: 'admin.memosFormPage.richText.underline'})
  if (label === 'Link') toolTipText = intl.formatMessage({id: 'admin.memosFormPage.richText.link'})

  return (
    <span className={cls(className, active ? classes.activeButton : "")} onMouseDown={onToggleCustom}>
      <div title={toolTipText}>
        <button>{getIconIfValid(label)}</button>
      </div>
    </span>
  );
};

const getIconIfValid = (label) => {
  switch (label) {
    case 'Bold': return <BoldOutlined />
    case 'Italic': return <ItalicOutlined />
    case 'Underline': return <UnderlineOutlined />
    case 'Link': return <LinkOutlined />
    case 'OL': return <OrderedListOutlined />
    case 'UL': return <UnorderedListOutlined />
    default: return label
  }
};
