import React, { memo, useCallback, useEffect, useState } from 'react'
import style from './CreateButton.module.scss'
import { Link, useLocation, useParams } from 'react-router-dom'
import cls from 'classnames'

export const CreateButton = ({ className, children }) => {
  const location = useLocation()
  const [url, setUrl] = useState('')
  useEffect(() => {
    // @TODO since create button doesn't have project id we have to get it manually
    let projectId = location.pathname.replace('/app/projects/', '').split('/')[0]
    if (projectId === 'all')
      projectId = 'GEN'
    setUrl(`/app/projects/${projectId}/memos/new`)
  }, [location.pathname])

  return (
    <Link
      to={url}
      className={cls(style.component, className)}>
      {children}
    </Link>
  )
}

export default memo(CreateButton)