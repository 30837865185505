import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/firestore'
import history from '../../history'

const {
  REACT_APP_API
} = process.env

const SAVING_START = 'ASSISTANCE/SAVING_START'
const SAVING_END = 'ASSISTANCE/SAVING_END'
const RESET = 'PROFASSISTANCEILE/RESET'
const SET_ERROR = 'ASSISTANCE/SET_ERROR'
const SAVE_MEMO_URL = 'ASSISTANCE/SAVE_MEMO_URL'
const CLEAR_MEMO_URL = 'ASSISTANCE/CLEAR_MEMO_URL'

export const submit = (values, nextUrl) => async (dispatch, getState) => {
  dispatch({ type: SAVING_START })
  try {
    const {
      firstName, lastName, email, subject, attachmentUrl, deviceInformation
    } = values
    const url = `${REACT_APP_API}/assistance`
    const token = await firebase.auth().currentUser.getIdToken(true)
    const result = await axios.post(url, {
      email,
      subject,
      firstName,
      lastName,
      attachmentUrl,
      deviceInformation
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    if (result.data.success) {
      history.push(nextUrl)
    }
  } catch (ex) {
    console.warn(ex)
  }
  dispatch({ type: SAVING_END })
}

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET
  })
}

export const setError = (error) => {
  return {
    type: SET_ERROR,
    error
  }
}

export const saveMemoUrl = (params) => (dispatch) => {
  dispatch({
    type: SAVE_MEMO_URL,
    params
  })
}

export const clearMemoUrl = () => (dispatch) => {
  dispatch({
    type: CLEAR_MEMO_URL,
  })
}

const initialState = {
  saving: false,
  error: false,
  data: {},
  memoURL: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SAVING_START:
      return {
        ...state,
        saving: true
      }
    case SAVING_END:
      return {
        ...state,
        saving: false
      }
    case SET_ERROR:
      return {
        ...state,
        saving: false,
        loading: false,
        error: action.error
      }
    case RESET:
      return {
        ...initialState,
        memoURL: state.memoURL
      }
    case SAVE_MEMO_URL:
      return {
        ...state,
        memoURL: action.params
      }
    case CLEAR_MEMO_URL:
      return {
        ...state,
        memoURL: null
      }
    default:
      return state
  }
}
