import React from "react";

const ShareByTagIcon = (props) => (
  <svg
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5342 8.38525C10.509 8.38525 9.67593 8.76972 8.97106 9.47459C8.26619 10.1795 7.88172 11.0766 7.88172 12.0378C7.88172 13.063 8.26619 13.896 8.97106 14.6009C9.67593 15.3058 10.573 15.6903 11.5342 15.6903C12.5595 15.6903 13.3925 15.3058 14.0974 14.6009C14.8023 13.896 15.1867 12.9989 15.1867 12.0378C15.1867 11.0125 14.8023 10.1795 14.0974 9.47459C13.3925 8.70564 12.5595 8.38525 11.5342 8.38525ZM12.4313 12.9349C12.175 13.1912 11.9187 13.3193 11.5342 13.3193C11.1497 13.3193 10.8934 13.1912 10.6371 12.9349C10.3808 12.6785 10.2526 12.4222 10.2526 12.0378C10.2526 11.6533 10.3808 11.397 10.6371 11.1406C10.8934 10.8843 11.1497 10.7562 11.5342 10.7562C11.9187 10.7562 12.175 10.8843 12.4313 11.1406C12.6876 11.397 12.8158 11.6533 12.8158 12.0378C12.8158 12.4222 12.6876 12.6785 12.4313 12.9349ZM46.3932 24.2128L25.8879 3.77156C24.9267 2.81038 23.7092 2.04143 22.1713 1.40064C20.6334 0.759848 19.2237 0.439453 17.878 0.439453H6.08751C4.48553 0.439453 3.01171 1.08024 1.79421 2.23367C0.576711 3.38709 0 4.92498 0 6.52696V18.3816C0 19.7272 0.320395 21.137 0.961185 22.6749C1.60198 24.2128 2.37092 25.4303 3.33211 26.3274L23.7092 46.8327C24.8627 47.9861 26.3365 48.5628 28.0025 48.5628C29.6045 48.5628 31.0783 47.9861 32.2317 46.8327L46.265 32.7994C47.4185 31.6459 47.9952 30.1721 47.9952 28.5061C48.0593 26.9041 47.4825 25.4303 46.3932 24.2128ZM44.599 31.0692L30.6298 45.1025C29.8608 45.8074 29.0278 46.1278 28.0025 46.1278C26.9773 46.1278 26.1442 45.7433 25.4394 45.1025L4.99816 24.6613C4.29329 23.9564 3.6525 22.9953 3.13987 21.7778C2.62724 20.5603 2.37092 19.4709 2.37092 18.4457V6.52696C2.37092 5.56577 2.7554 4.66867 3.46027 3.9638C4.16514 3.25893 5.06224 2.87446 6.02343 2.87446H17.9421C18.9674 2.87446 20.0567 3.13077 21.2742 3.6434C22.4917 4.15604 23.4529 4.73275 24.1578 5.5017L44.599 25.8788C45.3039 26.6478 45.6243 27.4808 45.6243 28.5061C45.6883 29.4672 45.3039 30.3644 44.599 31.0692Z"
      fill="currentColor"
    />
  </svg>
);

export default ShareByTagIcon;
