import React from "react";

const SuccessIcon = (props) => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="40" cy="40" r="40" fill="#6FCF97" />
    <g clipPath="url(#successIcon)">
      <rect width="6.25001" height="21.25" rx="3.12501" transform="matrix(-0.715802 0.698303 -0.715802 -0.698303 39.9438 50.6348)" fill="white" />
      <rect width="6.25001" height="35.0001" rx="3.12501" transform="matrix(0.715802 0.698303 -0.715802 0.698303 56.0261 26.1953)" fill="white" />
    </g>
    <defs>
      <clipPath id="successIcon">
        <rect width="40" height="40" fill="white" transform="translate(20.5 20)" />
      </clipPath>
    </defs>
  </svg>

);

export default SuccessIcon;