import React, { memo, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, NavLink, useParams, useRouteMatch } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { Spin } from 'antd'
import cls from 'classnames'
import Icon from 'components/Icon'
import ProjectNameItem from './ProjectNameItem'
import style from './ProjectsList.module.scss'
import { loadAll, reset } from './reducer'
import { loadAll as loadAllProjects } from 'components/projectSelect/reducer'
import { saveForm } from './reducer'

export const ProjectsList = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { items, loading } = useSelector(s => s.projects)

  useEffect(() => {
    dispatch(loadAll())
    return () => dispatch(reset())
  }, [])

  const handleSaveProjectName = async ({ name, acronym }) => {
    const id = await dispatch(saveForm(
      { name, acronym }
    ))
    await dispatch(loadAll())
    await dispatch(loadAllProjects())
  }

  return (
    <div className={cls(style.component, {
      [style.loading]: loading
    })}>
      {loading ? (<Spin />) : (
        <React.Fragment>
          <NavLink to={`/app/projects/all/memos`} className={style.link}>
            <Icon name='allProjects' className={style.icon} small />
            <span>
              <FormattedMessage id='admin.projects.all' />
            </span>
          </NavLink>
          {items.map((item, k) => {
            return (
              <ProjectNameItem key={`project-name-item-${k}`} project={item} onSave={handleSaveProjectName} />
            )
          })}
          <Link
            className={style.button}
            to="/app/projects/new">
            <FormattedMessage id='admin.projects.create' />
          </Link>
        </React.Fragment>
      )}
    </div>
  )
}

export default memo(ProjectsList)
