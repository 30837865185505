import React from 'react'
import style from './MemoFooter.module.scss'
import cls from 'classnames'
import { Avatar } from 'antd'
import moment from 'moment'
import 'moment/locale/fr'
import { FormattedMessage } from 'react-intl'
import Icon from '@ant-design/icons'
import DropdownUp from '../icons/DropdownUp'
import DropdownDown from '../icons/DropdownDown'
import ProjectIcon from '../icons/ProjectIcon'
import { useIntl } from 'react-intl'
import RichTextEditor from "../../pages/admin/memos/share/RichTextEditor/RichTextEditor"
import Attachment from "../../pages/admin/memos/share/Attachment/Attachment"

const urlify = (text) => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  })
}

const MemoFooter = (props) => {
  const intl = useIntl()
  const [collapsed, setCollapsed] = React.useState(false)

  const onCollapseToggle = () => {
    setCollapsed(!collapsed)
  }

  const { className, memo, projectName, createdAt, isDefaultProject } = props

  const name = memo.name ? (
    <div className={style.name}>
      {memo.name}
    </div>
  ) : null

  const date = createdAt ? moment(createdAt).locale(intl.locale || 'fr').format('MMM DD, YYYY') : ''

  const renderDuration = React.useMemo(() => {
    if (memo.videoDuration) {
      return memo.videoDuration > 1 ? `${memo.videoDuration} mins` : `${memo.videoDuration} min`
    }
    else {
      return intl.formatMessage({ id: 'general.unknown' })
    }
  }, [memo.videoDuration])

  const htmlDescription = () => {
    if (memo && memo.description) {
      const descritpionWithLink = urlify(memo.description);

      return (
        <div className={style.description} dangerouslySetInnerHTML={{ __html: descritpionWithLink }}>
        </div>
      )
    }
    return null
  };

  if (!memo)
    return null

  return (
    <div className={cls(style.memo_footer, collapsed ? style.collapsed : style.uncollapsed, className)}>
      <div onClick={onCollapseToggle} className={style.collapseButton}>
        {collapsed ? <DropdownUp /> : <DropdownDown />}
      </div>
      {name}
      {collapsed && <div className={style.collapsedDate}>{date}</div>}
      {!collapsed && (
        <>
          <div className={style.details}>
            <span><b>{isDefaultProject ? <FormattedMessage id='admin.projects.default' /> : projectName}</b> • {date}• <FormattedMessage id="individual.memoPage.estimatedTime" values={{ time: renderDuration }} /></span>
          </div>
          {htmlDescription()}
          {memo?.content && <RichTextEditor savedState={memo.content} readOnly displayMode/>}
          {memo?.attachments && memo?.attachments !== '[]' && (
            <div className={style.attachmentsBlock}>
              <div className={style.details}>
                <b>{intl.formatMessage({ id: 'admin.memosFormPage.placeholders.attachments.title' })}</b>
              </div>
              <div className={style.attachmentsList}>
                {JSON.parse(memo?.attachments).map((file, index) => <Attachment file={file} displayMode key={index}/>)}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default MemoFooter;