export default {
  languages: {
    fr: "Français",
    en: "Anglais",
  },
  general: {
    start: "Commencer",
    back: "Annuler",
    create: "Sauvegarder",
    save: "Sauvegarder",
    confirm: "Confirmer",
    logOut: "Déconnexion",
    gotIt: "J’ai compris",
    warningBeforeCloseTab:
      "Attention! Si vous fermez  cet onglet, vous aller perdre votre progrès",
    send: "Envoyer",
    delete: "Supprimer",
    cancel: "Annuler",
    edit: "Modifier",
    update: "Mettre à jour",
    validation: {
      onlyLetters: "Seules les lettres sont acceptées.",
    },
    ok: 'Ok',
    share: 'Partager',
    getStarted: 'Commencer',
    next: 'Suivant',
    unknown: 'Inconnue'
  },
  video: {
    cannotSkip: "Au premier visionnement, la vidéo ne peut pas être avancée.",
  },
  navBar: {
    allMemos: "Tous les mémos",
    myMemos: "Mes mémos",
    createMemo: "+ Créer un nouveau mémo",
    assistance: "Soutien technique",
    contacts: "Liste de contacts",
  },
  copyButton: {
    notification: "Copié",
  },
  supervisorPage: {
    title: "Training pages",
  },
  authTypePage: {
    userTitle: "Complete this training as an individual",
    supervisorTitle: "Configure this training for others to complete",
    signIn: "Sign in",
    signUp: "Sign up",
  },
  admin: {
    admins: {
      title: "Super admins",
      create: "Créer le admin",
      update: "Mettre à jour le admin",
    },
    settings: {
      title: "Paramètres",
      formTitle: {
        create: "Créer",
        update: "Mettre à jour",
      },
    },
    teams: {
      title: "Équipes",
      create: "Créer une équipe",
      update: "Modifier une équipe",
    },
    creators: {
      title: "Créateurs de contenu",
      create: "Créer un créateur",
      update: "Mettre à jour un créateur",
      fields: {
        resetPassword: "Réinitialiser votre mot de passe",
        password: "Mot de passe",
        passwordAgain: "Confirmer votre mot passe",
      },
    },
    projects: {
      default: "Général",
      all: "Tous les projets",
      create: "Ajouter un nouveau projet",
    },
    projectsFormPage: {
      create: "Ajouter un nouveau projet",
      update: "Mettre à jour un projet",
      desc: "Entrez le nom du projet et son acronyme",
      desc2: "Note: Un acronyme ne peut pas être modifié une fois créé",
      validation: {
        acronymsDoNotMatch: "Les acronymes saisis ne sont pas identiques",
        acronymsIsNotUnique: "Cet acronyme existe déjà",
        acronymsLength: "Cet acronyme doit avoir 3 caractères",
      },
      fields: {
        name: "Nom du projet",
        acronym: "Acronyme du projet (ne peut être changé plus tard)",
        confirmAcronym: "Confirmer l’acronyme du projet",
      },
    },
    media: {
      title: "Media",
      formTitle: {
        create: "Enregistrer votre écran",
        update: "Téléverser une vidéo",
      },
      update: "Mettre à jour",
      create: "Créer",
    },
    documents: {
      title: "Mémos",
      settingsTitle: "Paramètres",
      form: {
        noSharingUrl: "Aucun",
      },
      formTitle: {
        create: "Mémo",
        update: "Mémo",
      },
      update: "Mettre à jour",
      create: "Créer",
    },
    superUsers: {
      roles: {
        admin: "Admin",
        creator: "Créateur",
      },
    },
    memos: {
      title: "Memos",
      share: {
        chooseSharingOption: "Choisir une option de partage",
        shareByTag: "Partager par étiquette",
        shareByContact: "Partager par contact",
        delete_link: "Effacer lien",
        share_by_link: "Partager le lien",
        copy_link: "Copier le lien",
        shareable_link_note:
          "Notez que tout ceux qui auront le lien, pourront accéder au mémo",
        create_link: "Créer lien",
        no_link: "Aucun lien",
        submit: "Envoyer l’invitation",
        close: "Fermer",
        title: "Partager “{name} [{projectId}-{memoId}]”",
        hasInvalidEmail:
          "Il y a au moins une adresse courriel invalide dans la liste",
        addSelected: "Ajouter la sélection",
        selectedContacts: "Contact(s) sélectionné(s)",
        nContactsSelected: "contact(s) sélectionné(s)",
        more: "de plus",
        selectAll: "Tout sélectionner",
        deselectAll: "Tout désélectionner",
        checkSkippingDescription:
          "Si un ou plusieurs contacts sélectionnés ont déjà rempli le mémo, ne pas leur envoyer à nouveau.",
        fields: {
          emails:
            "Envoyer un mémo aux personnes. Entrer chaque courriel séparé par un point-virgule(;)",
        },
        placeholders: {
          emails: "",
        },
        closeConfirm: {
          title: "Fermer le menu de partage?",
          description:
            "Êtes-vous certain(e) de vouloir fermer? Vous allez perdre les contacts que vous avez entré pour l’instant.",
        },
        contacts: {
          searchPlaceHolder: "Chercher par adresse courriel",
          success: "Mémo envoyé au(x) destinataire(s) sélectionné(s)",
        },
        tags: {
          tagList: "Liste d’étiquettes",
          noContactsWaring:
            " l’étiquette sélectionnée n’est associée à aucun contact.",
        },
      },
      item: {
        completed:
          "{count,plural,=0{Personne n’a complété ce Memo}one{# personne a complété ce Memo}other{# personnes ont complété ce Memo}}",
      },
    },
    memosPage: {
      creatorItem: {
        title: "Il n’y a aucun mémo!",
        hint: "Créer le premier mémo",
        button: "Créer un mémo",
      },
    },
    memosStatsPage: {
      listTitle: "Détails",
      status: {
        completed: "Complété",
        opened: "Consulté",
        sent: "Non vu",
        sending: "En cours d’envoi",
      },
      counter: {
        completed: "{count} Complété",
        opened: "{count} Consulté",
        sent: "{count} Non vu",
      },
      filter: {
        emailAsc: "Adresses courriel de A à Z",
        emailDesc: "Adresses courriel de Z à A ",
        nameAsc: "Noms de A à Z",
        nameDesc: "Noms de Z à A",
      },
      viewMemo: "Voir le mémo",
      noInvitations: "Le mémo n’a pas encore été partagé!",
      share: "Partager maintenant",
      remind: "Rappel",
      remindAll: "Rappeler Tous",
      exportCSV: "Téléchargez un CSV",
      emailedAt: "Dernier rappel{br}{ts}",
    },
    memosFormPage: {
      create: "Créer votre mémo",
      update: "Mettre à jour votre mémo",
      deletedTitle: "Attention",
      deletedDesc: "Ce mémo a été supprimé et n’est plus disponible.",
      upload: "Téléverser",
      beforeLeavePromt:
        "Attention! Si vous quittez cette page sans sauvegarder, vous perdrez tous vos changements.",
      validation: {},
      confirm: {
        delete: "Les utilisateurs vont perdre leur accès et leur progression.",
      },
      delete: {
        title: "Supprimer le mémo?",
        body: "Êtes-vous certain de vouloir supprimer ce mémo? Les utilisateurs vont perdre leur accès et leur progression.",
      },
      buttons: {
        create: "Sauvegarder et publier",
        update: "Mettre à jour",
        delete: "Supprimer le mémo",
        question: "Ajouter une question",
      },
      fields: {
        thumbnail: "Vignette personnalisée",
        name: "Nom",
        desc: "Courte description",
        content: "Contenu",
        attachments: "Pièces jointes",
        project: "Projet",
        language: "Langue",
        linkText: "Texte",
        linkUrl: "URL",
        isPublic: "Rendre ce mémo public",
        isPublicFalse: "Non",
        isPublicTrue: "Oui",
        isPublicHint: "Les mémos publics sont accessibles à tous les utilisateurs qui possedent le lien partagé, les mémos privés sont accessibles seulement aux utilisateurs qui sont dans la liste des contacts.",
      },
      charactersLimit: "Max: {number} caractères",
      attachmentSizeError: "Votre fichier est trop volumineux",
      placeholders: {
        name: "Écrire le nom",
        desc: "Écrire la description",
        content: "Écrire la description",
        attachments: {
          title: "Téléverser un fichier",
          text: "Glisser votre fichier ici ou cliquer pour choisir votre fichier",
          limitLabel: "Maximum: 10mb"
        },
        url: "Lien vidéo",
        chooseLanguage: "Choisir une langue",
        linkText: "Texte",
        linkUrl: "URL"
      },
      richText: {
        italic: "Italique",
        bold: "Gras",
        underline: "Souligné",
        ol: "Liste numéroté",
        ul: "Liste à puces",
        link: "Hyperlien",
      },
      recordButton: {
        stop: "Arrêter",
        record: "Enregistrer",
      },
      form: {
        questions: {
          question: "Question",
          fields: {
            name: "Question",
            options: "Réponses possibles (cocher la bonne réponse)",
          },
          placeholders: {
            name: "Entrer du texte",
            option: "Ajouter une réponse",
          },
        },
      },
    },
    contactsPage: {
      noResultFound: "Aucun résultat trouvé",
      emptyList: `Vous n'avez aucun contact dans votre liste de contacts.
        Commencez par ajouter un contact ou utilisez notre fonctionnalité intéressante d'importation de contacts pour
        importer vos contacts à partir d'une feuille de calcul.`,
      searchPlaceHolder: "Recherche par prénom, nom ou e-mail",
      clearSelection: "Annuler la sélection",
      deleteSelection: "Supprimer la sélection",
      create: {
        title: "Ajouter un contact",
        submit: "Ajouter le nouveau contact",
        existingEmail:
          "Il existe déjà un contact avec cette adresse courriel dans votre liste de contacts.",
        emailRequired: "Vous devez entrer une adresse courriel.",
        success: "Le contact a été ajouté avec succès",
        validation: {
          email: "Vous devez entrer une adresse courriel valide.",
        },
      },
      edit: {
        title: "Modifier le contact",
        success: "Contact mis à jour",
      },
      view: {
        title: "Afficher le contact",
      },
      delete: {
        title: "Supprimer un contact",
        description:
          "Voulez-vous vraiment supprimer le contact {email}? Si vous le faites, ce contact n’aura plus accès aux mémos que vous lui avez envoyés.",
        success: "Contact supprimé",
      },
      bulkActions: {
        delete: {
          title: "Supprimer les contacts sélectionnés?",
          description:
            "Voulez-vous vraiment supprimer les contacts sélectionnés? Si vous le faites, ces contacts n’auront plus accès au mémo ou aux mémos que vous leur avez envoyé(s).",
          success: "Contacts sélectionnés supprimés",
          label: "Supprimer les contacts",
        },
      },
      editIndividual: {
        title: "Modifier le nom",
        success: "Nom mis à jour",
      },
    },
    tagsPage: {
      tags: "Étiquettes",
      tagsManagement: "Gestion des étiquettes",
      searchPlaceHolder: "Trouver une étiquette",
      findTag: "Trouver une étiquette",
      tagsName: "Nom de l’étiquette",
      allTags: "All tags",
      selectedTags: "Étiquette(s) sélectionnée(s)",
      otherTags: "Other tags",
      manageTags: "Gérer les étiquettes",
      filterByTags: "Filtrer par étiquettes",
      create: {
        title: "Ajouter une étiquette",
        submit: "Ajouter",
        existingTag: "Il existe déjà une étiquette portant ce nom.",
        success: "Étiquette ajoutée",
        validation: {
          max: "Maximum ${max} caractères",
        },
      },
      delete: {
        title: "Supprimer une étiquette",
        description:
          "Voulez-vous vraiment supprimer l’étiquette {name}? L’étiquette sera supprimée de tous les contacts qui y sont associés.",
        success: "Étiquette supprimée",
      },
      edit: {
        title: "Modifier l’étiquette",
        submit: "Mettre à jour",
        success: "Étiquette modifiée",
      },
      bulkActions: {
        delete: {
          title: "Supprimer les étiquettes?",
          description:
            "Voulez-vous vraiment supprimer les étiquettes sélectionnées? Elles seront supprimées de tous les contacts qui y sont associés.",
          success: "Étiquettes supprimées",
          label: "Supprimer les étiquettes",
        },
      },
    },
  },
  individual: {
    stepper: {
      welcome: "Bienvenue",
      watch: "Regarder la vidéo en entier",
      answer: "Répondre aux questions",
      complete: "Terminé",
    },
    memoPage: {
      completed:
        "{count} {count, plural, one {personnes} personnes {others}} ont complété le mémo",
      start: "Commencer",
      submit: "Envoyer",
      retry: "Réessayer",
      title: "Bonjour,",
      hint: "{sender} recevra un avis lorsque vous aurez complété ce mémo.",
      success:
        "Vous avez déjà complété ce mémo. Les réponses ne peuvent pas être modifiées.",
      reviewMemo: "Revoir le mémo",
      startQuestions: "Commencer les questions",
      timeToAnswer: "Il est temps de tester vos connaissances!",
      whatsNext: "Que faire maintenant?",
      questionNote:
        "Visionnez la vidéo jusqu'à la fin puis répondez aux questions.",
      oops: "Oups",
      noAccessMessage: "vous n’avez plus accès à ce mémo.",
      connectedAs: "Utilisateur connecté",
      status: {
        completed: "Terminé",
        notCompleted: "Pas terminé",
      },
      estimatedTime: "Durée totale estimée : {time}",
    },
    welcomePage: {
      title: "Bienvenue sur Memo!",
      verifyName: "Confirmer votre nom",
      whosHere: "Qui est là?",
      startMemo: 'Lancer le mémo',
      viewTutorial: 'Regarder le tutoriel'
    },
    tutorialPage: {
      helloTitle: 'Bonjour',
      helloDescription: '{name} vous invite à compléter ce mémo. Suivons les étapes!',
      watchTitle: 'Regardez ce mémo',
      watchDescription: 'Regardez le mémo en entier pour vous assurer d’avoir bien compris les informations.',
      questionsTitle: 'Un mémo peut contenir ou non des questions',
      questionsDescription: ' S’il y a des questions, il faudra y répondre pour compléter le mémo!'
    },
    signUpPage: {
      title: 'Bienvenue sur {name}',
      linkedEmail: 'Ce mémo a été envoyé à {email}. Merci de confirmer vos informations pour commencer',
      hint: 'Obtenir la bonne information au bon moment afin de créer des choses extraordinaires!',
      fields: {
        firstName: "Prénom",
        lastName: "Nom",
        placeholder: {
          firstName: "Patrick",
          lastName: "Lalancette",
        },
      },
      submit: "Commencer",
    },
    memoAlert: {
      completed: {
        title: "Félicitations!",
        text: "{name} recevra un avis que vous avez complété le mémo.",
      },
      failed: {
        title: "Oups",
        text: "Vous n’avez pas répondu correctement à toutes les questions",
      },
      thanksForYourTime: `Merci d’avoir pris le temps de répondre.`,
      canCloseTab: "Vous pouvez maintenant fermer l’onglet.",
    },
  },
  validation: {
    required: "Ce champ est obligatoire.",
    invalidEmail: "Adresse courriel non valide.",
    passwordsDoNotMatch:
      "Les champs Nouveau mot de passe et Confirmer le nouveau mot de passe ne concordent pas.",
  },
  landingPage: {
    duration: "{duration} min",
    expires: "expires on {date}",
  },
  signInPage: {
    title: "Se connecter",
    desc: "Se connecter avec les identifiants qui vous ont été remis",
    submit: "Se connecter",
    forgotPassword: "Mot de passe oublié?",
    fields: {
      email: "Courriel",
      password: "Mot de passe",
      remember: "Se souvenir de moi",
      placeholder: {
        email: "name@domain.com",
        password: "Au moins 8 caractères",
      },
    },
  },
  forgotPassword: {
    title: "Mot de passe oublié?",
    desc: "Entrer le courriel associé à votre compte pour réinitialiser votre mot de passe.",
    submit: "Envoyer un courriel",
    resubmit: "Renvoyer le courriel",
    success: "Succès",
    back: "Annuler",
    fields: {
      email: "Courriel",
    },
    success: {
      title: "Consulter votre boîte de réception",
      desc: "Des instructions pour réinitialiser votre mot de passe ont été envoyées à votre adresse courriel",
    },
  },
  resetPassword: {
    title: "Réinitialiser mon mot de passe",
    desc: "Saisir et confirmer un nouveau mot de passe",
    submit: "Modifier le mot de passe",
    back: "Annuler",
    wrongPassword:
      "Le mot de passe saisi ne correspond pas au mot de passe actuel du compte.",
    success: "Mot de passe modifié",
    rules: {
      lengthRule: "Minimum de 8 caractères",
      caseRule: "Minimum de 1 lettre majuscule et de 1 lettre minuscule",
      numberRule: "Minimum de 1 chiffre",
      specialRule: "Minimum de 1 caractère spécial",
    },
    fields: {
      password: "Nouveau mot de passe",
      password2: "Confirmer votre mot passe",
      currentPassword: "Mot de passe actuel",
      newPassword: "Nouveau mot de passe",
      confirmPassword: "Confirmer le nouveau mot de passe",
    },
  },
  assistance: {
    title: "Avez-vous besoin d’aide?",
    thankYou: "Merci !",
    formDescription:
      "Signalez un problème ou faite une demande de nouvelle fonctionnalité en complétant ce formulaire",
    goBackToMemo: "Retourner à memo",
    notification:
      "Notre équipe va se pencher sur le problème et vous aidera dès que possible !",
    addPicture: "Ajoutez une image",
    needHelp: "Besoin d’aide?",
    contactUs: "Communiquez avec nous",
    attachedFile: "Fichier attaché",
    fields: {
      subject: "Description",
    },
  },
  profile: {
    manageAccount: "Gérer le compte",
    title: "Bienvenue {name}!",
    personal: "Personnel",
    company: "Compagnie",
    avatar: {
      upload: "Téléverser",
    },
    fields: {
      firstName: "Prénom",
      lastName: "Nom",
      email: "Adresse courriel",
      language: "Langue",
      companyName: "Name",
      companyAddress: "Adresse",
      companyCountry: "Pays",
      companyState: "Province",
      companyCity: "Ville",
      companyZipCode: "Code postal",
      companyPhoneNumber: "Numéro de téléphone",
      companyEmail: "Courriel",
    },
    role: {
      creator: "Créateur de contenu",
      admin: "Super admin",
      supervisor: "Superviseur",
    },
  },
  notFound: {
    title: "Oups... ",
    subtitle: "cette page\n n'est pas disponible",
    text: "Le mémo que vous recherchez a été supprimé.\n Contactez son propriétaire pour plus d'informations"
  },
  shared: {
    sharedPage: {
      getStarted: {
        title: "Bonjour,",
        hint: "{sender} sera avisé lorsque vous aurez complété ce mémo.",
        submit: "Commencer",
      },
      sendMail: {
        title: "Qui est là?",
        hint: "Entrez votre courriel pour confirmer votre identité",
        submit: "Soumettre",
        fields: {
          email: "Courriel",
        },
      },
      done: {
        title: "Consultez vos courriels!",
        hint: "Suivez le lien envoyé sur votre boite courriel.",
      },
      none: {
        title: "Non trouvé.",
        hint: "Le mémo n'existe pas.",
      },
      blank: {
        title: " ",
        hint: " ",
      },
    },
  },
  "auth/email-already-exists":
    "Ce courriel est déjà associé à un autre compte.",
  "auth/invalid-action-code": "Code invalide",
  "auth/weak-password": "Adresse courriel ou mot de passe erroné.",
  "auth/wrong-password": "Adresse courriel ou mot de passe erroné",
  "auth/too-many-requests": "Trop de requête, veuillez attendre",
  "auth/user-not-found": "Aucun compte n’est associé à ce courriel",
};
