import axios from "axios";
import firebase from "firebase/app";
import "firebase/firestore";
import { trim } from "lodash";
import { loadAll as loadAllContacts } from "../contacts/reducer";

const { REACT_APP_API } = process.env;

const LOADING_START = "TAGS/LOADING_START";
const LOADING_END = "TAGS/LOADING_END";
const LOADING_SUCCESS = "TAGS/LOADING_SUCCESS";
const RESET = "TAGS/RESET";
const SET_ERROR = "TAGS/SET_ERROR";
const SAVING_START = "TAGS/SAVING_START";
const SAVING_END = "TAGS/SAVING_END";
const DELETING_START = "TAGS/DELETING_START";
const DELETING_END = "TAGS/DELETING_END";
const SET_FORM = "TAGS/SET_FORM";
const RESET_FORM = "TAGS/RESET_FORM";

export const loadAll =
  ({ pagination, sorter, projectId, teamId, userId } = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: LOADING_START,
    });
    try {
      const { user } = getState().session;

      let query = firebase.firestore().collection(`teams/${user.teamId}/tags`);

      query = query.orderBy("createdAt", "desc");

      const result = await query.get();

      dispatch({
        type: LOADING_SUCCESS,
        items: result.docs.map((d) => {
          return {
            id: d.id,
            key: d.id,
            ...d.data(),
          };
        }),
      });
    } catch (ex) {
      console.warn(ex);
    }
    dispatch({
      type: LOADING_END,
    });
  };

export const saveForm = (values) => async (dispatch, getState) => {
  dispatch({ type: SAVING_START });
  let resultTagId = "";
  const stagedValues = values;

  try {
    const { form } = getState().tags;
    const { user } = getState().session;
    // add createdAt if user creates new tag

    if (!!values.id) {
      await firebase
        .firestore()
        .collection(`teams/${user.teamId}/tags`)
        .doc(values.id)
        .set(
          {
            name: stagedValues.name,
          },
          { merge: true }
        );

      resultTagId = values.id;
    } else {
      if (!form.createdAt)
        stagedValues.createdAt =
          firebase.firestore.FieldValue.serverTimestamp();

      const newTag = await firebase
        .firestore()
        .collection(`teams/${user.teamId}/tags`)
        .add({
          name: stagedValues.name,
          createdAt: stagedValues.createdAt,
        });

      resultTagId = newTag.id;
    }

    dispatch({ type: SAVING_END });
    dispatch(loadAll());
    return resultTagId;
  } catch (ex) {
    console.warn(ex);
    dispatch({
      type: SAVING_END,
    });

    return;
  }
};

export const deleteTag = (tagId) => async (dispatch, getState) => {
  dispatch({ type: DELETING_START });

  try {
    const { user } = getState().session;
    // add createdAt if user creates new team
    await firebase
      .firestore()
      .collection(`teams/${user.teamId}/tags`)
      .doc(tagId)
      .delete();

    dispatch({ type: DELETING_END, payload: tagId });
    dispatch(loadAllContacts());
    return tagId;
  } catch (ex) {
    console.warn(ex);
    dispatch({
      type: DELETING_END,
    });

    return;
  }
};

export const deleteTags = (tagIds) => async (dispatch, getState) => {
  dispatch({ type: DELETING_START });

  try {
    const { user } = getState().session;
    // add createdAt if user creates new team

    let batch = firebase
      .firestore().batch();

    tagIds.forEach(tagId => {
      let tagDoc = firebase
        .firestore().collection(`teams/${user.teamId}/tags`).doc(tagId);
      batch.delete(tagDoc);
    })

    await batch.commit()

    dispatch({ type: DELETING_END });
    dispatch(loadAll());
    dispatch(loadAllContacts());
    return tagIds;
  } catch (ex) {
    console.warn(ex);
    dispatch({
      type: DELETING_END,
    });

    return;
  }
};

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setForm = (tagData) => {
  return {
    type: SET_FORM,
    form: tagData,
  };
};

export const reSetForm = () => {
  return {
    type: RESET_FORM,
  };
};

export const setError = (error) => {
  return {
    type: SET_ERROR,
    error,
  };
};

const initialState = {
  saving: false,
  loading: false,
  error: false,
  items: [],
  form: {
    id: null,
    name: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case LOADING_END:
      return {
        ...state,
        loading: false,
      };
    case LOADING_SUCCESS:
      return {
        ...state,
        items: action.items,
      };
    case DELETING_START:
      return {
        ...state,
        deleting: true,
      };
    case DELETING_END:
      return {
        ...state,
        deleting: false,
        items: state.items.filter((item) => item.id !== action.payload),
      };
    case SET_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.form,
        },
      };
    case RESET_FORM:
      return {
        ...state,
        form: {
          ...initialState.form,
        },
      };
    default:
      return state;
  }
}
