import axios from 'axios'
import _ from 'lodash'

const LOADING_START = 'INDIVIDUAL_WELCOME/LOADING_START'
const LOADING_END = 'INDIVIDUAL_WELCOME/LOADING_END'
const SAVING_START = 'INDIVIDUAL_WELCOME/SAVING_START'
const SAVING_END = 'INDIVIDUAL_WELCOME/SAVING_END'
const INIT_FIELDS = 'INDIVIDUAL_WELCOME/INIT_FIELDS'
const SAVE_FIELDS = 'INDIVIDUAL_WELCOME/SAVE_FIELDS'
const RESET = 'INDIVIDUAL_WELCOME/RESET'

export const saveFields = (values) => async (dispatch) => {
  dispatch({
    type: SAVING_START
  })
  try {
    dispatch({
      type: SAVE_FIELDS,
      playload: values
    })
  } catch (ex) {
    console.warn(ex)
  }
  dispatch({
    type: SAVING_END
  })
}

export const initFields = () => async (dispatch) => {
  try {
    const data = localStorage.getItem('userFields')
    const fields = JSON.parse(data)
    dispatch({
      type: INIT_FIELDS,
      payload: fields
    })
  } catch (ex) {
    console.warn(ex)
  }
}

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET
  })
}

const initialState = {
  saving: false,
  loading: false,
  form: {}
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        loading: true
      }
    case LOADING_END:
      return {
        ...state,
        loading: false
      }
    case SAVING_START:
      return {
        ...state,
        saving: true
      }
    case SAVING_END:
      return {
        ...state,
        saving: false
      }
    case INIT_FIELDS:
      return {
        ...state,
        form: {
          ...action.payload
        }
      }
    case SAVE_FIELDS:
      return {
        ...state,
        form: {
          exists: true,
          ...action.payload
        }
      }
    case RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
