import React from "react";

const RetryIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.6534 4.82115C14.0261 4.63981 14.1842 4.18805 13.9664 3.83536C12.6377 1.68379 10.258 0.25 7.54352 0.25C3.37735 0.25 0 3.62735 0 7.79352C0 11.9597 3.37735 15.337 7.54352 15.337C10.258 15.337 12.6377 13.9032 13.9664 11.7517C14.1842 11.399 14.0261 10.9472 13.6534 10.7659C13.281 10.5847 12.836 10.7428 12.6098 11.0897C11.5315 12.7437 9.66521 13.837 7.54352 13.837C4.20578 13.837 1.5 11.1313 1.5 7.79352C1.5 4.45578 4.20578 1.75 7.54352 1.75C9.6652 1.75 11.5315 2.84332 12.6098 4.4973C12.836 4.84419 13.281 5.0023 13.6534 4.82115Z" fill="#F2994A" />
      <path d="M14.2786 5.94814L10.9717 4.73182L14.8787 2.47616L14.2786 5.94814Z" fill="#F2994A" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default RetryIcon;