import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import _, { trim } from 'lodash'

const {
  REACT_APP_API,
  REACT_APP_API_MEMO,
  REACT_APP_API_MEMO_ANSWERS
} = process.env

const LOADING_START = 'INDIVIDUAL_MEMO/LOADING_START'
const LOAD_ONE_SUCCESS = 'INDIVIDUAL_MEMO/LOAD_ONE_SUCCESS'
const LOADING_END = 'INDIVIDUAL_MEMO/LOADING_END'
const SAVING_START = 'INDIVIDUAL_MEMO/SAVING_START'
const SAVING_END = 'INDIVIDUAL_MEMO/SAVING_END'
const SET_STATUS = 'INDIVIDUAL_MEMO/SET_STATUS'
const RESET = 'INDIVIDUAL_MEMO/RESET'
const SET_CONTACT_INFO = 'INDIVIDUAL_MEMO/SET_CONTACT_INFO'
const RESET_SUBMISSION = 'INDIVIDUAL_MEMO/RESET_SUBMISSION'
const SUBMIT_ANSWERS_SUCCESS = 'INDIVIDUAL_MEMO/SUBMIT_ANSWERS_SUCCESS'
const SET_CONTACT_PASSED_TUTORIAL = 'INDIVIDUAL_MEMO/SET_CONTACT_PASSED_TUTORIAL'
const SET_MEMO_PASSED_VIDEO = 'INDIVIDUAL_MEMO/SET_MEMO_PASSED_VIDEO'

export const SUBMISSION_STATUS = {
  COMPLETED: 'completed',
  FAILED: 'failed',
  RESET: 'reset'
}

export const setContactInfo = (payload) => {
  return {
    type: SET_CONTACT_INFO,
    payload
  }
}

export const setContactPassedTutorial = (payload) => {
  return {
    type: SET_CONTACT_PASSED_TUTORIAL,
    payload
  }
}

export const setMemoPassedVideo = () => async (dispatch, getState) => {
  const { project, teamId, id } = getState().individualMemo.form;
  const { email } = getState().individualMemo.contact;

  try {
    const url = `${REACT_APP_API_MEMO.replace(':id', id)}/passVideo`
    const token = await firebase.auth().currentUser.getIdToken(true)
    const result = await axios.post(url, {
      projectId: project.id,
      teamId,
      email
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }
  catch (ex) {
    console.warn("update contact info error", ex)
  }
}

export const setTimestamp = (resumedTime) => async (dispatch, getState) => {

  const { project, teamId, id } = getState().individualMemo.form;
  const { email } = getState().individualMemo.contact;

  try {
    const url = `${REACT_APP_API_MEMO.replace(':id', id)}/timestamp`
    const token = await firebase.auth().currentUser.getIdToken(true)
    const result = await axios.post(url, {
      projectId: project.id,
      teamId,
      email,
      resumedTime
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }
  catch (ex) {
    console.warn("update contact info error", ex)
  }
}

export const updateContactInfo = (payload) => async (dispatch, getState) => {

  const { contact, form } = getState().individualMemo;

  const { values: formValues, isVerified } = payload;

  const stagedValues = {
    ...formValues,
    firstName: trim(formValues.firstName),
    lastName: trim(formValues.lastName),
  };

  try {
    if (contact && contact.id) {
      const url = `${REACT_APP_API}/contact`
      const token = await firebase.auth().currentUser.getIdToken(true)
      const result = await axios.post(url, {
        contactId: contact.id,
        teamId: form.teamId,
        firstName: stagedValues.firstName,
        lastName: stagedValues.lastName,
        isVerified: !!isVerified
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (result.data.success) {
        dispatch(setContactInfo(result.data.result))
        return result.data.result
      }
    }
  }
  catch (ex) {
    console.warn("update contact info error", ex)
  }
}

export const submitAnswers = (answers) => async (dispatch, getState) => {
  dispatch({ type: SAVING_START })
  try {
    const { teamId, projectId, id, submission } = getState().individualMemo.form
    const { id: contactId } = getState().individualMemo.contact;

    const firstNameOfIndividual = _.get(submission, 'firstName')
    const lastNameOfIndividual = _.get(submission, 'lastName')
    const { firstName, lastName } = getState().session.user
    const url = REACT_APP_API_MEMO_ANSWERS.replace(':id', id)
    const token = await firebase.auth().currentUser.getIdToken(true)
    const result = await axios.post(url, {
      teamId,
      projectId,
      answers,
      firstName: firstName || firstNameOfIndividual,
      lastName: lastName || lastNameOfIndividual,
      contactId
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    if (result.data.success)
      dispatch({
        type: SUBMIT_ANSWERS_SUCCESS,
        payload: {
          answers,
          status: result.data.status
        }
      })
  } catch (ex) {
    console.warn(ex)
  }
  dispatch({ type: SAVING_END })
}

export const resetSubmission = () => async (dispatch, getState) => {
  dispatch({
    type: RESET_SUBMISSION
  })
}

export const loadOne = (params) => async (dispatch, getState) => {
  dispatch({ type: LOADING_START })
  try {
    const token = await firebase.auth().currentUser.getIdToken(true)
    const url = REACT_APP_API_MEMO.replace(':id', params.memoId)
    const result = await axios.get(url + `?t=${params.teamId}&p=${params.projectId}&pt=${params.token}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    if (!result.data.success)
      throw { MEMO_NOT_FOUND: true }
    const { memo } = result.data

    if (!memo) {
      window.location = '/401';
      throw { MEMO_NOT_FOUND: true }
    }
    dispatch({
      type: LOAD_ONE_SUCCESS,
      payload: memo
    })
    dispatch(setContactInfo(memo.contact))
  } catch (ex) {
    throw ex
  }
  dispatch({ type: LOADING_END })
}

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET
  })
}

const initialState = {
  saving: false,
  loading: false,
  visible: false,
  exists: true,
  form: {
    submission: {
      status: null,
      answers: {}
    },
    questions: []
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        loading: true
      }
    case LOAD_ONE_SUCCESS:
      return {
        ...state,
        form: {
          ...action.payload
        }
      }
    case LOADING_END:
      return {
        ...state,
        loading: false
      }
    case SAVING_START:
      return {
        ...state,
        saving: true
      }
    case SAVING_END:
      return {
        ...state,
        saving: false
      }
    case SUBMIT_ANSWERS_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          submission: {
            ...state.form.submission,
            answers: action.payload.answers,
            status: action.payload.status
          }
        }
      }
    case SET_CONTACT_INFO:
      return {
        ...state,
        contact: { ...state.contact, ...action.payload }
      }
    case SET_CONTACT_PASSED_TUTORIAL:
      return {
        ...state,
        contact: {
          ...state.contact,
          passedTutorial: action.payload
        }
      }
    case RESET:
      return {
        ...initialState
      }
    case RESET_SUBMISSION: {
      return {
        ...state,
        form: {
          ...state.form,
          submission: {
            ...state.form.submission,
            answers: null,
            status: SUBMISSION_STATUS.RESET
          }
        }
      }
    }
    default:
      return state
  }
}
