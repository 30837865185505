import React, { memo, useCallback, useEffect, useState } from "react";
import style from "./MemoButton.module.scss";
import cls from "classnames";
import { Button } from "antd";

export const MemoButton = ({ url, size, variant, className, children, onClick, ...otherProps }) => {
  return (
    <Button
      className={cls(style.component, {
        [style.small]: size === "small",
        [style.outlined]: variant === "outlined",
      }, className)}
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export default memo(MemoButton);
