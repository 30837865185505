import React, { forwardRef, useImperativeHandle } from "react";
import style from "./Video.module.scss";
import MobileDetect from "mobile-detect";
import { FormattedMessage } from "react-intl";
import { Popover, Slider, Spin, Tooltip, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import VolumeControl from "./VolumeControl";
import { setTimestamp } from "pages/individual/memo/reducer";
import { useDispatch } from "react-redux";

const VideoNative = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = React.useState(false);
  const handle = useFullScreenHandle();

  const [showVolume, setShowVolume] = React.useState(false);
  const [playing, setPlaying] = React.useState(false);
  const [duration, setDuration] = React.useState(0);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [remainedTime, setRemainedTime] = React.useState(0);
  const [videoEnded, setVideoEnded] = React.useState(false);
  const [isSeeking, setIsSeeking] = React.useState(false);
  const [fullScreen, setFullScreen] = React.useState(false);
  const [currentTimeValue, setCurrentTimeValue] = React.useState(-1);
  const [userSeeking, setUserSeeking] = React.useState(false);
  const [lastPlayerStatePlaying, setLastPlayerStatePlaying] =
    React.useState(false);
  const [timelineValue, setTimelineValue] = React.useState(0);
  const [isShowPopover, setIsShowPopover] = React.useState(true);
  const [muted, setMuted] = React.useState(false);
  const [volume, setVolume] = React.useState(0.5);
  const [playedMaxTime, setPlayedMaxTime] = React.useState(0);
  const player = React.useRef(null);

  const mainContainer = React.useRef();
  const mobileDetect = new MobileDetect(navigator.userAgent);

  React.useEffect(() => {
    document.addEventListener("fullscreenchange", onFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", onFullscreenChange);
    };
  }, []);

  const getIsFullscreen = () => {
    return (
      !!document.webkitCurrentFullScreenElement || !!document.fullscreenElement
    );
  };

  useImperativeHandle(ref, () => ({
    getCurrentTime() {
      if (player && player.current) return player.current.currentTime;

      return 0;
    },

    getDuration() {
      if (player && player.current) return player.current.duration;

      return 0;
    },
  }));

  const getInternalPlayer = () => {
    return player ? player : null;
  };

  const updateTime = React.useCallback(() => {
    if (player && player.current) {
      let obj = {
        currentTime: secondsValueAsTime(Math.round(player.current.currentTime)),
        duration: secondsValueAsTime(player.current.duration),
        remainedTime: secondsValueAsTime(
          player.current.duration - Math.round(player.current.currentTime)
        ),
      };

      if (!userSeeking) {
        obj.timelineValue = player.current.currentTime;
        obj.playedMaxTime =
          playedMaxTime < player.current.currentTime
            ? player.current.currentTime
            : playedMaxTime;
      }

      if (!isSeeking) obj.currentTimeValue = player.current.currentTime;

      if (obj.currentTime) {
        setCurrentTime(obj.currentTime);
      }

      if (obj.duration) {
        setDuration(obj.duration);
      }

      if (obj.remainedTime) {
        setRemainedTime(obj.remainedTime);
      }

      if (obj.currentTimeValue) {
        setCurrentTimeValue(obj.currentTimeValue);
      }
      if (obj.playedMaxTime) {
        setPlayedMaxTime(obj.playedMaxTime);
      }
      if (obj.timelineValue) {
        setTimelineValue(obj.timelineValue);
      }


    }
  }, [
    dispatch,
    player,
    userSeeking,
    isSeeking,
    currentTimeValue,
    playedMaxTime,
    setCurrentTime,
    setDuration,
    setRemainedTime,
    setCurrentTimeValue,
    setPlayedMaxTime,
    setTimelineValue,
  ]);

  React.useEffect(() => {
    if (currentTimeValue !== -1 && props.isIndividual) {
      dispatch(setTimestamp(Math.floor(currentTimeValue)));
    }
  }, [currentTime, props.isIndividual])

  const secondsValueAsTime = (seconds) => {
    const sec_num = parseInt(seconds, 10)
    const hours = Math.floor(sec_num / 3600)
    const minutes = Math.floor(sec_num / 60) % 60
    const secs = sec_num % 60

    return [hours, minutes, secs]
      .map(v => v < 10 ? "0" + v : v)
      .filter((v,i) => v !== "00" || i > 0)
      .join(":")
  };

  const onProgress = (e) => {
    updateTime();

    if (props.onProgress) {
      props.onProgress();
    }
  };

  const onFullscreenChange = () => {
    setFullScreen(!fullScreen);
  };

  const onFullscreenClick = () => {
    let isFullscreen = getIsFullscreen();

    if (isFullscreen) {
      mainContainerExitFullscreen();
    } else {
      mainContainerRequestFullscreen();
    }

    setFullScreen(!fullScreen);
  };

  const mainContainerRequestFullscreen = () => {
    let e = mainContainer.current;

    if (e.requestFullscreen) e.requestFullscreen();
    else if (e.webkitRequestFullscreen) e.webkitRequestFullscreen();
    else if (e.mozRequestFullscreen) e.mozRequestFullscreen();
    else if (e.msRequestFullscreen) e.msRequestFullscreen();
    else {
      // handle full screen on ios safari
      setVisible(true);
    }
  };

  const mainContainerExitFullscreen = () => {
    if (document.exitFullscreen) document.exitFullscreen();
    else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
    else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
    else if (document.msExitFullscreen) document.msExitFullscreen();
    else {
      setVisible(false);
    }
  };

  const onVideoClick = () => {
    onPlayPauseClick();
  };

  const onPlay = () => {
    setPlaying(true);
    if (player && player.current && player.paused) {
      player.current.play();
    }
    if (props.onPlay) props.onPlay();
  };

  const onPause = () => {
    setPlaying(false);
    if (player && player.current && !player.current.paused) {
      player.current.paused();
    }
    if (props.onPause) props.onPause();
  };

  const onEnded = () => {
    setVideoEnded(true);

    if (props.onEnded) props.onEnded();
  };

  const onSeeking = (e) => {
    setIsSeeking(true);
  };

  const onSeeked = (e) => {
    setIsSeeking(false);

    return false;

    if (videoEnded) return;

    if (!player) return;

    updateCurrentTime(currentTimeValue, !player.current.paused);
  };

  const onPlayPauseClick = () => {
    if (player && player.current) {
      if (player.current.paused) player.current.play();
      else player.current.pause();
    }
  };

  const onTimeUpdate = () => {
    updateTime();

    if (props.onTimeUpdate) props.onTimeUpdate();
  };

  const onTimelineDown = (e) => {
    setUserSeeking(true);
    setLastPlayerStatePlaying(!player.paused);
    updateCurrentTime(e.target.value, false);
  };

  const onTimelineMove = (e) => {
    if (e && e.target.value) {
      if (!userSeeking) return;
      setTimelineValue(e.target.value);
      updateCurrentTime(e.target.value, false);
    }
  };

  const onTimelineChange = (value) => {
    setTimelineValue(value);
    updateCurrentTime(value, !player.paused);
  };

  const onTimelineUp = (e) => {
    setUserSeeking(false);
    updateCurrentTime(e.target.value, lastPlayerStatePlaying);
  };

  const updateCurrentTime = (value, shouldPlayAfter) => {
    // console.trace();
    value = +value;
    if (!Number.isFinite(value)) return;

    if (player && player.current) {
      player.current.currentTime = value;
    }

    if (shouldPlayAfter) {
      player.current.play();
    }
  };

  const renderPlayPauseButton = () => {
    let cls = playing ? style.stop : style.play;

    return (
      <div
        className={`${style.playPauseButton} ${cls}`}
        onClick={onPlayPauseClick}
      ></div>
    );
  };

  const hidePopover = () => {
    setIsShowPopover(false);
  };

  React.useEffect(() => {
    if (player && player.current) {
      player.current.currentTime = props.resumedTime;
    }
  }, [player, props.resumedTime]);

  const renderTime = () => {
    return (
      <React.Fragment>
        <Popover
          placement="topLeft"
          content={
            <div className={style.popoverContainer}>
              <FormattedMessage id="video.cannotSkip" />
              <CloseOutlined
                onClick={hidePopover}
                className={style.closeIcon}
              />
            </div>
          }
          trigger="click"
          overlayClassName={style.popover}
          getPopupContainer={() =>
            document.querySelector("[class*=Video_playerContainer]")
          }
          visible={
            !props.hideSkipPopover &&
            isShowPopover &&
            !videoEnded &&
            !props.isSkippable
          }
        >
          <div>
            {" "}
            {videoEnded || props.isSkippable
              ? `${currentTime}/${duration}`
              : `-${remainedTime}`}
          </div>
        </Popover>
      </React.Fragment>
    );
  };

  const renderFullscreen = () => {
    let cls = fullScreen ? style.exitFullscreen : style.enterFullscreen;

    return (
      <div
        className={`${style.fullscreenButton} ${cls}`}
        onClick={onFullscreenClick}
      ></div>
    );
  };

  const handleChangeMuted = (isMuted) => {
    if (isMuted) {
      if (player && player.current) {
        player.current.volume = 0;
      }
    } else {
      if (player && player.current) {
        player.current.volume = volume;
      }
    }
    setMuted(isMuted);
  };

  const handleChangeVolume = (volume) => {
    setVolume(volume);
    if (player && player.current) {
      player.current.volume = volume;
    }
  };

  const renderVolume = () => {
    return (
      <div className={style.volumeContainer}>
        <VolumeControl
          muted={muted}
          volume={volume}
          onChangeVolume={handleChangeVolume}
          onMute={handleChangeMuted}
        />
      </div>
    );
  };

  const renderTimeline = () => {
    let max = 0;
    let width = "100%";
    if (player && player.current && Number.isFinite(player.current.duration)) {
      max = props.isSkippable ? player.current.duration : playedMaxTime;

      width = props.isSkippable
        ? width
        : `${Math.round((playedMaxTime / player.current.duration) * 100)}%`;

      // if (Math.round(player.current.duration) === Math.round(playedMaxTime)) {
      //   props.onEnded()
      // }
    }

    if (mobileDetect.mobile()) {
      return (
        <div
          style={{ width }}
          onTouchStart={onTimelineDown}
          onTouchEnd={onTimelineUp}
          onTouchCancel={onTimelineUp}
          onTouchMove={onTimelineMove}
        >
          <Slider
            min={0}
            max={max}
            step={0.01}
            value={timelineValue}
            className={style.volumeBar}
            tooltipVisible={false}
            trackStyle={{ backgroundColor: "#fff" }}
            handleStyle={{ borderColor: "#fff" }}
            onChange={onTimelineChange}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{ width }}
          onMouseDown={onTimelineDown}
          onMouseUp={onTimelineUp}
        >
          <Slider
            min={0}
            max={max}
            step={0.01}
            value={timelineValue}
            className={style.volumeBar}
            tooltipVisible={false}
            trackStyle={{ backgroundColor: "#fff" }}
            handleStyle={{ borderColor: "#fff" }}
            onChange={onTimelineChange}
          />
        </div>
      );
    }
  };

  const renderSources = () => {
    if (props.transcodedSrc) {
      let sources = [];
      props.transcodedSrc.forEach((source, index) => {
        sources.push(
          <source src={source.url} type={source.type} key={index} />
        );
      });

      return sources;
    }
    return null;
  };

  const renderPlayer = () => {
    return (
      <video
        className={`${style.player} video-js`}
        autoPlay={true}
        onPlay={onPlay}
        onPause={onPause}
        onEnded={onEnded}
        onTimeUpdate={onTimeUpdate}
        onClick={onVideoClick}
        onProgress={onProgress}
        onSeeking={onSeeking}
        onSeeked={onSeeked}
        ref={player}
        playsInline
      >
        {renderSources()}
      </video>
    );
  };

  return (
    <div
      ref={mainContainer}
      allowFullScreen={true}
      className={style.mainContainer}
      width={props.width}
      height={props.height}
    >
      <div className={style.playerContainer}>
        <div className={`${style.fullHeight}`}>{renderPlayer()}</div>
        <div className={style.bottomBarShadow} />
        {renderPlayPauseButton()}
        {props.showVolume && renderVolume()}
        {renderFullscreen()}
        <div className={style.timelineContainer}>{renderTimeline()}</div>
        {!props.isSkippable && (
          <div className={style.dummnyTimeline}>
            <div className="ant-slider">
              <div className="ant-slider-step"></div>
            </div>
          </div>
        )}
        <div
          className={`${style.controlsContainer} ${!props.showVolume ? style.fullWidth : ""
            }`}
        >
          {renderTime()}
        </div>
      </div>
      <Modal
        title="Press ESC or Click close icon to exit full screen "
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width="100vw"
        className={style.videoModal}
        footer={null}
      >
        <div className={style.playerContainer}>
          <div className={`${style.fullHeight}`}>{renderPlayer()}</div>
          <div className={style.bottomBarShadow} />
          {renderPlayPauseButton()}
          {props.showVolume && renderVolume()}
          {renderFullscreen()}
          <div className={style.timelineContainer}>{renderTimeline()}</div>
          {!props.isSkippable && (
            <div className={style.dummnyTimeline}>
              <div className="ant-slider">
                <div className="ant-slider-step"></div>
              </div>
            </div>
          )}
          <div
            className={`${style.controlsContainer} ${!props.showVolume ? style.fullWidth : ""
              }`}
          >
            {renderTime()}
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default VideoNative;
