import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './MemoViewMode.module.scss'
import { Modal, Form, Card, Button, Alert, Row } from 'antd'
import { deleteOne, loadOne, reset } from './reducer'
import { reset as resetQuestions, loadQuestions } from './form/questions/reducer'
import _ from 'lodash'
import { Prompt, useRouteMatch } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import Toolbar from './toolbar/Toolbar'
import { initField } from './form/url/reducer'
import Icon from 'components/Icon'
import LoadingMemoAnimation from 'components/animations/LoadingMemoAnimation'
import MemoQuestions from '../../individual/memo/MemoQuestions'
import Profile from 'components/header/Profile'
import MemoFooter from 'components/footer/MemoFooter'
import EmbedPlayer from 'components/embedPlayer/EmbedPlayer'
import { SUBMISSION_STATUS } from 'pages/individual/memo/reducer'
import MemoAlert from 'pages/individual/memo/MemoAlert'
import { resetSubmission } from 'pages/individual/memo/reducer'
import moment from 'moment'
import { loadAll as loadUsers } from 'pages/admin/users/reducer'

export default function MemoViewMode({ role }) {
  const [prompt, setPrompt] = useState(true)
  const [antdForm] = Form.useForm()
  const [deleteModal, setDeleteModal] = useState(false)
  const intl = useIntl()
  const dispatch = useDispatch()
  const { params } = useRouteMatch()
  const { form, loading, deleting } = useSelector(state => state.memos)
  const { items: creators, loading: creatorsLoading } = useSelector(state => state.adminCreators)
  const userId = _.get(form, 'user.uid')
  const creator = creators.find(creatorData => creatorData.id === userId)
  const { items: questions } = useSelector(state => state.memosFormQuestions)
  const { user } = useSelector(s => s.session)
  const isEditing = !!params.id
  const { deleted } = form
  const loadingMask = loading || (isEditing && !form.id)
  const embed = _.get(form, 'url.embed')
  const [status, setStatus] = React.useState('')
  const [answers, setAnswers] = useState({})
  const [profile, setProfile] = React.useState({
    name: '',
    avatar: '',
    team: '',
    project: ''
  })

  useEffect(() => {
    dispatch(loadUsers({
      role: 'creator'
    }))
  }, [])

  const [answerStatusModal, setAnswerStatusModal] = useState(false)
  const sender = `${form.user && form.user.firstName} ${form.user && form.user.lastName}`
  const createdAt = moment.unix(_.get(form, 'createdAt.seconds'))

  const canSubmit = React.useMemo(() => {
    const answersKeys = Object.keys(answers)
    return answersKeys.length === questions.length
  }, [answers, questions])

  React.useEffect(() => {
    if (form && creator) {
      setProfile({
        name: creator ? `${creator.firstName} ${creator.lastName}` : '',
        avatar: creator && creator.avatar ? creator.avatar.url : '',
        team: form.teamId,
        project: `${form.projectId}-${form.id}`
      })
    }
  }, [form, creator])

  const onAnswersChange = useCallback((fields) => {
    fields.forEach(f => {
      setAnswers({
        ...answers,
        [f.name[0]]: f.value
      })
    })
  }, [answers])

  const onDeleteClick = useCallback(() => setDeleteModal(true), [])
  const onConfirmCancel = useCallback(() => setDeleteModal(false), [])
  const onConfirmDelete = useCallback(async () => {
    try {
      await dispatch(deleteOne(form))
      setDeleteModal(false)
      dispatch(loadOne(form.id, params.pid))
    } catch (ex) {
      console.error(ex)
    }
  }, [form, params])

  useEffect(() => {
    dispatch(initField(form.url))
    antdForm.resetFields()
    antdForm.setFieldsValue({
      ...form,
      userId: user.uid,
      teamId: user.teamId,
      projectId: params.pid
    })
    return () => {
    }
  }, [form])

  useEffect(() => {
    if (params.id) {
      dispatch(loadOne(params.id, params.pid))
      dispatch(loadQuestions(params.id, params.pid, user.teamId))
    }
    return () => {
      dispatch(reset())
      dispatch(resetQuestions())
    }
  }, [params.id, params.pid])

  const onEnded = () => {

  }

  const validateAnswers = (questions) => {
    const correctnessMap = questions.reduce((acc, q) => {
      acc[q.id] = q.config.correctId
      return acc
    }, {})
    let result = true
    for (let questionId in answers) {
      if (correctnessMap[questionId] !== answers[questionId]) {
        result = false
        break
      }
    }
    return result
  }

  const onSubmit = () => {
    const isValid = validateAnswers(questions)
    setStatus(isValid ? SUBMISSION_STATUS.COMPLETED : SUBMISSION_STATUS.FAILED)
    setAnswerStatusModal(true)
  }

  const onRetry = () => {
    setAnswerStatusModal(false)
    setAnswers({})
    dispatch(resetSubmission())
  }

  const onReview = () => {
    setAnswerStatusModal(false)
  }

  const renderPlayer = () => {
    return (
      <React.Fragment>
        <EmbedPlayer
          className={style.embed}
          playable
          embed={embed}
          canSeek={false}
          onEnded={onEnded}
          hideSkipPopover={true}
          isSkippable
        />
      </React.Fragment>
    )
  }

  const renderFormContent = () => {
    if (loadingMask) {
      return (
        <div className={style.loading}>
          <LoadingMemoAnimation />
        </div>
      )
    }
    else {
      return (
        <>
          <div className={style.formContainer}>
            {deleted && (
              <Alert
                className={style.deletedWarning}
                type='warning'
                message={intl.formatMessage({ id: 'admin.memosFormPage.deletedTitle' })}
                description={intl.formatMessage({ id: 'admin.memosFormPage.deletedDesc' })} />
            )}
            <Card
              className={style.playerCard}
              bodyStyle={{ padding: 0 }}
              bordered={false}>
              <Profile profile={profile} completed={status === SUBMISSION_STATUS.COMPLETED} />
              {renderPlayer()}
              <MemoFooter memo={form} projectName={form.project && form.project.name || ''} isDefaultProject={form.project && form.project.isDefault} createdAt={createdAt} />
            </Card>
            <div className={style.questionsContainer}>
              <MemoQuestions onFieldsChange={onAnswersChange} questions={questions} />
            </div>
            <Button
              onClick={onSubmit}
              className={style.submitButton}
              disabled={!canSubmit}
            >
              <FormattedMessage id='individual.memoPage.submit' />
            </Button>
          </div>
          <React.Fragment>
            <Toolbar
              className={style.toolbar}
              isView
              onDelete={onDeleteClick} />
          </React.Fragment>
        </>
      )
    }
  }

  return (
    <div className={style.container}>
      <Prompt
        when={prompt && !deleted}
        message={() => intl.formatMessage({ id: 'admin.memosFormPage.beforeLeavePromt' })}
      />
      {renderFormContent()}
      <Modal
        maskClosable
        maskStyle={{
          backgroundColor: '#F3F5F7'
        }}
        closable={false}
        visible={deleteModal}
        className={style.deleteModal}
        footer={null}>
        <Icon name='close' onClick={onConfirmCancel} className={style.closeButton} />
        <h2>
          <FormattedMessage id='admin.memosFormPage.delete.title' />
        </h2>
        <p>
          <FormattedMessage
            id='admin.memosFormPage.delete.body'
            values={{
              id: 'MKT-43'
            }} />
        </p>
        <footer>
          <Button onClick={onConfirmDelete} className={style.deleteButton} loading={deleting}>
            <FormattedMessage
              id='admin.memosFormPage.buttons.delete' />
          </Button>
          <Button className={style.cancelButton} onClick={onConfirmCancel}>
            <FormattedMessage
              id='general.cancel' />
          </Button>
        </footer>
      </Modal>

      <Modal
        maskClosable
        className={style.statusModal}
        centered
        closable={false}
        visible={answerStatusModal}
        width={440}
        footer={null}>
        <p>
          <MemoAlert status={status} sender={sender} />
        </p>
        <footer>
          {status === SUBMISSION_STATUS.FAILED && (
            <Row justify="center">
              <Button onClick={onRetry} className={style.primaryButton}>
                <FormattedMessage id='individual.memoPage.retry' />
              </Button>
            </Row>

          )}
          {status === SUBMISSION_STATUS.COMPLETED && (
            <Row justify="center">
              <Button onClick={onReview} className={`${style.primaryButton}`}>
                <FormattedMessage id='individual.memoPage.reviewMemo' />
              </Button>
            </Row>
          )}
        </footer>
      </Modal>
    </div>
  )
}