import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/firestore'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
const { REACT_APP_API } = process.env

const LOADING_START = 'MEMOS/LOADING_START'
const LOADING_SUCCESS = 'MEMOS/LOADING_SUCCESS'
const LOADING_END = 'MEMOS/LOADING_END'
const SAVING_START = 'MEMOS/SAVING_START'
const SAVING_END = 'MEMOS/SAVING_END'
const DELETING_START = 'MEMOS/DELETING_START'
const DELETING_END = 'MEMOS/DELETING_END'
const RESET = 'MEMOS/RESET'
const LOADING_ONE_SUCCESS = 'MEMOS/LOADING_ONE_SUCCESS'

/**
 * @TODO find a solution to update a counter and return the result in one trasaction
 * @param {String} teamId
 * @param {String} projectId
 */
export const createMemoId = (teamId, projectId) => {
  const projects = firebase.firestore().collection(`teams/${teamId}/projects`)
  const memoCount = firebase.firestore.FieldValue.increment(1)
  return new Promise((resolve, reject) => {
    const changeId = uuidv4()
    const project = projects.doc(projectId)
    const unsubscribe = project.onSnapshot({
      includeMetadataChanges: true
    }, doc => {
      if (doc.metadata.hasPendingWrites || !doc.exists) {
        // https://firebase.google.com/docs/firestore/query-data/listen#web
        return // waiting for the real document to be received
      }
      const { memoCount, changeId: resultChangeId } = doc.data()
      // check if the change was made by current user.
      if (changeId === resultChangeId) {
        unsubscribe()
        resolve(memoCount.toString())
      }
    })
    project.update({
      memoCount,
      changeId
    })
  })
}


export const loadAll = ({ pagination, sorter, projectId, teamId, userId } = {}) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_START
  })
  try {
    // const { session, projects } = getState()
    let query = firebase
      .firestore()
      .collectionGroup('memos')

    if (teamId)
      query = query.where('teamId', '==', teamId)
    if (projectId)
      query = query.where('projectId', '==', projectId)
    if (userId)
      query = query.where('userId', '==', userId)
    query = query
      .orderBy('createdAt', 'desc')
    const result = await query.get()
    dispatch({
      type: LOADING_SUCCESS,
      items: result.docs
        .filter(d => !d.data().deleted)
        .map(d => {
          return {
            id: d.id,
            ...d.data()
          }
        })
    })
  } catch (ex) {
    console.warn(ex)
  }
  dispatch({
    type: LOADING_END
  })
}

export const loadOne = (id, projectId) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_START
  })
  try {
    const { user } = getState().session
    const result = await firebase
      .firestore()
      .collection(`teams/${user.teamId}/projects/${projectId}/memos`)
      .doc(id)
      .get()

    const project = await firebase.firestore().collection(`teams/${user.teamId}/projects`).doc(projectId).get()

    dispatch({
      type: LOADING_ONE_SUCCESS,
      payload: {
        form: {
          id,
          ...result.data(),
          project: project.data()
        }
      }
    })
  } catch (ex) {
    console.warn(ex)
    throw ex
  }
  dispatch({
    type: LOADING_END
  })
}

export const saveForm = (values) => async (dispatch, getState) => {
  dispatch({
    type: SAVING_START
  })
  try {
    const { videoDuration } = getState().memosFormUrl
    
    const { user } = getState().session
    let memoId = getState().memos.form.id
    if (!memoId) {
      memoId = await createMemoId(values.teamId, values.projectId)
      values.createdAt = firebase.firestore.FieldValue.serverTimestamp()
      values.videoDuration = Math.ceil(videoDuration / 60)
    }
    // demormalize required user's data
    values.user = {
      uid: user.uid,
      avatar: user.avatar || null,
      firstName: user.firstName,
      lastName: user.lastName
    }
    await firebase
      .firestore()
      .collection(`teams/${values.teamId}/projects/${values.projectId}/memos`)
      .doc(memoId)
      .set(values, { merge: true })

    if (!values.url.embed.isTranscoded)
      await axios.get(`${REACT_APP_API}/coconut/start/${values.teamId}/${values.projectId}/${memoId}`)

    const { items: questions } = getState().memosFormQuestions
    const promises = questions.map((q, index) => {
      const data = {
        ...q,
        memoId
      }

      data.config.options = data.config.options.filter(o => !!o.title)
      data.order = index
      return firebase
        .firestore()
        .collection(`teams/${values.teamId}/projects/${values.projectId}/memos/${memoId}/questions`)
        .doc(q.id)
        .set(data, { merge: true })
    })
    await Promise.all(promises)
  } catch (ex) {
    console.warn(ex)
    dispatch({
      type: SAVING_END
    })
    throw ex
  }
  dispatch({
    type: SAVING_END
  })
}

export const deleteOne = (data) => async (dispatch, getState) => {
  dispatch({
    type: DELETING_START
  })
  try {
    await firebase
      .firestore()
      .collection(`teams/${data.teamId}/projects/${data.projectId}/memos`)
      .doc(data.id)
      .update({
        deleted: true
      })
  } catch (ex) {
    console.warn(ex)
    throw ex
  }
  dispatch({
    type: DELETING_END
  })
}

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET
  })
}

export const actions = {
  loadAll,
  loadOne,
  deleteOne,
  reset
}

const initialState = {
  saving: false,
  loading: false,
  deleting: false,
  items: [],
  form: {
    id: null,
    name: null,
    userId: null,
    isPublicMemo: false
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        loading: true
      }
    case LOADING_ONE_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case LOADING_END:
      return {
        ...state,
        loading: false
      }
    case LOADING_SUCCESS:
      return {
        ...state,
        items: action.items
      }
    case SAVING_START:
      return {
        ...state,
        saving: true
      }
    case SAVING_END:
      return {
        ...state,
        saving: false
      }
    case DELETING_START:
      return {
        ...state,
        deleting: true
      }
    case DELETING_END:
      return {
        ...state,
        deleting: false
      }
    case RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
