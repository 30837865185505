import firebase from 'firebase/app'
import 'firebase/storage'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import qs from 'qs'

const { REACT_APP_EMBEDLY_API_KEY } = process.env

const FETCH_EMBED_START = 'MEMOS_FORM_URL/FETCH_EMBED_START'
const FETCH_EMBED_SUCCESS = 'MEMOS_FORM_URL/FETCH_EMBED_SUCCESS'
const FETCH_EMBED_END = 'MEMOS_FORM_URL/FETCH_EMBED_END'
const INIT_FIELD = 'MEMOS_FORM_URL/INIT_FIELD'
const UPLOAD_FILE_START = 'MEMOS_FORM_URL/UPLOAD_FILE_START'
const UPLOAD_FILE_END = 'MEMOS_FORM_URL/UPLOAD_FILE_END'
const RESET = 'MEMOS_FORM_URL/RESET'
const SET_THUMBNAIL = 'MEMOS_FORM_URL/SET_THUMBNAIL'
const SET_THUMBNAIL_CANDIDATES = 'MEMOS_FORM_URL/SET_THUMBNAIL_CANDIDATES'

const DEFAULT_THUMBNAIL = 'https://fakeimg.pl/440x320/282828/eae0d0/?retina=1'
const THUMBNAILS_COUNT = 11
const SET_VIDEO_DURATION = 'MEMOS/SET_VIDEO_DURATION'

export const fetchEmbed = (url) => async (dispatch, getState) => {
  dispatch({ type: FETCH_EMBED_START })
  let embed = {
    provider_url: "https://firebasestorage.googleapis.com",
    provider_name: "Firebasestorage",
    version: "1.0",
    type: "link",
    thumbnailId: Math.floor(Math.random() * THUMBNAILS_COUNT),
    isTranscoded: false,
    url,
  }
  dispatch({
    type: FETCH_EMBED_SUCCESS,
    payload: { embed }
  })

  dispatch({ type: FETCH_EMBED_END })
  return embed
}

export const uploadFile = (file) => async (dispatch, getState) => {
  dispatch({ type: UPLOAD_FILE_START })
  let url = null
  let embed = null
  try {
    const { session } = getState()
    const id = uuidv4()
    const path = `uploads/${session.user.uid}/files`
    const result = await firebase.storage().ref(path).child(id).put(file)
    url = await result.ref.getDownloadURL()
    embed = await dispatch(fetchEmbed(url))
  } catch (ex) {
    dispatch({ type: UPLOAD_FILE_END })
    console.warn(ex)
    throw ex
  }
  dispatch({ type: UPLOAD_FILE_END })
  return {
    url,
    embed
  }
}

export const setThumbnail = (payload) => {
  return {
    type: SET_THUMBNAIL,
    payload
  }
}

export const setThumbnailCandidates = (payload) => {
  return {
    type: SET_THUMBNAIL_CANDIDATES,
    payload
  }
}

export const setVideoDuration = (payload) => async (dispatch, getState) => {
  dispatch({
    type: SET_VIDEO_DURATION,
    payload
  })
}

export const initField = (data) => (dispatch) => {
  if (!data)
    return

  dispatch({
    type: INIT_FIELD,
    payload: {
      embed: data.embed
    }
  })
}

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET
  })
}

export const actions = {
  fetchEmbed
}

const initialState = {
  loading: false,
  embed: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EMBED_START:
      return {
        ...state,
        loading: true
      }
    case FETCH_EMBED_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_EMBED_END:
      return {
        ...state,
        loading: false
      }
    case INIT_FIELD:
      return {
        ...state,
        ...action.payload
      }
    case UPLOAD_FILE_START:
      return {
        ...state,
        uploading: true
      }
    case UPLOAD_FILE_END:
      return {
        ...state,
        uploading: false
      }
    case SET_THUMBNAIL:
      return {
        ...state,
        embed: {
          ...state.embed,
          thumbnail_url: action.payload.url
        }
      }
    case SET_THUMBNAIL_CANDIDATES:
      return {
        ...state,
        embed: {
          ...state.embed,
          thumbnail_candidates: action.payload
        }
      }
    case SET_VIDEO_DURATION:
      return {
        ...state,
        videoDuration: action.payload
      }

    case RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
