import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './TeamsFormPage.module.scss'
import { Form, Input, Button, Card, Spin, message } from 'antd'
import { loadOne, saveForm, reset } from './reducer'
import { saveForm as saveProject, DEFAULT_PROJECT } from 'pages/admin/projects/reducer'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Logo from './Logo'

export default function TeamsFormPage() {
  const [antdForm] = Form.useForm()
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const { params } = useRouteMatch()
  const { form, loading, saving } = useSelector(state => state.adminTeams)
  const onSubmit = useCallback(async () => {
    try {
      const existing = !!params.id
      const values = await antdForm.validateFields()
      const teamId = await dispatch(saveForm(values))
      if (!existing)
        await dispatch(saveProject({
          ...DEFAULT_PROJECT,
          teamId
        }))
      dispatch(reset())
      history.push('/app/teams')
    } catch (ex) {
      console.warn(ex)
    }
  }, [])
  const onLoadingChange = useCallback(() => {

  }, [])

  useEffect(() => {
    antdForm.setFieldsValue(form)
  }, [form])

  useEffect(() => {
    if (params.id)
      dispatch(loadOne(params.id))
    // return () => dispatch(reset())
  }, [])

  const props = {
    name: 'file',
    async customRequest(file) {
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Spin spinning={loading}>
      <div className={style.component}>
        <Card className={style.content}>
          <header>
            <h1>
              {intl.formatMessage({
                id: `admin.teams.${params.id ? 'update' : 'create'}`
              })}
            </h1>
          </header>
          <Form
            autoComplete="off"
            form={antdForm}
            validateMessages={{
              required: intl.formatMessage({ id: 'validation.required' })
            }}>
            <Form.Item
              labelCol={{ xs: 24 }}
              label='Name'
              className={style.formItem}
              rules={[{ required: true }]}
              name='name'>
              <Input className={style.input} placeholder='' />
            </Form.Item>
            <Form.Item
              labelCol={{ xs: 24 }}
              label='Shortcode URL'
              className={style.formItem}
              rules={[{ required: true }]}
              name='shortcode'>
              <Input className={style.input} placeholder='' disabled={params.id} />
            </Form.Item>
            <Form.Item
              labelCol={{ xs: 24 }}
              label='Logo'
              className={style.formItem}
              rules={[{ required: true }]}
              name='logo'>
              <Logo onLoadingChange={onLoadingChange} />
            </Form.Item>
            <Button onClick={onSubmit} type='primary'>
              {intl.formatMessage({ id: params.id ? 'general.save' : 'general.create' })}
            </Button>
          </Form>
        </Card>
      </div>
    </Spin>
  )
}