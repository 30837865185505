import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import { API_TOKEN_ADMIN, API_URL } from 'const'
import _ from 'lodash'

const LOADING_START = 'ADMIN_USERS/LOADING_START'
const LOADING_SUCCESS = 'ADMIN_USERS/LOADING_SUCCESS'
const LOADING_END = 'ADMIN_USERS/LOADING_END'
const SAVING_START = 'ADMIN_USERS/SAVING_START'
const SAVING_END = 'ADMIN_USERS/SAVING_END'
const SAVING_ERROR = 'ADMIN_USERS/SAVING_ERROR'
const RESET = 'ADMIN_USERS/RESET'
const SET_FORM = 'ADMIN_USERS/SET_FORM'
const LOADING_ONE_SUCCESS = 'ADMIN_USERS/LOADING_ONE_SUCCESS'
const RESET_PASSWORD_START = 'ADMIN_USERS/RESET_PASSWORD_START'
const RESET_PASSWORD_END = 'ADMIN_USERS/RESET_PASSWORD_END'

const { REACT_APP_API_USER } = process.env

export const setForm = (form) => async (dispatch, getState) => {
  dispatch({
    type: SET_FORM,
    form
  })
}

export const loadAll = ({ role, pagination, sorter }) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_START
  })
  try {
    const result = await firebase
      .firestore()
      .collection('users')
      .where('role', '==', role)
      .orderBy('createdAt', 'desc')
      .limit(100)
      .get()

    dispatch({
      type: LOADING_SUCCESS,
      items: result.docs.map(d => {
        return {
          id: d.id,
          ...d.data()
        }
      })
    })
  } catch (ex) {
    console.warn(ex)
  }
  dispatch({
    type: LOADING_END
  })
}

export const loadOne = (id) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_START
  })
  try {
    const result = await firebase
      .firestore()
      .collection('users')
      .doc(id)
      .get()
    dispatch({
      type: LOADING_ONE_SUCCESS,
      payload: {
        form: {
          id,
          ...result.data()
        }
      }
    })
  } catch (ex) {
    console.warn(ex)
    throw ex
  }

  dispatch({
    type: LOADING_END
  })
}

export const resetPassword = () => async (dispatch, getState) => {
  dispatch({
    type: RESET_PASSWORD_START
  })
  try {
    await firebase.auth().sendPasswordResetEmail(
      getState().adminCreators.form.email
    )
  } catch (ex) {
    dispatch({
      type: RESET_PASSWORD_END
    })
    console.warn(ex)
    throw ex
  }
  dispatch({
    type: RESET_PASSWORD_END
  })
}

export const saveForm = (values) => async (dispatch, getState) => {
  dispatch({
    type: SAVING_START
  })
  try {
    const { email, password } = values
    const { form } = getState().adminCreators
    let userId = form.id

    if (!userId) {
      // create user
      const token = await firebase.auth().currentUser.getIdToken(true)
      const response = await axios.post(REACT_APP_API_USER, {
        email,
        password
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      if (!response.data.success)
        throw response.data
      userId = response.data.uid
      values.createdAt = firebase.firestore.FieldValue.serverTimestamp()
    }
    values = _.omit(values, ['password', 'passwordAgain'])
    await firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .set(values, {
        merge: true
      })
  } catch (ex) {
    console.warn(ex)
    dispatch({
      type: SAVING_ERROR,
      payload: ex.error
    })
    throw ex
  }

  dispatch({
    type: SAVING_END
  })
}

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET
  })
}

export const actions = {
  setForm,
  loadAll,
  loadOne,
  reset
}

const initialState = {
  saving: false,
  loading: false,
  resetPasswordLoading: false,
  items: [],
  error: null,
  form: {
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    companyName: null,
    language: null,
    phoneNumber: null,
    role: null,
    password: null,
    passwordAgain: null
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        loading: true
      }
    case LOADING_ONE_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case LOADING_END:
      return {
        ...state,
        loading: false
      }
    case LOADING_SUCCESS:
      return {
        ...state,
        items: action.items
      }
    case SET_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.form
        }
      }
    case SAVING_START:
      return {
        ...state,
        error: false,
        saving: true
      }
    case SAVING_END:
      return {
        ...state,
        saving: false
      }
    case SAVING_ERROR:
      return {
        ...state,
        saving: false,
        error: action.payload
      }
    case RESET_PASSWORD_START:
      return {
        ...state,
        resetPasswordLoading: true
      }
    case RESET_PASSWORD_END:
      return {
        ...state,
        resetPasswordLoading: false
      }
    case RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
