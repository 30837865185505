import React from 'react'
import { Menu, Dropdown } from 'antd'
import cls from 'classnames'
import INVITATION_FILTERS from 'constants/filters'

import { StyledDropdownIcon } from '../projectSelect/ProjectSelect'

import style from './ProjectFilter.module.scss'
import FilterIcon from '../icons/FilterIcon';
import { FormattedMessage } from 'react-intl'


const filterOptions = INVITATION_FILTERS

export const StyledFilterIcon = () => {
  return (
    <div className={style.filterIcon}>
      <FilterIcon />
    </div>
  )
}

const ProjectFilter = ({ onChange }) => {
  const [value, setValue] = React.useState(filterOptions[0])

  const handleChangeItem = (index) => {
    setValue(filterOptions[index])
    onChange(filterOptions[index].value)
  }

  const menu = (
    <Menu className={style.menuContainer}>
      {filterOptions.map((filter, index) => (
        <Menu.Item key={filter.value} onClick={() => handleChangeItem(index)}>
          <span className={style.label}><FormattedMessage id={`admin.memosStatsPage.filter.${filter.value}`} /></span>
        </Menu.Item>
      ))}

    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <a className={cls("ant-dropdown-link", style.menu)} onClick={e => e.preventDefault()}>
        <StyledFilterIcon /><span className={style.label}><FormattedMessage id={`admin.memosStatsPage.filter.${value.value}`} /></span> <StyledDropdownIcon />
      </a>
    </Dropdown>
  )
}

export default ProjectFilter