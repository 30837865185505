import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { useState } from 'react'

const LOADING_START = 'PROFILE/LOADING_START'
const LOADING_SUCCESS = 'PROFILE/LOADING_SUCCESS'
const LOADING_END = 'PROFILE/LOADING_END'
const SAVING_START = 'PROFILE/SAVING_START'
const SAVING_END = 'PROFILE/SAVING_END'
const RESET = 'PROFILE/RESET'
const SET_ERROR = 'PROFILE/SET_ERROR'

export const loadOne = () => async (dispatch, getState) => {
	dispatch({
		type: LOADING_START
	})
	try {
		const id = getState().session.user.uid
		const result = await firebase.firestore().collection('users').doc(id).get()
		const user = result.data()

		dispatch({
			type: LOADING_SUCCESS,
			payload: {
				data: {
					id: result.id,
					...user
				}
			}
		})
	} catch (ex) {
		setError(ex)
		throw ex
	}
	dispatch({
		type: LOADING_END
	})
}

export const submit = (values) => async (dispatch, getState) => {
	dispatch({ type: SAVING_START })
	try {
		const { user } = getState().session
		await firebase.firestore().collection('users').doc(user.uid).update(values)
	} catch (ex) {
		dispatch(setError(ex))
		throw ex
	}
	dispatch({ type: SAVING_END })
}

export const reset = () => (dispatch) => {
	dispatch({
		type: RESET
	})
}

export const setError = (error) => {
	return {
		type: SET_ERROR,
		error
	}
}

const initialState = {
	saving: false,
	error: false,
	data: {}
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case LOADING_START:
			return {
				...state,
				loading: true
			}
		case LOADING_END:
			return {
				...state,
				loading: false
			}
		case LOADING_SUCCESS:
			return {
				...state,
				...action.payload
			}
		case SAVING_START:
			return {
				...state,
				saving: true
			}
		case SAVING_END:
			return {
				...state,
				saving: false
			}
		case SET_ERROR:
			return {
				...state,
				saving: false,
				loading: false,
				error: action.error
			}
		case RESET:
			return {
				...initialState
			}
		default:
			return state
	}
}
