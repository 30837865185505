import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/fr'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'
// import 'antd/dist/antd.less'
// import './theme.less'
import './fonts/icomoon/style.css'
import './fonts/public-sans/style.css'
import './index.css'
import 'video.js/dist/video-js.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import { AppContainer } from 'react-hot-loader'
import configureStore from './store'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

var config = {}

const { REACT_APP_FIRESTORE_EMULATOR_HOST, NODE_ENV, FIREBASE_APP_CFG } = process.env

async function execute() {
  if (!FIREBASE_APP_CFG) {
    /*
    Now what, well, get the config from here
    https://console.firebase.google.com/project/_/settings/general/web
    and make it a json then place it in this folder named as firebase-config.json
    */
    let fbCfg = await import('./firebase-config.json')
    config = fbCfg
  } else {
    config = JSON.parse(decodeURIComponent(FIREBASE_APP_CFG))
  }

  firebase.initializeApp(config)

  // if (REACT_APP_FIRESTORE_EMULATOR_HOST)
  //   firebase.firestore().settings({
  //     host: REACT_APP_FIRESTORE_EMULATOR_HOST,
  //     ssl: false
  //   })

  const store = configureStore()

  const render = () => {
    ReactDOM.render(
      <AppContainer>
        <Provider store={store}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </Provider>
      </AppContainer>
      , document.getElementById('root'))
  }

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  // populateFakeData()
  serviceWorker.unregister();
  render()

  // Webpack Hot Module Replacement API
  if (module.hot) {
    module.hot.accept('./App', () => {
      render()
    })
  }
}

execute()
