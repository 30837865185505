import React from "react";

const AttachmentIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.25 8.25L0.5 12V15.25H15.5V3.5L7.5 11.5L4.25 8.25Z" fill="#1A202C" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.3333 1.33333H2.66667C1.93029 1.33333 1.33333 1.93029 1.33333 2.66667V13.3333C1.33333 14.0697 1.93029 14.6667 2.66667 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V2.66667C14.6667 1.93029 14.0697 1.33333 13.3333 1.33333ZM2.66667 0C1.19391 0 0 1.19391 0 2.66667V13.3333C0 14.8061 1.19391 16 2.66667 16H13.3333C14.8061 16 16 14.8061 16 13.3333V2.66667C16 1.19391 14.8061 0 13.3333 0H2.66667Z" fill="#1A202C" />
    <circle cx="6.5" cy="4.5" r="2" fill="#1A202C" />
  </svg>

);

export default AttachmentIcon;