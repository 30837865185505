import axios from 'axios'
import { changeLocale } from 'components/language/reducer'
import firebase from 'firebase/app'
import 'firebase/firestore'

const LOADING_START = 'SESSION/LOADING_START'
const LOADING_SUCCESS = 'SESSION/LOADING_SUCCESS'
const LOADING_END = 'SESSION/LOADING_END'
const RESET = 'SESSION/RESET'
const LOADING_ERROR = 'SESSION/LOADING_ERROR'
const INIT_USER = 'SESSION/INIT_USER'
const UPDATE_FIELDS = 'SESSION/UPDATE_FIELDS'
const SET_TOKEN = 'SESSION/SET_TOKEN'
const SET_MEMO_DATA = 'SESSION/SET_MEMO_DATA'

export const load = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_START })
  dispatch({ type: LOADING_END })
}

export const logOut = () => async (dispatch, getState) => {
  await firebase.auth().signOut()
  dispatch(initUser(null))
}

export const updateFields = (fields) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_FIELDS,
    fields
  })
}

export const initUser = (user) => async (dispatch, getState) => {
  try {
    if (user) {
      const result = await firebase.firestore().collection('users').doc(user.uid).get()
      const { claims } = await firebase.auth().currentUser.getIdTokenResult()
      const data = result.data()

      user = {
        uid: user.uid,
        isAnonymous: user.isAnonymous,
        claims,
        ...data,
      }
    }

    dispatch({
      type: INIT_USER,
      user
    })
    dispatch(changeLocale(user.language || 'fr'))
  } catch (ex) {
    console.warn(ex)
  }
}

export const reset = () => async (dispatch, getState) => {
  dispatch({
    type: RESET
  })
}

export const setIndividualToken = (token) => async (dispatch, getState) => {
  dispatch({
    type: SET_TOKEN,
    token
  })
}

export const setMemoData = (data) => async (dispatch, getState) => {
  dispatch({
    type: SET_MEMO_DATA,
    data
  })
}

export const actions = {
  load,
  reset,
  initUser
}

const initialState = {
  user: null,
  error: false,
  loading: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case INIT_USER:
      return {
        ...state,
        user: action.user
      }
    case LOADING_START:
      return {
        ...state,
        error: false,
        loading: true
      }
    case LOADING_END:
      return {
        ...state,
        loading: false
      }
    case LOADING_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case LOADING_ERROR:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_FIELDS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.fields
        }
      }
    case SET_TOKEN:
      return {
        ...state,
        token: action.token
      }
    case SET_MEMO_DATA:
      return {
        ...state,
        memoData: action.data
      }
    case RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
