import React from "react";

const LeftArrowIcon = (props) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M22.5 13.501C23.0523 13.501 23.5 13.0533 23.5 12.501C23.5 11.9487 23.0523 11.501 22.5 11.501V13.501ZM1.79289 11.7939C1.40237 12.1844 1.40237 12.8176 1.79289 13.2081L8.15685 19.572C8.54738 19.9626 9.18054 19.9626 9.57107 19.572C9.96159 19.1815 9.96159 18.5484 9.57107 18.1578L3.91421 12.501L9.57107 6.84412C9.96159 6.4536 9.96159 5.82043 9.57107 5.42991C9.18054 5.03938 8.54738 5.03938 8.15685 5.42991L1.79289 11.7939ZM22.5 11.501H2.5V13.501H22.5V11.501Z" fill="black" />
 </svg>
);

export default LeftArrowIcon;




