import React, { memo, useCallback, useEffect } from 'react'
import style from './LanguageSwitch.module.scss'
import cls from 'classnames'
import { changeLocale,selectLocale } from './reducer'
import { useDispatch, useSelector } from 'react-redux'

export const LanguageSwitch = memo(() => {
	const dispatch = useDispatch()
	const language = useSelector(s => s.language)
	const session = useSelector(s => s.session)

	const onChange = useCallback((value) => {
		dispatch(selectLocale(value))
	}, [])

	useEffect(() => {
    if (session.user && session.user.language) {
			dispatch(changeLocale(session.user.language))
		}
	}, [session, language.localeSelected])

	return (
		<div className={style.component}>
			<span onClick={() => onChange('en')} className={cls({ [style.active]: language.locale === 'en' })}>EN</span>
			{' / '}
			<span onClick={() => onChange('fr')} className={cls({ [style.active]: language.locale === 'fr' })}>FR</span>
		</div>
	)
})

export default LanguageSwitch