import React from "react"
import style from "../../MemosFormPage.module.scss"
import linkStyle from "./RichEditor.module.scss"
import {Form, Input} from "antd"
import {useIntl} from "react-intl"

const LinkInput = ({positionTop, bottomMode, initialData, setLinkData, close}) => {

  const [antdForm] = Form.useForm()
  const intl = useIntl()

  return (
    <div>
      <div className={linkStyle.linkInputOverlay} onClick={() => {
        setLinkData({
          text: antdForm.getFieldValue('text'),
          url: antdForm.getFieldValue('url')
        })
        close()
      }}/>
      <div>
      <div
        className={linkStyle.linkInput}
        style={{
          top: positionTop, transform: bottomMode ? "translate(0, 25px)" : "translate(0, calc(-100% - 5px))"
        }}
      >
        <Form
          initialValues={initialData}
          autoComplete="off"
          form={antdForm}
          onSubmitCapture={() => {
            setLinkData({
              text: antdForm.getFieldValue('text'),
              url: antdForm.getFieldValue('url')
            })
            close()
          }}
          validateMessages={{
            required: intl.formatMessage({ id: 'validation.required' })
          }}
          onKeyPress={(e) => {if (e.key === "Enter") close()}}
        >
          <Form.Item
            labelCol={{ xs: 24 }}
            label={intl.formatMessage({ id: 'admin.memosFormPage.fields.linkText' })}
            className={linkStyle.linkInputItem}
            name='text'>
            <Input
              onChange={(() => {setLinkData({
                text: antdForm.getFieldValue('text'),
                url: antdForm.getFieldValue('url')
              })})}
              placeholder={intl.formatMessage({ id: 'admin.memosFormPage.placeholders.linkText' })}
              className={style.input} />
          </Form.Item>
          <Form.Item
            labelCol={{ xs: 24 }}
            label={intl.formatMessage({ id: 'admin.memosFormPage.fields.linkUrl' })}
            className={linkStyle.linkInputItem}
            name='url'>
            <Input
              onChange={(() => {setLinkData({
                text: antdForm.getFieldValue('text'),
                url: antdForm.getFieldValue('url')
              })})}
              placeholder={intl.formatMessage({ id: 'admin.memosFormPage.placeholders.linkUrl' })}
              className={style.input} />
          </Form.Item>
        </Form>
      </div>
      </div>
    </div>
  )
}

export default LinkInput