import React from "react";

const ContactsIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="8.25" cy="7.5" r="4.5" fill="currentColor" />
    <path d="M8.25 12.75C4.10786 12.75 0.75 16.1079 0.75 20.25H15.75C15.75 16.1079 12.3921 12.75 8.25 12.75Z" fill="currentColor" />
    <circle cx="17.5435" cy="9.32609" r="3.32609" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.364 18.7495C16.0561 17.0732 15.2415 15.5742 14.0848 14.4171C15.0329 13.6592 16.2353 13.2061 17.5435 13.2061C20.6051 13.2061 23.087 15.688 23.087 18.7495H16.364Z" fill="currentColor" />
  </svg>

);

export default ContactsIcon;