import { MEMO_STEPS } from "./MemoStepper/MemoStepper";

const RESET = "APP_HEADER/RESET";
const SET_STEPS = "APP_HEADER/SET_STEPS";
const SHOW_STEPS = "APP_HEADER/SHOW_STEPS";
const HIDE_STEPS = "APP_HEADER/HIDE_STEPS";
const SET_CURRENT_STEP = "APP_HEADER/SET_CURRENT_STEP";

export const reset = () => async (dispatch, getState) => {
  dispatch({
    type: RESET,
  });
};

export const showSteps = () => async (dispatch, getState) => {
  dispatch({
    type: SHOW_STEPS,
  });
};

export const hideSteps = () => async (dispatch, getState) => {
  dispatch({
    type: HIDE_STEPS,
  });
};

export const setCurrentStep = (payload) => async (dispatch, getState) => {
  dispatch({
    type: SET_CURRENT_STEP,
    payload
  });
};

export const setSteps = (havingQuestions) => async (dispatch, getState) => {
  dispatch({
    type: SET_STEPS,
    payload: havingQuestions
      ? [
        MEMO_STEPS.WELCOME,
        MEMO_STEPS.WATCH,
        MEMO_STEPS.ANSWER,
        MEMO_STEPS.COMPLETE,
      ]
      : [
        MEMO_STEPS.WELCOME,
        MEMO_STEPS.WATCH,
        MEMO_STEPS.COMPLETE,
      ],
  });
};

export const actions = {
  reset,
};

export const initialState = {
  showStepper: false,
  steps: [],
  currentStep: 1
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET:
      return {
        ...initialState,
      };
    case SET_STEPS:
      return {
        ...state,
        steps: action.payload,
        currentStep: MEMO_STEPS.WELCOME
      };
    case SHOW_STEPS:
      return {
        ...state,
        showStepper: true
      };
    case HIDE_STEPS:
      return {
        ...state,
        showStepper: false
      };
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload
      };
    default:
      return state;
  }
}
