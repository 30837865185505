import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import style from './UserItems.module.scss'
import cls from 'classnames'

export const UserRole = ({ className }) => {
  const { user } = useSelector(s => s.session)
  return (
    <span className={cls(style.role, className)}>
      <FormattedMessage id={`profile.role.${user.role}`} />
    </span>
  )
}

export const UserName = ({ className }) => {
  const { user } = useSelector(s => s.session)
  return (
    <span className={cls(style.name, className)}>
      {user.firstName} {user.lastName}
    </span>
  )
}