import { useEffect } from "react"
import _ from "lodash"
import { useSelector } from "react-redux"
import { SUBMISSION_STATUS } from "pages/individual/memo/reducer"
import { useIntl } from "react-intl"

const useSaveMemoUnload = () => {
  
  const intl = useIntl()
  const { form, loading } = useSelector((s) => s.individualMemo)
  const status = _.get(form, "submission.status")
  const completed =
    [SUBMISSION_STATUS.FAILED, SUBMISSION_STATUS.COMPLETED].indexOf(status) >
    -1

  useEffect(() => {
    const pageUnloadHandler = (e) => {

      if (!completed) {
        e.preventDefault()
        e.returnValue = intl.formatMessage({ id: "general.warningBeforeCloseTab" })
      }
    }
    window.addEventListener("beforeunload", pageUnloadHandler)

    return () => window.removeEventListener("beforeunload", pageUnloadHandler)
  }, [completed])
}

export default useSaveMemoUnload
