import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import style from './UrlField.module.scss'
import cls from 'classnames'
import { Button, Input, Spin, Upload } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import { debounce } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEmbed, uploadFile, setThumbnail, setThumbnailCandidates, reset } from './reducer'
import EmbedProvider from './EmbedProvider'
import RecordButton from './RecordButton'
import _ from 'lodash'
import VideoSnapshot from 'video-snapshot'
import Icon from 'components/Icon'
import { PROVIDER } from 'components/embedPlayer/EmbedPlayer'
import Avatar from 'pages/profile/Avatar'
import getBlobDuration from 'get-blob-duration'
import { v4 as uuidv4 } from 'uuid'
import firebase from 'firebase/app'
import { THUMBNAIL, fetchThumbnail, getThumbnail } from 'utils'
import { setVideoDuration } from './reducer'

const { REACT_APP_AUTO_GENERATE_THUMBNAIL_DISABLED } = process.env

export const Thumbnail = ({ value, onChange, onLoadingChange }) => {
  return (
    <Avatar
      value={value}
      className={style.thumbnail}
      onChange={onChange}
      onLoadingChange={onLoadingChange} />
  )
}

export default function UrlField({ onChange, value, className, inputClassName, readOnly, memoCode }) {
  const intl = useIntl()
  const dispatch = useDispatch()
  const url = _.get(value, 'url')

  const { embed, uploading } = useSelector(s => s.memosFormUrl)
  const [uploadedThumbnail, setUploadedThumbnail] = useState(null)
  const { user } = useSelector(s => s.session)
  const provider = _.get(embed, 'provider_name')
  const thumbnail = _.get(embed, 'provider_thumbnail') || _.get(embed, 'thumbnail_url')
  const thumbnailCandidates = _.get(embed, 'thumbnail_candidates')

  const getThumbnails = async (url) => {
    try {

      let blob = await fetch(url).then(r => r.blob());

      const duration = await getBlobDuration(blob)
      dispatch(setVideoDuration(duration))

      const snapshoter = new VideoSnapshot(blob);
      const previewStartBlob = await snapshoter.takeSnapshot(0);
      const previewMiddleBlob = await snapshoter.takeSnapshot(duration / 2);
      const previewEndBlob = await snapshoter.takeSnapshot(Math.floor(duration));

      const previewStart = await uploadScreenshot(previewStartBlob);
      const previewMiddle = await uploadScreenshot(previewMiddleBlob);
      const previewEnd = await uploadScreenshot(previewEndBlob);
      dispatch(setThumbnailCandidates([previewStart, previewMiddle, previewEnd]))
    }
    catch (error) {
      console.warn("error", error)
    }
  }

  const uploadProps = useMemo(() => {
    return {
      accept: '.mp4',
      showUploadList: false,
      multiple: false,
      customRequest: async (data) => {
        try {
          const uploadedResult = await dispatch(uploadFile(data.file))
          if (uploadedResult && uploadedResult.url) {
            getThumbnails(uploadedResult.url)
          }
        } catch (ex) {
          console.warn(ex)
        }
      }
    }
  }, [])

  const onRecord = useCallback(async (file) => {
    try {
      const uploadedResult = await dispatch(uploadFile(file))
      if (uploadedResult && uploadedResult.url) {
        getThumbnails(uploadedResult.url)
      }
    } catch (ex) {
      console.warn(ex)
    }
  }, [])

  const fetchData = useCallback(debounce(async (url) => {
    if (!url)
      return dispatch(reset())
    try {
      await dispatch(fetchEmbed(url))
    } catch (ex) {
      console.warn(ex)
    }
  }, 250), [])

  const onThumbnailUpload = useCallback((data) => {
    dispatch(setThumbnail(data))
    setUploadedThumbnail(data)
  }, [])

  const onThumbnailLoadingChange = useCallback(() => {

  }, [])
  const onInputChange = useCallback((e) => {
    fetchData(e.target.value)
  }, [])

  useEffect(() => {
    return () => dispatch(reset())
  }, [])

  useEffect(() => {
    if (embed)
      onChange({
        embed,
        url: embed.url
      })
  }, [embed])

  const uploadScreenshot = async (blobData) => {
    try {
      const blob = await fetch(blobData).then(r => r.blob());
      const file = new File([blob], "screenshot.png", { type: "image/png", lastModified: new Date() })
      const id = uuidv4()
      const path = `users/${user.uid}`
      const result = await firebase.storage().ref(path).child(id).put(file)
      const thumbnailResult = await fetchThumbnail(path, id, THUMBNAIL.MEDIUM)
      const url = await result.ref.getDownloadURL()
      return ({ url, thumbnailResult })
    }
    catch (error) {
      console.warn(error)
      return ({
        url: '',
        thumbnail: ''
      })
    }
  }

  React.useEffect(() => {
    if (url && (!thumbnailCandidates || (thumbnailCandidates && thumbnailCandidates.length < 1))) {
      getThumbnails(url)
    }
  }, [url, thumbnailCandidates])

  const handleClickScreenshot = (clickedThumbnail) => {
    dispatch(setThumbnail(clickedThumbnail))
    setUploadedThumbnail(null)
  }

  return (
    <div className={cls(style.component, className)}>
      <EmbedProvider isSkippable downloadable memoCode={memoCode} />
      {provider === PROVIDER.FIREBASE_STORAGE && (
        <div className={style.thumbnailField}>
          <div className={style.thumbnailLabel}>
            <FormattedMessage id='admin.memosFormPage.fields.thumbnail' />
          </div>
          <div className={style.thumbnails}>
            {thumbnailCandidates && thumbnailCandidates.length ? thumbnailCandidates.map(((screenshot, index) => (
              <div key={index} className={cls(style.screenshotContainer, { selected: thumbnail && screenshot && getThumbnail(thumbnail) === getThumbnail(screenshot) })} onClick={() => handleClickScreenshot(screenshot)} >
                <img src={getThumbnail(screenshot)} width="80px" height="80px" />
              </div>
            )
            )) : <div style={{ marginRight: '4px' }}><Spin /></div>}
            <div className={cls(style.screenshotContainer, { selected: thumbnail && uploadedThumbnail && getThumbnail(thumbnail) === getThumbnail(uploadedThumbnail) })}>
              <Thumbnail
                value={uploadedThumbnail}
                onChange={onThumbnailUpload}
                onLoadingChange={onThumbnailLoadingChange} />
            </div>
          </div>
        </div>
      )}
      {!readOnly && (
        <>
          <Input
            onChange={onInputChange}
            disabled
            value={url}
            placeholder={intl.formatMessage({ id: 'admin.memosFormPage.placeholders.url' })}
            className={cls(style.input, inputClassName)} />
          <div className={style.buttons}>
            <Upload {...uploadProps}>
              <Button className={style.uploadButton} loading={uploading}>
                <Icon name='image' className={style.uploadIcon} small /> <FormattedMessage id='admin.memosFormPage.upload' />
              </Button>
            </Upload>
            <RecordButton
              className={style.recordButton}
              onRecord={onRecord} />
          </div>
        </>
      )}
    </div>
  )
}
