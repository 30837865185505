import React, { useCallback, useEffect } from 'react'
import style from './MemoQuestions.module.scss'
import _ from 'lodash'
import cls from 'classnames'
import { PageTitle } from 'components/PageItems'
import { useSelector } from 'react-redux'
import { Form, Radio } from 'antd'

export default function MemoQuestions({ questions, onFieldsChange, className }) {
  const [antForm] = Form.useForm()
  const { form } = useSelector(s => s.individualMemo)

  useEffect(() => {
    const answers = _.get(form, 'submission.answers')
    if (answers)
      antForm.setFieldsValue(answers)
    else
      antForm.resetFields()
  }, [form.submission])

  return (
    <Form form={antForm} onFieldsChange={onFieldsChange} className={cls(style.container, className)} autoComplete="off">
      {questions.map(q => {
        const { name, config } = q
        return (
          <div className={style.question} key={q.id}>
            <PageTitle variant="h3" className={style.title}>
              {name}
            </PageTitle>
            <Form.Item name={q.id} className={style.formItem}>
              <Radio.Group className={style.options}>
                {config.options.map(item => {
                  return (
                    <Radio key={item.id} value={item.id} className={style.option}>
                      <p className={style.label}>
                        {item.title}
                      </p>
                    </Radio>
                  )
                })}
              </Radio.Group>
            </Form.Item>
          </div>
        )
      })}
    </Form>
  )
}