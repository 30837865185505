import React, { memo, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './TeamsPage.module.scss'
import { Table, Button } from 'antd'
import { loadAll, reset } from './reducer'
import _ from 'lodash'
import { Switch, Route, Redirect, useRouteMatch, useHistory, Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { getThumbnail } from 'utils'

const CONFIG = {
  DEFAULT: {
    createUrl: '/app/teams/new',
    editUrl: '/app/teams',
    title: 'admin.teams.title'
  }
}

export const TeamsPage = () => {
  const config = CONFIG.DEFAULT
  const match = useRouteMatch()
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const { items } = useSelector(state => state.adminTeams)
  const createColumns = useCallback(() => {
    return [{
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    }, {
      title: 'Logo',
      dataIndex: 'logo',
      key: 'logo',
      render(value) {
        return <img height={40} src={getThumbnail(value)} />
      }
    }, {
      title: 'Shortcode URL',
      dataIndex: 'shortcode',
      key: 'shortcode'
    }]
  }, [])

  const onRowClick = useCallback(row => {
    history.push(config.editUrl + '/' + row.id)
  }, [])
  const onChange = useCallback((pagination, filters, sorter) => {
    dispatch(loadAll({
      pagination,
      sorter
    }))
  }, [])

  useEffect(() => {
    dispatch(loadAll())
    return () => dispatch(reset())
  }, [match.path])

  return (
    <div className={style.component}>
      <div className={style.content}>
        <header className={style.header}>
          <h1>
            {intl.formatMessage({ id: config.title })}
          </h1>
          <Link to={config.createUrl} className={style.button}>
            Create
					</Link>
        </header>
        <Table
          rowKey="id"
          pagination={false}
          onChange={onChange}
          onRow={(record) => ({
            onClick: () => onRowClick(record)
          })}
          columns={createColumns()}
          dataSource={items} />
      </div>
    </div>
  )
}

export default memo(TeamsPage)