import React, { useCallback } from 'react'
import { Button } from 'antd'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import style from './Footer.module.scss'
import cls from 'classnames'

const Footer = ({ className, onSubmit, onDelete, hideDelete = false }) => {
  const { saving, form } = useSelector(s => s.memos)
  const { user } = useSelector(s => s.session)
  const { params } = useRouteMatch()
  const isEditing = !!params.id
  const intl = useIntl()
  const isMine = !form.userId || form.userId === user.uid

  return (
    <footer className={cls(style.component, className)}>
      {isMine && (
        <Button
          className={style.submitButton}
          onClick={onSubmit}
          loading={saving}
          type='primary'
          htmlType='submit'>
          {intl.formatMessage({
            id: `admin.memosFormPage.buttons.${isEditing ? 'update' : 'create'}`
          })}
        </Button>
      )}
      {isMine && isEditing && !hideDelete && (
        <Button
          danger
          className={style.deleteButton}
          onClick={onDelete}>
          {intl.formatMessage({
            id: 'admin.memosFormPage.buttons.delete'
          })}
        </Button>
      )}
    </footer>
  )
}

export default Footer