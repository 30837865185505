import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import style from './PageItems.module.scss'
import cls from 'classnames'

export const PageTitle = ({ className, large, big, med, small, variant = 'h4', type, children, ...others }) => {
  return (
    <h3 className={cls(style.title, {
      [style.h1]: variant === 'h1',
      [style.h2]: variant === 'h2',
      [style.h3]: variant === 'h3',
      [style.h4]: variant === 'h4',
      [style.h5]: variant === 'h5',
      [style.overline]: variant === 'overline',
      [style.medium]: type === 'medium',
      [style.regular]: type === 'regular',
      [style.underline]: type === 'underline',
    }, className)} {...others}>
      {children}
    </h3>
  )
}