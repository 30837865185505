import React, { memo } from 'react'
import { Select } from 'antd'
import { FormattedMessage } from 'react-intl'

export default memo(({ value, onChange, className }) => {
  return (
    <Select onChange={onChange} value={value} className={className}>
      <Select.Option value='en'>
        <FormattedMessage id='languages.en' />
      </Select.Option>
      <Select.Option value='fr'>
        <FormattedMessage id='languages.fr' />
      </Select.Option>
    </Select>
  )
})