import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./StatsPage.module.scss";
import { Button } from "antd";
import _ from "lodash";
import { useParams, Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { PageTitle } from "components/PageItems";
import {
  STATUS,
  STATUS_NAMES,
  loadAll,
  reset,
  remindAll,
} from "./reducer";
import Icon from "components/Icon";
import cls from "classnames";
import { openShareModal } from "../share/reducer";
import { loadAll as loadAllContacts } from "../../contacts/reducer";
import InitialSharingCard from "../share/InitialSharingCard";
import moment from "moment";
import { CSVLink } from "react-csv";
import ProjectFilter from "components/projectFilter/ProjectFilter";
import { EMAIL_ASC, EMAIL_DESC, NAME_ASC, NAME_DESC } from "constants/filters";
import StatsRecipientsList from "./StatsRecipientsList";

const Counter = ({ status, icon }) => {
  const className = STATUS_NAMES[status] + "Counter";
  const { counters } = useSelector((s) => s.memoStats);
  const value = counters[status];

  return (
    <div className={cls(style.counter, style[className])}>
      <Icon name={icon} className={style.icon} large />
      <span className={style.text}>
        <FormattedMessage
          id={`admin.memosStatsPage.counter.${STATUS_NAMES[status]}`}
          values={{
            count: <b>{value}</b>,
          }}
        />
      </span>
    </div>
  );
};

export const StatsPage = () => {
  const headers = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Email", key: "email" },
    { label: "Status of memo", key: "status" },
    { label: "Date completed", key: "completedDate" },
    { label: "Retry", key: "retries" },
  ];

  const params = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { memo, items, reminding, invitationIds } = useSelector(
    (s) => s.memoStats
  );
  const { items: contactsList } = useSelector((state) => state.contacts);

  const [exportData, setExportData] = useState([]);
  const thumbnail = _.get(memo, "url.embed.thumbnail_url");
  const thumbnailId = _.get(memo, "url.embed.thumbnailId");
  let thumbnailCls = null;
  if (!thumbnail && thumbnailId) thumbnailCls = `thumbnail-${thumbnailId + 1}`;

  const [invitations, setInvitations] = React.useState(items);
  const [currentFilter, setCurrentFilter] = React.useState(EMAIL_ASC);

  const onShareClick = useCallback(() => {
    dispatch(
      openShareModal({
        data: memo,
      })
    );
  }, [memo]);

  const onRemind = useCallback(() => {
    dispatch(loadAll(params.id, params.pid));
  }, [params]);

  const handleRemindAll = async () => {
    const tagetInvitations = invitations.filter(
      (invitation) =>
        invitation.status !== STATUS.COMPLETED &&
        invitation.status !== STATUS.SENDING
    );

    await dispatch(remindAll(tagetInvitations));
    dispatch(loadAll(params.id, params.pid));
  };

  useEffect(() => {
    dispatch(loadAll(params.id, params.pid));
    dispatch(loadAllContacts());

    return () => dispatch(reset());
  }, []);

  useEffect(() => {
    if (items && items.length) {
      const data = items.map((item) => {
        const currentContact = contactsList.find(
          (contact) => contact.email === item.email
        );

        return {
          firstName: currentContact ? currentContact.firstName : "",
          lastName: currentContact ? currentContact.lastName : "",
          email: item.email,
          status: intl.formatMessage({
            id: `admin.memosStatsPage.status.${STATUS_NAMES[item.firstEverCompleted ? STATUS.COMPLETED : item.status]}`,
          }),
          completedDate:
            item.status === STATUS.COMPLETED || item.firstEverCompleted
              ? moment.unix(item.completedAt.seconds).format("YYYY/MM/DD")
              : "",
          retries: item.retries,
        };
      });
      setExportData(data);
    }
  }, [items]);

  useEffect(() => {
    if (items && items.length) {
      if (currentFilter === EMAIL_ASC) {
        setInvitations(
          _.orderBy(items, [(item) => item.email.toLowerCase()], ["asc"])
        );
        return;
      }
      if (currentFilter === EMAIL_DESC) {
        setInvitations(
          _.orderBy(items, [(item) => item.email.toLowerCase()], ["desc"])
        );
        return;
      }
      if (currentFilter === NAME_ASC) {
        setInvitations(
          _.orderBy(
            items,
            [(item) => (item.lastName || "").toLowerCase()],
            ["asc"]
          )
        );
        return;
      }
      if (currentFilter === NAME_DESC) {
        setInvitations(
          _.orderBy(
            items,
            [(item) => (item.lastName || "").toLowerCase()],
            ["desc"]
          )
        );
        return;
      }
    }
  }, [items, currentFilter]);

  const invitationsWithContacts = React.useMemo(() => {
    return invitations.map((invitation) => {
      const currentContact = contactsList.find(
        (contact) => contact.email === invitation.email
      );
      return {
        ...invitation,
        firstName: currentContact ? currentContact.firstName : "",
        lastName: currentContact ? currentContact.lastName : "",
      };
    });
  }, [invitations, contactsList]);

  const exportCSVFileName = useMemo(() => {
    return `${memo.projectId}-${memo.id}_${moment().format("MM_DD_YYYY")}.CSV`;
  }, [memo]);

  const handleChangeFilter = (filterValue) => {
    setCurrentFilter(filterValue);
  };

  const renderProject = React.useCallback(() => {
    return (
      <>
        {memo.projectId && memo.id && (
          <div className={style.projectContainer}>
            <div className={style.project}>
              {memo.projectId}-{memo.id}
            </div>
          </div>
        )}
      </>
    );
  }, [memo.projectId, memo.id]);

  return (
    <div className={style.component}>
      <div className={style.info}>
        <div className={style.memo}>
          <div
            className={cls(style.bg, thumbnailCls)}
            style={
              thumbnail && {
                backgroundImage: `url("${thumbnail}")`,
              }
            }
          >
            <Link
              className={style.button}
              to={`/app/projects/${params.pid}/memos/${params.id}/view`}
            >
              <FormattedMessage id="admin.memosStatsPage.viewMemo" />
            </Link>
          </div>
          <footer className={style.footer}>
            <h4 className={style.title}>{memo.name}</h4>
            {renderProject()}
          </footer>
        </div>
        <Counter icon="eye" status={STATUS.SENT} />
        <Counter icon="refresh" status={STATUS.OPENED} />
        <Counter icon="complete" status={STATUS.COMPLETED} />
      </div>
      <header className={style.header}>
        <PageTitle variant="h1">
          <FormattedMessage id="admin.memosStatsPage.listTitle" />
        </PageTitle>
        <div className={style.actionHeader}>
          <Button
            loading={reminding && invitationIds && invitationIds.length === 0}
            type="primary"
            className={style.remindAllButton}
            onClick={handleRemindAll}
          >
            <Icon name="notify" className={style.icon} />
            <FormattedMessage id="admin.memosStatsPage.remindAll" />
          </Button>
          <div className={style.filter}>
            <ProjectFilter onChange={handleChangeFilter} />
          </div>
          {invitationsWithContacts && invitationsWithContacts.length > 0 && (
            <CSVLink
              className={style.csvButton}
              data={exportData}
              headers={headers}
              filename={exportCSVFileName}
            >
              <FormattedMessage id="admin.memosStatsPage.exportCSV" />
            </CSVLink>
          )}
        </div>
      </header>
      <div className={style.items}>
        {invitationsWithContacts.length === 0 && (
          <div>
            <span className={style.noInvitations}>
              <FormattedMessage id="admin.memosStatsPage.noInvitations" />
            </span>
            <Button className={style.shareButton} onClick={onShareClick}>
              <FormattedMessage id="admin.memosStatsPage.share" />
            </Button>
          </div>
        )}
        <StatsRecipientsList itemsPerPage={20} items={invitationsWithContacts} />
      </div>
      <InitialSharingCard />
    </div>
  );
};

export default memo(StatsPage);
