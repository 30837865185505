import React from 'react'
import { Router } from 'react-router-dom'
import style from './App.module.scss'
import ScrollToTop from './ScrollToTop'
import firebase from 'firebase/app'
import 'firebase/auth'
import { actions } from 'session/reducer'
import { connect } from 'react-redux'
import AdminPage from 'pages/admin/AdminPage'
import PublicPage from 'pages/public/PublicPage'
import IntlProvider from './intl'
import history from './history'
class App extends React.Component {
  state = {
    initialized: false
  }

  async componentDidMount() {
    await this.init()
  }

  async init() {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user)
        await this.props.initUser(user)

      this.setState({ initialized: true })
    })
  }

  render() {
    const { user, token } = this.props.state.session
    const { initialized } = this.state

    if (!initialized)
      return null

    return (
      <Router history={history}>
        <IntlProvider>
          <ScrollToTop>
            {(() => {
              if (!user || user.claims.role === 'user')
                return <PublicPage />
              return <AdminPage />
            })()}
          </ScrollToTop>
        </IntlProvider>
      </Router>
    )
  }
}

export default connect(state => ({ state }), actions)(App)
