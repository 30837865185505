import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import firebase from "firebase/app";
import "firebase/auth";
import _ from "lodash";
import cls from "classnames";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router";
import EmbedPlayer from "components/embedPlayer/EmbedPlayer";
import DummyPlayer from "components/contentStructure/DummyPlayer";
import { loadOne, resetSubmission, SUBMISSION_STATUS } from "./reducer";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card } from "antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { PageTitle } from "components/PageItems";
import MemoQuestions from "./MemoQuestions";
import MemoFooter from "components/footer/MemoFooter";
import Profile from "components/header/Profile";
import style from "./MemoPage.module.scss";
import { useMediaQuery } from "react-responsive";
import StickyNotificaton from "components/notifications/StickyNotification";
import ShowQuestions from "./ShowQuestions";
import DEVICE_WIDTH from "constants/mediaQueries";
import useSaveMemoUnload from "hooks/useSaveMomoUnload";
import MemoFooterIN from "./MemoFooterIN";
import { updateFields } from "session/reducer";
import { changeLocale } from "components/language/reducer";
import LoadingMemoAnimation from "components/animations/LoadingMemoAnimation";
import { memo } from "react";
import { setCurrentStep, showSteps } from "components/appHeader/reducer";
import { MEMO_STEPS } from "components/appHeader/MemoStepper/MemoStepper";
import { setMemoPassedVideo } from "./reducer";

const MemoPage = ({ baseUrl, className }) => {
  const intl = useIntl();
  useSaveMemoUnload();
  const history = useHistory();
  const params = useParams();
  const isMobile = useMediaQuery({ maxWidth: DEVICE_WIDTH.XS });
  const questionsRef = useRef(null);
  const [showQuestions, setShowQuestions] = React.useState(false);
  const dispatch = useDispatch();
  const language = useSelector((s) => s.language);
  const [answers, setAnswers] = useState({});
  const [ended, setEnded] = useState(false);
  const { form, loading, contact } = useSelector((s) => s.individualMemo);
  const { showStepper } = useSelector((s) => s.appHeader);
  const { questions = [] } = form;
  const embed = _.get(form, "url.embed");
  const thumbnailUrl = _.get(embed, "thumbnail_url");
  const thumbnailUrlId = _.get(embed, "thumbnailId");

  const [profile, setProfile] = React.useState({
    name: "",
    avatar: "",
    team: "",
    project: "",
  });

  const [udpated, setUpdated] = useState(false);
  const status = _.get(form, "submission.status");
  const { user, token, memoData } = useSelector((s) => s.session);

  const isInteractive = React.useMemo(
    () =>
      (!!user.firstName && !!user.lastName) ||
      (contact &&
        !!contact.firstName &&
        !!contact.lastName &&
        contact.isVerified) ||
      _.get(form, "submission.id") ||
      form.passedVideo,
    [user, form]
  );
  const completed = [
    SUBMISSION_STATUS.FAILED,
    SUBMISSION_STATUS.COMPLETED,
  ].includes(status);

  const onEnded = useCallback(() => {
    setEnded(true);
    dispatch(setMemoPassedVideo());
  }, []);

  React.useEffect(() => {
    dispatch(setCurrentStep(MEMO_STEPS.WELCOME));
    dispatch(showSteps());
  }, []);

  React.useEffect(() => {
    if (isInteractive) {
      dispatch(setCurrentStep(MEMO_STEPS.WATCH));
    }

    if (showQuestions) {
      if (questions && questions.length > 0) {
        dispatch(setCurrentStep(MEMO_STEPS.ANSWER));
      } else {
        dispatch(setCurrentStep(MEMO_STEPS.WATCH));
      }
    }

    if (completed) {
      if (status === SUBMISSION_STATUS.COMPLETED) {
        dispatch(setCurrentStep(MEMO_STEPS.COMPLETE));
      } else {
        dispatch(setCurrentStep(MEMO_STEPS.ANSWER));
      }
    }
  }, [isInteractive, completed, showQuestions, questions]);

  const onResetAnswers = useCallback(() => {
    setAnswers({});
    dispatch(resetSubmission());
  }, []);

  const onAnswersChange = useCallback(
    (fields) => {
      fields.forEach((f) => {
        setAnswers({
          ...answers,
          [f.name[0]]: f.value,
        });
      });
    },
    [answers]
  );

  useEffect(() => {
    if (!form.id && token) {
      dispatch(loadOne({
        teamId: memoData.teamId, projectId: memoData.projectId, memoId: memoData.memoId, token: params.token
      }));
    }
  }, [
    dispatch,
    memoData,
    params.token,
    user,
    token,
    form.id,
  ]);

  useEffect(() => {
    if (form && form.language && !language.localeSelected) {
      dispatch(changeLocale(form.language));
    }
  }, [form]);

  React.useEffect(() => {
    if (form) {
      setProfile({
        name: form.user ? `${form.user.firstName} ${form.user.lastName}` : "",
        avatar: form.user && form.user.avatar ? form.user.avatar.url : "",
        team: form.team ? form.team.name : "",
        project: form.project ? `${form.projectId}-${form.id}` : "",
      });
    }
  }, [form]);

  const handleShowQuestions = () => {
    setShowQuestions(true);
    setTimeout(() => {
      if (questionsRef && questionsRef.current) {

        questionsRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100);
  };

  React.useEffect(() => {
    if (form.passedVideo && status !== SUBMISSION_STATUS.COMPLETED) {
      handleShowQuestions();
    }
  }, [form.passedVideo, handleShowQuestions, status]);

  const handleResetAnswer = () => {
    setShowQuestions(true);
    onResetAnswers();
  };

  const handleClickStartMemo = useCallback(() => {
    if (
      contact &&
      contact.firstName &&
      contact.lastName
    ) {
      setUpdated(true);
      setTimeout(() => {
        dispatch(
          updateFields({
            firstName: contact.firstName,
            lastName: contact.lastName,
          })
        );
      }, 400);
    } else {
      history.push(`${baseUrl}/welcome`);
    }
  }, [contact]);

  const renderTitleAndHint = () => {
    return loading ? null : (
      <div className={cls(style.titleContainer, style.flexColumn)}>
        <PageTitle variant="h2" className={style.title}>
          <FormattedMessage
            id={"individual.memoPage.title"}
            values={{
              name: (
                <span>
                  memo<span className={style.domain}>.do</span>
                </span>
              ),
            }}
          />
        </PageTitle>
        <p className={style.hint}>
          <FormattedMessage
            id={"individual.memoPage.hint"}
            values={{
              sender: `${form.user && form.user.firstName} ${form.user && form.user.lastName
                }`,
            }}
          />
        </p>
      </div>
    );
  };

  const MemoStartButton = () => {
    return loading ? null : (
      <Button
        onClick={handleClickStartMemo}
        className={cls(style.centered, style.startButton)}
        type="primary"
      >
        <FormattedMessage id="individual.memoPage.start" />
      </Button>
    );
  };

  const renderPlayer = () => {
    if (!ended || showQuestions || completed) {
      return (
        <React.Fragment>
          <EmbedPlayer
            className={style.embed}
            playable={!!isInteractive}
            embed={embed}
            canSeek={false}
            onEnded={onEnded}
            hideSkipPopover={isMobile}
            isSkippable={ended || completed || _.get(form, "submission.id")}
            memoId={params.memoId}
            downloadable={false}
            resumedTime={form.resumedTime}
          />
          {!showQuestions && isMobile && !completed && (
            <StickyNotificaton>
              <FormattedMessage id="video.cannotSkip" />
            </StickyNotificaton>
          )}
        </React.Fragment>
      );
    }

    return (
      <DummyPlayer
        className={style.dummyPlayer}
        backgroundUrl={thumbnailUrl}
        thumbnailUrlId={thumbnailUrlId}
      >
        {!isMobile && <ShowQuestions onShowQuestions={handleShowQuestions} />}
      </DummyPlayer>
    );
  };

  const renderPlayerSection = () => {
    if (loading || !form.id) {
      return (
        <div className={cls(style.centered)}>
          <LoadingMemoAnimation />
        </div>
      );
    }
    if (!isInteractive) {
      if (isMobile) {
        return (
          <React.Fragment>
            {renderTitleAndHint()}
            <Card
              className={cls(style.centered, style.component)}
              bodyStyle={{ padding: 0 }}
              bordered={false}
            >
              {/* {status === SUBMISSION_STATUS.COMPLETED && (
                <div className={style.successMessage}>
                  <FormattedMessage id={"individual.memoPage.success"} />
                </div>
              )} */}
              <Profile
                profile={profile}
                completed={status === SUBMISSION_STATUS.COMPLETED}
              />
              <DummyPlayer
                className={style.dummyPlayer}
                backgroundUrl={thumbnailUrl}
                thumbnailUrlId={thumbnailUrlId}
              />
              <MemoFooter
                memo={form}
                projectName={(form.project && form.project.name) || ""}
                createdAt={form.createdAt}
                isDefaultProject={form.project && form.project.isDefault}
              />
            </Card>
            <p className={style.questionNote}>
              <FormattedMessage id={"individual.memoPage.questionNote"} />
            </p>
            <MemoStartButton />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <Card
              className={cls(style.centered, style.component)}
              bodyStyle={{ padding: 0 }}
              bordered={false}
            >
              {/* {status === SUBMISSION_STATUS.COMPLETED && (
                <div className={style.successMessage}>
                  <FormattedMessage id={"individual.memoPage.success"} />
                </div>
              )} */}
              <Profile
                profile={profile}
                completed={status === SUBMISSION_STATUS.COMPLETED}
              />
              <DummyPlayer
                className={style.dummyPlayer}
                backgroundUrl={thumbnailUrl}
                thumbnailUrlId={thumbnailUrlId}
              >
                <div
                  className={`${udpated
                    ? style.disappearAnimation
                    : style.animationContainer
                    }`}
                >
                  {renderTitleAndHint()}
                  <MemoStartButton />
                </div>
              </DummyPlayer>
              <MemoFooter
                memo={form}
                projectName={(form.project && form.project.name) || ""}
                createdAt={form.createdAt}
                isDefaultProject={form.project && form.project.isDefault}
              />
            </Card>
            <p className={style.questionNote}>
              <FormattedMessage id={"individual.memoPage.questionNote"} />
            </p>
          </React.Fragment>
        );
      }
    } else {
      return (
        <React.Fragment>
          <Card
            className={cls(style.centered, style.component)}
            bodyStyle={{ padding: 0 }}
            bordered={false}
          >
            {/* {status === SUBMISSION_STATUS.COMPLETED && (
              <div className={style.successMessage}>
                <FormattedMessage id={"individual.memoPage.success"} />
              </div>
            )} */}
            <Profile
              profile={profile}
              completed={status === SUBMISSION_STATUS.COMPLETED}
            />
            {renderPlayer()}
            <MemoFooter
              memo={form}
              projectName={(form.project && form.project.name) || ""}
              isDefaultProject={form.project && form.project.isDefault}
              createdAt={form.createdAt}
            />
          </Card>
          <p className={style.questionNote}>
            <FormattedMessage id={"individual.memoPage.questionNote"} />
          </p>
          {isMobile && !showQuestions && ended && (
            <ShowQuestions onShowQuestions={handleShowQuestions} />
          )}
        </React.Fragment>
      );
    }
  };

  return (
    <div className={cls(style.container, className, { completed })}>
      {renderPlayerSection()}
      {isInteractive && (showQuestions || completed) ? (
        <>
          {(ended || form.passedVideo || completed || status === SUBMISSION_STATUS.RESET) && (
            <div
              ref={questionsRef}
              className={cls(style.centered, style.questions)}
            >
              <MemoQuestions
                onFieldsChange={onAnswersChange}
                questions={questions}
                answers={answers}
              />
            </div>
          )}
          {form.id && (
            <MemoFooterIN
              ended={ended || status === SUBMISSION_STATUS.RESET}
              completed={completed}
              resetAnswers={handleResetAnswer}
              answers={answers}
              sender={`${form.user && form.user.firstName} ${form.user && form.user.lastName
                }`}
            />
          )}
        </>
      ) : null}
    </div>
  );
};

export default memo(MemoPage);
