import { Avatar, Popover, Spin } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import _ from 'lodash'
import style from './SideBar.module.scss'
import ProfilePopover from 'pages/profile/ProfilePopover'
import { loadOne as loadTeam } from 'pages/admin/teams/reducer'
import { getThumbnail } from 'utils'
import ProjectsList from 'pages/admin/projects/ProjectsList'
import { UserName } from 'pages/profile/UserItems'
import AppLogo from '../AppLogo'
import AssistanceIcon from 'components/icons/AssistanceIcon'
import ContactsIcon from 'components/icons/ContactsIcon'
import { FormattedMessage } from 'react-intl'

export const TeamName = () => {
  const { user } = useSelector(s => s.session)
  const { loading, form } = useSelector(s => s.adminTeams)
  const dispatch = useDispatch()

  useEffect(() => {
    if (user.role !== 'creator')
      return

    dispatch(loadTeam(user.teamId))
  }, [])

  if (user.role !== 'creator')
    return null

  return (
    <div className={style.teamName}>
      {form.id && (
        <React.Fragment>
          <Avatar
            className={style.teamLogo}
            src={getThumbnail(form.logo)} />
          <span>{form.name}</span>
        </React.Fragment>
      )}
    </div>
  )
}

export default function SideBar() {
  const { user } = useSelector(s => s.session)

  return (
    <aside className={style.component}>
      <header className={style.header}>
        <AppLogo wrapperClassName={style.appLogo} isWithName={false} />
        <TeamName />
      </header>
      <main className={style.main}>
        <div className={`${style.projectsContainer}`}>
          {user.role === 'creator' && <ProjectsList />}
        </div>
        <div className={style.contactsContainer}>
          <Link
            className={style.contactsLink}
            to="/app/contactlist">
            <div className={style.contactsIcon}>
              <ContactsIcon />
            </div>
            <FormattedMessage id="navBar.contacts" />
          </Link>
        </div>
        <div className={style.assistanceContainer}>
          <Link
            className={style.assistanceLink}
            to="/app/assistance">
            <div className={style.assistanceIcon}>
              <AssistanceIcon />
            </div>
            <FormattedMessage id="navBar.assistance" />
          </Link>
        </div>

      </main>
      <footer className={style.footer}>
        <Popover
          overlayClassName={style.popover}
          trigger="hover"
          placement="topLeft"
          className={style.user}
          content={<ProfilePopover />}>
          <Avatar
            src={_.get(user, 'avatar.url')}
            className={style.avatar} />
          <UserName />
        </Popover>
      </footer>
    </aside>
  )
}